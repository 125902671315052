import React from 'react';

const AddTestKitGreenIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.59778 0.0182196C1.89494 -0.0170504 2.19777 0.0129291 2.49741 0.00305352C2.50663 4.03936 2.49848 8.07566 2.50167 12.112C2.49848 13.6078 2.50663 15.1039 2.49741 16.5994C2.19813 16.5877 1.89494 16.6202 1.59778 16.5839C1.4914 16.5775 1.38325 16.5786 1.28042 16.5472C1.21836 16.5317 1.15737 16.513 1.09638 16.4936C1.0517 16.4728 1.00631 16.4537 0.961273 16.4343C0.80844 16.3617 0.66518 16.2685 0.540005 16.155C0.53114 16.1447 0.51341 16.1239 0.504545 16.1133L0.489297 16.1148C0.411285 16.0146 0.320506 15.9239 0.25455 15.8146C0.23221 15.7758 0.209161 15.7374 0.186112 15.6993C0.158098 15.6439 0.131858 15.5878 0.109163 15.5303C0.029023 15.3293 -0.00253674 15.1124 0.000300079 14.8972C-5.45237e-05 10.5001 -5.45237e-05 6.10301 0.000300079 1.70589C-0.00360055 1.49004 0.0304413 1.27454 0.10739 1.07244C0.140368 0.998374 0.175119 0.925365 0.206679 0.850592C0.213771 0.848829 0.22831 0.844949 0.235402 0.843186L0.230792 0.816028C0.309159 0.700695 0.400292 0.594885 0.488588 0.487311L0.504545 0.488369C0.513056 0.478141 0.530786 0.457332 0.539651 0.447103C0.699931 0.297911 0.894253 0.192101 1.09602 0.109216C1.15737 0.0894651 1.21907 0.0697139 1.28148 0.053137C1.38361 0.0217466 1.49211 0.024921 1.59778 0.0182196Z"
      fill="#19D3C5"
    />
    <path
      d="M4.16504 0.0199503C4.26397 -0.00791299 4.36752 0.00407883 4.46893 0.00231532C8.25574 0.00231532 12.0425 0.00302072 15.829 0.00196262C15.8368 5.52807 15.8308 11.0545 15.8322 16.5806C11.944 16.581 8.05539 16.581 4.16717 16.581C4.16539 11.0605 4.16929 5.54006 4.16504 0.0199503ZM8.34439 4.15466C8.34013 4.98033 8.34332 5.80565 8.34261 6.63097C8.32843 6.62956 8.29971 6.62638 8.28552 6.62462L8.28056 6.65424C8.08198 6.64649 7.88305 6.65989 7.68483 6.64719L7.6483 6.60557L7.64617 6.65389C7.11533 6.64649 6.58378 6.65954 6.0533 6.64754L6.01713 6.60451L6.01571 6.65354C5.95401 6.65248 5.89302 6.65283 5.83203 6.65319C5.83628 7.75749 5.82777 8.86215 5.83628 9.96681C6.6657 9.96469 7.49547 9.9661 8.32488 9.96575C8.32453 10.7911 8.32666 11.616 8.32382 12.4414C9.44082 12.4473 10.5582 12.4473 11.6748 12.4414C11.673 11.616 11.6745 10.7911 11.6741 9.96575C12.5039 9.9661 13.3333 9.96504 14.1631 9.96645C14.1719 8.86215 14.1631 7.75784 14.1677 6.65389C13.4985 6.65072 12.8294 6.65142 12.1606 6.65354C12.1393 6.64613 12.0968 6.63202 12.0755 6.62497L12.0745 6.65495C11.9603 6.65142 11.8464 6.65142 11.7326 6.65213C11.7124 6.64366 11.6716 6.62709 11.6514 6.61862C11.6539 6.59534 11.6596 6.54914 11.6624 6.52586L11.7011 6.50117C11.6209 6.48107 11.6699 6.38231 11.656 6.32553C11.6567 5.60179 11.6581 4.8784 11.655 4.15466C10.5514 4.15642 9.44791 4.15607 8.34439 4.15466Z"
      fill="#19D3C5"
    />
    <path
      d="M17.5019 0.0026179C17.8012 0.0128462 18.1044 -0.0178387 18.4019 0.017784C18.5083 0.024838 18.6171 0.0216637 18.7203 0.0537595C18.782 0.0699837 18.843 0.0893822 18.9044 0.10737C19.0916 0.185317 19.2756 0.280546 19.4246 0.41951L19.4522 0.418099L19.4476 0.444904C19.4593 0.453016 19.4831 0.468535 19.4951 0.476295C19.5682 0.552125 19.6338 0.634657 19.6997 0.716836C19.7146 0.740114 19.7295 0.763393 19.7448 0.786671C19.7668 0.824763 19.7894 0.862854 19.8125 0.900946C19.8409 0.957025 19.8675 1.01416 19.8916 1.07236C19.9033 1.10763 19.9153 1.1429 19.9281 1.17782C20.0189 1.46244 19.9969 1.76365 19.9994 2.0578C19.999 6.33782 19.9997 10.6178 19.999 14.8979C20.0019 15.1123 19.9682 15.3274 19.8909 15.5285C19.8515 15.628 19.8026 15.7232 19.7441 15.8131C19.6781 15.9225 19.5891 16.0145 19.5104 16.1143L19.4951 16.1133C19.4863 16.1235 19.4689 16.144 19.46 16.1542C19.3338 16.2685 19.1902 16.363 19.0352 16.4346C18.9912 16.455 18.9469 16.4751 18.9037 16.497C18.881 16.4991 18.8356 16.5037 18.8129 16.5058L18.8139 16.5284C18.3842 16.6339 17.9395 16.588 17.5016 16.6C17.493 12.4931 17.5005 8.38595 17.4977 4.27911C17.5008 2.8535 17.4923 1.42788 17.5019 0.0026179Z"
      fill="#19D3C5"
    />
  </svg>
);
export default AddTestKitGreenIcon;
