import React, { useContext, useState } from 'react';
import Select from 'react-select';

import {
  FilterIcon,
} from 'assets/images/svg-icons';

import FilterForm from 'components/shared/filter/components/filter-form';
import { LookupContext } from 'context/lookup-context/lookup-context';

const Filter = (props) => {
  const {
    filters = [],
    onFilter = null,
    defaultValues = {},
    onPerPageChange,
    meta,
    addButton,
    clearFilter,
  } = props;

  const [currentFilters, setCurrentFilters] = useState(null);
  const lookupData = useContext(LookupContext);

  const dropFilter = () => {
    const filter = document.querySelector('.table__filter-inner');
    filter.classList.toggle('table__filter-inner--active');
  };

  const handlePerPageChange = (option) => {
    onPerPageChange((option?.value || 1), 1);
  };

  return (
    <div className="table__filter">
      <button
        type="button"
        className="button-icon button-icon--filter button-icon--blue"
        onClick={dropFilter}
      >
        <FilterIcon />
        <span className="button-icon__text">Filter</span>
      </button>
      <div className="table__filter-inner">
        <FilterForm
          filters={filters}
          onFilter={(filter, rowFilter, isResetFilter) => {
            onFilter(filter, rowFilter, isResetFilter);
            setCurrentFilters(rowFilter);
          }}
          defaultValues={defaultValues}
          currentFilters={currentFilters}
          clearFilter={clearFilter}
        />
      </div>
      <div className="table__show-count">
        {
          addButton || ''
        }
        <Select
          options={lookupData?.lookupDetails?.rowsPerPage}
          className="selectbox"
          placeholder={meta?.per_page || 1}
          onChange={handlePerPageChange}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
