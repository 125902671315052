import React from 'react';

const PendingSchedulingIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="5" fill="#A5C5ED" />
    <path
      d="M14.1524 5.85208C13.3319 5.03106 12.2863 4.47182 11.1479 4.2451C10.0096 4.01839 8.8295 4.13438 7.75705 4.57841C6.6846 5.02243 5.76793 5.77455 5.12298 6.73961C4.47803 7.70468 4.13379 8.83934 4.13379 10.0001C4.13379 11.1608 4.47803 12.2955 5.12298 13.2606C5.76793 14.2256 6.6846 14.9777 7.75705 15.4218C8.8295 15.8658 10.0096 15.9818 11.1479 15.7551C12.2863 15.5283 13.3319 14.9691 14.1524 14.1481C14.7007 13.6054 15.136 12.9594 15.4331 12.2474C15.7302 11.5354 15.8832 10.7716 15.8832 10.0001C15.8832 9.2286 15.7302 8.46477 15.4331 7.75278C15.136 7.04079 14.7007 6.39477 14.1524 5.85208V5.85208ZM13.4044 13.4041C12.7324 14.0737 11.8771 14.5292 10.9465 14.7131C10.0158 14.8969 9.05156 14.8009 8.17545 14.4371C7.29934 14.0733 6.55066 13.4581 6.02397 12.6691C5.49727 11.8801 5.21618 10.9527 5.21618 10.0041C5.21618 9.05544 5.49727 8.12807 6.02397 7.33908C6.55066 6.55008 7.29934 5.93485 8.17545 5.57106C9.05156 5.20727 10.0158 5.11124 10.9465 5.2951C11.8771 5.47896 12.7324 5.93446 13.4044 6.60408C13.8519 7.05002 14.2069 7.5799 14.4492 8.16335C14.6915 8.7468 14.8162 9.37234 14.8162 10.0041C14.8162 10.6358 14.6915 11.2614 14.4492 11.8448C14.2069 12.4283 13.8519 12.9581 13.4044 13.4041ZM10.5364 10.2281V7.30008C10.5364 7.16005 10.4808 7.02575 10.3817 6.92673C10.2827 6.82771 10.1484 6.77208 10.0084 6.77208C9.86836 6.77208 9.73406 6.82771 9.63504 6.92673C9.53602 7.02575 9.48039 7.16005 9.48039 7.30008V10.1281L8.07639 11.5321C8.0268 11.5805 7.9874 11.6384 7.9605 11.7023C7.93359 11.7661 7.91973 11.8348 7.91973 11.9041C7.91973 11.9734 7.93359 12.042 7.9605 12.1059C7.9874 12.1698 8.0268 12.2276 8.07639 12.2761C8.12475 12.3257 8.1826 12.3651 8.24652 12.3919C8.31043 12.4187 8.37909 12.4324 8.44839 12.4321C8.58815 12.4314 8.72193 12.3753 8.82039 12.2761L10.3804 10.7161C10.4356 10.6596 10.4775 10.5915 10.5031 10.5168C10.5287 10.442 10.5373 10.3626 10.5284 10.2841C10.5333 10.2658 10.536 10.247 10.5364 10.2281V10.2281Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
  </svg>
);
export default PendingSchedulingIcon;
