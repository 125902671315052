import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import Loader from 'components/shared/loader';
import ButtonWithLoader from 'components/shared/loading-button';
import SelectField from 'components/shared/form-fields/select-field';
import CustomNumberField from 'components/shared/form-fields/custom-number-field';

import { getLabPayoutInfo, updateLabPayoutInfo } from 'services/lab-management';
import catchHandler from 'helpers/catch-handler';
import { LookupContext } from 'context/lookup-context/lookup-context';

const schema = Yup.object().shape({
  routing_number: Yup.string()
    .required('Routing number is required')
    .min(9, 'Enter a valid 9 digit number'),
  account_number: Yup.string().required('Account number is required'),
  name_on_bank: Yup.string().required('Name on bank is required'),
  business_street_address: Yup.string().required(
    'Business street address is required',
  ),
  business_state: Yup.object().required('State is required').nullable(),
  business_city: Yup.string().required('Business city is required'),
  business_zip: Yup.string().required('Zip is required'),
  business_name: Yup.string().required('Business name is required'),
  business_tax_id: Yup.string().required('Tax Id is required'),
  account_holder_type: Yup.object()
    .required('Account holder type is required')
    .nullable(),
});

const buildFormData = (data) => {
  const formData = {
    routing_number: data.routing_number || '',
    account_number: data.account_number || '',
    name_on_bank: data.name_on_bank || '',
    business_street_address: data.business_street_address || '',
    ...(!isEmpty(data?.business_state)
      ? {
        business_state: {
          label: data?.business_state,
          value: data?.business_state,
        },
      }
      : ''),
    business_city: data.business_city || '',
    business_zip: data.business_zip || '',
    business_name: data.business_name || '',
    business_website: data.business_website || '',
    business_tax_id: data.business_tax_id || '',
    ...(!isEmpty(data?.account_holder_type)
      ? {
        account_holder_type: {
          label: data?.account_holder_type,
          value: data?.account_holder_type,
        },
      }
      : ''),
  };
  return formData;
};

const LabPayoutInfo = () => {
  const { labId } = useParams();
  const { lookupDetails } = useContext(LookupContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [labPaymentInfo, setLabPaymentInfo] = useState({});

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(labPaymentInfo),
    resolver: yupResolver(schema),
  });

  const { setError, reset } = methods;

  const fetchLabPaymentInfo = useCallback(() => {
    if (labId) {
      setIsLoading(true);
      getLabPayoutInfo(labId)
        .then((response) => {
          setLabPaymentInfo(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [labId]);

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      business_state: formData?.business_state?.value,
      account_holder_type: formData?.account_holder_type?.value,
    };
    setIsButtonLoading(true);
    updateLabPayoutInfo(labId, payload)
      .then((res) => {
        toast.success(
          res?.data?.message || 'Lab payment details updated successfully',
        );
        setIsButtonLoading(false);
        fetchLabPaymentInfo();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        toast.error('Failed to update details');
        catchHandler(error, setError, true);
      });
  };

  useEffect(() => {
    if (!isEmpty(labPaymentInfo)) {
      reset(buildFormData(labPaymentInfo));
    }
  }, [reset, labPaymentInfo]);

  useEffect(() => {
    fetchLabPaymentInfo();
  }, [fetchLabPaymentInfo]);

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <Loader />
      ) : (
        <Form className="tab__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="tab__form-sec">
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Routing Number</Label>
                <CustomNumberField
                  className="form-control"
                  name="routing_number"
                  format="#########"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Account Number</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="account_number"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Name on the Bank Account</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="name_on_bank"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Account Holder Type</Label>
                <SelectField
                  name="account_holder_type"
                  options={lookupDetails?.accountType}
                  className="selectbox"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Business Street Address</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="business_street_address"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Suite</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="suite"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>State</Label>
                <SelectField
                  name="business_state"
                  options={lookupDetails?.stateOptions}
                  className="selectbox"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>City</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="business_city"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Zip</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="business_zip"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Business Name</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="business_name"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Business Tax ID</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="business_tax_id"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Business Web Site</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="business_web_site"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="tab__btn d-flex justify-content-center align-items-center">
            <ButtonWithLoader isLoading={isButtonLoading} color="secondary">
              Save Changes
            </ButtonWithLoader>
          </div>
        </Form>
      )}
    </FormProvider>
  );
};

export default LabPayoutInfo;
