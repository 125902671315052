import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

import Loader from 'components/shared/loader';
import ListingCard from 'components/shared/listing-card';

import {
  OrderIcon,
  RevenueIcon,
  LabIcon,
  ConsumerIconYellow,
  SellingKitIcon,
  PendingLabIcon,
  LatestOrdersIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import getDashboardDetails from 'services/dashboard';

Chart.register(...registerables);
let gradient;

const Dashboard = () => {
  const [, setHeader] = useContext(HeaderText);

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setHeader('Dashboard');
  }, [setHeader]);

  const pendingLabsMapping = [
    {
      key: 'lab_name',
      render: (item) => (
        <Link
          to={`/lab-management${item?.lab_id ? `/${item.lab_id}` : ''}`}
          className="inline_link m-0"
        >
          {item.lab_name}
        </Link>
      ),
    },
    {
      key: 'created_at',
    },
  ];

  const latestOrdersMapping = [
    {
      key: 'order_id',
      render: (item) => (
        <Link
          to={`/orders${item?.order_id ? `/${item.order_id}` : ''}`}
          className="text"
        >
          #
          {item.order_id}
        </Link>
      ),
    },
    {
      key: 'order_content',
    },
  ];

  const topSellingKitMappings = [
    {
      key: 'kit_name',
    },
    { key: 'count' },
  ];

  if (document.getElementsByTagName('canvas')[0]) {
    const ctx = document.getElementsByTagName('canvas')[0].getContext('2d');
    gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#DAE4F4');
    gradient.addColorStop(0.2, '#E2EAF8');
    gradient.addColorStop(0.45, '#F6F6FC');
    gradient.addColorStop(1, '#FFFFFF');
  }

  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: 'Revenue',
        fill: true,
        lineTension: 0.2,
        backgroundColor: gradient,
        borderColor: '#0048B5',
        borderCapStyle: 'round',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#fff',
        pointBackgroundColor: '#0048B5',
        pointBorderWidth: 5,
        pointRadius: 8,
        data: chartData,
      },
    ],
  };

  const options = {
    animation: true,
    animationEasing: 'easeInOutQuart',
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setIsLoading(true);
    getDashboardDetails()
      .then((response) => {
        setDashboardData(response.data);
        const graphData = response.data?.revenue_graph_data?.data;
        graphData?.forEach((item) => {
          setChartLabel((prevState) => prevState.concat(item?.date));
          setChartData((prevState) => prevState.concat(item?.revenue));
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              <Row
                className="dashboard__whitebox dashboard__strip"
                xs="1"
                md="2"
                xl="4"
              >
                <Col className="dashboard__strip-outer">
                  <OrderIcon className="dashboard__strip-icon" />
                  <div className="dashboard__strip-content">
                    <div className="dashboard__strip-head">
                      {dashboardData?.total_orders || 0}
                    </div>
                    <div className="dashboard__strip-text">Total Orders</div>
                  </div>
                </Col>
                <Col className="dashboard__strip-outer">
                  <RevenueIcon className="dashboard__strip-icon" />
                  <div className="dashboard__strip-content">
                    <div className="dashboard__strip-head">
                      $
                      {' '}
                      {dashboardData?.total_revenue || 0}
                    </div>
                    <div className="dashboard__strip-text">Total Revenue</div>
                  </div>
                </Col>
                <Col className="dashboard__strip-outer">
                  <LabIcon className="dashboard__strip-icon" />
                  <div className="dashboard__strip-content">
                    <div className="dashboard__strip-head">
                      {dashboardData?.total_labs || 0}
                    </div>
                    <div className="dashboard__strip-text">Total Labs</div>
                  </div>
                </Col>
                <Col className="dashboard__strip-outer">
                  <ConsumerIconYellow className="dashboard__strip-icon" />
                  <div className="dashboard__strip-content">
                    <div className="dashboard__strip-head">
                      {dashboardData?.total_consumers || 0}
                    </div>
                    <div className="dashboard__strip-text">Total Consumers</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="dashboard__card-outer">
            <Col xs="12" xl="8">
              <div className="dashboard__whitebox dashboard__graph">
                <div className="dashboard__graph-cont">
                  <div className="dashboard__graph-head">
                    {dashboardData?.total_revenue ? (
                      <div>
                        $
                        {dashboardData?.total_revenue}
                      </div>
                    ) : (
                      <div> $ 0 </div>
                    )}
                    <span>Total Revenue</span>
                  </div>
                  <div className="dashboard__graph-sub-text">Past 30 days</div>
                </div>
                <div className="dashboard__graph-image">
                  <Line data={data} options={options} height={80} />
                </div>
              </div>
            </Col>
            <Col xs="12" xl="4">
              <ListingCard
                icon={<SellingKitIcon />}
                title="Month Top Selling Kits"
                dataSource={dashboardData?.monthly_top_selling_kits}
                mappings={topSellingKitMappings}
                primaryKey="kit_name"
              />
            </Col>
          </Row>
          <Row className="dashboard__card-outer">
            <Col xs="12" xl="4">
              <ListingCard
                icon={<PendingLabIcon />}
                title="Pending Labs"
                actionLink={{ title: 'View All Labs', to: '/lab-management' }}
                dataSource={dashboardData?.pending_labs}
                mappings={pendingLabsMapping}
                primaryKey="lab_id"
              />
            </Col>
            <Col xs="12" xl="8">
              <ListingCard
                className="mhl-card__table mhl-card__table--latest-Orders"
                icon={<LatestOrdersIcon />}
                title="Latest Orders"
                actionLink={{ title: 'View All Orders', to: '/orders' }}
                dataSource={dashboardData?.latest_orders}
                mappings={latestOrdersMapping}
                primaryKey="order_id"
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

export default Dashboard;
