import React from 'react';

const DotIcon = (props) => (
  <svg
    {...props}
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1" cy="1" r="1" fill="#444444" />
    <circle cx="7" cy="1" r="1" fill="#444444" />
    <circle cx="13" cy="1" r="1" fill="#444444" />
  </svg>
);
export default DotIcon;
