import React from 'react';

const FoodIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="28.843"
    height="29.422"
    viewBox="0 0 28.843 29.422"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#0052d7" />
        <stop offset="1" stopColor="#0047ba" />
      </linearGradient>
    </defs>
    <g id="food-icon" transform="translate(-0.038 -0.311)">
      <path
        id="Path_20"
        data-name="Path 20"
        d="M6.6.318c.413-.009.826-.009,1.238,0,.163,0,.319.062.482.064a7.112,7.112,0,0,1,3.446,1.541,7.385,7.385,0,0,1,1.956,2.495,7.481,7.481,0,0,1,.6,1.779c0,.148.07.284.068.432s.038.3.057.445c.01.4.012.8,0,1.2-.035.187-.045.379-.071.568a2.221,2.221,0,0,0-.066.483c-.011-.065-.018-.129-.027-.194A1.609,1.609,0,0,1,14.2,9.6a7.364,7.364,0,0,1-3.558,4.553,6.9,6.9,0,0,1-1.825.692c-.142.045-.292.043-.434.091-.182.02-.366.035-.547.069-.353.015-.706.01-1.059.006-.186,0-.366-.056-.551-.063-.136,0-.263-.066-.4-.066a7.155,7.155,0,0,1-2.752-1.214,7.375,7.375,0,0,1-2.86-4.32A4.568,4.568,0,0,1,.1,8.633a2.26,2.26,0,0,1-.055-.4c-.006-.423-.009-.846,0-1.268.053-.333.088-.67.171-1A7.356,7.356,0,0,1,2.571,2.051,7.116,7.116,0,0,1,6.159.381C6.306.373,6.449.328,6.6.318Zm.088,2.968a2.089,2.089,0,0,0-.444.076,4.265,4.265,0,0,0-1.851.979,5.649,5.649,0,0,0-.543.58A4.449,4.449,0,0,0,2.994,6.8a1.064,1.064,0,0,0-.035.382L2.93,7.139a1.231,1.231,0,0,1-.016.271,4.443,4.443,0,0,0,.05.971,5.381,5.381,0,0,0,.174.684,4.385,4.385,0,0,0,3.643,2.992c.326.007.654.014.979-.005.2-.041.4-.067.6-.125a4.336,4.336,0,0,0,2.413-1.709,4.53,4.53,0,0,0,.786-2.107q.012-.466,0-.931a5.222,5.222,0,0,0-.247-1.049A4.462,4.462,0,0,0,9.488,3.884a4.409,4.409,0,0,0-1.567-.592c-.248-.012-.493-.06-.741-.062C7.016,3.257,6.848,3.256,6.684,3.286Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M18.54,3.266a.912.912,0,0,1,.52.006,1.462,1.462,0,0,1,1.154,1.191c.008.167.008.336,0,.5a1.509,1.509,0,0,1-.676,1.006,1.649,1.649,0,0,1-.51.2,3.613,3.613,0,0,1-.451,0,1.485,1.485,0,0,1-1.212-1.2c-.009-.169-.008-.338,0-.507A1.475,1.475,0,0,1,18.54,3.266Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_22"
        data-name="Path 22"
        d="M24.084,6.208c.3-.019.6-.012.906-.006a4.367,4.367,0,0,1,3.839,3.658,2.71,2.71,0,0,1,.051.681c0,.2,0,.4-.01.6a5.847,5.847,0,0,1-.235.971,4.405,4.405,0,0,1-2.864,2.735,5.536,5.536,0,0,1-.785.16c-.274.009-.549.009-.824,0a4.374,4.374,0,0,1-3.623-2.77,4.313,4.313,0,0,1-.3-1.413,2.222,2.222,0,0,1,.012-.63c.034-.148.012-.3.06-.448a4.49,4.49,0,0,1,.645-1.59,4.805,4.805,0,0,1,1-1.084,4.393,4.393,0,0,1,1.372-.7A5.427,5.427,0,0,1,24.084,6.208Zm.076,2.981a1.481,1.481,0,0,0-1.028,1.227,1.75,1.75,0,0,0,.041.577,1.476,1.476,0,0,0,1.145,1.064,4.594,4.594,0,0,0,.492,0,1.479,1.479,0,0,0,1.078-.87,2.257,2.257,0,0,0,.131-.583,1.9,1.9,0,0,0-.17-.667,1.468,1.468,0,0,0-1.039-.787A1.973,1.973,0,0,0,24.16,9.189Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M5.626,16.506a1.142,1.142,0,0,1,.547.039,1.481,1.481,0,0,1,1.05,1.17,4.916,4.916,0,0,1,0,.5,1.511,1.511,0,0,1-.708,1.025,1.758,1.758,0,0,1-.456.176,3.08,3.08,0,0,1-.439.008A1.473,1.473,0,0,1,4.373,18.2c-.007-.164-.006-.328,0-.492A1.472,1.472,0,0,1,5.626,16.506Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M14.011,17.984c.345,0,.69-.009,1.034,0,.218.041.441.061.656.116a5.731,5.731,0,0,1,3.163,1.944A5.942,5.942,0,0,1,20.1,22.614c.05.212.069.43.108.644.015.36.009.721,0,1.081,0,.177-.054.347-.066.522a5.934,5.934,0,0,1-2.14,3.64,5.75,5.75,0,0,1-3.058,1.226c-.357.006-.714.01-1.071,0a7.441,7.441,0,0,1-.99-.2,5.828,5.828,0,0,1-3.515-2.878,5.97,5.97,0,0,1-.6-1.762c-.013-.187-.075-.368-.071-.557,0-.356-.008-.712.005-1.068.04-.223.057-.45.113-.67a5.882,5.882,0,0,1,1.73-3.065,5.757,5.757,0,0,1,2.894-1.47C13.635,18.043,13.819,17.979,14.011,17.984Zm-.1,2.977a2.919,2.919,0,0,0-1.595.929,2.96,2.96,0,0,0-.7,1.434c-.014.364-.014.728,0,1.092a3.31,3.31,0,0,0,.214.661,2.917,2.917,0,0,0,1.937,1.638,1.2,1.2,0,0,0,.411.046c.274,0,.549.007.824-.01A2.941,2.941,0,0,0,17.3,24.389c.013-.292.011-.584.006-.876a1.027,1.027,0,0,0-.037-.333,3.021,3.021,0,0,0-.214-.607,2.912,2.912,0,0,0-2.073-1.614C14.624,20.947,14.267,20.943,13.911,20.962Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_25"
        data-name="Path 25"
        d="M24.382,19.451a1.605,1.605,0,0,1,.56.042,1.472,1.472,0,0,1,.961.869,2.508,2.508,0,0,1,.111.489,1.97,1.97,0,0,1-.058.441,1.485,1.485,0,0,1-.842.98,2.3,2.3,0,0,1-.52.125,2.523,2.523,0,0,1-.405-.063A1.476,1.476,0,0,1,23.131,21.1a1.722,1.722,0,0,1,.04-.571A1.46,1.46,0,0,1,24.382,19.451Z"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
);
export default FoodIcon;
