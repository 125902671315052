import { request } from 'helpers/api';

export const getAllRegisteredKits = (params) => request({
  method: 'GET',
  url: '/api/registered-kits',
  params,
});

export const getRegisteredKitDetails = (id) => request({
  method: 'GET',
  url: `/api/registered-kits/${id}`,
});
