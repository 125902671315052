import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ListingComponent from 'components/shared/listing-component';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import AddEditConsumerPhlebotomistModal from 'components/shared/modals/add-edit-consumer-phlebotomist';

import { ApprovedIcon, DotIcon, DeactivateIcon } from 'assets/images/svg-icons';

import { getLabPhlebotomist, deleteLabPhlebotomist } from 'services/lab-management';
import { getPhlebotomistDetailsById, savePhlebotomist, updatePhlebotomist } from 'services/phlebotomist';
import catchHandler from 'helpers/catch-handler';

const LabPhlebotomist = ({ labName }) => {
  const { labId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [labPhlebotomist, setLabPhlebotomist] = useState({});
  const [editItem, setEditItem] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [showPhlebotomistModal, setShowPhlebotomistModal] = useState(false);
  const [isEditModalLoading, setEditModalLoading] = useState(false);
  const [phlebotomistButtonLoading, setPhlebotomistButtonLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [confirmationModal, setConfirmationModal] = useState('');
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Lab Phlebotomist</span>
      </>
    ),
    confirmationText: `Please confirm deleting  ${deleteItem?.first_name} ${deleteItem?.last_name}. This action cannot be undone`,
  };

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
        {' '}
        <span>Lab Phlebotomist</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'active' ? 'deactivating' : 'activating'
    } user ${updateStatusItem?.first_name} ${updateStatusItem?.last_name}.`,
  };

  const fetchLabPhelebotomists = useCallback(() => {
    setIsLoading(true);
    getLabPhlebotomist(labId)
      .then((response) => {
        setLabPhlebotomist(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [labId]);

  const editPhlebotomistHandler = (phlebotomistId) => {
    setEditItem({});
    setEditModal(true);
    setShowPhlebotomistModal(true);
    setEditModalLoading(true);
    getPhlebotomistDetailsById(phlebotomistId)
      .then((response) => {
        setEditItem(response.data);
        setEditModalLoading(false);
      })
      .catch(() => {
        setEditModalLoading(false);
      });
  };
  const formatPayload = (formData) => {
    const getShippingDetails = () => {
      if (isEmpty(editItem)) {
        return {
          ...(formData?.state ? { state: formData?.state?.value } : {}),
          ...(formData?.city ? { city: formData.city } : {}),
          ...(formData?.zip ? { zip: formData.zip } : {}),
          ...(formData?.street_address
            ? { street_address: formData.street_address }
            : {}),
        };
      }
      return {
        state: formData?.state?.value || '',
        city: formData?.city || '',
        zip: formData?.zip || '',
        street_address: formData?.street_address || '',
      };
    };

    const payload = {
      lab_id: labId,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      phone_number: formData?.phone_number,
      password: formData?.password,
      password_confirmation: formData?.password_confirmation,
      ...getShippingDetails(),
    };
    return payload;
  };

  const formSubmitHandler = (formData, setError) => {
    setPhlebotomistButtonLoading(true);
    if (isEmpty(editItem)) {
      savePhlebotomist(formatPayload(formData))
        .then(() => {
          setPhlebotomistButtonLoading(false);
          setShowPhlebotomistModal(false);
          setEditItem({});
          fetchLabPhelebotomists();
        })
        .catch((error) => {
          setPhlebotomistButtonLoading(false);
          catchHandler(error, setError, true);
        });
    } else {
      updatePhlebotomist(formatPayload(formData), editItem?.phlebotomist_id)
        .then(() => {
          setPhlebotomistButtonLoading(false);
          setShowPhlebotomistModal(false);
          fetchLabPhelebotomists();
          setEditItem({});
          setEditModal(false);
        })
        .catch((error) => {
          setPhlebotomistButtonLoading(false);
          catchHandler(error, setError, true);
        });
    }
  };

  const confirmDeletePhlebotomistHandler = (item) => {
    setDeleteItem(item);
    setConfirmationModal('delete-phlebotomist');
  };

  const deleteLabPhlebotomistHandler = () => {
    setIsConfirmationLoading(true);
    deleteLabPhlebotomist(labId, deleteItem?.phlebotomist_id)
      .then(() => {
        setIsConfirmationLoading(false);
        setConfirmationModal('');
        setDeleteItem({});
        fetchLabPhelebotomists();
      })
      .catch(() => {
        setDeleteItem({});
        setIsConfirmationLoading(false);
      });
  };

  const statusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModal('status-update');
  };

  const confirmStatusUpdateHandler = () => {
    setIsConfirmationLoading(true);
    updatePhlebotomist(
      {
        lab_id: labId,
        status:
          updateStatusItem?.status_slug === 'active' ? 'deactivated' : 'active',
      },
      updateStatusItem?.phlebotomist_id,
    )
      .then(() => {
        fetchLabPhelebotomists();
        setUpdateStatusItem({});
        setIsConfirmationLoading(false);
        setConfirmationModal('');
      })
      .catch(() => {
        setIsConfirmationLoading(false);
      });
  };

  useEffect(() => {
    fetchLabPhelebotomists();
  }, [fetchLabPhelebotomists]);

  const getPhlebotomistStatus = (phlebotomistStatus) => {
    switch (phlebotomistStatus) {
      case 'active':
        return <ApprovedIcon className="table__status-icon" />;
      case 'deactivated':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const mappings = [
    {
      key: 'name',
      label: 'Name',
      className: 'text-start',
      render: (item) => (
        <span className="inline__list">
          {item?.first_name}
          {' '}
          {item?.last_name}
        </span>
      ),
    },
    {
      key: 'email',
      label: 'EMAIL ADDRESS',
    },
    {
      key: 'phone_number',
      label: 'PHONE NUMBER',
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getPhlebotomistStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => editPhlebotomistHandler(item?.phlebotomist_id)}
                >
                  Edit User
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => statusUpdateHandler(item)}
                >
                  {item?.status_slug === 'active'
                    ? 'Deactivate User'
                    : 'Activate User'}
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular red"
                  onClick={() => confirmDeletePhlebotomistHandler(item)}
                >
                  Delete User
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  return (
    <div className="tab__table tab__table--top">
      <div className="tab__btn ">
        <Button
          color="secondary"
          onClick={() => setShowPhlebotomistModal(true)}
        >
          Add Phlebotomist
        </Button>
      </div>
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        dataSource={labPhlebotomist}
        primaryKey="phlebotomist_id"
      />
      {showPhlebotomistModal ? (
        <AddEditConsumerPhlebotomistModal
          hideModal={() => {
            setShowPhlebotomistModal(false);
            setEditItem({});
            setEditModal(false);
          }}
          labName={labName}
          editItem={editItem}
          isEditModal={editModal}
          isEditModalLoading={isEditModalLoading}
          isLoading={phlebotomistButtonLoading}
          title="Phlebotomist"
          type="lab"
          onSuccessHandler={(formData, setError) => {
            formSubmitHandler(formData, setError);
          }}
        />
      ) : (
        ''
      )}
      {confirmationModal === 'delete-phlebotomist' ? (
        <ConfirmationModal
          hideModal={() => {
            setConfirmationModal('');
            setDeleteItem({});
          }}
          renderHtml={confirmDeleteHtml}
          type="deleteModal"
          successButtonText="Delete Phlebotomist"
          btnColor="primary"
          isLoading={isConfirmationLoading}
          successHandler={() => deleteLabPhlebotomistHandler()}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
      {confirmationModal === 'status-update' ? (
        <ConfirmationModal
          hideModal={() => {
            setConfirmationModal('');
            setUpdateStatusItem({});
          }}
          renderHtml={confirmDeactivateHtml}
          successButtonText="Confirm"
          successHandler={() => confirmStatusUpdateHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default LabPhlebotomist;
