import { request } from 'helpers/api';

export const getAllNotifications = (params) => request({
  method: 'GET',
  url: '/api/notifications',
  params,
});

export const getLatestNotification = () => request({
  method: 'GET',
  url: '/api/notifications/latest',
});

export const getSidebarStatus = () => request({
  method: 'GET',
  url: '/api/sidebar-status',
});
