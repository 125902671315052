import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

const AsyncSelectField = (props) => {
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const { control } = useFormContext();

  const {
    name: propsName,
    options,
    loadOptions,
    placeholder,
    defaultValue,
    defaultOptions,
    showOptionCheckBoxes,
    ...otherProps
  } = props;

  const inputChangeHandler = (inputValue, event) => {
    if (event.action === 'input-change' || event.action === 'set-value') {
      setSearchKeyWord(inputValue);
    }
  };

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange, onBlur, value, name,
          },
          fieldState: { error },
        }) => (
          <React.Fragment>
            <AsyncSelect
              closeMenuOnSelect
              name={name}
              inputValue={searchKeyWord}
              onInputChange={inputChangeHandler}
              onBlur={onBlur}
              onChange={onChange}
              loadOptions={loadOptions}
              options={options}
              defaultOptions={defaultOptions}
              placeholder={placeholder || ''}
              value={value || placeholder}
              {...otherProps}
            />
            {error && error.message ? (
              <small className="error-color ml-2" style={{ color: 'red' }}>{error.message}</small>
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default AsyncSelectField;
