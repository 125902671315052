const queryParams = (object) => new URLSearchParams(object).toString();

export const generateRandomString = () => Math.random().toString(36).substring(2, 15)
  + Math.random().toString(36).substring(2, 15);

export const generateDropdownOptions = (data, valueParam, labelParam) => (
  (data || []).map((item) => ({
    ...item,
    value: item[valueParam],
    label: item[labelParam],
  }))
);

export const generateDropdownOptionsFromHash = (data) => (
  Object.keys(data || {}).map((key) => ({
    value: key,
    label: data[key],
  }))
);

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return (array || []).reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    initialValue,
  );
};

// To add "All" option in filter field.

export const filterOptions = (data = [], selectAllLabel) => {
  if (selectAllLabel) {
    return [{ slug: '', value: '', label: selectAllLabel }, ...data];
  }
  return data;
};

export function openInANewTab(url) {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getKeyByValue = (object, value) => (
  Object.keys(object).find((key) => object[key] === value)
);

export default queryParams;

export const mergeSchema = (...schemas) => {
  const [first, ...rest] = schemas;
  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first,
  );
  return merged;
};

const isObject = (object) => object != null && typeof object === 'object';

export const objectDeepCompare = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !objectDeepCompare(val1, val2))
      || (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const setLocalStorage = (key, value, append) => {
  let stringValue = value;
  if (typeof value === 'object') {
    stringValue = JSON.stringify(value);
    if (append) {
      stringValue = JSON.stringify({
        ...getLocalStorage(key),
        ...value,
      });
    }
  }

  localStorage.setItem(key, stringValue);
};

export const getLocalStorage = (key) => {
  const storageItem = localStorage.getItem(key);
  try {
    return JSON.parse(storageItem);
  } catch (err) {
    return storageItem;
  }
};
