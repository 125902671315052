import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
} from 'reactstrap';
import ButtonWithLoader from '../loading-button';

const ConfirmationModal = ({
  hideModal,
  successButtonText,
  successHandler,
  btnColor,
  renderHtml,
  isLoading,
  cancelButtonText,
}) => (
  <Modal isOpen toggle={() => hideModal()} centered className="modal__alert">
    <ModalBody>
      <div className="modal__head">{renderHtml?.title}</div>
      <Form className="modal__form">
        <p className="modal__text">{renderHtml?.confirmationText}</p>
        <div className="modal__btn d-flex align-items-center justify-content-center">
          <Button color="primary outline" onClick={() => hideModal()}>
            {cancelButtonText}
          </Button>
          <ButtonWithLoader
            color={btnColor || 'secondary'}
            onClick={() => successHandler()}
            isLoading={isLoading}
          >
            {successButtonText}
          </ButtonWithLoader>
        </div>
      </Form>
    </ModalBody>
  </Modal>
);

export default ConfirmationModal;
