import React, { useContext, useEffect, useState } from 'react';
import { pick, debounce } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Filter from 'components/shared/filter/filter';
import ListingComponent from 'components/shared/listing-component';

import {
  PendingSchedulingIcon,
  ApprovedIcon,
  PendingIcon,
  DeactivateIcon,
  OrderSubmittedIcon,
} from 'assets/images/svg-icons';

import { getAllRegisteredKits } from 'services/registered-kits';
import { getAllConsumers } from 'services/consumers';
import { filterOptions } from 'helpers/utils';
import HeaderText from 'header-context';
import { LookupContext } from 'context/lookup-context/lookup-context';

const RegisteredKits = () => {
  const [, setHeader] = useContext(HeaderText);
  const lookupData = useContext(LookupContext);

  const [isLoading, setIsLoading] = useState(false);
  const [registeredKits, setRegisteredKits] = useState([]);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [defaultOptions, setDefaultOptions] = useState([]);

  const fetchAllOrders = (params) => {
    setIsLoading(true);
    getAllRegisteredKits(params)
      .then((response) => {
        setRegisteredKits(response.data.data);
        setMeta(response.data.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchAllConsumers = async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllConsumers(params);
      const consumers = (res?.data?.data || []).map((data) => ({
        value: data.consumer_id,
        label: data.first_name,
      }));
      setDefaultOptions(consumers);
      if (callback) {
        return callback(consumers);
      }
      return filterOptions(consumers, 'All');
    } catch (e) {
      return [];
    }
  };

  const loadOptionsHandler = (searchKeyWord, callback) => {
    const consumers = fetchAllConsumers(searchKeyWord, callback);
    return consumers;
  };

  const loadOptions = debounce(loadOptionsHandler, 400);

  useEffect(() => {
    fetchAllConsumers();
  }, []);

  useEffect(() => {
    fetchAllOrders(filter);
  }, [filter]);

  useEffect(() => {
    setHeader('Registered Kits');
  }, [setHeader]);

  const getOrderStatus = (status) => {
    switch (status) {
      case 'result-released':
        return <ApprovedIcon className="table__status-icon" />;
      case 'schedule-approved':
        return <OrderSubmittedIcon className="table__status-icon" />;
      case 'scheduled-request':
        return <PendingSchedulingIcon className="table__status-icon" />;
      case 'pending-scheduling':
        return <PendingIcon className="table__status-icon" />;
      case 'schedule-request-declined':
        return <DeactivateIcon className="table__status-icon" />;
      case 'schedule-request-cancelled':
        return <DeactivateIcon className="table__status-icon" />;
      case 'result-rejected':
        return <DeactivateIcon className="table__status-icon" />;
      case 'sample-rejected':
        return <DeactivateIcon className="table__status-icon" />;
      case 'pending-result':
        return <PendingIcon className="table__status-icon" />;
      case 'pending-result-confirmation':
        return <PendingSchedulingIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const mappings = [
    {
      key: 'test_kit_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">{item?.test_kit_id}</span>
      ),
    },
    {
      key: 'test_kit_and_lab_name',
      label: 'TEST KIT',
      render: (item) => (
        <div className="table__main-content">
          <div className="table__main-text">{item?.product_name}</div>
          <div className="table__main-title">{item?.lab_name}</div>
        </div>
      ),
    },
    {
      key: 'registered_by',
      label: 'REGISTERED BY',
      render: (item) => item?.registered_by,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status text-nowrap">
          {getOrderStatus(item?.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'register_date',
      label: 'REGISTERED DATE',
      render: (item) => <>{moment(item?.register_date).format('LL')}</>,
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center ',
      render: (item) => (
        <Link
          className="inline_link link-action"
          to={`/registered-kits/${item?.test_kit_id}`}
        >
          View
        </Link>
      ),
    },
  ];

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap order-table">
      <Filter
        filters={[
          {
            filter_type: 'custom-input',
            name: 'search',
            type: 'text',
            placeholder: 'Search by Order ID, Test Kit, Lab',
            inputProps: {
              className: 'table__searchbox text-box',
            },
          },
          {
            filter_type: 'async-select',
            name: 'registered_by',
            loadOptions,
            defaultOptions: filterOptions(defaultOptions, 'All'),
            placeholder: 'Registered by',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'select',
            name: 'status',
            options: filterOptions(
              lookupData?.lookupDetails?.registeredKitStatus,
              'All Status',
            ),
            placeholder: 'All Status',
            inputProps: {
              className: 'selectbox selectbox--activeplaceholder',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        meta={meta}
      />

      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={registeredKits}
        shouldPaginate
        primaryKey="test_kit_id"
      />
    </div>
  );
};

export default RegisteredKits;
