import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import moment from 'moment';

import {
  PhoneNumberIcon,
  EmailIcon,
  LocationIcon,
} from 'assets/images/svg-icons';

const LabConsumerOrderModal = (props) => {
  const {
    modalData,
    type,
    hideModal,
    viewLabDetails,
  } = props;
  return (
    <Modal isOpen toggle={hideModal} centered className="modal--info">
      <ModalBody>
        <div className="modal__head">{modalData?.name}</div>
        <div className="modal__head-text">
          Registered on
          {' '}
          {modalData?.registeredDate ? moment(modalData?.registeredDate).format('MMMM DD, YYYY') : '-'}
          – Last
          Logged in
          {' '}
          {modalData?.lastLogin ? moment(modalData?.lastLogin).format('MMMM DD, YYYY') : '-'}
        </div>
        <div className="modal__card-outer">
          <div className="inline-card">
            <PhoneNumberIcon className="inline-card__image" />
            <div className="inline-card__content">
              <div className="inline-card__sub-text">Phone Number</div>
              <div className="inline-card__main-text">
                {modalData?.phoneNumber}
              </div>
            </div>
          </div>
          <div className="inline-card">
            <EmailIcon className="inline-card__image" />
            <div className="inline-card__content">
              <div className="inline-card__sub-text">Email Address</div>
              <div className="inline-card__main-text">{modalData?.email}</div>
            </div>
          </div>
          <div className="inline-card">
            <LocationIcon className="inline-card__image" />
            <div className="inline-card__content">
              <div className="inline-card__sub-text">Address</div>
              <div className="inline-card__main-text">
                {modalData?.streetAddress}
              </div>
            </div>
          </div>
        </div>
        <div className="modal__btn">
          <Button color="primary outline" onClick={hideModal}>
            Close
          </Button>
          {type === 'labModal' ? (
            <Button color="secondary" onClick={viewLabDetails}>
              View Details
            </Button>
          ) : (
            ''
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LabConsumerOrderModal;
