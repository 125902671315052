import React from 'react';

const LabIcon = (props) => (
  <svg
    {...props}
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="55" height="55" rx="10" fill="#78818F" />
    <path
      d="M21.6985 13.2485C21.9172 13.0812 22.1946 12.9978 22.4695 13.0004C26.1499 12.9999 29.8303 12.9999 33.5102 13.0004C33.961 12.99 34.4264 13.2371 34.608 13.6587C34.7788 14.0591 34.7647 14.548 34.5331 14.9219C34.2712 15.3425 33.7361 15.4901 33.2645 15.4425C33.2619 18.0928 33.2639 20.743 33.2634 23.3928C33.2509 23.5062 33.3254 23.5979 33.3743 23.6932C34.4545 25.6826 35.5341 27.6725 36.6138 29.6619C37.6221 31.4322 38.6081 33.215 39.6232 34.9812C39.7762 35.2774 39.8835 35.597 39.9459 35.9243C40.0287 36.2993 39.9959 36.6852 39.9803 37.0653C39.9615 37.9862 39.6404 38.9071 39.0297 39.6063C38.7543 39.9212 38.4347 40.2009 38.0724 40.4138C37.8366 40.549 37.6169 40.7168 37.3587 40.8111C36.9626 40.9623 36.5341 41.0048 36.1125 40.9996C30.7324 40.9996 25.3519 40.9996 19.9718 40.9996C19.6995 40.998 19.4263 40.9903 19.1576 40.9411C18.1202 40.781 17.1519 40.1704 16.598 39.2774C16.1415 38.5808 15.9749 37.7293 16.003 36.9074C16.029 36.4847 16.1128 36.0621 16.282 35.6721C18.4231 31.5762 20.5694 27.4819 22.7069 23.3845C22.7313 22.0581 22.7105 20.7291 22.7173 19.4016C22.7167 18.0819 22.7188 16.7622 22.7162 15.4425C22.28 15.4829 21.7995 15.3694 21.5137 15.0167C21.1217 14.5014 21.1701 13.6561 21.6985 13.2485ZM25.1759 15.444C25.1733 18.2316 25.1759 21.0191 25.1743 23.8066C25.1686 23.9532 25.1801 24.1102 25.1088 24.2443C24.6397 25.0704 24.1853 25.9048 23.7266 26.7371C24.1915 26.7252 24.6569 26.7071 25.1212 26.7377C25.7865 26.821 26.4471 26.9681 27.0744 27.2064C28.1478 27.5943 29.1473 28.1604 30.2363 28.5069C31.1562 28.7959 32.177 28.7535 33.0614 28.3645C33.0692 28.35 33.0843 28.32 33.0922 28.3049C32.9355 27.9424 32.7069 27.614 32.5331 27.2592C32.0042 26.3 31.4815 25.3371 30.9511 24.3784C30.8631 24.2179 30.8032 24.0392 30.8074 23.8543C30.8043 21.0507 30.8084 18.2476 30.8053 15.444C28.9287 15.4399 27.0525 15.4399 25.1759 15.444Z"
      fill="#19D3C5"
    />
  </svg>
);
export default LabIcon;
