import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import LabConsumerOrderModal from 'components/shared/modals/order-registered-kit-info-modal';
import Loader from 'components/shared/loader';

import {
  ChevronLeftIcon,
  ConsumerIcon,
  TestKitIcon,
  LabBlueIcon,
  OrderDateIcon,
  OrderSubmittedIcon,
  ApprovedIcon,
  PendingIcon,
  PendingSchedulingIcon,
  DeactivateIcon,
  LocationIcon,
  PhoneNumberIcon,
  ScheduleTimeIcon,
  TimezoneIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { getRegisteredKitDetails } from 'services/registered-kits';
import { generateRandomString } from 'helpers/utils';

const RegisteredKitsDetails = () => {
  const { kitId } = useParams();
  const history = useHistory();
  const [, setHeader] = useContext(HeaderText);

  const [showModal, setShowModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [kitDetails, setKitDetails] = useState({});

  useEffect(() => {
    setIsLoading(true);
    getRegisteredKitDetails(kitId)
      .then((res) => {
        setKitDetails(res?.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [kitId]);

  useEffect(() => {
    setHeader('Registered Kits');
  }, [setHeader]);

  const orderedUserData = {
    name: kitDetails?.ordered_by,
    registeredDate: kitDetails?.ordered_user_registered_on,
    lastLogin: kitDetails?.ordered_user_last_login,
    email: kitDetails?.ordered_user_email,
    phoneNumber: kitDetails?.ordered_user_phone_number,
    streetAddress: kitDetails?.ordered_user_street_address,
  };

  const labModalData = {
    labId: kitDetails?.lab_id,
    name: kitDetails?.lab_name,
    registeredDate: kitDetails?.lab_registered_on,
    lastLogin: kitDetails?.lab_last_login,
    email: kitDetails?.lab_email,
    phoneNumber: kitDetails?.lab_phone_number,
    streetAddress: kitDetails?.lab_street_address,
  };

  const registeredUserData = {
    name: kitDetails?.test_kit_registered_by,
    registeredDate: kitDetails?.kit_user_registered_on,
    lastLogin: kitDetails?.kit_user_last_login,
    email: kitDetails?.kit_user_email,
    phoneNumber: kitDetails?.kit_user_phone_number,
    streetAddress: kitDetails?.kit_user_street_address,
  };

  const getOrderHistory = (orderHistory) => {
    switch (orderHistory) {
      case 'pending-scheduling':
        return <PendingIcon className="inline-card__image" />;
      case 'scheduled-request':
        return <PendingSchedulingIcon className="inline-card__image" />;
      case 'schedule-approved':
        return <OrderSubmittedIcon className="inline-card__image" />;
      case 'pending-result':
        return <PendingIcon className="inline-card__image" />;
      case 'result-released':
        return <ApprovedIcon className="inline-card__image" />;
      case 'schedule-request-declined':
        return <DeactivateIcon className="inline-card__image" />;
      case 'schedule-request-cancelled':
        return <DeactivateIcon className="inline-card__image" />;
      case 'sample-rejected':
        return <DeactivateIcon className="inline-card__image" />;
      case 'result-rejected':
        return <DeactivateIcon className="inline-card__image" />;
      case 'pending-result-confirmation':
        return <PendingSchedulingIcon />;
      default:
        return null;
    }
  };

  const getOrderStatus = (slug, status) => {
    switch (slug) {
      case 'pending-scheduling':
        return (
          <button
            type="button"
            className="button-icon button-icon--yellow cursor-auto"
          >
            <PendingIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'pending-result':
        return (
          <button
            type="button"
            className="button-icon button-icon--yellow cursor-auto"
          >
            <PendingIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'scheduled-request':
        return (
          <button
            type="button"
            className="button-icon button-icon--gray cursor-auto"
          >
            <PendingSchedulingIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'schedule-approved':
        return (
          <button
            type="button"
            className="button-icon button-icon--gray cursor-auto"
          >
            <OrderSubmittedIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'pending-result-confirmation':
        return (
          <button
            type="button"
            className="button-icon button-icon--gray cursor-auto"
          >
            <PendingSchedulingIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'result-released':
        return (
          <button type="button" className="button-icon cursor-auto">
            <ApprovedIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'schedule-request-declined':
        return (
          <button
            type="button"
            className="button-icon button-icon--red cursor-auto"
          >
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'schedule-request-cancelled':
        return (
          <button
            type="button"
            className="button-icon button-icon--red cursor-auto"
          >
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'sample-rejected':
        return (
          <button type="button" className="button-icon button-icon--red">
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'result-rejected':
        return (
          <button type="button" className="button-icon button-icon--red">
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="order-detail registered-kits">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="dashboard__whitebox dashboard__whitebox--wrap order-detail__strip ">
            <div className="details__header-outer">
              <div className="details__header">
                <h1 className="details__header-main">
                  Kit #
                  {kitDetails?.test_kit_id}
                </h1>
              </div>
              <div className="details__header-button">
                <Link
                  to="/registered-kits"
                  className="button-icon button-icon--blue"
                >
                  <ChevronLeftIcon />
                  <span className="button-icon__text">Back to List</span>
                </Link>
                {getOrderStatus(
                  kitDetails?.test_kit_status_slug,
                  kitDetails?.test_kit_status,
                )}
              </div>
            </div>
          </div>
          <div className="order-detail__wraper">
            <Row xs="1" xl="2">
              <Col>
                <div className="order-detail__box">
                  <h2 className="details__sub-head details__sub-head--line">
                    General
                    {' '}
                    <span>Info</span>
                  </h2>
                  <Row className="order-detail__card-outer" xs="1" md="2">
                    <Col
                      className="inline-card cursor-pointer"
                      onClick={() => setShowModal('registeredUserInfo')}
                      role="button"
                    >
                      <ConsumerIcon className="inline-card__image" />
                      <div className="inline-card__content">
                        <div className="inline-card__sub-text">
                          Registered by
                        </div>
                        <div className="inline-card__main-text">
                          {kitDetails?.test_kit_registered_by}
                        </div>
                      </div>
                    </Col>
                    <Col className="inline-card">
                      <OrderDateIcon className="inline-card__image" />
                      <div className="inline-card__content">
                        <div className="inline-card__sub-text">
                          Register Date
                        </div>
                        <div className="inline-card__main-text">
                          {moment(kitDetails?.test_kit_register_date).format(
                            'MMMM DD, YYYY',
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="inline-card "
                      role="button"
                      onClick={() => setShowModal('labInfo')}
                    >
                      <LabBlueIcon className="inline-card__image" />
                      <div className="inline-card__content">
                        <div className="inline-card__sub-text">Lab</div>
                        <div className="inline-card__main-text">
                          {kitDetails?.lab_name}
                        </div>
                      </div>
                    </Col>
                    <Col className="inline-card">
                      <TestKitIcon className="inline-card__image" />
                      <div className="inline-card__content">
                        <div className="inline-card__sub-text">Test Kit</div>
                        <div className="inline-card__main-text">
                          {kitDetails?.test_kit}
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="inline-card cursor-pointer"
                      role="button"
                      onClick={() => setShowModal('orderedUserInfo')}
                    >
                      <ConsumerIcon className="inline-card__image" />
                      <div className="inline-card__content">
                        <div className="inline-card__sub-text">Ordered by</div>
                        <div className="inline-card__main-text">
                          {kitDetails?.ordered_by}
                        </div>
                      </div>
                    </Col>
                    <Col className="inline-card">
                      <OrderDateIcon className="inline-card__image" />
                      <div className="inline-card__content">
                        <div className="inline-card__sub-text">
                          Ordered Date
                        </div>
                        <div className="inline-card__main-text">
                          {moment(kitDetails?.ordered_date).format(
                            'MMMM DD, YYYY',
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="">
                <div className="order-detail__box mb-0">
                  <h2 className="details__sub-head details__sub-head--line">
                    Order
                    {' '}
                    <span>Status</span>
                  </h2>
                  <Row>
                    {kitDetails?.status_history?.map((item) => (
                      <Col
                        key={generateRandomString()}
                        className="inline-card inline-card--single"
                        xs="12"
                      >
                        {getOrderHistory(item?.status_slug)}
                        <div className="inline-card__content">
                          <div className="inline-card__main-text">
                            {item?.status}
                          </div>
                          <div className="inline-card__sub-text">
                            {moment(item?.date).format('MMMM DD, YYYY')}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          {kitDetails?.phlebotomist_schedule_request ? (
            <div className="order-detail__wraper schedule-info">
              <Row xs="1">
                <Col>
                  <div className="order-detail__box mb-0">
                    <h2 className="details__sub-head details__sub-head--line">
                      Schedule
                      {' '}
                      <span>Info</span>
                    </h2>
                    <Row className="order-detail__card-outer" xs="1" md="3">
                      <div className="order-detail__subhed">
                        General Info
                        <hr />
                      </div>
                      <Col className="inline-card cursor-pointer">
                        <ConsumerIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">Consumer</div>
                          <div className="inline-card__main-text">
                            {`${kitDetails?.phlebotomist_schedule_request?.first_name}  ${kitDetails?.phlebotomist_schedule_request?.last_name}`}
                          </div>
                        </div>
                      </Col>
                      <Col className="inline-card">
                        <LocationIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">
                            Consumer Address
                          </div>
                          <div className="inline-card__main-text">
                            {
                              kitDetails?.phlebotomist_schedule_request
                                ?.consumer_address
                            }
                          </div>
                        </div>
                      </Col>
                      <Col className="inline-card ">
                        <PhoneNumberIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">
                            Phone Number
                          </div>
                          <div className="inline-card__main-text">
                            {
                              kitDetails?.phlebotomist_schedule_request
                                ?.phone_number
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="order-detail__card-outer" xs="1" md="3">
                      <div className="order-detail__subhed">
                        Scheduled Date / Time
                        <hr />
                      </div>
                      <Col className="inline-card cursor-pointer">
                        <OrderDateIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">
                            Scheduled Date
                          </div>
                          <div className="inline-card__main-text">
                            {moment(
                              kitDetails?.phlebotomist_schedule_request
                                ?.requested_date,
                            ).format('MMM DD, YYY')}
                          </div>
                        </div>
                      </Col>
                      <Col className="inline-card">
                        <ScheduleTimeIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">
                            Schedule Time
                          </div>
                          <div className="inline-card__main-text">
                            {`${kitDetails?.phlebotomist_schedule_request?.start_time} - ${kitDetails?.phlebotomist_schedule_request?.end_time}`}
                          </div>
                        </div>
                      </Col>
                      <Col className="inline-card ">
                        <TimezoneIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">Timezone</div>
                          <div className="inline-card__main-text">
                            {
                              kitDetails?.phlebotomist_schedule_request
                                ?.timezone_name
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="order-detail__card-outer" xs="1" md="2">
                      <div className="order-detail__subhed">
                        Test Kit Information
                        <hr />
                      </div>
                      <Col className="inline-card cursor-pointer">
                        <TestKitIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">Test Kit</div>
                          <div className="inline-card__main-text">
                            {
                              kitDetails?.phlebotomist_schedule_request
                                ?.product_name
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="order-detail__card-outer " xs="1" md="2">
                      <div className="order-detail__subhed">
                        Phlebotomist
                        <hr />
                      </div>
                      <Col className="inline-card cursor-pointer">
                        <ConsumerIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">
                            Phlebotomist
                          </div>
                          <div className="inline-card__main-text">
                            {
                              kitDetails?.phlebotomist_schedule_request
                                ?.phlebotomist
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            ''
          )}

          {showModal === 'registeredUserInfo' ? (
            <LabConsumerOrderModal
              modalData={registeredUserData}
              hideModal={() => setShowModal('')}
            />
          ) : (
            ''
          )}

          {showModal === 'orderedUserInfo' ? (
            <LabConsumerOrderModal
              modalData={orderedUserData}
              hideModal={() => setShowModal('')}
            />
          ) : (
            ''
          )}

          {showModal === 'labInfo' ? (
            <LabConsumerOrderModal
              modalData={labModalData}
              type="labModal"
              viewLabDetails={() => history.push(`/lab-management/${kitDetails?.lab_id}`)}
              hideModal={() => setShowModal('')}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default RegisteredKitsDetails;
