import React from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

import DotIcon from 'assets/images/dot-icon';
import Loader from './loader';

const ListingCard = (props) => {
  const {
    icon,
    title,
    className = 'mhl-card__table',
    dataSource = [],
    mappings = [],
    actionLink = {},
    actionDropDown = {},
    primaryKey = null,
    isLoading,
  } = props;

  return (
    <div className="dashboard__whitebox mhl-card">
      <div className="mhl-card__header">
        <div className="mhl-card__icon-text">
          {icon}
          <h2>{title}</h2>
        </div>
        {Object.keys(actionLink).length > 0 ? (
          <Link className="inline_link" to={actionLink.to}>
            {actionLink.title}
          </Link>
        ) : (
          ''
        )}
        {Object.keys(actionDropDown).length > 0 ? (
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">{actionDropDown.label}</DropdownItem>
              {actionDropDown.render()}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          ''
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table responsive borderless className={className}>
          {dataSource?.length > 0 ? (
            <tbody>
              {dataSource?.map((data) => (
                <React.Fragment key={data[primaryKey]}>
                  <tr>
                    {mappings
                      && mappings.map((cell) => (
                        <td key={cell.id ? cell.id : cell.key}>
                          {cell.render
                            ? cell.render(data)
                            : data[cell.key || cell]}
                        </td>
                      ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          ) : (
            <caption>
              <div className="no__data__message no__data__message--small">
                No data found
              </div>
            </caption>
          )}
        </Table>
      )}
    </div>
  );
};
export default ListingCard;
