import React from 'react';

const Logo = (props) => (
  <svg
    {...props}
    width="191"
    height="33"
    viewBox="0 0 191 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7062 0.94782C16.4933 0.77146 18.2982 0.939287 20.0824 0.709829C20.6154 0.633027 21.2133 0.700347 21.6326 1.07203C21.8704 1.27209 21.9813 1.57646 22.033 1.87608C22.5435 4.60587 23.1752 7.31195 23.9066 9.98864C25.216 8.58629 26.455 7.11568 27.6066 5.57774C27.9272 5.15107 28.227 4.66371 28.7281 4.4314C29.357 4.11282 30.1974 4.32047 30.5979 4.90833C30.9175 5.34829 30.9513 5.9096 31.0293 6.43015C31.4505 9.18649 32.384 11.8546 33.7019 14.3028C34.0385 14.8935 33.9905 15.6919 33.5374 16.2067C32.8944 17.0051 31.4928 16.8894 30.9889 15.9944C29.8834 13.9852 29.0571 11.8243 28.4762 9.60368C21.7078 17.8471 12.609 24.087 2.57673 27.5478C2.27403 27.6464 1.97603 27.7801 1.65453 27.8029C0.779342 27.8389 -0.0450849 27.0396 0.00191782 26.1464C0.0169587 25.4173 0.581931 24.7943 1.26159 24.6028C6.80133 22.7671 12.0111 19.9653 16.6888 16.4618C15.1377 12.0338 13.9796 7.46366 13.2661 2.82236C13.2003 2.38051 13.2623 1.89884 13.5415 1.53853C13.8141 1.17254 14.2644 0.987643 14.7062 0.94782ZM16.4237 3.82837C17.0695 7.44849 17.9852 11.0202 19.1452 14.5076C19.9828 13.805 20.7978 13.0759 21.5903 12.3221C21.4643 12.1059 21.3449 11.8821 21.28 11.6394C20.5449 9.03004 19.9123 6.39127 19.3887 3.7307C18.4007 3.77527 17.4117 3.79802 16.4237 3.82837Z"
      fill="#294F9D"
    />
    <path
      d="M51.7125 9.21118C52.6206 8.88975 53.6678 9.64449 53.7421 10.5927C53.8295 11.3711 53.2617 12.1088 52.5332 12.3202C45.6576 14.6044 39.2765 18.3383 33.8082 23.1114C30.9881 25.5785 28.4151 28.3349 26.1628 31.3377C25.8394 31.7644 25.5461 32.2537 25.0488 32.4954C24.4603 32.8055 23.6754 32.6547 23.2448 32.1456C22.9619 31.8241 22.8434 31.3956 22.7899 30.9793C22.4101 28.1101 21.4804 25.3225 20.122 22.7757C19.8484 22.3225 19.7328 21.7545 19.9208 21.2454C20.1239 20.6272 20.7434 20.1806 21.3911 20.1958C21.9654 20.1844 22.5436 20.5001 22.8077 21.0226C23.886 23.0137 24.7038 25.1443 25.2754 27.3383C32.1631 18.947 41.4678 12.6312 51.7125 9.21118Z"
      fill="#B23035"
    />
    <path
      d="M92.7164 9.97598C92.685 9.75398 92.7628 9.41419 93.0683 9.48215C93.9801 9.4746 94.895 9.44892 95.8068 9.49725C95.8472 9.53501 95.9266 9.60901 95.9655 9.64676C96.0224 11.4514 95.9595 13.2607 95.9939 15.0669C96.4701 14.5187 97.0211 13.9886 97.7428 13.8028C99.1741 13.4147 100.868 13.6926 101.929 14.795C102.723 15.5818 103.06 16.7266 103.057 17.829C103.052 20.3964 103.07 22.9667 103.049 25.5356C103.116 25.8588 102.732 25.8406 102.522 25.8482C101.609 25.827 100.688 25.9222 99.7835 25.7681C99.7835 23.373 99.7865 20.9778 99.7835 18.5826C99.788 18.0676 99.6637 17.533 99.3314 17.1298C98.6426 16.2373 97.1124 16.2448 96.4341 17.1449C96.1047 17.5451 95.9865 18.0767 95.9865 18.5871C95.982 20.9838 95.9924 23.3805 95.982 25.7772C94.9024 25.8935 93.799 25.8935 92.7194 25.7772C92.7119 20.5096 92.7194 15.2436 92.7164 9.97598Z"
      fill="#294F9D"
    />
    <path
      d="M109.48 13.7319C110.995 13.4888 112.657 13.7002 113.913 14.6471C115.003 15.4324 115.675 16.6904 115.949 17.9952C116.273 19.6005 116.183 21.3192 115.565 22.8445C115.091 24.03 114.157 25.0191 113.003 25.5447C110.915 26.4554 108.213 26.1382 106.609 24.4317C105.501 23.293 105.085 21.6559 105.028 20.1019C105.001 18.7352 105.182 17.305 105.895 16.115C106.654 14.8268 108.021 13.9615 109.48 13.7319ZM109.908 16.562C108.864 16.8776 108.406 18.042 108.348 19.0493C108.289 20.1729 108.24 21.4354 108.935 22.3869C109.601 23.3428 111.124 23.4969 111.956 22.6814C112.649 22.0274 112.81 21.0171 112.829 20.105C112.848 19.1354 112.83 18.0707 112.212 17.2688C111.702 16.553 110.722 16.3083 109.908 16.562Z"
      fill="#294F9D"
    />
    <path
      d="M123.241 13.8468C124.464 13.4708 125.891 13.5826 126.956 14.3361C127.429 14.6608 127.74 15.1547 128.064 15.6198C128.514 14.8028 129.221 14.1066 130.13 13.8438C131.539 13.4179 133.273 13.6158 134.313 14.7514C135.113 15.6168 135.336 16.8461 135.324 17.9908C135.322 20.3558 135.318 22.7208 135.327 25.0858C135.313 25.3198 135.327 25.5675 135.246 25.7925C134.623 25.9088 133.984 25.8273 133.355 25.8514C132.933 25.8348 132.494 25.9088 132.091 25.7638C131.998 23.7825 132.078 21.792 132.052 19.8076C132.019 18.9196 132.206 17.9168 131.653 17.1466C131.012 16.2179 129.462 16.2526 128.829 17.1723C128.487 17.6163 128.423 18.1947 128.432 18.7384C128.438 20.8557 128.429 22.973 128.438 25.0903C128.429 25.3259 128.43 25.569 128.36 25.7971C127.313 25.8937 126.246 25.8771 125.198 25.7895C125.166 23.3385 125.204 20.8859 125.18 18.4348C125.205 17.4759 124.481 16.4912 123.483 16.4731C122.509 16.3538 121.556 17.1496 121.529 18.1464C121.453 20.6896 121.582 23.2418 121.467 25.782C120.898 25.9118 120.306 25.8288 119.728 25.8514C119.252 25.8333 118.763 25.9103 118.3 25.7744C118.186 22.1801 118.289 18.5738 118.249 14.9765C118.267 14.608 118.189 14.2138 118.343 13.8665C119.357 13.803 120.39 13.7925 121.405 13.8695C121.613 14.2455 121.47 14.7484 121.52 15.1728C121.991 14.614 122.521 14.0583 123.241 13.8468Z"
      fill="#294F9D"
    />
    <path
      d="M139.469 14.6276C140.819 13.5916 142.652 13.4164 144.266 13.8046C145.48 14.093 146.542 14.901 147.196 15.9672C148.072 17.4079 148.313 19.1839 148.135 20.8421C145.623 20.904 143.11 20.8421 140.598 20.8753C140.726 21.561 140.927 22.3115 141.529 22.7314C142.35 23.2977 143.457 23.3596 144.384 23.0425C145.021 22.831 145.447 22.2874 145.901 21.8222C146.57 22.4943 147.283 23.124 147.892 23.855C146.38 25.8801 143.553 26.4283 141.222 25.785C139.836 25.409 138.621 24.4183 138.004 23.1044C137.353 21.8071 137.235 20.3166 137.314 18.8864C137.417 17.2569 138.135 15.6032 139.469 14.6276ZM141.565 16.76C140.9 17.1648 140.686 17.9788 140.589 18.7052C142.011 18.7218 143.434 18.7188 144.856 18.7067C144.753 18.2189 144.705 17.6918 144.398 17.281C143.781 16.3976 142.459 16.2375 141.565 16.76Z"
      fill="#294F9D"
    />
    <path
      d="M150.236 9.50043C151.256 9.46872 152.28 9.44908 153.299 9.49741C153.551 9.61068 153.454 9.91272 153.482 10.1332C153.47 15.3494 153.488 20.5657 153.473 25.7819C152.394 25.8907 151.293 25.8922 150.214 25.7819C150.22 20.3543 150.178 14.9266 150.236 9.50043Z"
      fill="#B23035"
    />
    <path
      d="M168.229 9.51405C169.13 9.42948 170.04 9.48988 170.948 9.47478C171.154 9.44307 171.472 9.52764 171.422 9.80854C171.445 11.506 171.413 13.2035 171.436 14.9009C171.916 14.3965 172.461 13.9087 173.161 13.7562C174.652 13.424 176.342 13.797 177.437 14.91C178.566 16.026 179.025 17.6525 179.091 19.208C179.166 20.9417 178.948 22.8084 177.88 24.2325C176.815 25.7155 174.796 26.3996 173.063 25.8529C172.402 25.6551 171.904 25.1522 171.44 24.6644C171.431 25.0359 171.425 25.4089 171.422 25.7804C170.343 25.8967 169.247 25.8831 168.169 25.7865C168.146 21.3737 168.164 16.9609 168.158 12.548C168.203 11.5392 168.062 10.5123 168.229 9.51405ZM172.932 16.5591C171.916 16.9035 171.578 18.086 171.487 19.054C171.416 20.2259 171.395 21.5413 172.131 22.526C172.795 23.4442 174.324 23.4275 175.005 22.5365C175.598 21.7663 175.718 20.7454 175.709 19.8C175.709 18.8622 175.574 17.8504 174.962 17.1058C174.483 16.5169 173.634 16.3417 172.932 16.5591Z"
      fill="#B23035"
    />
    <path
      d="M67.0354 13.8937C68.2033 13.4784 69.5733 13.5524 70.6514 14.1867C71.2308 14.5174 71.6336 15.0671 71.969 15.6335C72.4406 14.7953 73.1713 14.0764 74.1146 13.8242C75.528 13.4195 77.2588 13.637 78.2725 14.7983C79.0825 15.6984 79.2742 16.97 79.2457 18.1434C79.2173 20.6745 79.2906 23.2101 79.2098 25.7367C79.1619 25.7594 79.063 25.8032 79.0151 25.8258C78.0614 25.8696 77.1016 25.8696 76.1493 25.8243C76.1104 25.7926 76.0311 25.7276 75.9906 25.6959C75.9277 23.2328 76.0011 20.7636 75.9577 18.299C75.9502 17.414 75.2989 16.5154 74.3751 16.4792C73.324 16.3161 72.3612 17.2494 72.3672 18.2975C72.3148 20.7742 72.4092 23.257 72.3238 25.7322C72.2744 25.7548 72.1771 25.8016 72.1292 25.8258C71.1515 25.862 70.1647 25.8877 69.19 25.8077C69.0942 25.5751 69.1002 25.3214 69.0957 25.0767C69.1047 22.9141 69.1002 20.7515 69.1002 18.5889C69.1107 18.0226 68.9624 17.4306 68.5687 17.0108C67.8215 16.1862 66.3063 16.3176 65.7298 17.2796C65.357 17.8444 65.4528 18.5512 65.4423 19.193C65.4379 21.3148 65.4588 23.4367 65.4334 25.557C65.4843 25.856 65.1264 25.8409 64.9393 25.8485C64.0184 25.8303 63.0886 25.9164 62.1753 25.7745C62.1828 21.8056 62.1513 17.8353 62.1918 13.865C63.2653 13.8031 64.3463 13.8031 65.4199 13.865C65.4409 14.3015 65.4453 14.7394 65.4618 15.1759C65.8885 14.6443 66.3737 14.1187 67.0354 13.8937Z"
      fill="#B23035"
    />
    <path
      d="M156.939 14.7257C158.303 13.7214 160.091 13.4979 161.73 13.7168C163.154 13.9222 164.605 14.6275 165.355 15.9157C165.668 16.4503 165.822 17.071 165.81 17.6902C165.81 20.2122 165.809 22.7343 165.813 25.2548C165.779 25.4662 165.89 25.8483 165.56 25.8287C164.581 25.8845 163.599 25.8317 162.618 25.8483C162.555 25.4571 162.53 25.06 162.516 24.6643C161.928 25.3786 161.093 25.8709 160.172 25.9721C158.769 26.1624 157.183 25.96 156.119 24.9407C155.22 24.0512 155.093 22.6436 155.366 21.4627C155.626 20.3693 156.512 19.519 157.543 19.143C159.137 18.5344 160.871 18.8002 162.536 18.7307C162.54 18.0843 162.599 17.3021 162.031 16.8596C161.344 16.3491 160.371 16.3763 159.604 16.6738C159.16 16.8294 158.981 17.287 158.742 17.6479C157.656 17.6343 156.572 17.4017 155.494 17.2522C155.563 16.2374 156.126 15.3131 156.939 14.7257ZM159.577 20.8797C158.944 21.064 158.43 21.6847 158.541 22.3733C158.565 22.9925 159.121 23.4592 159.702 23.5392C160.613 23.6887 161.657 23.4199 162.233 22.6527C162.66 22.0985 162.51 21.3539 162.528 20.7045C161.545 20.7378 160.53 20.5837 159.577 20.8797Z"
      fill="#B23035"
    />
    <path
      d="M80.2188 13.9406C80.3116 13.7246 80.611 13.8439 80.7937 13.8001C81.7205 13.8273 82.6533 13.7473 83.5771 13.8439C83.658 13.9829 83.7194 14.1309 83.7613 14.2879C84.4036 16.7647 85.0145 19.252 85.6853 21.7211C85.7736 21.5475 85.829 21.3617 85.8874 21.1759C86.5522 18.7536 87.0942 16.292 87.8039 13.8832C88.8924 13.708 90.0258 13.8243 91.1308 13.8318C91.0095 14.4858 90.7655 15.1049 90.5634 15.7332C89.4644 19.0707 88.3609 22.4068 87.2634 25.7443C86.8367 26.9751 86.4489 28.2935 85.5475 29.2676C84.3153 30.4878 82.4542 30.4924 80.8401 30.4999C80.7608 29.6149 80.7608 28.7209 80.8386 27.8344C81.716 27.703 82.7701 27.8495 83.4543 27.1594C83.8421 26.6882 84.197 26.0101 83.9215 25.4C82.7881 21.9582 81.6741 18.5089 80.5422 15.0657C80.4239 14.6942 80.2727 14.3302 80.2188 13.9406Z"
      fill="#B23035"
    />
    <path
      d="M181.301 15.9084C181.898 14.6579 183.246 13.9648 184.559 13.7639C186.085 13.5404 187.744 13.6491 189.088 14.4752C190.007 15.031 190.684 16.0126 190.779 17.1014C190.776 17.3008 190.88 17.698 190.572 17.7085C189.656 17.7538 188.732 17.7674 187.818 17.6783C187.723 17.3219 187.682 16.8991 187.35 16.6756C186.595 16.1168 185.516 16.1183 184.677 16.4747C184.204 16.6529 183.949 17.2706 184.24 17.7085C184.542 18.0695 185.034 18.1601 185.46 18.299C187.025 18.7113 188.688 19.0375 189.998 20.0569C191.097 20.892 191.211 22.5321 190.729 23.7388C190.129 25.0919 188.639 25.7473 187.258 25.9301C185.523 26.137 183.616 26.0056 182.145 24.962C181.221 24.3217 180.66 23.1543 180.895 22.0247C181.871 22.0187 182.849 21.9809 183.827 22.0353C184.02 22.3584 184.02 22.8024 184.34 23.0471C185.001 23.5847 185.93 23.6497 186.734 23.4654C187.234 23.3386 187.799 22.9429 187.73 22.3539C187.64 21.8586 187.091 21.7272 186.692 21.5867C185.069 21.1397 183.367 20.732 182 19.6914C180.85 18.8427 180.748 17.1347 181.301 15.9084Z"
      fill="#B23035"
    />
  </svg>
);
export default Logo;
