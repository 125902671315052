import React, { useState } from 'react';
import { Form, Label, FormGroup } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import ButtonWithLoader from 'components/shared/loading-button';
import CustomInputField from 'components/shared/form-fields/custom-input-field';

import { HidePasswordIcon, ShowPasswordIcon } from 'assets/images/svg-icons';

import { resetPassword } from 'services/auth';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Special Case Character',
    ),
  password_confirmation: Yup.string()
    .required('Confirm password required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const params = useParams();
  const history = useHistory();
  const { token, email } = params;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      password: '',
      password_confirmation: '',
      email,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = (formData) => {
    if (token && email) {
      const payload = {
        token,
        email,
        password: formData?.password,
        password_confirmation: formData?.password_confirmation,
      };
      setIsLoading(true);
      resetPassword(payload)
        .then((res) => {
          toast.success(res?.data?.message || 'Password updated successfully');
          setIsLoading(false);
          window.setTimeout(() => {
            history.push('/auth/login');
          }, 3000);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.errors?.message?.[0] || 'Something went wrong!');
          setIsLoading(false);
          catchHandler(error, setError, true);
        });
    }
  };
  return (
    <div className="auth__innerwrap">
      <FormProvider {...methods}>
        <Form
          className="form-signin auth__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2 className="auth__head">
            Reset
            {' '}
            <span className="auth__head--reg">Password</span>
          </h2>
          <FormGroup>
            <Label for="email">Email</Label>
            <CustomInputField className="form-control" name="email" disabled />
          </FormGroup>
          <FormGroup>
            <Label for="password">New Password</Label>
            <div
              className={`view-password ${
                !isEmpty(errors?.password) ? 'view-password--error' : ''
              }`}
            >
              <CustomInputField
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                name="password"
              />
              <span
                role="presentation"
                onKeyDown={() => setShowPassword(!showPassword)}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              </span>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="password_confirmation">Confirm Password</Label>
            <div
              className={`view-password ${
                !isEmpty(errors?.password_confirmation)
                  ? 'view-password--error'
                  : ''
              }`}
            >
              <CustomInputField
                type={showConfirmPassword ? 'text' : 'password'}
                className="form-control"
                name="password_confirmation"
              />
              <span
                role="presentation"
                onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <HidePasswordIcon />
                ) : (
                  <ShowPasswordIcon />
                )}
              </span>
            </div>
          </FormGroup>
          <div className="auth__forgot">
            <Link className="inline_link" to="/auth/login">
              Back To Login
            </Link>
          </div>
          <div className="auth__btn d-flex align-items-center justify-content-center">
            <ButtonWithLoader color="secondary" isLoading={isLoading}>
              Reset Password
            </ButtonWithLoader>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
}

export default ResetPassword;
