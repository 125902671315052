import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

const AsyncSelectField = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [searchKeyWord, setSearchKeyWord] = useState('');

  const {
    name: propsName,
    options,
    placeholder,
    defaultOptions,
    loadOptions,
    customizeOutputHandler,
    ...otherProps
  } = props;

  const inputChangeHandler = (inputValue, event) => {
    if (event.action === 'input-change' || event.action === 'set-value') {
      setSearchKeyWord(inputValue);
    }
  };

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        render={({
          field: {
            onChange, onBlur, name, value,
          },
        }) => (
          <React.Fragment>
            <AsyncSelect
              {...otherProps}
              closeMenuOnSelect
              name={name}
              onBlur={onBlur}
              onChange={(selectedItem) => {
                if (customizeOutputHandler) {
                  onChange(customizeOutputHandler(selectedItem));
                } else {
                  onChange(selectedItem);
                }
              }}
              onInputChange={inputChangeHandler}
              inputValue={searchKeyWord}
              defaultOptions={defaultOptions}
              loadOptions={loadOptions}
              options={options}
              placeholder={placeholder || ''}
              value={value || placeholder}
            />
            {errors && errors[propsName] && errors[propsName].message ? (
              <small className="text-danger">{errors[propsName].message}</small>
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default AsyncSelectField;
