import React from 'react';

const ConsumerIcon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path
      d="M19.6913 10.0058C21.9091 9.92885 24.1403 10.6246 25.9205 11.9352C27.0039 12.7292 27.927 13.7382 28.6128 14.8876C29.3529 16.1234 29.816 17.5219 29.9533 18.9526C30.1089 20.5277 29.8742 22.1393 29.271 23.6051C28.3022 25.9855 26.3635 27.9567 23.9875 28.9811C22.4463 29.6529 20.7307 29.9223 19.0547 29.7625C17.5792 29.6248 16.1373 29.1522 14.8685 28.3936C12.9219 27.2375 11.3893 25.4117 10.6095 23.2996C10.09 21.9061 9.89952 20.3949 10.0499 18.9175C10.1988 17.4221 10.7045 15.9647 11.5081 14.691C12.406 13.2634 13.6737 12.0658 15.1582 11.2479C16.5398 10.4823 18.1086 10.0514 19.6913 10.0058ZM19.6604 13.3231C19.1789 13.3831 18.7171 13.5735 18.3253 13.8558C17.6582 14.3341 17.188 15.0478 16.9281 15.8151C16.6862 16.5168 16.6245 17.2715 16.6994 18.0069C16.8086 18.9965 17.2167 19.976 17.9416 20.6787C18.4838 21.21 19.2289 21.5623 20.0002 21.5503C20.6349 21.5605 21.2583 21.3228 21.7562 20.94C22.5165 20.357 23.002 19.4752 23.2088 18.5551C23.419 17.5967 23.3637 16.5744 23.0141 15.6543C22.6953 14.8163 22.1123 14.0493 21.3023 13.6262C20.8034 13.36 20.2225 13.2564 19.6604 13.3231ZM17.8536 22.731C16.4976 23.1595 15.2358 23.9384 14.3333 25.038C14.1755 25.2491 14.1709 25.5416 14.2784 25.7755C14.3571 25.9658 14.5099 26.1109 14.6692 26.2369C15.5561 26.9657 16.5986 27.5051 17.704 27.8257C19.1157 28.2401 20.6417 28.2644 22.0648 27.8896C23.3531 27.5546 24.567 26.9239 25.5592 26.0413C25.7599 25.865 25.873 25.5816 25.8035 25.3189C25.7649 25.1304 25.6308 24.9853 25.5042 24.848C24.6712 23.8938 23.5652 23.1862 22.3566 22.795C22.2382 22.7665 22.1087 22.6759 21.9913 22.7532C20.7743 23.3467 19.2835 23.3562 18.0597 22.776C17.9948 22.75 17.926 22.6963 17.8536 22.731Z"
      fill="#0047BA"
    />
  </svg>
);
export default ConsumerIcon;
