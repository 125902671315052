import Login from 'components/auth/login';
import ForgotPassword from 'components/auth/forgot-password';
import ResetPassword from 'components/auth/reset-password';

const authRoutes = [
  {
    path: '/auth/forgot-password',
    component: ForgotPassword,
    key: 'forgot-password',
  },
  {
    path: '/auth/reset-password/token=:token&email=:email',
    component: ResetPassword,
    key: 'reset-password',
  },
  {
    path: '/auth/login',
    component: Login,
    key: 'login',
  },
  {
    path: '/auth',
    component: Login,
    key: 'login',
  },
];

export default authRoutes;
