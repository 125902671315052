import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';

import Filter from 'components/shared/filter/filter';
import ListingComponent from 'components/shared/listing-component';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import AddLabModal from 'components/lab-management/add-lab-modal';

import {
  PendingIcon,
  ApprovedIcon,
  DeactivateIcon,
  DotIcon,
  AddGreenIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { filterOptions } from 'helpers/utils';
import { LookupContext } from 'context/lookup-context/lookup-context';
import {
  getAllLabDetails,
  deleteSelectedLab,
  updateLabStatus,
} from 'services/lab-management';

const LabManagement = () => {
  const [, setHeader] = useContext(HeaderText);
  const lookupData = useContext(LookupContext);

  const [labsData, setLabsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLab, setDeleteLab] = useState({});
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [confirmationModal, setConfirmationModal] = useState('');
  const [confirmationButtonLoading, setConfirmationButtonLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [showAddLabModal, setShowAddLabModal] = useState(false);

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Lab</span>
      </>
    ),
    confirmationText: `Please confirm deleting ${deleteLab?.lab_name}. This action cannot be undone`,
  };

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'pending'
        || updateStatusItem?.status_slug === 'deactivated'
          ? 'Activate'
          : 'Deactivate'}
        {' '}
        <span>{updateStatusItem?.lab_name}</span>
      </>
    ),
    confirmationText: `Please Confirm ${
      updateStatusItem?.status_slug === 'pending'
      || updateStatusItem?.status_slug === 'deactivated'
        ? 'activating'
        : 'deactivating'
    } ${updateStatusItem?.lab_name}`,
  };

  const addButton = (
    <button
      type="button"
      className="button-icon"
      onClick={() => setShowAddLabModal(true)}
    >
      <AddGreenIcon />
      <span className="button-icon__text">Add Lab</span>
    </button>
  );

  const fetchAllLabs = (params) => {
    setIsLoading(true);
    getAllLabDetails(params)
      .then((response) => {
        setLabsData(response.data.data);
        setMeta(response.data.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const confirmDeleteLabHandler = (item) => {
    setDeleteLab(item);
    setConfirmationModal('delete-lab');
  };

  const deleteLabHandler = () => {
    setConfirmationButtonLoading(true);
    deleteSelectedLab(deleteLab?.lab_id)
      .then(() => {
        setConfirmationButtonLoading(false);
        setConfirmationModal('');
        setDeleteLab({});
        fetchAllLabs(filter);
      })
      .catch(() => {
        setConfirmationButtonLoading(false);
      });
  };

  const statusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModal('status-update');
  };

  const confirmStatusUpdateHandler = () => {
    setConfirmationButtonLoading(true);
    updateLabStatus(updateStatusItem?.lab_id, {
      status:
        updateStatusItem?.status_slug === 'deactivated'
        || updateStatusItem?.status_slug === 'pending'
          ? 'approved'
          : 'deactivated',
    })
      .then(() => {
        fetchAllLabs(filter);
        setConfirmationButtonLoading(false);
        setConfirmationModal('');
        setUpdateStatusItem({});
      })
      .catch(() => {
        setConfirmationButtonLoading(false);
      });
  };

  const getLabStatusUpdate = (currentStatus) => {
    switch (currentStatus) {
      case 'approved':
        return <ApprovedIcon className="table__status-icon" />;
      case 'pending':
        return <PendingIcon className="table__status-icon" />;
      case 'deactivated':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchAllLabs(filter);
  }, [filter]);

  useEffect(() => {
    setHeader('labs');
  }, [setHeader]);

  const mappings = [
    {
      key: 'id',
      label: 'ID',
      render: (item) => (
        <Link to={`/lab-management/${item?.lab_id}`} className="inline_link">
          {item?.lab_id}
        </Link>
      ),
    },
    {
      key: 'lab_name',
      label: 'Lab Name',
      render: (item) => (
        <span className="inline__list blue">{item?.lab_name}</span>
      ),
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getLabStatusUpdate(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'location',
      label: 'LOCATION',
      render: (item) => (
        <>
          {item?.city}
          {' '}
          {item?.state}
        </>
      ),
    },
    {
      key: 'phlebotomist_count',
      label: '# OF PHL',
      className: 'text-center',
    },
    {
      key: 'product_variant_count',
      label: '# OF KITS',
      className: 'text-center',
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <UncontrolledDropdown>
          <DropdownToggle className="button__action">
            <DotIcon />
          </DropdownToggle>
          <DropdownMenu className="table__dropdown">
            <DropdownItem tag="div">
              <Link
                to={`/lab-management/${item?.lab_id}`}
                className="inline_link"
              >
                View Details
              </Link>
            </DropdownItem>
            <DropdownItem tag="div">
              <Button
                className="inline__list inline__list--regular"
                onClick={() => statusUpdateHandler(item)}
              >
                {item?.status_slug === 'pending'
                || item?.status_slug === 'deactivated'
                  ? 'Activate Lab'
                  : 'Deactivate Lab'}
              </Button>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag="div">
              <Button
                onClick={() => confirmDeleteLabHandler(item)}
                className="inline__list inline__list--regular red"
              >
                Delete Lab
              </Button>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <React.Fragment>
        <Filter
          filters={[
            {
              filter_type: 'custom-input',
              name: 'search',
              placeholder: 'Lab ID or Lab Name',
              type: 'text',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'custom-input',
              name: 'location',
              placeholder: 'Search by location',
              type: 'text',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'select',
              name: 'status',
              options: filterOptions(
                lookupData?.lookupDetails?.labStatusOption,
                'All status',
              ),
              placeholder: 'All Status',
              inputProps: {
                className: 'selectbox',
                components: {
                  IndicatorSeparator: () => null,
                },
              },
            },
            {
              filter_type: 'button',
              name: 'submit',
              inputProps: {
                className: 'table__button',
              },
            },
          ]}
          onFilter={(params) => setFilter((prev) => ({
            ...pick(prev, ['per_page', 'sort']),
            page: 1,
            ...params,
          }))}
          onPerPageChange={(perPage, page) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          addButton={addButton}
          meta={meta}
        />

        <ListingComponent
          isLoading={isLoading}
          mappings={mappings}
          meta={meta}
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          dataSource={labsData}
          shouldPaginate
          primaryKey="lab_id"
        />
        {confirmationModal === 'delete-lab' ? (
          <ConfirmationModal
            hideModal={() => {
              setConfirmationModal('');
              setDeleteLab({});
            }}
            renderHtml={confirmDeleteHtml}
            btnColor="primary"
            type="deleteModal"
            cancelButtonText="Close"
            isLoading={confirmationButtonLoading}
            successButtonText=" Delete Lab"
            successHandler={() => deleteLabHandler()}
          />
        ) : (
          ''
        )}
        {confirmationModal === 'status-update' ? (
          <ConfirmationModal
            hideModal={() => setConfirmationModal('')}
            renderHtml={confirmDeactivateHtml}
            successButtonText="Confirm"
            successHandler={() => confirmStatusUpdateHandler()}
            cancelButtonText="Cancel"
            isLoading={confirmationButtonLoading}
          />
        ) : (
          ''
        )}
        {showAddLabModal ? (
          <AddLabModal
            hideModal={() => setShowAddLabModal(false)}
            reloadPage={() => fetchAllLabs(filter)}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    </div>
  );
};

export default LabManagement;
