import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import baseRoutes from 'routes/index';
import 'sass/App.scss';

function App() {
  return (
    <React.Fragment>
      <Switch>
        {baseRoutes.map((prop) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.to} key={prop.key} />;
          }
          return (
            <Route
              path={prop.path}
              exact={prop.exact}
              component={prop.component}
              key={prop.key}
            />
          );
        })}
      </Switch>
      <ToastContainer
        position="top-right"
        theme="dark"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}

export default App;
