import { request } from 'helpers/api';

export const updateProfilePicture = (params) => request({
  method: 'POST',
  url: '/api/profile-image',
  params,
});

export const updateUserProfile = (params) => request({
  method: 'PUT',
  url: '/api/profile',
  params,
});
