import React from 'react';

const KitManagementIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1.59778 0.0181281C1.89494 -0.0171419 2.19777 0.0128376 2.49741 0.00296197C2.50663 4.03927 2.49848 8.07557 2.50167 12.1119C2.49848 13.6077 2.50663 15.1038 2.49741 16.5993C2.19813 16.5876 1.89494 16.6201 1.59778 16.5838C1.4914 16.5774 1.38325 16.5785 1.28042 16.5471C1.21836 16.5316 1.15737 16.5129 1.09638 16.4935C1.0517 16.4727 1.00631 16.4536 0.961273 16.4342C0.80844 16.3616 0.66518 16.2684 0.540005 16.1549C0.53114 16.1446 0.51341 16.1238 0.504545 16.1133L0.489297 16.1147C0.411285 16.0145 0.320506 15.9239 0.25455 15.8145C0.23221 15.7757 0.209161 15.7373 0.186112 15.6992C0.158098 15.6438 0.131858 15.5877 0.109163 15.5302C0.029023 15.3292 -0.00253674 15.1123 0.000300079 14.8971C-5.45237e-05 10.5 -5.45237e-05 6.10292 0.000300079 1.7058C-0.00360055 1.48995 0.0304413 1.27445 0.10739 1.07235C0.140368 0.998282 0.175119 0.925273 0.206679 0.850501C0.213771 0.848737 0.22831 0.844858 0.235402 0.843094L0.230792 0.815936C0.309159 0.700603 0.400292 0.594793 0.488588 0.48722L0.504545 0.488278C0.513056 0.478049 0.530786 0.45724 0.539651 0.447012C0.699931 0.297819 0.894253 0.192009 1.09602 0.109125C1.15737 0.0893736 1.21907 0.0696223 1.28148 0.0530454C1.38361 0.0216551 1.49211 0.0248294 1.59778 0.0181281Z"
        fill="#BBBFC8"
      />
      <path
        d="M4.16504 0.0200114C4.26397 -0.00785195 4.36752 0.00413986 4.46893 0.00237636C8.25574 0.00237636 12.0425 0.00308175 15.829 0.00202365C15.8368 5.52813 15.8308 11.0546 15.8322 16.5807C11.944 16.5811 8.05539 16.5811 4.16717 16.5811C4.16539 11.0606 4.16929 5.54012 4.16504 0.0200114ZM8.34439 4.15472C8.34013 4.98039 8.34332 5.80571 8.34261 6.63103C8.32843 6.62962 8.29971 6.62644 8.28552 6.62468L8.28056 6.65431C8.08198 6.64655 7.88305 6.65995 7.68483 6.64725L7.6483 6.60563L7.64617 6.65395C7.11533 6.64655 6.58378 6.6596 6.0533 6.6476L6.01713 6.60458L6.01571 6.6536C5.95401 6.65254 5.89302 6.65289 5.83203 6.65325C5.83628 7.75755 5.82777 8.86221 5.83628 9.96687C6.6657 9.96475 7.49547 9.96616 8.32488 9.96581C8.32453 10.7911 8.32666 11.6161 8.32382 12.4414C9.44082 12.4474 10.5582 12.4474 11.6748 12.4414C11.673 11.6161 11.6745 10.7911 11.6741 9.96581C12.5039 9.96616 13.3333 9.9651 14.1631 9.96651C14.1719 8.86221 14.1631 7.75791 14.1677 6.65395C13.4985 6.65078 12.8294 6.65148 12.1606 6.6536C12.1393 6.64619 12.0968 6.63209 12.0755 6.62503L12.0745 6.65501C11.9603 6.65148 11.8464 6.65148 11.7326 6.65219C11.7124 6.64372 11.6716 6.62715 11.6514 6.61868C11.6539 6.59541 11.6596 6.5492 11.6624 6.52592L11.7011 6.50123C11.6209 6.48113 11.6699 6.38237 11.656 6.32559C11.6567 5.60185 11.6581 4.87846 11.655 4.15472C10.5514 4.15648 9.44791 4.15613 8.34439 4.15472Z"
        fill="#BBBFC8"
      />
      <path
        d="M17.502 0.0026179C17.8013 0.0128462 18.1045 -0.0178387 18.402 0.017784C18.5084 0.024838 18.6173 0.0216637 18.7204 0.0537595C18.7821 0.0699837 18.8431 0.0893822 18.9045 0.10737C19.0917 0.185317 19.2758 0.280546 19.4247 0.41951L19.4523 0.418099L19.4477 0.444904C19.4594 0.453016 19.4832 0.468535 19.4953 0.476295C19.5683 0.552125 19.6339 0.634657 19.6999 0.716836C19.7148 0.740114 19.7296 0.763393 19.7449 0.786671C19.7669 0.824763 19.7896 0.862854 19.8126 0.900946C19.841 0.957025 19.8676 1.01416 19.8917 1.07236C19.9034 1.10763 19.9155 1.1429 19.9282 1.17782C20.019 1.46244 19.997 1.76365 19.9995 2.0578C19.9991 6.33782 19.9999 10.6178 19.9991 14.8979C20.002 15.1123 19.9683 15.3274 19.891 15.5285C19.8516 15.628 19.8027 15.7232 19.7442 15.8131C19.6782 15.9225 19.5892 16.0145 19.5105 16.1143L19.4953 16.1133C19.4864 16.1235 19.469 16.144 19.4601 16.1542C19.3339 16.2685 19.1903 16.363 19.0353 16.4346C18.9914 16.455 18.947 16.4751 18.9038 16.497C18.8811 16.4991 18.8357 16.5037 18.813 16.5058L18.8141 16.5284C18.3843 16.6339 17.9396 16.588 17.5017 16.6C17.4932 12.4931 17.5006 8.38595 17.4978 4.27911C17.501 2.8535 17.4925 1.42788 17.502 0.0026179Z"
        fill="#BBBFC8"
      />
    </g>
  </svg>
);
export default KitManagementIcon;
