import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

import Loader from 'components/shared/loader';
import ButtonWithLoader from 'components/shared/loading-button';

import { StarIcon } from 'assets/images/svg-icons';

const ReviewCommentModal = (props) => {
  const {
    hideModal,
    isLoading,
    isModalLoading,
    dataSource,
    successHandler,
    btnColor,
    successButtonText,
  } = props;

  return (
    <Modal
      isOpen
      toggle={hideModal}
      centered
      className="review-comment__modal modal--info"
    >
      <ModalBody>
        {isModalLoading ? (
          <Loader />
        ) : (
          <>
            <h2 className="modal__head">{dataSource?.consumer}</h2>
            <div className="modal__head-text">
              On
              {' '}
              {dataSource?.product_name}
              {' '}
              by
              {' '}
              {dataSource?.consumer}
            </div>
            <div className={`star-rateing active-${dataSource.rating} small`}>
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <p className="star-rateing__text">
                <b>{dataSource?.rating}</b>
                /5
              </p>
            </div>
            <div className="review-comment">{dataSource?.review}</div>
            <div className="modal__btn d-flex align-items-center justify-content-center">
              <Button color="primary outline" onClick={hideModal}>
                Close
              </Button>
              <ButtonWithLoader
                color={btnColor || 'secondary'}
                isLoading={isLoading}
                onClick={successHandler}
              >
                {successButtonText}
              </ButtonWithLoader>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ReviewCommentModal;
