import { request } from 'helpers/api';

export const getAllReport = (params) => request({
  method: 'GET',
  url: '/api/reports',
  params,
});

export const exportCsvReport = (params) => request({
  method: 'POST',
  url: '/api/reports/export-csv',
  params,
});
