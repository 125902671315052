import React from 'react';

const HeaderBellIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90011 20.1292C9.04173 20.1292 8.2185 19.8008 7.61153 19.2163C7.00457 18.6318 6.66357 17.8391 6.66357 17.0125C6.66357 16.8059 6.74882 16.6077 6.90056 16.4615C7.05231 16.3154 7.25811 16.2333 7.47271 16.2333H12.3275C12.5421 16.2333 12.7479 16.3154 12.8997 16.4615C13.0514 16.6077 13.1367 16.8059 13.1367 17.0125C13.1367 17.8391 12.7957 18.6318 12.1887 19.2163C11.5817 19.8008 10.7585 20.1292 9.90011 20.1292ZM8.50031 17.7917C8.64256 18.0278 8.84675 18.2238 9.09241 18.3601C9.33808 18.4964 9.61661 18.5681 9.90011 18.5681C10.1836 18.5681 10.4621 18.4964 10.7078 18.3601C10.9535 18.2238 11.1577 18.0278 11.2999 17.7917H8.50031Z"
      fill="#444444"
    />
    <path
      d="M11.5181 3.2167H8.28155C8.06695 3.2167 7.86114 3.13461 7.7094 2.98849C7.55766 2.84237 7.47241 2.64419 7.47241 2.43754C7.47241 1.81759 7.72816 1.22304 8.18338 0.784675C8.63861 0.346308 9.25603 0.100037 9.89982 0.100037C10.5436 0.100037 11.161 0.346308 11.6163 0.784675C12.0715 1.22304 12.3272 1.81759 12.3272 2.43754C12.3272 2.64419 12.242 2.84237 12.0902 2.98849C11.9385 3.13461 11.7327 3.2167 11.5181 3.2167Z"
      fill="#444444"
    />
    <path
      d="M18.8003 17.7916H0.999322C0.784726 17.7916 0.57892 17.7095 0.427177 17.5634C0.275435 17.4172 0.190187 17.2191 0.190187 17.0124C0.189478 16.1325 0.409873 15.2654 0.832562 14.4853C1.25525 13.7051 1.86769 13.035 2.61759 12.5322V8.44158C2.61755 6.58982 3.37811 4.81321 4.73331 3.49945C6.08851 2.18568 7.92832 1.44142 9.85125 1.42908H9.94026C11.8716 1.42908 13.7239 2.16789 15.0896 3.48299C16.4552 4.79809 17.2225 6.58175 17.2225 8.44158V12.5322C17.9649 13.0393 18.5695 13.7113 18.985 14.4912C19.4005 15.2711 19.6147 16.1359 19.6094 17.0124V17.0124C19.6094 17.2191 19.5242 17.4172 19.3724 17.5634C19.2207 17.7095 19.0149 17.7916 18.8003 17.7916ZM1.88937 16.2332H17.9102C17.7956 15.6931 17.5633 15.1825 17.2285 14.7352C16.8938 14.2879 16.4642 13.9142 15.9683 13.6386C15.8448 13.57 15.7424 13.4711 15.6713 13.3521C15.6003 13.233 15.5632 13.098 15.5637 12.9607V8.44158C15.5637 6.99504 14.967 5.60775 13.9048 4.5849C12.8426 3.56204 11.402 2.98741 9.8998 2.98741C8.39763 2.98741 6.95699 3.56204 5.89479 4.5849C4.8326 5.60775 4.23586 6.99504 4.23586 8.44158V12.9607C4.23643 13.098 4.19931 13.233 4.12826 13.3521C4.05722 13.4711 3.95477 13.57 3.83129 13.6386C3.33538 13.9142 2.90585 14.2879 2.57109 14.7352C2.23633 15.1825 2.00397 15.6931 1.88937 16.2332V16.2332Z"
      fill="#444444"
    />
  </svg>
);
export default HeaderBellIcon;
