import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import ListingComponent from 'components/shared/listing-component';
import AddEditConsumerPhlebotomistModal from 'components/shared/modals/add-edit-consumer-phlebotomist';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';

import { ApprovedIcon, DotIcon, DeactivateIcon } from 'assets/images/svg-icons';

import {
  addLabManager,
  getLabManagers,
  getLabMangerDetails,
  updateLabManager,
  deleteManager,
} from 'services/lab-management';
import catchHandler from 'helpers/catch-handler';

const LabManagers = ({ labName }) => {
  const { labId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [labManagers, setLabManagers] = useState({});
  const [showManagerModal, setShowManagerModal] = useState(false);
  const [addManagerButtonLoading, setAddManagerButtonLoading] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [isEditModalLoading, setIsEditModalLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState('');
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [updateStatusItem, setUpdateStatusItem] = useState({});

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Lab Manager</span>
      </>
    ),
    confirmationText: `Please confirm deleting  ${deleteItem?.first_name} ${deleteItem?.last_name}. This action cannot be undone`,
  };

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
        {' '}
        <span>User</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'active' ? 'deactivating' : 'activating'
    } user ${updateStatusItem?.first_name} ${updateStatusItem?.last_name}.`,
  };

  const fetchLabManagers = useCallback(() => {
    setIsLoading(true);
    getLabManagers(labId)
      .then((response) => {
        setLabManagers(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [labId]);

  const editManagerHandler = (item) => {
    setEditItem({});
    setEditModal(true);
    setShowManagerModal(true);
    setIsEditModalLoading(true);
    getLabMangerDetails(labId, item?.lab_user_id)
      .then((response) => {
        setEditItem(response?.data);
        setIsEditModalLoading(false);
      })
      .catch(() => {
        setIsEditModalLoading(false);
      });
  };

  const formSubmitHandler = (formData, setError) => {
    const payload = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      phone_number: formData?.phone_number,
      password: formData?.password,
      password_confirmation: formData?.password_confirmation,
      state: formData?.state?.value,
      city: formData?.city,
      zip: formData?.zip,
      street_address: formData?.street_address,
    };
    setAddManagerButtonLoading(true);
    if (!isEmpty(editItem)) {
      updateLabManager(labId, editItem?.lab_user_id, payload)
        .then(() => {
          setAddManagerButtonLoading(false);
          setShowManagerModal(false);
          setEditItem({});
          fetchLabManagers();
        })
        .catch((error) => {
          catchHandler(error, setError, true);
          setAddManagerButtonLoading(false);
          setAddManagerButtonLoading(false);
        });
    } else {
      addLabManager(labId, payload)
        .then(() => {
          setAddManagerButtonLoading(false);
          setShowManagerModal(false);
          fetchLabManagers();
        })
        .catch((error) => {
          setAddManagerButtonLoading(false);
          catchHandler(error, setError, true);
        });
    }
  };

  const confirmDeleteManagerHandler = () => {
    setIsConfirmationLoading(true);
    deleteManager(labId, deleteItem?.lab_user_id)
      .then(() => {
        setIsConfirmationLoading(false);
        setConfirmationModal('');
        setDeleteItem({});
        fetchLabManagers();
      })
      .catch(() => {
        setDeleteItem({});
        setIsConfirmationLoading(false);
      });
  };

  const deleteManagerHandler = (item) => {
    setDeleteItem(item);
    setConfirmationModal('delete-manager');
  };

  const statusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModal('status-update');
  };

  const confirmStatusUpdateHandler = () => {
    setIsConfirmationLoading(true);
    updateLabManager(labId, updateStatusItem?.lab_user_id, {
      status:
        updateStatusItem?.status_slug === 'active' ? 'deactivated' : 'active',
    })
      .then(() => {
        fetchLabManagers();
        setUpdateStatusItem({});
        setIsConfirmationLoading(false);
        setConfirmationModal('');
      })
      .catch(() => {
        setIsConfirmationLoading(false);
      });
  };

  const getLabManagerStatus = (labManagerStatus) => {
    switch (labManagerStatus) {
      case 'active':
        return <ApprovedIcon className="table__status-icon" />;
      case 'deactivated':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchLabManagers();
  }, [fetchLabManagers]);

  const mappings = [
    {
      key: 'name',
      label: 'NAME',
      className: 'text-start',
      render: (item) => (
        <span className="inline__list">
          {item?.first_name}
          {' '}
          {item?.last_name}
        </span>
      ),
    },
    {
      key: 'email',
      label: 'EMAIL',
    },
    {
      key: 'phone_number',
      label: 'PHONE NUMBER',
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getLabManagerStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => editManagerHandler(item)}
                >
                  Edit User
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => statusUpdateHandler(item)}
                >
                  {item?.status_slug === 'active'
                    ? 'Deactivate User'
                    : 'Activate User'}
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular red"
                  onClick={() => deleteManagerHandler(item)}
                >
                  Delete User
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  return (
    <div className="tab__table tab__table--top">
      <div className="tab__btn ">
        <Button color="secondary" onClick={() => setShowManagerModal(true)}>
          Add Lab Manager
        </Button>
      </div>
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        dataSource={labManagers}
        primaryKey="email"
      />
      {showManagerModal ? (
        <AddEditConsumerPhlebotomistModal
          hideModal={() => {
            setShowManagerModal(false);
            setEditItem({});
          }}
          editItem={editItem}
          isEditModal={editModal}
          labName={labName}
          isLoading={addManagerButtonLoading}
          isEditModalLoading={isEditModalLoading}
          title="Lab Manager"
          type="lab"
          onSuccessHandler={formSubmitHandler}
        />
      ) : (
        ''
      )}
      {confirmationModal === 'delete-manager' ? (
        <ConfirmationModal
          hideModal={() => {
            setConfirmationModal('');
            setDeleteItem({});
          }}
          renderHtml={confirmDeleteHtml}
          type="deleteModal"
          successButtonText="Delete Manager"
          btnColor="primary"
          isLoading={isConfirmationLoading}
          successHandler={() => confirmDeleteManagerHandler()}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
      {confirmationModal === 'status-update' ? (
        <ConfirmationModal
          hideModal={() => {
            setConfirmationModal('');
            setUpdateStatusItem({});
          }}
          renderHtml={confirmDeactivateHtml}
          successButtonText="Confirm"
          successHandler={() => confirmStatusUpdateHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default LabManagers;
