import { request } from 'helpers/api';

export const getAllOrders = (params) => request({
  method: 'GET',
  url: '/api/orders',
  params,
});

export const getOrderDetailsById = (orderId) => request({
  method: 'GET',
  url: `/api/orders/${orderId}`,
});

export const generateQRCode = (id) => request({
  method: 'POST',
  url: `/api/orders/${id}/generate-qr`,
});

export const assignTrackingCode = (trackingCode, params) => request({
  method: 'POST',
  url: `/api/orders/${trackingCode}/assign-tracking-code`,
  params,
});
