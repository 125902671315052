import React from 'react';

const PhoneNumberIcon = (props) => (
  <svg
    {...props}
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="50" height="50" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path
      d="M24.6312 12.1394C24.8032 12.1131 24.9779 12.1281 25.1512 12.1284C26.7036 12.1694 28.2507 12.4939 29.6929 13.0935C32.2116 14.1301 34.4007 16.0011 35.8694 18.363C36.8789 19.9767 37.5531 21.816 37.8247 23.7195C37.9079 24.2965 37.9587 24.8783 37.9691 25.4617C37.9693 25.6659 37.9879 25.8755 37.9273 26.0737C37.8188 26.4775 37.5009 26.8132 37.1106 26.926C36.7146 27.0533 36.2593 26.9366 35.9596 26.6411C35.7958 26.4833 35.6769 26.2758 35.6195 26.0519C35.5723 25.8725 35.5824 25.6854 35.5781 25.5015C35.5388 23.3335 34.8638 21.184 33.6676 19.406C32.8077 18.1194 31.6839 17.0243 30.3935 16.213C28.8351 15.231 27.0317 14.6695 25.2089 14.6094C25.0473 14.5918 24.8848 14.6066 24.723 14.5963C24.3542 14.5639 24.0064 14.3421 23.812 14.015C23.7013 13.8265 23.6328 13.6084 23.6324 13.3864C23.6197 12.9697 23.8321 12.5568 24.168 12.3298C24.3083 12.2358 24.466 12.1682 24.6312 12.1394Z"
      fill="#0047BA"
    />
    <path
      d="M17.8789 15.9622C18.4489 15.8831 19.0496 15.9291 19.5754 16.1844C20.0177 16.3906 20.3937 16.7678 20.5619 17.2448C20.986 18.5656 21.4098 19.8869 21.8346 21.2077C22.0003 21.7325 21.9228 22.3354 21.617 22.7893C21.4871 22.9945 21.3031 23.1523 21.1404 23.3279C20.8086 23.6721 20.4771 24.0164 20.1447 24.3594C20.8868 26.0608 22.0166 27.5779 23.4107 28.7583C24.1872 29.4188 25.0468 29.9722 25.958 30.4088C26.3178 30.0334 26.6803 29.6606 27.0407 29.2856C27.4611 28.8544 28.1006 28.6591 28.676 28.8131C30.1151 29.1843 31.5544 29.5555 32.9924 29.9318C33.3822 30.0526 33.7178 30.3328 33.9368 30.6859C34.2475 31.1753 34.365 31.7753 34.3456 32.3554C34.3153 33.2181 33.991 34.0526 33.4952 34.7394C32.9804 35.4541 32.2853 36.0148 31.5225 36.419C31.2778 36.5409 31.0367 36.6828 30.7677 36.7381C29.7854 36.9857 28.7544 36.898 27.7793 36.6584C26.805 36.416 25.8733 36.0164 24.9842 35.5416C23.2701 34.6121 21.7092 33.4005 20.2862 32.0469C19.0954 30.9096 18.0064 29.657 17.0421 28.3083C16.0093 26.8461 15.1309 25.2478 14.5912 23.5176C14.2175 22.2964 14.015 20.9965 14.1736 19.7176C14.1879 19.5922 14.2193 19.4688 14.2177 19.3417C14.2163 19.2822 14.2416 19.2273 14.2618 19.1729C14.561 18.4048 15.0016 17.6877 15.591 17.1242C16.2199 16.5146 17.0231 16.0864 17.8789 15.9622Z"
      fill="#0047BA"
    />
    <path
      d="M25.1959 17.0811C25.9003 17.1212 26.6036 17.2363 27.2777 17.456C28.9211 17.9717 30.3959 19.0364 31.4452 20.445C32.179 21.4267 32.7084 22.5734 32.9756 23.7847C33.1195 24.4025 33.1814 25.0387 33.1921 25.6732C33.2025 25.978 33.1118 26.2889 32.9206 26.5232C32.6786 26.8353 32.2822 27.0062 31.8985 26.9736C31.6201 26.9453 31.3473 26.8217 31.1518 26.6116C30.9179 26.3757 30.7916 26.0334 30.8016 25.6966C30.7948 24.4839 30.4345 23.2752 29.7742 22.2737C29.2585 21.4833 28.5638 20.8197 27.7625 20.3509C26.8782 19.8297 25.863 19.5532 24.8463 19.55C24.4608 19.5621 24.0795 19.3581 23.8558 19.0341C23.7091 18.8174 23.6233 18.5532 23.6325 18.2873C23.635 17.7836 23.9706 17.3062 24.4303 17.1458C24.6741 17.0459 24.9401 17.0788 25.1959 17.0811Z"
      fill="#0047BA"
    />
  </svg>
);
export default PhoneNumberIcon;
