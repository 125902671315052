import React from 'react';

const LatestOrdersIcon = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#C10230" />
    <g opacity="0.8">
      <path
        d="M11.8081 5.6483C11.9768 5.59607 12.164 5.61864 12.3203 5.70028C14.1969 6.51929 16.0743 7.33677 17.9494 8.15958C18.0735 8.1966 18.0821 8.39159 17.9623 8.43951C17.2657 8.76128 16.5676 9.08001 15.8695 9.39899C15.7848 9.44235 15.6796 9.45071 15.5949 9.40304C13.7185 8.54474 11.8432 7.68415 9.96731 6.82432C9.91636 6.80074 9.86189 6.78249 9.81674 6.74825C9.73806 6.68385 9.75647 6.53932 9.85306 6.50205C10.1784 6.35067 10.5116 6.21679 10.8379 6.06744C11.1635 5.9328 11.481 5.77965 11.8081 5.6483Z"
        fill="white"
      />
      <path
        d="M8.30625 7.17522C8.39351 7.13389 8.49919 7.1306 8.5872 7.17142C10.4177 8.01223 12.2491 8.85025 14.0804 9.68979C14.1762 9.73696 14.2763 9.7755 14.3701 9.82748C14.4402 9.86298 14.4569 9.95603 14.4259 10.0237C14.3921 10.0821 14.3247 10.1034 14.2677 10.1307C13.6562 10.4097 13.0458 10.6911 12.4332 10.968C12.3359 11.0197 12.2194 11.0139 12.1223 10.9652C10.1057 10.1211 8.08936 9.27573 6.07303 8.43061C5.95197 8.39029 5.94844 8.19505 6.07151 8.15448C6.81399 7.82257 7.56378 7.50688 8.30625 7.17522Z"
        fill="white"
      />
      <path
        d="M18.6472 9.11396C18.7456 9.09266 18.8306 9.19636 18.8182 9.29196C18.8182 11.4901 18.8187 13.6885 18.818 15.8866C18.8248 16.1242 18.6805 16.3562 18.4629 16.45C16.6887 17.2639 14.9124 18.0731 13.1374 18.885C13.0537 18.9177 12.9748 18.9747 12.8822 18.9755C12.8055 18.98 12.7349 18.9068 12.7432 18.8299C12.7417 16.5296 12.7435 14.2291 12.7435 11.9287C12.7334 11.8577 12.7733 11.788 12.8408 11.7634C14.4252 11.0375 16.0107 10.3146 17.5951 9.58888C17.9469 9.43319 18.2924 9.26178 18.6472 9.11396Z"
        fill="white"
      />
      <path
        d="M5.27105 9.11068C5.30787 9.09344 5.35276 9.09623 5.39059 9.10891C7.48687 9.98851 9.58391 10.8661 11.6804 11.7454C11.7791 11.7705 11.864 11.8469 11.8567 11.9567C11.857 14.2468 11.8565 16.5372 11.857 18.8274C11.8535 18.8893 11.8285 18.957 11.7725 18.9902C11.7082 19.01 11.6391 18.9983 11.5793 18.9692C9.65377 18.1613 7.72772 17.355 5.80218 16.5474C5.64985 16.4728 5.47105 16.4368 5.35276 16.3062C5.23499 16.1914 5.17849 16.025 5.18177 15.8618C5.18127 13.6717 5.18228 11.4817 5.18127 9.29173C5.17622 9.22022 5.20245 9.14238 5.27105 9.11068Z"
        fill="white"
      />
    </g>
  </svg>
);
export default LatestOrdersIcon;
