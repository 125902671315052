import React from 'react';

const ConsumerIconYellow = (props) => (
  <svg
    {...props}
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="55" height="55" rx="10" fill="#78818F" />
    <path
      d="M27.5678 13.0081C30.6727 12.8994 33.7965 13.8829 36.2887 15.7356C37.8055 16.8581 39.0978 18.2844 40.0579 19.9092C41.0941 21.6562 41.7424 23.6331 41.9346 25.6556C42.1525 27.8821 41.8239 30.1603 40.9794 32.2325C39.623 35.5974 36.9088 38.384 33.5825 39.8321C31.4249 40.7818 29.0229 41.1626 26.6766 40.9367C24.6108 40.7421 22.5922 40.0739 20.8158 39.0015C18.0907 37.3672 15.945 34.7862 14.8533 31.8006C14.1259 29.8307 13.8593 27.6945 14.0698 25.6059C14.2783 23.4921 14.9863 21.4318 16.1113 19.6312C17.3684 17.6132 19.1432 15.9203 21.2214 14.7641C23.1557 13.6818 25.3521 13.0727 27.5678 13.0081ZM27.5246 17.6976C26.8504 17.7825 26.204 18.0515 25.6554 18.4507C24.7215 19.1268 24.0632 20.1356 23.6993 21.2203C23.3607 22.2122 23.2743 23.2791 23.3791 24.3187C23.532 25.7176 24.1034 27.1022 25.1182 28.0956C25.8773 28.8467 26.9204 29.3447 28.0002 29.3278C28.8889 29.3422 29.7617 29.0061 30.4587 28.465C31.5232 27.6409 32.2028 26.3943 32.4923 25.0936C32.7867 23.7388 32.7092 22.2937 32.2197 20.993C31.7734 19.8085 30.9572 18.7242 29.8232 18.126C29.1247 17.7497 28.3115 17.6032 27.5246 17.6976ZM24.9951 30.9968C23.0966 31.6025 21.3302 32.7036 20.0667 34.258C19.8457 34.5564 19.8393 34.9699 19.9897 35.3005C20.0999 35.5696 20.3139 35.7746 20.5368 35.9529C21.7785 36.983 23.2381 37.7455 24.7856 38.1988C26.762 38.7846 28.8983 38.8188 30.8907 38.2891C32.6943 37.8155 34.3937 36.9239 35.7828 35.6763C36.0638 35.4271 36.2222 35.0265 36.1249 34.6552C36.0708 34.3886 35.8831 34.1835 35.7059 33.9894C34.5397 32.6406 32.9912 31.6402 31.2992 31.0872C31.1334 31.047 30.9522 30.9189 30.7879 31.0281C29.084 31.8671 26.9969 31.8805 25.2835 31.0604C25.1927 31.0237 25.0964 30.9477 24.9951 30.9968Z"
      fill="#FFC700"
    />
  </svg>
);
export default ConsumerIconYellow;
