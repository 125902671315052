import React from 'react';
import loadIcon from 'assets/images/loader.svg';

const Loader = () => (
  <div className="loader-wrapper">
    <div className="loader">
      <img src={loadIcon} alt="" />
      <h6>LOADING</h6>
    </div>
  </div>
);
export default Loader;
