import React from 'react';

const CloseModalIcon = (props) => (
  <svg {...props} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.46875 1.46875L15.5312 15.5312" stroke="#78818F" strokeWidth="2" strokeLinecap="round" />
    <path d="M1.46875 15.5312L15.5312 1.46875" stroke="#78818F" strokeWidth="2" strokeLinecap="round" />

  </svg>

);
export default CloseModalIcon;
