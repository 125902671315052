import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import ButtonWithLoader from 'components/shared/loading-button';
import CustomTextArea from 'components/shared/form-fields/custom-text-area';
import Loader from 'components/shared/loader';
import SelectField from 'components/shared/form-fields/select-field';

import {
  getLabGeneralInfo,
  updateLabGeneralInfo,
} from 'services/lab-management';
import catchHandler from 'helpers/catch-handler';
import { LookupContext } from 'context/lookup-context/lookup-context';
import CustomNumberField from 'components/shared/form-fields/custom-number-field';

const schema = Yup.object().shape({
  lab_name: Yup.string().required('Lab Name is required'),
  lab_license_number: Yup.string().required('Lab License is required'),
  lab_description: Yup.string().required('Lab Description is required'),
  street_address: Yup.string().required('Street Address is required'),
  lab_service_radius: Yup.string().required('Lab Service Radius is required'),
  state: Yup.object().required('State is required').nullable(),
  city: Yup.string().required('City is required'),
  zip: Yup.string().required('Zip is required'),
});

const buildFormData = (data) => {
  const formData = {
    lab_name: data?.lab_name || '',
    lab_license_number: data?.lab_license_number || '',
    lab_description: data?.lab_description || '',
    lab_service_radius: data?.lab_service_radius || '',
    street_address: data?.street_address || '',
    ...(!isEmpty(data?.state)
      ? {
        state: {
          label: data?.state,
          value: data?.state,
        },
      }
      : ''),
    city: data?.city || '',
    zip: data?.zip || '',
  };
  return formData;
};
const LabGeneralInfo = ({ handleLabDetails }) => {
  const { labId } = useParams();
  const { lookupDetails } = useContext(LookupContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [labGeneralInfo, setLabGeneralInfo] = useState({});

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(labGeneralInfo),
    resolver: yupResolver(schema),
  });

  const { reset, setError } = methods;

  const fetchLabGeneralInfo = useCallback(() => {
    setIsLoading(true);
    getLabGeneralInfo(labId)
      .then((response) => {
        setLabGeneralInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [labId]);

  const onSubmit = (formData) => {
    const payload = {
      lab_name: formData.lab_name,
      lab_license_number: formData.lab_license_number,
      lab_description: formData.lab_description,
      lab_service_radius: formData?.lab_service_radius,
      street_address: formData.street_address,
      state: formData.state?.value,
      city: formData.city,
      zip: formData.zip,
    };
    setIsButtonLoading(true);
    updateLabGeneralInfo(labId, payload)
      .then((res) => {
        setIsButtonLoading(false);
        handleLabDetails();
        fetchLabGeneralInfo();
        toast.success(res.data?.message || 'Lab General info updated successfully');
      })
      .catch((error) => {
        toast.error('Failed to update details');
        setIsButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  useEffect(() => {
    fetchLabGeneralInfo();
  }, [fetchLabGeneralInfo]);

  useEffect(() => {
    if (!isEmpty(labGeneralInfo)) {
      reset(buildFormData(labGeneralInfo));
    }
  }, [reset, labGeneralInfo]);

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <Loader />
      ) : (
        <Form className="tab__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="tab__form-sec">
            <Col xs="12" md="6">
              <FormGroup>
                <Label>Lab Name</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="lab_name"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup>
                <Label>Lab License Number (CLIA / CAP)</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="lab_license_number"
                />
              </FormGroup>
            </Col>
            <Col xs="12">
              <FormGroup className="form-group">
                <Label>Lab Description</Label>
                <CustomTextArea
                  className="form-control"
                  name="lab_description"
                  type="textarea"
                  placeholder="In a few words, describe the lab, including the lab’s vision, mission, and values"
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup className="form-group">
                <Label>Lab Service Radius</Label>
                <CustomNumberField
                  className="form-control"
                  name="lab_service_radius"
                  suffix=" Miles"
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <Label>Street Address</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="street_address"
                />
              </FormGroup>
            </Col>
            <Col xs="12" xl="4">
              <FormGroup>
                <Label>State</Label>
                <SelectField
                  name="state"
                  options={lookupDetails?.locationOptions}
                  className="selectbox width-auto"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" xl="4">
              <FormGroup>
                <Label>City</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="city"
                />
              </FormGroup>
            </Col>
            <Col xs="12" xl="4">
              <FormGroup>
                <Label>Zip</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="zip"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="tab__btn d-flex justify-content-center align-items-center">
            <ButtonWithLoader isLoading={isButtonLoading} color="secondary">
              Save Changes
            </ButtonWithLoader>
          </div>
        </Form>
      )}
    </FormProvider>
  );
};

export default LabGeneralInfo;
