import { request } from 'helpers/api';

export const getAllReview = (params) => request({
  method: 'GET',
  url: '/api/reviews',
  params,
});

export const viewReview = (id) => request({
  method: 'GET',
  url: `/api/reviews/${id}`,
});

export const reviewStatusUpdate = (id, params) => request({
  method: 'PUT',
  url: `/api/reviews/${id}`,
  params,
});
