import React from 'react';

const AddGreenIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="5" fill="#19D3C5" />
    <path
      d="M14.0203 9.68C14.1536 9.68 14.2203 9.74667 14.2203 9.88V10.72C14.2203 10.8533 14.1536 10.92 14.0203 10.92H10.7003C10.6469 10.92 10.6203 10.9467 10.6203 11V14.24C10.6203 14.3733 10.5536 14.44 10.4203 14.44H9.58027C9.44694 14.44 9.38027 14.3733 9.38027 14.24V11C9.38027 10.9467 9.35361 10.92 9.30027 10.92H5.98027C5.84694 10.92 5.78027 10.8533 5.78027 10.72V9.88C5.78027 9.74667 5.84694 9.68 5.98027 9.68H9.30027C9.35361 9.68 9.38027 9.65333 9.38027 9.6V6.2C9.38027 6.06667 9.44694 6 9.58027 6H10.4203C10.5536 6 10.6203 6.06667 10.6203 6.2V9.6C10.6203 9.65333 10.6469 9.68 10.7003 9.68H14.0203Z"
      fill="white"
    />
  </svg>
);
export default AddGreenIcon;
