import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { pick, debounce } from 'lodash';

import ListingComponent from 'components/shared/listing-component';
import Filter from 'components/shared/filter/filter';

import { PendingIcon, ApprovedIcon } from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { getAllPayoutData } from 'services/payout-system';
import { getAllLabDetails } from 'services/lab-management';
import { filterOptions } from 'helpers/utils';

const PayoutSystem = () => {
  const [, setHeader] = useContext(HeaderText);

  const [payoutsData, setPayoutsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [defaultOptions, setDefaultOptions] = useState([]);

  const fetchAllPayoutsData = (params) => {
    setIsLoading(true);
    getAllPayoutData(params)
      .then((response) => {
        setPayoutsData(response.data.data);
        setMeta(response.data.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchAllLabs = async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllLabDetails(params);
      const lab = (res?.data?.data || []).map((data) => ({
        value: data.lab_name,
        label: data.lab_name,
      }));
      setDefaultOptions(lab);
      if (callback) {
        return callback(lab);
      }
      return filterOptions(lab, 'All');
    } catch (e) {
      return [];
    }
  };

  const loadOptionsHandler = (searchKeyWord, callback) => {
    const lab = fetchAllLabs(searchKeyWord, callback);
    return lab;
  };

  const loadOptions = debounce(loadOptionsHandler, 400);

  useEffect(() => {
    fetchAllLabs();
  }, []);

  useEffect(() => {
    fetchAllPayoutsData(filter);
  }, [filter]);

  useEffect(() => {
    setHeader('Payout System');
  }, [setHeader]);

  const mappings = [
    {
      key: 'id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">
          {item.payout_id}
        </span>
      ),
    },
    {
      key: 'account_owner',
      label: 'ACCOUNT OWNER',
      render: (item) => <b>{item.account_owner}</b>,
    },
    {
      key: 'lab_name',
      label: 'LAB NAME',
    },
    {
      key: 'amount',
      label: 'AMOUNT',
      render: (item) => (
        <>
          $
          {' '}
          {item.amount}
        </>
      ),
    },
    {
      key: 'date',
      label: 'DATE',
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getPaymentStatus(item?.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center ',
      render: (item) => (
        <Link to={`/payouts/${item?.payout_id}`} className="inline_link link-action">
          View
        </Link>
      ),
    },
  ];

  const getPaymentStatus = (currentStatus) => {
    switch (currentStatus) {
      case 'pending':
        return <PendingIcon className="table__status-icon" />;
      case 'paid':
        return <ApprovedIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <React.Fragment>
        <Filter
          filters={[
            {
              filter_type: 'custom-input',
              name: 'search',
              placeholder: 'Search by Account Owner, Lab Name',
              type: 'text',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'async-select',
              name: 'lab_name',
              loadOptions,
              defaultOptions: filterOptions(defaultOptions, 'All Labs'),
              placeholder: 'Lab Name',
              inputProps: {
                className: 'selectbox',
                components: {
                  IndicatorSeparator: () => null,
                },
              },
            },
            {
              filter_type: 'button',
              name: 'submit',
              inputProps: {
                className: 'table__button',
              },
            },
          ]}
          onFilter={(params) => setFilter((prev) => ({
            ...pick(prev, ['per_page', 'sort']),
            page: 1,
            ...params,
          }))}
          onPerPageChange={(perPage, page) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          meta={meta}
        />
        <ListingComponent
          isLoading={isLoading}
          mappings={mappings}
          meta={meta}
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          dataSource={payoutsData}
          shouldPaginate
          primaryKey="payout_id"
        />
      </React.Fragment>
    </div>
  );
};

export default PayoutSystem;
