import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { Input, FormFeedback } from 'reactstrap';

import { generateRandomString } from 'helpers/utils';

const CustomTextArea = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    children,
    label,
    name: propsName,
    placeholder = '',
    type,
    defaultValue,
    clearError,
    ...inputProps
  } = props;

  const { message: errorMessage = false } = get(errors, propsName, {});

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange, onBlur, value, name,
          },
        }) => (
          <Input
            autoComplete="off"
            invalid={!!errorMessage}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            type="textarea"
            label={label}
            value={value || ''}
            id={generateRandomString()}
            {...inputProps}
          >
            {children}
          </Input>
        )}
      />
      <React.Fragment>
        {errorMessage ? (
          <FormFeedback className="ml-2">{errorMessage}</FormFeedback>
        ) : (
          ''
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default CustomTextArea;
