import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';

const TextEditor = (props) => {
  const { control } = useFormContext();

  const {
    name: propsName,
    placeholder,
    defaultValue,
    customizedTextHandler,
    htmlText = null,
    ...otherProps
  } = props;

  const [editorState, setEditorState] = useState();

  const onEditorStateChange = (current) => {
    setEditorState(current);
  };

  useEffect(() => {
    const contentBlock = htmlToDraft(htmlText);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const parsedText = EditorState.createWithContent(contentState);
    setEditorState(parsedText);
  }, [htmlText]);

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange, onBlur, value, name,
          },
          fieldState: { error },
        }) => (
          <React.Fragment>
            <Editor
              name={name}
              onBlur={onBlur}
              onChange={() => {
                onChange(
                  draftToHtml(convertToRaw(editorState.getCurrentContent())),
                );
              }}
              placeholder={placeholder || ''}
              value={value || placeholder}
              toolbar={{
                options: ['inline', 'textAlign', 'fontSize', 'list', 'history'],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
                textAlign: {
                  options: ['left', 'center'],
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
              }}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              {...otherProps}
            />
            {error && error.message ? (
              <small className="error-color ml-2">{error.message}</small>
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default TextEditor;
