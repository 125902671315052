import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import Select, { components } from 'react-select';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import Moment from 'moment';
import { isEqual, isObject, omit } from 'lodash';
import { Button, CustomInput } from 'reactstrap';
import {
  addDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
} from 'date-fns';
import AsyncSelect from 'react-select/async';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import useOutsideClick from 'hooks/use-outside-click';
import InputCloseIcon from 'assets/images/input-close-icon';

const Placeholder = (props) => <components.Placeholder {...props} />;

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLast30Days: startOfDay(addDays(new Date(), -29)),
  endOfLast30Days: endOfDay(new Date()),
};

const sideBarOptions = () => {
  const customRangeObjects = [
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: defineds.startOfLast30Days,
        endDate: defineds.endOfLast30Days,
        staticRange: true,
      }),
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
        staticRange: true,
      }),
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
        staticRange: true,
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
        staticRange: true,
      }),
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
        staticRange: true,
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
        staticRange: true,
      }),
    },
  ];

  return customRangeObjects;
};

const staticRanges = [...createStaticRanges(sideBarOptions())];

const FilterForm = (props) => {
  const {
    filters = [],
    onFilter = null,
    defaultValues = {},
    currentFilters,
    clearFilter,
  } = props;

  const [showRangePicker, setShowRangePicker] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [initialCurrentFilters, setInitialCurrentFilters] = useState([]);
  const [beforeDebouncedChange, setBeforeDebouncedChange] = useState({});
  const [stepCount, setStepCount] = useState(0);
  const [dateRange, setDateRange] = useState({});
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [filterObject, setFilterObject] = useState({});
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (showRangePicker) {
      setShowRangePicker(false);
    }
  });

  const onFocusChange = (range) => {
    const [, rangeStep] = range;
    setFocusedRange(range);
    setStepCount(rangeStep);
    if (rangeStep === 0) {
      setShowRangePicker(false);
    }
  };

  const parseDefaultValues = (values) => {
    const object = {};
    Object.keys(values).map((key) => {
      if (isObject(values[key]) && values[key]?.type === 'date-range') {
        // construct default value
        object[key] = {
          startDate: Moment(values[key].startDate).toDate(),
          endDate: Moment(values[key].endDate).toDate(),
          key: 'selection',
        };
      } else {
        object[key] = values[key];
      }
      return true;
    });
    return object;
  };

  const parseAndSubmitFilter = useCallback(
    (values, resetFilter) => {
      const object = {};
      filterOptions
        && filterOptions.map((filter) => {
          if (
            filter.filter_type === 'select'
            || filter.filter_type === 'async-select'
          ) {
            object[filter.name] = values[filter.name]?.value;
          } else if (filter.filter_type === 'date-range') {
            if (
              values[filter.name]?.endDate
              && values[filter.name]?.startDate
            ) {
              object[filter.from_name] = Moment(
                values[filter.name]?.startDate,
              ).format('YYYY-MM-DD');
              object[filter.to_name] = Moment(
                values[filter.name]?.endDate,
              ).format('YYYY-MM-DD');
            }
          } else if (values[filter.name]) {
            object[filter.name] = values[filter.name];
          }
          return false;
        });
      setFilterObject(object);
      if (resetFilter?.reset) {
        onFilter(object, values, resetFilter?.reset);
      }
    },
    [filterOptions, onFilter],
  );

  const onChange = (key, value) => {
    const newFilter = {
      ...filterValues,
      [key]: value,
    };
    setFilterValues(newFilter);
    parseAndSubmitFilter(newFilter);
  };

  useEffect(() => {
    setFilterOptions(filters);
  }, [filterOptions, filters]);

  useEffect(() => {
    if (!isEqual(defaultValues, defaultFilters)) {
      setDefaultFilters(defaultValues);
      setFilterValues(parseDefaultValues(defaultValues));
    }
  }, [defaultFilters, defaultValues]);

  useEffect(() => {
    if (!isEqual(initialCurrentFilters, currentFilters) && currentFilters) {
      setInitialCurrentFilters(currentFilters);
      setFilterValues(currentFilters);
      setBeforeDebouncedChange(currentFilters);
    }
  }, [initialCurrentFilters, currentFilters]);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  const displayDateRange = (range) => {
    if (
      range
      && Moment(range?.endDate).format('YYYY-MM-DD')
        === Moment().format('YYYY-MM-DD')
    ) {
      return `Last ${
        Moment(range?.endDate).diff(Moment(range?.startDate), 'days') + 1
      } days`;
    }

    return range
      ? `From: ${Moment(range?.startDate).format('MM/DD/YYYY')} to ${Moment(
        range?.endDate,
      ).format('MM/DD/YYYY')}`
      : 'Select Date';
  };

  useEffect(() => {
    if (dateRange) {
      if (stepCount === 0) {
        onChange(dateRange.name, dateRange.range);
        if (dateRange?.range?.staticRange) {
          setShowRangePicker(false);
        }
      } else {
        setFilterValues((prevFilter) => ({
          ...prevFilter,
          dateRange: dateRange.range,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepCount, dateRange]);

  const onSubmitHandler = () => {
    let finalPayload = {};
    Object.keys(filterObject).forEach((key) => {
      if (filterObject[key]) {
        finalPayload = {
          ...finalPayload,
          [key]: filterObject[key],
        };
      }
    });
    onFilter(finalPayload);
  };

  const resetHandler = useCallback(
    (inputName) => {
      if (inputName) {
        if (filterValues?.[inputName]) {
          setBeforeDebouncedChange(
            omit(beforeDebouncedChange, [`${inputName}`]),
          );
          setFilterValues(
            parseDefaultValues(omit(filterValues, [`${inputName}`])),
          );
          parseAndSubmitFilter(
            parseDefaultValues(omit(filterValues, [`${inputName}`])),
            { reset: true },
          );
        }
      } else {
        setBeforeDebouncedChange({});
        setFilterValues(defaultValues);
        parseAndSubmitFilter(defaultValues);
      }
    },
    [defaultValues, parseAndSubmitFilter, beforeDebouncedChange, filterValues],
  );

  useEffect(() => {
    if (clearFilter) {
      clearFilter.current = resetHandler;
    }
  }, [clearFilter, resetHandler]);

  const inputChangeHandler = (inputValue, event) => {
    if (event.action === 'input-change' || event.action === 'set-value') {
      setSearchKeyWord(inputValue);
    }
  };

  const renderFilter = (filter) => {
    switch (filter.filter_type) {
      case 'custom-input':
        return (
          <div className="table__searchbox-outer">
            <CustomInput
              autoComplete="off"
              name={filter.name}
              onChange={(event) => {
                if (filter.type === 'checkbox') {
                  onChange(filter.name, event.target.checked);
                } else {
                  setBeforeDebouncedChange({
                    ...beforeDebouncedChange,
                    [filter.name]: event.target.value,
                  });
                  onChange(filter.name, event.target.value);
                }
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  onSubmitHandler();
                }
              }}
              placeholder={filter.placeholder}
              type={filter.type}
              label={filter.label}
              id={filter.name}
              value={
                filter.type === 'checkbox'
                  ? filterValues[filter.name]
                  : beforeDebouncedChange[filter.name] ?? ''
              }
              {...filter.inputProps}
            />
            <Button onClick={() => resetHandler(filter.name)}>
              <InputCloseIcon />
            </Button>
          </div>
        );
      case 'select':
        return (
          <Select
            closeMenuOnSelect
            name={filter.name}
            onChange={(value) => onChange(filter.name, value)}
            options={filter.options}
            placeholder={filter.placeholder || ''}
            value={filterValues[filter.name] || filter.placeholder}
            components={{ Placeholder }}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: '#A6A6A6',
              }),
            }}
            {...filter.inputProps}
          />
        );
      case 'date-range':
        return (
          <div
            ref={ref}
            className={`global-date-picker ${filter?.inputProps?.className}`}
          >
            <div className="dark">
              <CustomInput
                name={`${filter.name}DateDisplay`}
                placeholder={filter.placeholder || 'Please select a Date Range'}
                onClick={() => setShowRangePicker(true)}
                value={displayDateRange(filterValues[filter.name])}
                autoComplete="off"
                className="form-control"
                id={`${filter.name}DateDisplay`}
                type="text"
                readOnly
              />
              {showRangePicker && (
                <div className="date-range-picker tl-date-range">
                  <DateRangePicker
                    color="#ff0000"
                    name={filter.name}
                    ranges={
                      filterValues[filter.name]
                        ? [filterValues[filter.name]]
                        : [selectionRange]
                    }
                    onChange={(range) => {
                      setDateRange({
                        name: filter.name,
                        range: range && range.selection,
                      });
                    }}
                    onRangeFocusChange={onFocusChange}
                    focusedRange={focusedRange}
                    months={2}
                    direction="horizontal"
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    showMonthAndYearPickers={false}
                    monthDisplayFormat="MMMM"
                    weekdayDisplayFormat="EEEEE"
                    staticRanges={staticRanges}
                    inputRanges={[]}
                  />
                </div>
              )}
            </div>
            {/* <CalendarIcon /> */}
          </div>
        );
      case 'async-select':
        return (
          <AsyncSelect
            closeMenuOnSelect
            name={filter.name}
            inputValue={searchKeyWord}
            onInputChange={inputChangeHandler}
            loadOptions={filter.loadOptions}
            onChange={(value) => onChange(filter.name, value)}
            defaultOptions={filter.defaultOptions}
            options={filter.options}
            placeholder={filter.placeholder || ''}
            components={{ Placeholder }}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: '#A6A6A6',
              }),
            }}
            {...filter.inputProps}
          />
        );
      case 'button':
        return (
          <div {...filter.inputProps}>
            <Button color="secondary" onClick={() => onSubmitHandler()}>
              Search
            </Button>
          </div>
        );

      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      {filterOptions
        && filterOptions.map((filter) => (
          <React.Fragment key={filter.name}>
            {renderFilter(filter)}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default FilterForm;
