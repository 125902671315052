import React from 'react';

const TestKitIcon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path
      d="M11.5978 12.0181C11.8949 11.9828 12.1978 12.0128 12.4974 12.0029C12.5066 16.0392 12.4985 20.0755 12.5017 24.1118C12.4985 25.6076 12.5066 27.1038 12.4974 28.5992C12.1981 28.5876 11.8949 28.6201 11.5978 28.5837C11.4914 28.5774 11.3833 28.5784 11.2804 28.547C11.2184 28.5315 11.1574 28.5128 11.0964 28.4934C11.0517 28.4726 11.0063 28.4536 10.9613 28.4342C10.8084 28.3615 10.6652 28.2684 10.54 28.1548C10.5311 28.1446 10.5134 28.1238 10.5045 28.1132L10.4893 28.1146C10.4113 28.0145 10.3205 27.9238 10.2546 27.8145C10.2322 27.7757 10.2092 27.7372 10.1861 27.6992C10.1581 27.6438 10.1319 27.5877 10.1092 27.5302C10.029 27.3292 9.99746 27.1123 10.0003 26.8971C9.99995 22.5 9.99995 18.1029 10.0003 13.7058C9.9964 13.4899 10.0304 13.2744 10.1074 13.0723C10.1404 12.9983 10.1751 12.9252 10.2067 12.8505C10.2138 12.8487 10.2283 12.8448 10.2354 12.8431L10.2308 12.8159C10.3092 12.7006 10.4003 12.5948 10.4886 12.4872L10.5045 12.4882C10.5131 12.478 10.5308 12.4572 10.5397 12.447C10.6999 12.2978 10.8943 12.192 11.096 12.1091C11.1574 12.0893 11.2191 12.0696 11.2815 12.053C11.3836 12.0216 11.4921 12.0248 11.5978 12.0181Z"
      fill="#0047BA"
    />
    <path
      d="M14.165 12.02C14.264 11.9921 14.3675 12.0041 14.4689 12.0024C18.2557 12.0024 22.0425 12.0031 25.829 12.002C25.8368 17.5281 25.8308 23.0546 25.8322 28.5807C21.944 28.5811 18.0554 28.5811 14.1672 28.5811C14.1654 23.0606 14.1693 17.5401 14.165 12.02ZM18.3444 16.1547C18.3401 16.9804 18.3433 17.8057 18.3426 18.631C18.3284 18.6296 18.2997 18.6264 18.2855 18.6247L18.2806 18.6543C18.082 18.6465 17.883 18.6599 17.6848 18.6473L17.6483 18.6056L17.6462 18.654C17.1153 18.6465 16.5838 18.6596 16.0533 18.6476L16.0171 18.6046L16.0157 18.6536C15.954 18.6525 15.893 18.6529 15.832 18.6532C15.8363 19.7576 15.8278 20.8622 15.8363 21.9669C16.6657 21.9648 17.4955 21.9662 18.3249 21.9658C18.3245 22.7911 18.3267 23.6161 18.3238 24.4414C19.4408 24.4474 20.5582 24.4474 21.6748 24.4414C21.673 23.6161 21.6745 22.7911 21.6741 21.9658C22.5039 21.9662 23.3333 21.9651 24.1631 21.9665C24.1719 20.8622 24.1631 19.7579 24.1677 18.654C23.4985 18.6508 22.8294 18.6515 22.1606 18.6536C22.1393 18.6462 22.0968 18.6321 22.0755 18.625L22.0745 18.655C21.9603 18.6515 21.8464 18.6515 21.7326 18.6522C21.7124 18.6437 21.6716 18.6271 21.6514 18.6187C21.6539 18.5954 21.6596 18.5492 21.6624 18.5259L21.7011 18.5012C21.6209 18.4811 21.6699 18.3824 21.656 18.3256C21.6567 17.6018 21.6581 16.8785 21.655 16.1547C20.5514 16.1565 19.4479 16.1561 18.3444 16.1547Z"
      fill="#0047BA"
    />
    <path
      d="M27.5018 12.0026C27.8011 12.0128 28.1043 11.9822 28.4018 12.0178C28.5081 12.0248 28.617 12.0217 28.7202 12.0538C28.7819 12.07 28.8429 12.0894 28.9042 12.1074C29.0915 12.1853 29.2755 12.2805 29.4244 12.4195L29.4521 12.4181L29.4475 12.4449C29.4592 12.453 29.483 12.4685 29.495 12.4763C29.5681 12.5521 29.6337 12.6347 29.6996 12.7168C29.7145 12.7401 29.7294 12.7634 29.7446 12.7867C29.7666 12.8248 29.7893 12.8629 29.8124 12.9009C29.8407 12.957 29.8673 13.0142 29.8915 13.0724C29.9032 13.1076 29.9152 13.1429 29.928 13.1778C30.0188 13.4624 29.9968 13.7637 29.9993 14.0578C29.9989 18.3378 29.9996 22.6178 29.9989 26.8979C30.0017 27.1123 29.968 27.3274 29.8907 27.5285C29.8514 27.628 29.8024 27.7232 29.7439 27.8131C29.678 27.9225 29.589 28.0145 29.5103 28.1143L29.495 28.1133C29.4861 28.1235 29.4688 28.144 29.4599 28.1542C29.3337 28.2685 29.19 28.363 29.0351 28.4346C28.9911 28.455 28.9468 28.4751 28.9035 28.497C28.8808 28.4991 28.8354 28.5037 28.8128 28.5058L28.8138 28.5284C28.384 28.6339 27.9394 28.588 27.5014 28.6C27.4929 24.4931 27.5004 20.386 27.4975 16.2791C27.5007 14.8535 27.4922 13.4279 27.5018 12.0026Z"
      fill="#0047BA"
    />
  </svg>
);
export default TestKitIcon;
