import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  Button,
  Form,
} from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import Loader from 'components/shared/loader';
import ListingComponent from 'components/shared/listing-component';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import LabConsumerOrderModal from 'components/shared/modals/order-registered-kit-info-modal';
import ButtonWithLoader from 'components/shared/loading-button';
import FedexTrackingModal from 'components/orders/fedex-tracking-status-modal';

import {
  ChevronLeftIcon,
  PendingIcon,
  ConsumerIcon,
  TestKitIcon,
  LabBlueIcon,
  OrderDateIcon,
  PendingDeliveryIcon,
  OrderSubmittedIcon,
  AlertYellowIcon,
  TrackingCodeIcon,
  ApprovedIcon,
  DeactivateIcon,
} from 'assets/images/svg-icons';
import {
  assignTrackingCode,
  generateQRCode,
  getOrderDetailsById,
} from 'services/orders';
import catchHandler from 'helpers/catch-handler';
import HeaderText from 'header-context';
import { generateRandomString } from 'helpers/utils';

const schema = Yup.object().shape({
  tracking_code: Yup.number().typeError('Enter a valid tracking code '),
});

const OrderDetails = () => {
  const { orderId } = useParams();
  const history = useHistory();

  const [, setHeader] = useContext(HeaderText);
  const [showModal, setShowModal] = useState('');
  const [orderDetails, setOrderDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [generateQRCodeLoading, setGenerateQRCodeLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showFedexTrackingModal, setShowFedexTrackingModal] = useState(false);

  const methods = useForm({
    defaultValues: {
      tracking_code: '',
    },
    resolver: yupResolver(schema),
  });

  const { setError, setValue } = methods;

  useEffect(() => {
    if (orderDetails?.tracking_code) {
      setValue('tracking_code', orderDetails?.tracking_code);
    }
  }, [setValue, orderDetails?.tracking_code]);

  useEffect(() => {
    setHeader('Orders');
  });

  const fetchOrderDetails = useCallback(() => {
    getOrderDetailsById(orderId)
      .then((response) => {
        setOrderDetails(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [orderId]);

  useEffect(() => {
    setIsLoading(true);
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  const handleQRCodeGenerate = () => {
    setGenerateQRCodeLoading(true);
    generateQRCode(orderDetails?.purchase_order_item_id)
      .then(() => {
        fetchOrderDetails();
        setGenerateQRCodeLoading(false);
      })
      .catch((error) => {
        setGenerateQRCodeLoading(false);
        toast.error(error?.response?.data?.message || 'Something went wrong !');
      });
  };

  const handleTrackingCode = (formData) => {
    setIsButtonLoading(true);
    const payload = {
      tracking_code: formData?.tracking_code,
    };
    assignTrackingCode(orderDetails?.purchase_order_item_id, payload)
      .then((res) => {
        toast.success(
          res?.data?.message || 'Tracking code assigned successfully',
        );
        setIsButtonLoading(false);
        fetchOrderDetails();
      })
      .catch((error) => {
        catchHandler(error, setError, true);
        setIsButtonLoading(false);
      });
  };

  const covertQrCodeToImage = (imageSvg) => {
    const blob = new Blob([imageSvg], { type: 'image/svg+xml' });
    const convertedSvg = URL.createObjectURL(blob);
    return convertedSvg;
  };

  const getOrderHistoryIcon = (slug) => {
    switch (slug) {
      case 'order-placed':
        return <OrderSubmittedIcon className="inline-card__image" />;
      case 'pending-delivery':
        return <PendingDeliveryIcon className="inline-card__image" />;
      case 'delivered':
        return <ApprovedIcon className="inline-card__image" />;
      case 'cancelled':
        return <DeactivateIcon className="inline-card__image" />;
      default:
        return null;
    }
  };

  const getOrderStatus = (slug, status) => {
    switch (slug) {
      case 'order-placed':
        return (
          <button
            type="button"
            className="button-icon button-icon--gray cursor-auto"
          >
            <OrderSubmittedIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'pending-delivery':
        return (
          <button
            type="button"
            className="button-icon button-icon--yellow cursor-auto"
          >
            <PendingDeliveryIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'delivered':
        return (
          <button type="button" className="button-icon cursor-auto">
            <ApprovedIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      case 'cancelled':
        return (
          <button
            type="button"
            className="button-icon button-icon--red cursor-auto"
          >
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
          </button>
        );
      default:
        return null;
    }
  };

  const getPaymentStatus = (paymentStatus) => {
    switch (paymentStatus) {
      case 'pending':
        return <PendingIcon className="table__status-icon" />;
      case 'paid':
        return <ApprovedIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const mappings = [
    {
      key: 'payment_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">
          {item.payment_id}
        </span>
      ),
    },
    {
      key: 'product_name',
      label: 'PRODUCT NAME',
    },
    {
      key: 'product_type',
      label: 'PRODUCT TYPE',
    },
    {
      key: 'price',
      label: 'PRICE',
      className: 'text-center',
      render: (item) => (
        <>
          $
          {item?.price}
        </>
      ),
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getPaymentStatus(item?.payment_status_slug)}
          {item?.payment_status}
        </div>
      ),
    },
    {
      key: 'ordered_date',
      label: 'ORDERED DATE',
      render: (item) => moment(item.ordered_date).format('MMM DD, YYYY HH:MMa'),
    },
  ];

  const consumerModalData = {
    name: orderDetails?.ordered_by,
    registeredDate: orderDetails?.registered_on,
    lastLogin: orderDetails?.last_login,
    email: orderDetails?.consumer_email,
    phoneNumber: orderDetails?.consumer_phone_number,
    streetAddress: orderDetails?.consumer_street_address,
  };

  const labModalData = {
    labId: orderDetails?.lab_id,
    name: orderDetails?.lab_name,
    registeredDate: orderDetails?.lab_registered_on,
    lastLogin: orderDetails?.lab_last_login,
    email: orderDetails?.lab_email,
    phoneNumber: orderDetails?.lab_phone_number,
    streetAddress: orderDetails?.lab_street_address,
  };

  const payoutInfoData = [
    {
      payment_id: orderDetails?.payment_id || generateRandomString(),
      product_name: orderDetails?.product_name,
      product_type: orderDetails?.product_type,
      price: orderDetails?.price,
      ordered_date: orderDetails?.submit_date,
      payment_status: orderDetails?.payment_status,
      payment_status_slug: orderDetails?.payment_status_slug,
    },
  ];

  const handlePrintQrCode = () => {
    document.querySelector('body').classList.add('qr-code-print');
    window.print();
  };

  useEffect(() => {
    const printEvent = window.addEventListener('afterprint', () => {
      document.querySelector('body').classList.remove('qr-code-print');
    });
    return () => {
      document.removeEventListener(printEvent, null);
    };
  }, []);

  const getProductIdForPrint = (id) => {
    const formattedId = id?.split('-');
    return (
      <>
        <span className="pdf-blue">{formattedId?.[0]}</span>
        -
        <span className="pdf-green">{formattedId?.[1]}</span>
        -
        <span className="pdf-red">{formattedId?.[2]}</span>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="order-detail">
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="dashboard__whitebox dashboard__whitebox--wrap order-detail__strip ">
              <div className="details__header-outer">
                <div className="details__header">
                  <h1 className="details__header-main">
                    Order #
                    {orderDetails?.order_id}
                  </h1>
                </div>
                <div className="details__header-button">
                  <Link to="/orders" className="button-icon button-icon--blue">
                    <ChevronLeftIcon />
                    <span className="button-icon__text">Back to List</span>
                  </Link>
                  {getOrderStatus(
                    orderDetails?.status_slug,
                    orderDetails?.status,
                  )}
                </div>
              </div>
            </div>
            <div className="order-detail__wraper">
              <Row xs="1" xl="2">
                <Col>
                  <div className="order-detail__box">
                    <h2 className="details__sub-head details__sub-head--line">
                      General
                      {' '}
                      <span>Info</span>
                    </h2>
                    <Row className="order-detail__card-outer" xs="1" md="2">
                      <Col
                        className="inline-card cursor-pointer"
                        onClick={() => setShowModal('consumerInfo')}
                        role="button"
                      >
                        <ConsumerIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">Consumer</div>
                          <div className="inline-card__main-text">
                            {orderDetails?.ordered_by}
                          </div>
                        </div>
                      </Col>
                      <Col className="inline-card">
                        <OrderDateIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">
                            Ordered Date
                          </div>
                          <div className="inline-card__main-text">
                            {moment(orderDetails?.submit_date).format(
                              'MMMM DD, YYYY',
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="inline-card "
                        onClick={() => setShowModal('labInfo')}
                        role="button"
                      >
                        <LabBlueIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">Lab</div>
                          <div className="inline-card__main-text">
                            {orderDetails?.lab_name}
                          </div>
                        </div>
                      </Col>
                      <Col className="inline-card">
                        <TestKitIcon className="inline-card__image" />
                        <div className="inline-card__content">
                          <div className="inline-card__sub-text">Test Kit</div>
                          <div className="inline-card__main-text">
                            {orderDetails?.product_name}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="">
                  <div className="order-detail__box mb-0">
                    <h2 className="details__sub-head details__sub-head--line">
                      Order
                      {' '}
                      <span>Status</span>
                    </h2>
                    <Row>
                      {orderDetails?.status_history?.map((item) => (
                        <Col
                          key={item?.status_slug}
                          className="inline-card inline-card--single"
                          xs="12"
                        >
                          {getOrderHistoryIcon(item?.status_slug)}
                          <div className="inline-card__content">
                            <div className="inline-card__main-text">
                              {item?.status}
                            </div>
                            <div className="inline-card__sub-text">
                              {moment(item?.date).format('MMMM DD, YYYY')}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="order-detail__section order-detail__card-wrap">
              <Row>
                <Col xs="12" xl="6">
                  <h2 className="details__sub-head details__sub-head--line">
                    QR
                    {' '}
                    <span>Code</span>
                  </h2>
                  {generateQRCodeLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {orderDetails.qr_code !== null ? (
                        <div className="order-detail__qrcode-wrap order-detail__card">
                          <div className="order-detail__qrcode">
                            <div className="order-detail__qrcode-content">
                              <div className="order-detail__qrcode-head">
                                {orderDetails?.unique_code}
                              </div>
                              <p>Unique code generated for this order.</p>
                              <Button
                                color="secondary"
                                onClick={handlePrintQrCode}
                              >
                                Print QR Code
                              </Button>
                            </div>
                            <div className="order-detail__qrcode-image">
                              <img
                                src={covertQrCodeToImage(orderDetails?.qr_code)}
                                alt="qrcode"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="order-detail__qrcode-wrap order-detail__card">
                          <div className="order-detail__qrcode-generate ">
                            {orderDetails?.status_slug !== 'cancelled' ? (
                              <>
                                <p>
                                  QR Code generation pending. Please use below
                                  button generate and print QR code.
                                </p>
                                <div>
                                  <ButtonWithLoader
                                    color="secondary"
                                    type="button"
                                    onClick={() => handleQRCodeGenerate()}
                                  >
                                    Generate QR
                                  </ButtonWithLoader>
                                </div>
                              </>
                            ) : (
                              <p>
                                This order has been cancelled by the customer,
                                and you cannot generate a QR code for this order
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Col>
                <Col xs="12" xl="6">
                  <h2 className="details__sub-head details__sub-head--line">
                    Fedex
                    {' '}
                    <span>Shipping</span>
                  </h2>
                  <div className="order-detail__fedex-wrap order-detail__card">
                    {orderDetails?.qr_code ? (
                      <div className="order-detail__fedex">
                        <FormProvider {...methods}>
                          <Form
                            className="order-detail__fedex-form"
                            onSubmit={methods.handleSubmit(handleTrackingCode)}
                          >
                            <div className="order-detail__track-code">
                              <CustomInputField
                                name="tracking_code"
                                className="form-control"
                                placeholder="Enter Tracking Code"
                              />
                              <Button className="" type="button">
                                <TrackingCodeIcon />
                              </Button>
                            </div>
                            <div className="order-detail__track-btn">
                              <ButtonWithLoader
                                isLoading={isButtonLoading}
                                color="secondary"
                              >
                                {orderDetails?.tracking_code
                                  ? 'Update Tracking Code'
                                  : 'Assign Tracking Code'}
                              </ButtonWithLoader>
                              {!isEmpty(orderDetails?.tracking_events) ? (
                                <Button
                                  color="secondary"
                                  type="button"
                                  onClick={() => setShowFedexTrackingModal(true)}
                                >
                                  View Tracking Info
                                </Button>
                              ) : (
                                ''
                              )}
                            </div>
                          </Form>
                        </FormProvider>
                      </div>
                    ) : (
                      <div className="order-detail__fedex-alert ">
                        {orderDetails?.status_slug !== 'cancelled' ? (
                          <>
                            <AlertYellowIcon />
                            <p>
                              Please complete QR Code generation before
                              accessing this section
                            </p>
                          </>
                        ) : (
                          <p> This order has been cancelled by the customer</p>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="order-detail__section">
              <h2 className="details__sub-head ">
                Payment
                {' '}
                <span>Info</span>
              </h2>
              <ListingComponent
                isLoading={isLoading}
                mappings={mappings}
                dataSource={payoutInfoData}
                primaryKey="payment_id"
              />
            </div>
            {showModal === 'consumerInfo' ? (
              <LabConsumerOrderModal
                modalData={consumerModalData}
                hideModal={() => setShowModal('')}
              />
            ) : (
              ' '
            )}
            {showModal === 'labInfo' ? (
              <LabConsumerOrderModal
                modalData={labModalData}
                type="labModal"
                viewLabDetails={() => history.push(`/lab-management/${orderDetails?.lab_id}`)}
                hideModal={() => setShowModal('')}
              />
            ) : (
              ' '
            )}
            {showFedexTrackingModal ? (
              <FedexTrackingModal
                hideModal={() => setShowFedexTrackingModal(false)}
                orderDetails={orderDetails}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      </div>
      <div id="print-area">
        <div className="order-detail__qrcode-image">
          <img
            id="print-qr"
            src={covertQrCodeToImage(orderDetails?.qr_code)}
            alt="qrcode"
          />
        </div>
        <p>{getProductIdForPrint(orderDetails?.unique_code)}</p>
        <p>
          <b>{orderDetails?.product_name}</b>
        </p>
      </div>
    </React.Fragment>
  );
};

export default OrderDetails;
