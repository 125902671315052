import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap';
import { pick, debounce } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import Filter from 'components/shared/filter/filter';
import ListingComponent from 'components/shared/listing-component';
import ResultPreviewModal from 'components/test-results/result-preview-modal';

import {
  PendingDeliveryIcon,
  ApprovedIcon,
  DeactivateIcon,
  DotIcon,
  PendingSchedulingIcon,
} from 'assets/images/svg-icons';

import { getAllTestResults, updateTestResult } from 'services/test-result';
import { getAllConsumers } from 'services/consumers';
import { filterOptions } from 'helpers/utils';
import HeaderText from 'header-context';
import { LookupContext } from 'context/lookup-context/lookup-context';

const TestResults = () => {
  const [, setHeader] = useContext(HeaderText);
  const { lookupDetails } = useContext(LookupContext);

  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [testResultData, setTestResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState('');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [updateResultStatusItem, setUpdateResultStatusItem] = useState({});

  const confirmPublishHtml = {
    title: (
      <>
        Publish
        {' '}
        <span>Result</span>
      </>
    ),
    confirmationText: 'Please Confirm publishing test result',
  };

  const confirmDeclineHtml = {
    title: (
      <>
        Decline
        {' '}
        <span>Result</span>
      </>
    ),
    confirmationText: 'Please confirm declining result.',
  };

  const getTestResultUploadStatus = (uploadStatus) => {
    switch (uploadStatus) {
      case 'published':
        return <ApprovedIcon className="table__status-icon" />;
      case 'rejected':
        return <DeactivateIcon className="table__status-icon" />;
      case 'pending-result':
        return <PendingDeliveryIcon className="table__status-icon" />;
      case 'pending-result-confirmation':
        return <PendingSchedulingIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const fetchAllTestResults = (params) => {
    setIsLoading(true);
    getAllTestResults(params)
      .then((response) => {
        setTestResultData(response.data.data);
        setMeta(response.data.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateResult = (data) => {
    if (data?.status === 'published') {
      setConfirmationModal('publish-result');
      setUpdateResultStatusItem(data);
    } else {
      setConfirmationModal('decline-result');
      setUpdateResultStatusItem(data);
    }
  };

  const handleCloseModal = () => {
    setConfirmationModal('');
    setUpdateResultStatusItem({});
  };

  const updateResultStatusHandler = (id, params) => {
    setIsButtonLoading(true);
    updateTestResult(id, params)
      .then((response) => {
        toast.success(
          response?.data?.message || 'Test Result update successfully.',
        );
        setIsButtonLoading(false);
        setShowPreviewModal(false);
        handleCloseModal();
        fetchAllTestResults(filter);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong!');
        setIsButtonLoading(false);
      });
  };

  useEffect(() => {
    fetchAllTestResults(filter);
  }, [filter]);

  useEffect(() => {
    setHeader('Test Result');
  }, [setHeader]);

  const mappings = [
    {
      key: 'test_report_id',
      label: 'ID',
      render: (item) => (
        <span className="inline_list inline_list--regular blue">
          {item?.test_report_id}
        </span>
      ),
    },
    {
      key: 'test_kit_and_lab_name',
      label: 'TEST KIT',
      render: (item) => (
        <div className="table__main-content">
          <div className="table__main-text">{item?.product_name}</div>
          <div className="table__main-title">{item?.lab_name}</div>
        </div>
      ),
    },
    {
      key: 'registered_by',
      label: 'ORDERED BY',
      render: (item) => item?.ordered_by,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getTestResultUploadStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'register_date',
      label: 'SUBMIT   DATE',
      render: (item) => <>{moment(item?.register_date).format('LL')}</>,
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <Button
                  className="inline_link"
                  onClick={() => {
                    setShowPreviewModal(true);
                    setUpdateResultStatusItem({
                      id: item.media_id,
                    });
                  }}
                >
                  View Uploaded Result
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => handleUpdateResult({
                    id: item?.media_id,
                    status: 'published',
                  })}
                >
                  Publish Result
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular red"
                  onClick={() => handleUpdateResult({
                    id: item?.media_id,
                    status: 'rejected',
                  })}
                >
                  Decline Result
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const fetchAllConsumers = async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllConsumers(params);
      const consumers = (res?.data?.data || []).map((data) => ({
        value: data.consumer_id,
        label: data.first_name,
      }));
      setDefaultOptions(consumers);
      if (callback) {
        return callback(consumers);
      }
      return filterOptions(consumers, 'All');
    } catch (e) {
      return [];
    }
  };

  const loadOptionsHandler = (searchKeyWord, callback) => {
    const consumers = fetchAllConsumers(searchKeyWord, callback);
    return consumers;
  };

  const loadOptions = debounce(loadOptionsHandler, 400);

  useEffect(() => {
    fetchAllConsumers();
  }, []);

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <Filter
        filters={[
          {
            filter_type: 'custom-input',
            name: 'search',
            placeholder: 'Search by Order ID, Test Kit, Lab',
            type: 'text',
            inputProps: {
              className: 'table__searchbox text-box',
            },
          },
          {
            filter_type: 'async-select',
            name: 'ordered_by',
            loadOptions,
            defaultOptions: filterOptions(defaultOptions, 'All'),
            placeholder: 'Ordered by',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'select',
            name: 'status',
            options: filterOptions(lookupDetails?.testResultStatus, 'All'),
            placeholder: 'All Status',
            inputProps: {
              className: 'selectbox selectbox--active-placeholder',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        meta={meta}
      />
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={testResultData}
        shouldPaginate
        primaryKey="media_id"
      />

      {showPreviewModal ? (
        <ResultPreviewModal
          closeModal={() => setShowPreviewModal(false)}
          testResultId={updateResultStatusItem?.id}
          updateResultStatusHandler={updateResultStatusHandler}
        />
      ) : (
        ''
      )}

      {confirmationModal === 'publish-result' ? (
        <ConfirmationModal
          hideModal={handleCloseModal}
          renderHtml={confirmPublishHtml}
          successButtonText="Publish Result"
          successHandler={() => updateResultStatusHandler(updateResultStatusItem?.id, {
            status: updateResultStatusItem?.status,
          })}
          isLoading={isButtonLoading}
          cancelButtonText="close"
        />
      ) : (
        ''
      )}
      {confirmationModal === 'decline-result' ? (
        <ConfirmationModal
          hideModal={handleCloseModal}
          renderHtml={confirmDeclineHtml}
          successButtonText="Decline Result"
          btnColor="primary"
          isLoading={isButtonLoading}
          successHandler={() => updateResultStatusHandler(updateResultStatusItem?.id, {
            status: updateResultStatusItem?.status,
          })}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default TestResults;
