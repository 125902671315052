import React, { useState } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';
import Duo from 'react-duo-web';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import ButtonWithLoader from 'components/shared/loading-button';

import { HidePasswordIcon, ShowPasswordIcon } from 'assets/images/svg-icons';
import { login, twoFactorLogin } from 'services/auth';
import catchHandler from 'helpers/catch-handler';
import { setLocalStorage } from 'helpers/utils';

const schema = yup.object().shape({
  email: yup.string().email('Not a valid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
});

const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [duo, setDuo] = useState({});

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = (formData) => {
    const payLoad = {
      username: formData.email,
      password: formData.password,
    };
    setLoading(true);
    login(payLoad)
      .then((response) => {
        setLoading(false);
        if (response?.data?.['2fa_enabled']) {
          setDuo(response.data.duo);
        } else {
          setLocalStorage('userAccessToken', response.data.access_token);
          history.push('/dashboard');
        }
      })
      .catch((error) => {
        catchHandler(error, setError);
        setLoading(false);
      });
  };
  const handle2FAComplete = (sigResponse) => {
    const payload = {
      sig_response: sigResponse,
    };
    twoFactorLogin(payload).then((response) => {
      setLocalStorage('userAccessToken', response.data.access_token);
      history.push('/dashboard');
    });
  };

  return (
    <div
      className={`auth__innerwrap ${
        !isEmpty(duo) ? 'auth__innerwrap--duo' : ''
      }`}
    >
      <FormProvider {...methods}>
        <Form
          className="form-signin auth__form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h2 className="auth__head">
            Admin
            {' '}
            <span className="auth__head--reg">Login</span>
          </h2>
          {isEmpty(duo) ? (
            <>
              <ErrorMessage
                errors={errors}
                name="general_errors"
                render={({ message }) => (
                  <p className="login-error">{message}</p>
                )}
              />
              <FormGroup>
                <Label for="email">Email Address</Label>
                <CustomInputField
                  type="email"
                  className="form-control"
                  name="email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <div
                  className={`view-password ${
                    !isEmpty(errors?.password) ? 'view-password--error' : ''
                  }`}
                >
                  <CustomInputField
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    name="password"
                  />
                  <span
                    role="presentation"
                    onKeyDown={() => setShowPassword(!showPassword)}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                  </span>
                </div>
              </FormGroup>
              <div className="auth__forgot">
                <Link className="inline_link" to="/auth/forgot-password">
                  Forgot Password?
                </Link>
              </div>
              <div className="auth__btn d-flex align-items-center justify-content-center">
                <ButtonWithLoader color="secondary" isLoading={loading}>
                  Login
                </ButtonWithLoader>
              </div>
            </>
          ) : (
            <div className="auth__duo">
              <Duo
                host={duo?.host}
                sigRequest={duo?.sig_request}
                sigResponseCallback={(sigResponse) => handle2FAComplete(sigResponse)}
              />
            </div>
          )}
        </Form>
      </FormProvider>
    </div>
  );
};

export default Login;
