import React, { createContext, useEffect, useState } from 'react';

import getLookUpData from 'services/lookup';
import { generateDropdownOptions, convertArrayToObject } from 'helpers/utils';

export const LookupContext = createContext();

export const LookupProvider = ({ children }) => {
  const [lookupDetails, setLookupDetails] = useState({});

  useEffect(() => {
    getLookUpData().then((res) => {
      setLookupDetails({
        ...res?.data,
        rowsPerPage: (res?.data?.per_page || []).map((perPage) => ({
          label: perPage,
          value: perPage,
        })),
        productCategoriesOptions: generateDropdownOptions(
          res?.data?.product_categories,
          'slug',
          'category_name',
        ),
        productCategoryAddOptions: generateDropdownOptions(
          res?.data?.product_categories,
          'product_category_id',
          'category_name',
        ),
        kitTypeOptions: generateDropdownOptions(
          res?.data?.product_type,
          'slug',
          'label',
        ),
        kitTypeObject: convertArrayToObject(
          generateDropdownOptions(res?.data?.product_type, 'slug', 'label'),
          'label',
        ),
        statusOptions: generateDropdownOptions(
          res?.data?.status,
          'slug',
          'label',
        ),
        kitStatusOptions: generateDropdownOptions(
          res?.data?.kit_status,
          'slug',
          'label',
        ),
        kitFilterStatusOptions: generateDropdownOptions(
          res?.data?.kit_filter_status,
          'slug',
          'label',
        ),
        bioMarkerOptions: generateDropdownOptions(
          res?.data?.bio_markers,
          'bio_marker_id',
          'bio_marker_name',
        ),
        genderOptions: generateDropdownOptions(
          res?.data?.genders,
          'slug',
          'label',
        ),
        genderObject: convertArrayToObject(
          generateDropdownOptions(res?.data?.genders, 'slug', 'label'),
          'slug',
        ),
        locationOptions: generateDropdownOptions(
          res?.data?.states,
          'name',
          'name',
        ),
        turnAroundTimeOptions: (res?.data?.turnaround_time || []).map(
          (option) => ({ label: `${option} hrs`, value: option }),
        ),
        orderStatusOptions: generateDropdownOptions(
          res?.data?.order_status,
          'slug',
          'status',
        ),
        registeredKitStatus: generateDropdownOptions(
          res?.data?.test_kit_status,
          'slug',
          'status',
        ),
        kitStatusObject: convertArrayToObject(
          generateDropdownOptions(res?.data?.kit_status, 'slug', 'label'),
          'slug',
        ),
        labStatusOption: generateDropdownOptions(
          res?.data?.lab_status,
          'slug',
          'label',
        ),
        reportFilter: generateDropdownOptions(
          res?.data?.report_filter,
          'slug',
          'label',
        ),
        notificationCategory: generateDropdownOptions(
          res?.data?.notification_categories,
          'slug',
          'category_name',
        ),
        accountType: generateDropdownOptions(
          res?.data?.payout_account_holder_types,
          'slug',
          'label',
        ),
        stateOptions: generateDropdownOptions(
          res?.data?.states,
          'state_code',
          'name',
        ),
        reviewStatus: generateDropdownOptions(
          res?.data?.review_status,
          'slug',
          'label',
        ),
        testResultStatus: generateDropdownOptions(
          res?.data?.test_result_status,
          'slug',
          'label',
        ),
      });
    });
  }, []);
  return (
    <LookupContext.Provider
      value={{
        lookupDetails,
      }}
    >
      {children}
    </LookupContext.Provider>
  );
};
