import { request } from 'helpers/api';

export const getAllKitVariants = (params) => request({
  method: 'GET',
  url: '/api/product-variants',
  params,
});

export const addKitVariant = (params) => request({
  method: 'POST',
  url: '/api/product-variants',
  params,
});

export const getKitVariantDetails = (id) => request({
  method: 'GET',
  url: `/api/product-variants/${id}`,
});

export const updateKitVariant = (params, id) => request({
  method: 'PUT',
  url: `/api/product-variants/${id}`,
  params,
});

export const deleteProductVariant = (id) => request({
  method: 'DELETE',
  url: `/api/product-variants/${id}`,
});

export const setKitVariantAsFeatured = (params, id) => request({
  method: 'PUT',
  url: `/api/product-variants/${id}/mark-featured`,
  params,
});
