import React from 'react';

const FilterIcon = (props) => (
  <svg
    {...props}
    width="15"
    height="15"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8166 1.168C27.4856 0.448 26.7846 0 25.9876 0H2.01562C1.21862 0 0.517624 0.448 0.185624 1.168C-0.143376 1.882 -0.029376 2.698 0.482624 3.296L0.483624 3.297L10.0016 14.371V23C10.0016 23.369 10.2046 23.708 10.5296 23.882C10.6776 23.961 10.8396 24 11.0016 24C11.1956 24 11.3886 23.943 11.5566 23.832L17.5566 19.832C17.8346 19.646 18.0016 19.334 18.0016 19V14.371L27.5206 3.297C28.0326 2.698 28.1466 1.882 27.8166 1.168Z"
      fill="#444444"
    />
  </svg>
);
export default FilterIcon;
