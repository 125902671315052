import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import Loader from 'components/shared/loader';
import AddEditTestKit from 'components/shared/modals/add-edit-test-kit-modal';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import CustomTextArea from 'components/shared/form-fields/custom-text-area';
import ListingComponent from 'components/shared/listing-component';
import SelectField from 'components/shared/form-fields/select-field';
import FileUploader from 'components/shared/form-fields/file-uploader';
import ButtonWithLoader from 'components/shared/loading-button';
import TextEditor from 'components/shared/form-fields/text-editor';

import {
  AddGreenIcon,
  DotIcon,
  ChevronLeftIcon,
  DeactivateIcon,
  ApprovedIcon,
  DeleteIcon,
  PluseIcon,
  StarIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { LookupContext } from 'context/lookup-context/lookup-context';
import {
  getKitDetailsById,
  updateKitDetails,
  saveKit,
  deleteKit,
} from 'services/kit-management';
import {
  getKitVariantDetails,
  addKitVariant,
  updateKitVariant,
  deleteProductVariant,
  setKitVariantAsFeatured,
} from 'services/test-kit-variants';
import catchHandler from 'helpers/catch-handler';
import { generateRandomString } from 'helpers/utils';

const schema = Yup.object().shape({
  imageUpload: Yup.array().min(1, 'Please upload an image').nullable(),
  product_name: Yup.string().required('Kit Name is required'),
  product_category_id: Yup.object()
    .required('Product Category is required')
    .nullable(),
  gender: Yup.object().required('Gender is required').nullable(),
  kit_type: Yup.object().required('ProductType is required').nullable(),
  short_description: Yup.string().required('Short Description is required'),
  long_description: Yup.string().min(9, 'Long Description is required'),
});

const AddEditKit = () => {
  const { productId } = useParams();
  const history = useHistory();

  const [, setHeader] = useContext(HeaderText);
  const lookupData = useContext(LookupContext);

  const [kitData, setKitData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModalLoading, setEditModalLoading] = useState(false);
  const [showAddTestKitModal, setAddTestKitModal] = useState(false);
  const [addEditButtonLoading, setAddEditButtonLoading] = useState(false);
  const [isConfirmationLoading, setConfirmationLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [featureItem, setFeatureItem] = useState({});
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [deletedImages, setDeletedImages] = useState([]);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState('');
  const [isLabNameRequired, setIsLabNameRequired] = useState(false);
  const [addKitSuccessMessage, setAddKitSuccessMessage] = useState({});

  const confirmSuccessHtml = {
    title: (
      <>
        Add
        {' '}
        <span>Test Kit</span>
      </>
    ),
    confirmationText: addKitSuccessMessage?.message,
  };
  const confirmDeleteKitVariantHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm deleting ${deleteItem?.product_name} by ${deleteItem?.lab_name}. This action cannot be undone`,
  };

  const confirmDeactivateKitVariantHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'active' ? 'Deactivating' : 'Activating'
    }
    ${updateStatusItem?.product_name} by ${updateStatusItem?.lab_name}.`,
  };

  const confirmFeaturedHtml = {
    title: (
      <>
        {featureItem?.is_featured === 'true' ? 'Remove' : 'Add'}
        {' '}
        <span>
          Kit Variant
          {' '}
          {featureItem?.is_featured === 'true' ? 'From' : 'To'}
          {' '}
          Featured
        </span>
      </>
    ),
    confirmationText: `Please confirm ${featureItem?.product_name} by ${
      featureItem?.lab_name
    } 
 can be ${
  featureItem?.is_featured === 'true' ? 'removed from' : 'added to'
} featured.`,
  };

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Test Kit</span>
      </>
    ),
    confirmationText: `Please confirm deleting ${deleteItem.product_name}
    ${
  deleteItem.product_name?.includes('Kit') ? '' : ' Kit'
} and it's variants. This action cannot be undone.`,
  };

  const confirmStatusUpdateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'active' ? 'Deactivating' : 'Activating'
    }
    ${updateStatusItem?.product_name} ${
  updateStatusItem.product_name?.includes('Kit') ? '' : ' Kit'
}.`,
  };

  const buildFormData = useCallback(() => {
    const formData = {
      ...(!isEmpty(kitData?.images)
        ? {
          imageUpload: (Object.keys(kitData?.images) || []).map(
            (image) => kitData?.images[image],
          ),
        }
        : { imageUpload: [] }),
      ...(!isEmpty(kitData?.category_name)
        ? {
          product_category_id: {
            label: kitData?.category_name,
            value: kitData?.product_category_id,
          },
        }
        : ''),
      ...(!isEmpty(kitData?.gender)
        ? {
          gender: lookupData?.lookupDetails?.genderObject?.[kitData?.gender],
        }
        : ''),
      ...(!isEmpty(kitData?.product_type)
        ? {
          kit_type:
              lookupData?.lookupDetails?.kitTypeObject?.[kitData?.product_type],
        }
        : ''),
      product_name: kitData?.product_name || '',
      short_description: kitData?.short_description || '',
      long_description: kitData?.long_description || '',
      whats_included: kitData?.whats_included || '',
      symptoms: kitData?.symptoms || '',
      questions: kitData?.questions || '',
    };
    return formData;
  }, [
    kitData,
    lookupData?.lookupDetails?.genderObject,
    lookupData?.lookupDetails?.kitTypeObject,
  ]);

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(kitData),
    resolver: yupResolver(schema),
  });

  const {
    reset,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const fetchKitVariantDetails = useCallback(() => {
    if (productId) {
      setIsPageLoading(true);
      getKitDetailsById(productId)
        .then((response) => {
          setKitData(response?.data);
          setIsPageLoading(false);
        })
        .catch(() => {
          setIsPageLoading(false);
        });
    }
  }, [productId]);

  useEffect(() => {
    if (!isEmpty(kitData)) {
      reset(buildFormData(kitData));
    }
  }, [reset, kitData, buildFormData]);

  const images = watch('imageUpload');

  const customizeOutputHandler = (file) => {
    setValue('imageUpload', images.concat(file));
  };

  useEffect(() => {
    if (productId) {
      setHeader('Edit Kit');
    } else {
      setHeader('Add Kit');
    }
  }, [setHeader, productId]);

  useEffect(() => {
    fetchKitVariantDetails();
  }, [fetchKitVariantDetails]);

  useEffect(() => {
    setModalInfo({
      title: 'Kit Variant',
      lab: {
        labName: !isEmpty(editItem) ? `${editItem?.lab_name}` : '',
        description: '',
        title: !isEmpty(editItem) ? '' : 'Select Laboratory',
      },
      kit: {
        kitName: `${kitData?.category_name} / ${kitData?.product_name}`,
        title: '',
      },
      className: !isEmpty(editItem)
        ? 'addkit edit-textkit'
        : 'addkit kit variant',
      cancelButtonText: 'Close',
      successButtonText: !isEmpty(editItem) ? 'Save Changes' : 'Add Kit',
    });
  }, [editItem, kitData]);

  const confirmSuccessHandler = () => {
    setIsLabNameRequired(true);
    setConfirmationModalStatus('');
    setAddTestKitModal(true);
  };

  //  kit variant features

  const editModalHandler = (editInfo) => {
    setIsEditMode(true);
    setAddTestKitModal(true);
    setEditModalLoading(true);
    getKitVariantDetails(editInfo?.product_variant_id)
      .then((res) => {
        setEditItem(res.data);
        setEditModalLoading(false);
      })
      .catch(() => {
        setEditModalLoading(false);
      });
  };

  const addKitVariantHandler = (formData) => {
    const payload = {
      product_id: productId,
      ...(formData?.lab_id?.value ? { lab_id: formData?.lab_id?.value } : {}),
      price: formData?.price,
      phlebotomy_cost: formData?.phlebotomy_cost,
      turnaround_time: formData?.turnaround_time?.value,
      bio_markers: (formData?.bio_markers || []).map(
        (kitBioMarker) => kitBioMarker?.value,
      ),
      status: formData?.status?.value,
    };
    setAddEditButtonLoading(true);
    if (!isEditMode) {
      addKitVariant(payload, productId)
        .then((response) => {
          setAddKitSuccessMessage(response?.data);
          setAddTestKitModal(false);
          setConfirmationModalStatus('add-kit-success');
          fetchKitVariantDetails();
          setAddEditButtonLoading(false);
          setIsLabNameRequired(false);
        })
        .catch(() => {
          setAddEditButtonLoading(false);
        });
    } else {
      updateKitVariantInfo(payload, editItem?.product_variant_id);
    }
  };

  const updateKitVariantInfo = (payload, Id) => {
    updateKitVariant(payload, Id)
      .then(() => {
        setAddTestKitModal(false);
        setConfirmationModalStatus('');
        setAddEditButtonLoading(false);
        setConfirmationLoading(false);
        setEditItem({});
        setIsEditMode(false);
        fetchKitVariantDetails();
      })
      .catch(() => {
        setAddEditButtonLoading(false);
        setConfirmationLoading(false);
        setEditItem({});
        setIsEditMode(false);
      });
  };

  const kitVariantFeatureUpdateHandler = (item) => {
    setConfirmationModalStatus('set-as-featured');
    setFeatureItem(item);
  };

  const confirmFeatureHandler = () => {
    const payload = {
      is_featured: featureItem?.is_featured === 'true' ? 'false' : 'true',
    };
    setConfirmationLoading(true);
    setKitVariantAsFeatured(payload, featureItem?.product_variant_id)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchKitVariantDetails();
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const kitVariantStatusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModalStatus('kit-variant-status-update');
  };

  const confirmKitVariantStatusUpdateHandler = () => {
    setConfirmationLoading(true);
    updateKitVariantInfo(
      {
        status:
          updateStatusItem?.status_slug === 'active' ? 'deactivated' : 'active',
      },
      updateStatusItem?.product_variant_id,
    );
  };

  const deleteKitVariantHandler = (item) => {
    setDeleteItem(item);
    setConfirmationModalStatus('delete-kit-variant');
  };

  const confirmDeleteKitVariantHandler = () => {
    setConfirmationLoading(true);
    deleteProductVariant(deleteItem?.product_variant_id)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchKitVariantDetails();
        setDeleteItem({});
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  // kit  handler

  const kitUpdateHandler = (formData) => {
    const payload = new FormData();
    payload.append('product_name', formData?.product_name);
    payload.append('product_category_id', formData?.product_category_id?.value);
    payload.append('gender', formData?.gender.value);
    payload.append('kit_type', formData?.kit_type?.value);
    payload.append('short_description', formData?.short_description);
    payload.append('long_description', formData?.long_description);
    payload.append('whats_included', formData?.whats_included);
    payload.append('symptoms', formData?.symptoms);
    payload.append('questions', formData?.questions);
    (images || []).forEach((item) => {
      if (!item?.uuid) {
        payload.append('images[]', item);
      }
    });
    deletedImages.forEach((item, index) => {
      payload.append(`delete_images[${index}]`, item);
    });
    if (!isEmpty(productId)) {
      payload.append('_method', 'Put');
    }
    if (isEmpty(productId)) {
      payload.append('status', 'active');
    }
    setIsButtonLoading(true);
    if (!isEmpty(productId)) {
      updateKitDetails(productId, payload)
        .then(() => {
          setIsButtonLoading(false);
          setKitData({});
          history.push('/kit-management');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message || 'Something went wrong!');
          setIsButtonLoading(false);
          catchHandler(error, setError, true);
        });
    } else {
      saveKit(payload)
        .then(() => {
          setIsButtonLoading(false);
          history.push('/kit-management');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message || 'Something went wrong!');
          setIsButtonLoading(false);
          catchHandler(error, setError, true);
        });
    }
  };

  const kitStatusUpdate = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModalStatus('kit-status-update');
  };

  const confirmKitStatusUpdateHandler = () => {
    setConfirmationLoading(true);
    const payload = new FormData();
    payload.append(
      'status',
      updateStatusItem.status_slug === 'active' ? 'deactivated' : 'active',
    );
    payload.append('_method', 'Put');
    updateKitDetails(productId, payload)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchKitVariantDetails();
        setUpdateStatusItem({});
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const deleteKitHandler = (item) => {
    setDeleteItem(item);
    setConfirmationModalStatus('delete-kit');
  };

  const confirmDeleteKitHandler = () => {
    setConfirmationLoading(true);
    deleteKit(deleteItem?.product_id)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        setDeleteItem({});
        history.push('/kit-management');
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const renderImages = (image) => {
    if (image.original_url) {
      return image.original_url;
    }
    return URL.createObjectURL(image);
  };

  const deleteImageHandler = (deletedImage) => {
    setValue(
      'imageUpload',
      (images || []).filter((image) => image !== deletedImage),
    );
    if (deletedImage.uuid) {
      setDeletedImages((prevState) => prevState.concat(deletedImage.uuid));
    }
  };

  const getProductVariantStatus = (kitVariantStatus) => {
    switch (kitVariantStatus) {
      case 'active':
        return <ApprovedIcon className="table__status-icon" />;
      case 'deactivated':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const mappings = [
    {
      key: 'product_variant_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">
          {item.product_variant_id}
        </span>
      ),
    },
    {
      key: 'lab_name',
      label: 'LAB NAME',
      render: (item) => <b>{item.lab_name}</b>,
    },
    {
      key: 'price',
      label: 'PRICE',
      className: 'text-center',
      render: (item) => (
        <>
          $
          {item.price}
        </>
      ),
    },
    {
      key: 'biomarkers',
      label: 'BIOMARKERS',
      className: 'text-center',
      render: (item) => <>{item.bio_markers.length}</>,
    },
    {
      key: 'turnaround_time',
      label: 'TA-TIME',
      className: 'text-center',
      render: (item) => <>{item.turnaround_time}</>,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getProductVariantStatus(item?.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <Button
            type="button"
            className={`cursor-auto ${
              item?.is_featured === 'true'
                ? 'button__star button__action'
                : 'button__star button__action disable'
            }`}
          >
            <StarIcon />
          </Button>
          <UncontrolledDropdown>
            <DropdownToggle className="button__action ">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => editModalHandler(item)}
                >
                  Edit
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => kitVariantFeatureUpdateHandler(item)}
                >
                  {item?.is_featured === 'true'
                    ? 'Remove From Featured'
                    : 'Set as Featured'}
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => kitVariantStatusUpdateHandler(item)}
                >
                  {item?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  onClick={() => {
                    deleteKitVariantHandler(item);
                  }}
                  className="inline__list inline__list--regular red"
                >
                  Delete Test Kit
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const actionButton = (
    <UncontrolledDropdown>
      <DropdownToggle className="button__action__dot ">
        <DotIcon />
      </DropdownToggle>
      <DropdownMenu className="table__dropdown">
        <DropdownItem tag="div">
          <Button
            className="inline__list inline__list--regular"
            onClick={() => kitStatusUpdate(kitData)}
          >
            {kitData?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
          </Button>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag="div">
          <Button
            className="inline_link primary"
            onClick={() => deleteKitHandler(kitData)}
          >
            Delete Test Kit
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const getKitStatus = (slug, status) => {
    switch (slug) {
      case 'active':
        return (
          <span
            className="button-icon button-icon--dropdown cursor-auto"
            type="button"
          >
            <ApprovedIcon />
            <span className="button-icon__text">{status}</span>
            {actionButton}
          </span>
        );
      case 'deactivated':
        return (
          <span
            className="button-icon button-icon--dropdown  button-icon--red cursor-auto"
            type="button"
          >
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
            {actionButton}
          </span>
        );
      default:
        return null;
    }
  };

  const headerSchema = Yup.object().shape({
    lab_id: Yup.object().required('Laboratory required'),
  });

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap labmangement kit-detail">
      {isPageLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {productId ? (
            <div className="details__header-outer">
              <div className="details__header">
                <h1 className="details__header-main">
                  {kitData?.product_name}
                  {' '}
                  {kitData.product_name?.includes('Kit') ? '' : ' Kit'}
                </h1>
                <span className="details__header-sub">
                  {kitData?.category_name}
                </span>
              </div>
              <div className="details__header-button">
                <Link
                  to="/kit-management"
                  className="button-icon button-icon--blue"
                >
                  <ChevronLeftIcon />
                  <span className="button-icon__text">Back to List</span>
                </Link>
                <button
                  className="button-icon"
                  type="button"
                  onClick={() => {
                    setAddTestKitModal(true);
                    setIsLabNameRequired(true);
                  }}
                >
                  <AddGreenIcon />
                  <span className="button-icon__text">Add Variant</span>
                </button>
                <span>
                  {getKitStatus(kitData.status_slug, kitData?.status)}
                </span>
              </div>
            </div>
          ) : (
            <div className="details__header-outer">
              <p className="details__text">
                Use below form to add new test kit to the website.
              </p>
            </div>
          )}
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(kitUpdateHandler)}>
              <div className="kit-detail__section">
                <div className="kit-detail__head">
                  <h2>Kit Information</h2>
                  <hr />
                </div>
                <Row className="kit-information">
                  <Col xs="12" md="4">
                    <FormGroup>
                      <Label>Kit Name / Title</Label>
                      <CustomInputField
                        className="form-control"
                        name="product_name"
                        type="text"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup>
                      <Label>Category</Label>
                      <SelectField
                        name="product_category_id"
                        options={
                          lookupData?.lookupDetails?.productCategoryAddOptions
                        }
                        className="selectbox selectbox--disable"
                        placeholder="Select Product Category"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup>
                      <Label>Gender</Label>
                      <SelectField
                        name="gender"
                        options={lookupData?.lookupDetails?.genderOptions}
                        className="selectbox selectbox--disable"
                        placeholder="Select Gender"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="4">
                    <FormGroup className="mb-0">
                      <Label>Kit Type</Label>
                      <SelectField
                        name="kit_type"
                        options={lookupData?.lookupDetails?.kitTypeOptions}
                        className="selectbox selectbox--disable"
                        placeholder="Select Test Kit Type"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="kit-detail__section">
                <div className="kit-detail__head">
                  <h2>Description</h2>
                  <hr />
                </div>
                <Row className="kit-information">
                  <Col xs="12">
                    <FormGroup>
                      <Label>Short Description</Label>
                      <CustomTextArea
                        className="form-control"
                        name="short_description"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="long-textarea">
                      <Label>Long Description</Label>
                      <TextEditor
                        className="form-control"
                        name="long_description"
                        htmlText={kitData?.long_description || ''}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="long-textarea">
                      <Label>What’s Included</Label>
                      <TextEditor
                        className="form-control"
                        name="whats_included"
                        htmlText={kitData?.whats_included || ''}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="long-textarea">
                      <Label>Symptoms</Label>
                      <TextEditor
                        className="form-control"
                        name="symptoms"
                        htmlText={kitData?.symptoms || ''}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="long-textarea">
                      <Label>Questions</Label>
                      <TextEditor
                        className="form-control"
                        name="questions"
                        htmlText={kitData?.questions || ''}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="kit-detail__section">
                <div className="kit-detail__head">
                  <h2>Images Gallery</h2>
                  <hr />
                </div>
                <div className="kit-detail__image-wrapper">
                  {isArray(images)
                    ? images?.map((image) => (
                      <div
                        key={generateRandomString()}
                        className="kit-detail__image"
                      >
                        <img
                          src={renderImages(image)}
                          alt="product"
                          key={image}
                        />
                        <Button
                          type="button"
                          className="kit-detail__delete"
                          onClick={() => {
                            deleteImageHandler(image);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                    ))
                    : ' '}
                  <FileUploader
                    name="imageUpload"
                    uploadBoundary={(
                      <Label
                        className="kit-detail__image kit-detail__image--add"
                        for="imageUpload"
                      >
                        <PluseIcon />
                      </Label>
                    )}
                    customizeOutputHandler={(file) => customizeOutputHandler(file)}
                  />
                </div>
                {errors && errors.imageUpload && errors.imageUpload.message ? (
                  <small className="text-danger">
                    {errors.imageUpload.message}
                  </small>
                ) : (
                  ''
                )}
              </div>
              <div className="kit-detail__section">
                {productId ? (
                  <>
                    <div className="kit-detail__head">
                      <h2>Variants</h2>
                      <hr />
                    </div>
                    <div className="kit-detail__table">
                      <ListingComponent
                        mappings={mappings}
                        dataSource={kitData?.product_variants}
                        primaryKey="product_variant_id"
                      />
                    </div>
                  </>
                ) : (
                  ' '
                )}
              </div>
              <div className="kit-detail__section kit-detail__section--button">
                <hr className="line" />
                <div className="kit-detail__button">
                  <Button
                    color="primary outline"
                    type="button"
                    onClick={() => history.push('/kit-management')}
                  >
                    Close
                  </Button>
                  <ButtonWithLoader
                    isLoading={isButtonLoading}
                    color="secondary"
                    type="submit"
                  >
                    {!isEmpty(productId) ? 'Save Changes' : 'Add kit'}
                  </ButtonWithLoader>
                  {' '}
                </div>
              </div>
            </Form>
          </FormProvider>
        </React.Fragment>
      )}
      {showAddTestKitModal ? (
        <AddEditTestKit
          hideModal={() => {
            setAddTestKitModal(false);
            setEditItem({});
            setIsEditMode(false);
            setIsLabNameRequired(false);
          }}
          modalInfo={modalInfo}
          editItem={editItem}
          onSuccessHandler={(formData) => addKitVariantHandler(formData)}
          headerSchema={!isEditMode ? headerSchema : {}}
          isEditModal={isEditMode}
          isLabSelect={isLabNameRequired}
          isEditModalLoading={isEditModalLoading}
          addEditButtonLoading={addEditButtonLoading}
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'add-kit-success' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmSuccessHtml}
          successButtonText="Add Another Test Kit"
          successHandler={() => confirmSuccessHandler()}
          cancelButtonText="Done"
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'kit-variant-status-update' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmDeactivateKitVariantHtml}
          successButtonText="Confirm"
          successHandler={() => confirmKitVariantStatusUpdateHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'kit-status-update' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmStatusUpdateHtml}
          successButtonText="Confirm"
          successHandler={() => confirmKitStatusUpdateHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'delete-kit-variant' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmDeleteKitVariantHtml}
          successButtonText="Delete Kit"
          btnColor="primary"
          isLoading={isConfirmationLoading}
          successHandler={() => confirmDeleteKitVariantHandler()}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'delete-kit' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmDeleteHtml}
          successButtonText="Delete Kit"
          btnColor="primary"
          isLoading={isConfirmationLoading}
          successHandler={() => confirmDeleteKitHandler()}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'set-as-featured' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmFeaturedHtml}
          successButtonText="Confirm"
          successHandler={() => confirmFeatureHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default AddEditKit;
