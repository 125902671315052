import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';

import Loader from 'components/shared/loader';

import {
  ChevronLeftIcon,
  LabBlueIcon,
  ApprovedIcon,
  OrderDateIcon,
  IdIcon,
  MoneyIcon,
  PendingIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { getPayoutDetails } from 'services/payout-system';

const PayoutSystemDetails = () => {
  const { payoutId } = useParams();
  const [, setHeader] = useContext(HeaderText);

  const [payoutDetails, setPayoutDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getPayoutDetails(payoutId)
      .then((res) => {
        setPayoutDetails(res?.data[0]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [payoutId]);

  useEffect(() => {
    setHeader('Payout System');
  }, [setHeader]);

  const getPaymentStatus = (slug, status) => {
    switch (slug) {
      case 'pending':
        return (
          <button
            type="button"
            className="button-icon button-icon--yellow cursor-auto"
          >
            <PendingIcon className="table__status-icon" />
            <span className="button-icon__text">{payoutDetails?.status}</span>
          </button>
        );
      case 'paid':
        return (
          <button type="button" className="button-icon cursor-auto">
            <ApprovedIcon className="table__status-icon" />
            <span className="button-icon__text">{payoutDetails?.status}</span>
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="order-detail pay-out-detail">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="dashboard__whitebox dashboard__whitebox--wrap order-detail__strip">
            <div className="details__header-outer">
              <div className="details__header">
                <h1 className="details__header-main">
                  Payout #
                  {payoutDetails?.payout_id}
                </h1>
                <span className="details__header-sub">
                  Lab:
                  {' '}
                  {payoutDetails?.lab_name}
                </span>
              </div>
              <div className="details__header-button">
                <Link to="/payouts" className="button-icon button-icon--blue">
                  <ChevronLeftIcon />
                  <span className="button-icon__text">Back to List</span>
                </Link>
                {getPaymentStatus(
                  payoutDetails?.status_slug,
                  payoutDetails?.status,
                )}
              </div>
            </div>
          </div>
          <div className="dashboard__whitebox dashboard__whitebox--wrap order-detail__wraper">
            <div className="pay-out">
              <h2 className="details__sub-head details__sub-head--line">
                Payout
                {' '}
                <span>Info</span>
              </h2>
              <Row className="order-detail__card-outer">
                <Col
                  className="inline-card cursor-pointer"
                  xs="12"
                  md="6"
                  xl="4"
                >
                  <div className="inline-card__image">
                    <LabBlueIcon />
                  </div>
                  <div className="inline-card__content">
                    <div className="inline-card__sub-text">Lab</div>
                    <div className="inline-card__main-text">
                      {payoutDetails?.lab_name}
                    </div>
                  </div>
                </Col>
                <Col className="inline-card" xs="12" md="6" xl="4">
                  <div className="inline-card__image">
                    <MoneyIcon />
                  </div>
                  <div className="inline-card__content">
                    <div className="inline-card__sub-text">Amount</div>
                    <div className="inline-card__main-text">
                      $
                      {payoutDetails?.amount}
                    </div>
                  </div>
                </Col>
                <Col className="inline-card" xs="12" md="6" xl="4">
                  <div className="inline-card__image">
                    <OrderDateIcon />
                  </div>
                  <div className="inline-card__content">
                    <div className="inline-card__sub-text">Date</div>
                    <div className="inline-card__main-text">
                      {payoutDetails?.date}
                    </div>
                  </div>
                </Col>
                <Col className="inline-card" xs="12" md="6" xl="4">
                  <div className="inline-card__image">
                    <IdIcon />
                  </div>
                  <div className="inline-card__content">
                    <div className="inline-card__sub-text">Transaction ID</div>
                    <div className="inline-card__main-text">
                      {payoutDetails?.transaction_id}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PayoutSystemDetails;
