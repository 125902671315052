import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { pick, isEmpty } from 'lodash';
import * as Yup from 'yup';

import ListingComponent from 'components/shared/listing-component';
import Filter from 'components/shared/filter/filter';
import AddEditConsumerPhlebotomistModal from 'components/shared/modals/add-edit-consumer-phlebotomist';

import HeaderText from 'header-context';
import AddGreenIcon from 'assets/images/add-green-icon';

import {
  getAllPhlebotomistsData,
  getPhlebotomistDetailsById,
  savePhlebotomist,
  updatePhlebotomist,
} from 'services/phlebotomist';
import catchHandler from 'helpers/catch-handler';

const Phlebotomists = () => {
  const [, setHeader] = useContext(HeaderText);

  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [editItem, setEditItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [phlebotomistsData, setPhlebotomistsData] = useState([]);
  const [showPhlebotomistModal, setShowPhlebotomistModal] = useState(false);
  const [phlebotomistButtonLoading, setPhlebotomistButtonLoading] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [isEditModalLoading, setEditModalLoading] = useState(false);

  const clearFilterRef = useRef(null);

  useEffect(() => {
    setHeader('Phlebotomists');
  }, [setHeader]);

  const fetchAllPhlebotomists = (params) => {
    setIsLoading(true);
    getAllPhlebotomistsData(params)
      .then((response) => {
        setPhlebotomistsData(response.data.data);
        setMeta(response.data.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const editPhlebotomistHandler = (phlebotomistId) => {
    setEditItem({});
    setEditModal(true);
    setShowPhlebotomistModal(true);
    setEditModalLoading(true);
    getPhlebotomistDetailsById(phlebotomistId)
      .then((response) => {
        setEditItem(response.data);
        setEditModalLoading(false);
      })
      .catch(() => {
        setEditModalLoading(false);
      });
  };

  const formatPayload = (data) => {
    const getShippingDetails = () => {
      if (isEmpty(editItem)) {
        return {
          ...(data?.state ? { state: data?.state?.value } : {}),
          ...(data?.city ? { city: data.city } : {}),
          ...(data?.zip ? { zip: data.zip } : {}),
          ...(data?.street_address
            ? { street_address: data.street_address }
            : {}),
        };
      }
      return {
        state: data?.state?.value || '',
        city: data?.city || '',
        zip: data?.zip || '',
        street_address: data?.street_address || '',
      };
    };

    const payload = {
      lab_id: data?.lab_id.value,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone_number,
      password: data?.password,
      password_confirmation: data?.password_confirmation,
      ...getShippingDetails(),
    };
    return payload;
  };

  const formSubmitHandler = (data, setError) => {
    setPhlebotomistButtonLoading(true);
    if (isEmpty(editItem)) {
      savePhlebotomist(formatPayload(data))
        .then(() => {
          setPhlebotomistButtonLoading(false);
          setShowPhlebotomistModal(false);
          setFilter({});
          setEditItem({});
          clearFilterRef?.current();
        })
        .catch((error) => {
          setPhlebotomistButtonLoading(false);
          catchHandler(error, setError, true);
        });
    } else {
      updatePhlebotomist(formatPayload(data), editItem?.phlebotomist_id)
        .then(() => {
          setPhlebotomistButtonLoading(false);
          setShowPhlebotomistModal(false);
          setFilter({});
          clearFilterRef?.current();
          setEditItem({});
        })
        .catch((error) => {
          setPhlebotomistButtonLoading(false);
          catchHandler(error, setError, true);
        });
    }
  };

  useEffect(() => {
    fetchAllPhlebotomists(filter);
  }, [filter]);

  const addButton = (
    <button
      className="button-icon"
      type="button"
      onClick={() => setShowPhlebotomistModal(true)}
    >
      <AddGreenIcon />
      <span className="button-icon__text"> Add Phlebotomist</span>
    </button>
  );

  const mappings = [
    {
      key: 'phlebotomist_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">
          {item?.phlebotomist_id}
        </span>
      ),
    },
    {
      key: 'phlebotomists_name',
      label: 'Name',
      render: (item) => <b>{`${item?.first_name} ${item?.last_name}`}</b>,
    },
    {
      key: 'email',
      label: 'EMAIL ADDRESS',
    },
    {
      key: 'lab_name',
      label: 'LAB NAME',
    },
    {
      key: 'phone_number',
      label: 'PHONE NUMBER',
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div
          className="inline_link link-action"
          onClick={() => editPhlebotomistHandler(item.phlebotomist_id)}
          role="button"
          tabIndex={-1}
          onKeyDown={() => editPhlebotomistHandler(item.phlebotomist_id)}
        >
          Edit
        </div>
      ),
    },
  ];

  const handleHideModal = () => {
    setShowPhlebotomistModal(false);
    setEditItem({});
    setEditModal(false);
  };

  const headerSchema = Yup.object().shape({
    lab_id: Yup.object().nullable().required('Lab Name is required'),
  });

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <React.Fragment>
        <Filter
          filters={[
            {
              filter_type: 'custom-input',
              name: 'search',
              placeholder: 'Search by Phlebotomist Name, Email',
              type: 'text',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'custom-input',
              name: 'location',
              placeholder: 'Search by location',
              type: 'text',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'button',
              name: 'submit',
              inputProps: {
                className: 'table__button',
              },
            },
          ]}
          onFilter={(params) => setFilter((prev) => ({
            ...pick(prev, ['per_page', 'sort']),
            page: 1,
            ...params,
          }))}
          onPerPageChange={(perPage, page) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          addButton={addButton}
          meta={meta}
          clearFilter={clearFilterRef}
        />
        <ListingComponent
          isLoading={isLoading}
          mappings={mappings}
          meta={meta}
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          dataSource={phlebotomistsData}
          shouldPaginate
          primaryKey="phlebotomist_id"
        />
        {showPhlebotomistModal ? (
          <AddEditConsumerPhlebotomistModal
            hideModal={() => handleHideModal()}
            editItem={editItem}
            isEditModal={isEditModal}
            headerSchema={headerSchema}
            isEditModalLoading={isEditModalLoading}
            isLoading={phlebotomistButtonLoading}
            title="Phlebotomist"
            type="phlebotomist"
            onSuccessHandler={(formData, setError) => {
              formSubmitHandler(formData, setError);
            }}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    </div>
  );
};

export default Phlebotomists;
