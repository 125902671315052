import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { debounce, isEmpty, pick } from 'lodash';
import * as Yup from 'yup';

import Filter from 'components/shared/filter/filter';
import ListingComponent from 'components/shared/listing-component';
import AddEditTestKit from 'components/shared/modals/add-edit-test-kit-modal';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';

import {
  ApprovedIcon,
  DeactivateIcon,
  AddGreenIcon,
  DotIcon,
  StarIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import {
  addKitVariant,
  deleteProductVariant,
  getAllKitVariants,
  getKitVariantDetails,
  updateKitVariant,
  setKitVariantAsFeatured,
} from 'services/test-kit-variants';
import { LookupContext } from 'context/lookup-context/lookup-context';
import catchHandler from 'helpers/catch-handler';
import { filterOptions } from 'helpers/utils';
import { getAllLabDetails } from 'services/lab-management';

const TestKits = () => {
  const [, setHeader] = useContext(HeaderText);
  const lookupData = useContext(LookupContext);

  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [editItem, setEditItem] = useState({});
  const [modalInfo, setModalInfo] = useState({});
  const [deleteItem, setDeleteItem] = useState({});
  const [featureItem, setFeatureItem] = useState({});
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [addKitSuccessMessage, setAddKitSuccessMessage] = useState({});
  const [testKitVariants, setTestKitVariants] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isTableLoading, setTableLoading] = useState(false);
  const [isEditModalLoading, setEditModalLoading] = useState(false);
  const [showAddTestKitModal, setShowAddTestKitModal] = useState(false);
  const [addEditButtonLoading, setAddEditButtonLoading] = useState(false);
  const [isConfirmationLoading, setConfirmationLoading] = useState(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState('');
  const [defaultOptions, setDefaultOptions] = useState([]);

  const clearFilterRef = useRef(null);

  const confirmSuccessHtml = {
    title: (
      <>
        Add
        {' '}
        <span>Test Kit</span>
      </>
    ),
    confirmationText: addKitSuccessMessage?.message,
  };

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm deleting  ${deleteItem?.product_name} by ${deleteItem?.lab_name}. This action cannot be undone`,
  };

  const confirmFeaturedHtml = {
    title: (
      <>
        {featureItem?.is_featured === 'true' ? 'Remove' : 'Add'}
        {' '}
        <span>
          Kit Variant
          {' '}
          {featureItem?.is_featured === 'true' ? 'From' : 'To'}
          {' '}
          Featured
        </span>
      </>
    ),
    confirmationText: `Please confirm ${featureItem?.product_name} by ${
      featureItem?.lab_name
    } 
 can be ${
  featureItem?.is_featured === 'true' ? 'removed from' : 'added to'
} featured.`,
  };

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'approved' ? 'Disable' : 'Approve'}
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'approved' ? 'disabling' : 'approving'
    }
     ${updateStatusItem?.product_name} by ${updateStatusItem?.lab_name}.`,
  };

  const addButton = (
    <button
      type="button"
      className="button-icon"
      onClick={() => setShowAddTestKitModal(true)}
    >
      <AddGreenIcon />
      <span className="button-icon__text">Add Variant</span>
    </button>
  );

  const fetchAllKitVariants = (params) => {
    getAllKitVariants(params)
      .then((response) => {
        setTestKitVariants(response.data.data);
        setMeta(response.data.meta);
        setTableLoading(false);
      })
      .catch(() => {
        setTableLoading(false);
      });
  };

  const updateKitVariantInfo = (payload, Id, setError) => {
    updateKitVariant(payload, Id)
      .then(() => {
        setShowAddTestKitModal(false);
        setConfirmationModalStatus('');
        setAddEditButtonLoading(false);
        setConfirmationLoading(false);
        fetchAllKitVariants(filter);
        setEditItem({});
        setIsEditMode(false);
      })
      .catch((error) => {
        setAddEditButtonLoading(false);
        setConfirmationLoading(false);
        if (setError) {
          catchHandler(error, setError, true);
        }
      });
  };

  const addKitHandler = (formData, setError) => {
    const payload = {
      ...(formData?.product_id?.value
        ? { product_id: formData?.product_id?.value }
        : {}),
      ...(formData?.lab_id?.value ? { lab_id: formData?.lab_id?.value } : {}),
      price: formData?.price,
      phlebotomy_cost: formData?.phlebotomy_cost,
      turnaround_time: formData?.turnaround_time?.value,
      bio_markers: (formData?.bio_markers || []).map(
        (kitBioMarker) => kitBioMarker?.value,
      ),
      status: formData?.status?.value,
    };
    setAddEditButtonLoading(true);
    if (!isEditMode) {
      addKitVariant(payload)
        .then((response) => {
          setShowAddTestKitModal(false);
          setAddKitSuccessMessage(response?.data);
          setConfirmationModalStatus('add-kit-success');
          setFilter({});
          clearFilterRef?.current();
          setAddEditButtonLoading(false);
        })
        .catch((error) => {
          setAddEditButtonLoading(false);
          catchHandler(error, setError, true);
        });
    } else {
      updateKitVariantInfo(payload, editItem?.product_variant_id, setError);
    }
  };

  const editModalHandler = (editInfo) => {
    setEditItem({});
    setIsEditMode(true);
    setShowAddTestKitModal(true);
    setEditModalLoading(true);
    getKitVariantDetails(editInfo?.product_variant_id)
      .then((res) => {
        setEditItem(res.data);
        setEditModalLoading(false);
      })
      .catch(() => {
        setEditModalLoading(false);
      });
  };

  const setAsFeaturedHandler = (item) => {
    setConfirmationModalStatus('set-as-featured');
    setFeatureItem(item);
  };

  const confirmFeatureHandler = () => {
    const payload = {
      is_featured: featureItem?.is_featured === 'true' ? 'false' : 'true',
    };
    setConfirmationLoading(true);
    setKitVariantAsFeatured(payload, featureItem?.product_variant_id)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchAllKitVariants(filter);
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const statusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModalStatus('status-update');
  };

  const confirmStatusUpdateHandler = () => {
    setConfirmationLoading(true);
    updateKitVariantInfo(
      {
        status:
          updateStatusItem?.status_slug === 'approved' ? 'disabled' : 'approved',
      },
      updateStatusItem?.product_variant_id,
    );
  };

  const confirmSuccessHandler = () => {
    setConfirmationModalStatus('');
    setShowAddTestKitModal(true);
  };

  const confirmDeleteKitHandler = () => {
    setConfirmationLoading(true);
    deleteProductVariant(deleteItem?.product_variant_id)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchAllKitVariants(filter);
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const deleteKitHandler = (item) => {
    setDeleteItem(item);
    setConfirmationModalStatus('delete-kit');
  };

  const getKitVariantStatus = (kitVariantStatus) => {
    switch (kitVariantStatus) {
      case 'approved':
        return <ApprovedIcon className="table__status-icon" />;
      case 'disabled':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const fetchAllLabs = async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllLabDetails(params);
      const lab = (res?.data?.data || []).map((data) => ({
        value: data.lab_name,
        label: data.lab_name,
      }));
      setDefaultOptions(lab);
      if (callback) {
        return callback(lab);
      }
      return filterOptions(lab, 'All');
    } catch (e) {
      return [];
    }
  };

  const loadOptionsHandler = (searchKeyWord, callback) => {
    const lab = fetchAllLabs(searchKeyWord, callback);
    return lab;
  };

  const loadOptions = debounce(loadOptionsHandler, 400);

  useEffect(() => {
    fetchAllLabs();
  }, []);

  useEffect(() => {
    setModalInfo({
      title: isEditMode ? 'Kit Variant' : 'Test Kit',
      lab: {
        labName: !isEmpty(editItem) ? editItem?.lab_name : '',
        description: '',
        title: isEditMode ? '' : 'Select Laboratory',
      },
      kit: {
        kitName: !isEmpty(editItem)
          ? `${editItem?.category_name} / ${editItem?.product_name}`
          : '',
        title: isEditMode ? '' : 'Select Test Kit',
        description: '',
      },
      className: isEditMode ? 'addkit edit-textkit' : 'addkit kit',
      cancelButtonText: 'Close',
      successButtonText: isEditMode ? 'Save Changes' : 'Add Kit',
    });
  }, [editItem, isEditMode]);

  useEffect(() => {
    setTableLoading(true);
    fetchAllKitVariants(filter);
  }, [filter]);

  useEffect(() => {
    setHeader('Test Kits (Variants)');
  }, [setHeader]);

  const mappings = [
    {
      key: 'id',
      label: 'ID',
      render: (item) => (
        <Button
          onClick={() => editModalHandler(item)}
          className="inline__list inline__list--regular blue"
        >
          {item.product_variant_id}
        </Button>
      ),
    },
    {
      key: 'product_category',
      label: 'KIT CATEGORY / NAME',
      render: (item) => (
        <div className="table__main-content">
          <div className="table__main-title">{item.category_name}</div>
          <span className="inline__list blue">{item.product_name}</span>
        </div>
      ),
    },
    {
      key: 'lab_name',
      label: 'LAB NAME',
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getKitVariantStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'price',
      label: 'PRICE',
      className: 'text-center',
      render: (item) => (
        <>
          $
          {item.price}
        </>
      ),
    },
    {
      key: 'bio_markers_count',
      label: 'BIO',
      className: 'text-center',
    },
    {
      key: 'turnaround_time',
      label: 'TA TIME',
      className: 'text-center',
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <Button
            type="button"
            className={`cursor-auto ${
              item?.is_featured === 'true'
                ? 'button__star button__action'
                : 'button__star button__action disable'
            }`}
          >
            <StarIcon />
          </Button>
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <Button
                  className="inline_link"
                  onClick={() => editModalHandler(item)}
                >
                  Edit
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => setAsFeaturedHandler(item)}
                >
                  {item?.is_featured === 'true'
                    ? 'Remove From Featured'
                    : 'Set as Featured'}
                </Button>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => statusUpdateHandler(item)}
                >
                  {item?.status_slug === 'approved' ? 'Disable' : 'Approve'}
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  onClick={() => {
                    deleteKitHandler(item);
                  }}
                  className="inline__list inline__list--regular red"
                >
                  Delete Test Kit
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const headerSchema = Yup.object().shape({
    product_id: Yup.object().required('Test Kit is required'),
    lab_id: Yup.object().required('Laboratory required'),
  });

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <Filter
        filters={[
          {
            filter_type: 'select',
            name: 'category',
            options: filterOptions(
              lookupData?.lookupDetails?.productCategoriesOptions,
              'All',
            ),
            placeholder: 'Category',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'async-select',
            name: 'lab_name',
            loadOptions,
            defaultOptions: filterOptions(defaultOptions, 'All'),
            placeholder: 'Lab name',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'select',
            name: 'status',
            options: filterOptions(
              lookupData?.lookupDetails?.kitFilterStatusOptions,
              'All Status',
            ),
            placeholder: 'All Status',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        addButton={addButton}
        meta={meta}
        clearFilter={clearFilterRef}
      />
      <ListingComponent
        isLoading={isTableLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={testKitVariants}
        shouldPaginate
        primaryKey="product_variant_id"
      />
      {showAddTestKitModal ? (
        <AddEditTestKit
          hideModal={() => {
            setShowAddTestKitModal(false);
            setEditItem({});
            setIsEditMode(false);
          }}
          modalInfo={modalInfo}
          editItem={editItem}
          onSuccessHandler={(formData, setError) => addKitHandler(formData, setError)}
          headerSchema={!isEditMode ? headerSchema : {}}
          isLabSelect={!isEditMode}
          isKitSelect={!isEditMode}
          isEditModal={isEditMode}
          isEditModalLoading={isEditModalLoading}
          addEditButtonLoading={addEditButtonLoading}
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'delete-kit' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmDeleteHtml}
          successButtonText="Delete Kit"
          btnColor="primary"
          isLoading={isConfirmationLoading}
          successHandler={() => confirmDeleteKitHandler()}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'add-kit-success' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmSuccessHtml}
          successButtonText="Add Another Test Kit"
          successHandler={() => confirmSuccessHandler()}
          cancelButtonText="Done"
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'set-as-featured' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmFeaturedHtml}
          successButtonText="Confirm"
          successHandler={() => confirmFeatureHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'status-update' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmDeactivateHtml}
          successButtonText="Confirm"
          successHandler={() => confirmStatusUpdateHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default TestKits;
