import React from 'react';

const ExcelIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.5833 2.91669H10V17.0834H19.5833V2.91669Z" fill="white" />
    <path
      d="M19.583 17.5H11.2497C11.0194 17.5 10.833 17.3136 10.833 17.0833C10.833 16.853 11.0194 16.6667 11.2497 16.6667H19.1663V3.33333H11.2497C11.0194 3.33333 10.833 3.14697 10.833 2.91667C10.833 2.68636 11.0194 2.5 11.2497 2.5H19.583C19.8133 2.5 19.9997 2.68636 19.9997 2.91667V17.0833C19.9997 17.3136 19.8133 17.5 19.583 17.5Z"
      fill="#177848"
    />
    <path d="M11.6667 0L0 2.17391V17.8261L11.6667 20V0Z" fill="#177848" />
    <path
      opacity="0.2"
      d="M0 2.17392V2.38225L11.6667 0.208333V0L0 2.17392Z"
      fill="white"
    />
    <path d="M14.1663 4.16669H10.833V5.83335H14.1663V4.16669Z" fill="#177848" />
    <path d="M18.3333 4.16669H15V5.83335H18.3333V4.16669Z" fill="#177848" />
    <path d="M14.1663 6.66669H10.833V8.33335H14.1663V6.66669Z" fill="#177848" />
    <path d="M18.3333 6.66669H15V8.33335H18.3333V6.66669Z" fill="#177848" />
    <path d="M14.1663 9.16669H10.833V10.8334H14.1663V9.16669Z" fill="#177848" />
    <path d="M18.3333 9.16669H15V10.8334H18.3333V9.16669Z" fill="#177848" />
    <path d="M14.1663 11.6667H10.833V13.3334H14.1663V11.6667Z" fill="#177848" />
    <path d="M18.3333 11.6667H15V13.3334H18.3333V11.6667Z" fill="#177848" />
    <path d="M14.1663 14.1667H10.833V15.8334H14.1663V14.1667Z" fill="#177848" />
    <path d="M18.3333 14.1667H15V15.8334H18.3333V14.1667Z" fill="#177848" />
    <path
      opacity="0.1"
      d="M0 17.8261L11.6667 20V19.7917L0 17.6177V17.8261Z"
      fill="black"
    />
    <path
      d="M19.5837 17.5C19.814 17.5 20.0003 17.3136 20.0003 17.0833V10.8333L11.667 2.5V17.5H19.5837Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M6.13099 10.4167L8.04566 6.97021L8.0215 6.95679L6.56597 7.06075L5.41627 9.1302L4.35431 7.21873L2.97949 7.31689L4.70155 10.4167L2.97949 13.5164L4.35431 13.6146L5.41627 11.7031L6.56597 13.7726L8.0215 13.8765L8.04566 13.8631L6.13099 10.4167Z"
      fill="white"
    />
    <path
      opacity="0.05"
      d="M11.6667 0L0 2.17391V17.8261L11.6667 20V0Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M19.5833 2.5H11.6667V0L0 2.17392V17.8261L11.6667 20V17.5H19.5833C19.8136 17.5 20 17.3136 20 17.0833V2.91667C20 2.68636 19.8136 2.5 19.5833 2.5Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.91699"
        y1="6.25"
        x2="19.4617"
        y2="17.7947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.1" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-0.000679604"
        y1="9.99955"
        x2="11.6667"
        y2="9.99955"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-1.30288"
        y1="4.96794"
        x2="20.8712"
        y2="15.3079"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export default ExcelIcon;
