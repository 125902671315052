import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import moment from 'moment';

const FedexTrackingModal = ({ orderDetails, hideModal }) => {
  const trackingOrderInfo = () => {
    const infoArray = [...orderDetails?.tracking_events]?.reverse();
    return infoArray;
  };
  return (
    <Modal isOpen toggle={hideModal} centered className="fedex__modal">
      <ModalBody>
        <h2 className="modal__head">
          Tracking
          {' '}
          <span>Info</span>
        </h2>
        <ul className="fedex__order-status">
          {trackingOrderInfo()?.map((item) => (
            <li className="active" key={item?.date}>
              <p className="fedex__status">{item?.description}</p>
              <p className="fedex__time">{moment(item?.date).format('LLL')}</p>
            </li>
          ))}
        </ul>
        <div className="modal-footer">
          <Button color="primary outline" onClick={hideModal}>
            close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FedexTrackingModal;
