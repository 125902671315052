import React, { useEffect, useCallback, useState } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import CustomNumberField from 'components/shared/form-fields/custom-number-field';
import Loader from 'components/shared/loader';
import ButtonWithLoader from 'components/shared/loading-button';

import { ShowPasswordIcon, HidePasswordIcon } from 'assets/images/svg-icons';

import {
  getLabAccountInfo,
  updateLabAccountInfo,
} from 'services/lab-management';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Not a valid Email').required('Email is required'),
  phone_number: Yup.string().required('Phone Number is required'),
  password: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase and One Special Case Character',
      ),
  }),
  password_confirmation: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
});

const buildFormData = (data) => {
  const formData = {
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    email: data?.email || '',
    phone_number: data?.phone_number || '',
  };
  return formData;
};

const LabAccountInfo = () => {
  const { labId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [labAccountInfo, setLabAccountInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(labAccountInfo),
    resolver: yupResolver(schema),
  });

  const {
    register,
    setValue,
    reset,
    watch,
    setError,
    formState: { errors },
  } = methods;

  const password = watch('password');

  const fetchLabAccountInfo = useCallback(() => {
    if (labId) {
      setIsLoading(true);
      getLabAccountInfo(labId)
        .then((response) => {
          setLabAccountInfo(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [labId]);

  const onSubmit = (formData) => {
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone_number: formData.phone_number,
      ...(formData?.password ? { password: formData?.password } : {}),
      ...(formData?.password_confirmation
        ? { password_confirmation: formData?.password_confirmation }
        : {}),
    };
    setIsButtonLoading(true);
    updateLabAccountInfo(labId, payload)
      .then((res) => {
        toast.success(res?.data?.message || 'Lab account info updated successfully');
        setIsButtonLoading(false);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  useEffect(() => {
    fetchLabAccountInfo();
  }, [fetchLabAccountInfo]);

  useEffect(() => {
    if (!isEmpty(labAccountInfo)) {
      reset(buildFormData(labAccountInfo));
    }
  }, [reset, labAccountInfo]);

  useEffect(() => {
    register('isPasswordRequired', { value: true });
  }, [register]);

  useEffect(() => {
    if (!password) {
      setValue('isPasswordRequired', false);
    } else if (password) {
      setValue('isPasswordRequired', true);
    }
  }, [setValue, password]);

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <Loader />
      ) : (
        <Form className="tab__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="tab__form-sec">
            <Col xs="12" md="4">
              <FormGroup>
                <Label>First Name</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="first_name"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Last Name</Label>
                <CustomInputField
                  className="form-control"
                  type="text"
                  name="last_name"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Phone Number</Label>
                <CustomNumberField
                  format="(###) ### ####"
                  className="form-control"
                  type="text"
                  name="phone_number"
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Email Address</Label>
                <CustomInputField
                  className="form-control"
                  type="email"
                  name="email"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="tab__form-sec">
            <h2 className="tab__head">
              Change
              {' '}
              <span>Password</span>
            </h2>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>New Password</Label>
                <div
                  className={`view-password ${
                    !isEmpty(errors?.password) ? 'view-password--error' : ''
                  }`}
                >
                  <CustomInputField
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    name="password"
                  />
                  <span
                    role="presentation"
                    onKeyDown={() => setShowPassword(!showPassword)}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                  </span>
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Confirm New Password</Label>
                <div
                  className={`view-password ${
                    !isEmpty(errors?.password_confirmation)
                      ? 'view-password--error'
                      : ''
                  }`}
                >
                  <CustomInputField
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="form-control"
                    name="password_confirmation"
                  />
                  <span
                    role="presentation"
                    onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <HidePasswordIcon />
                    ) : (
                      <ShowPasswordIcon />
                    )}
                  </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <div className="tab__btn d-flex justify-content-center align-items-center">
            <ButtonWithLoader isLoading={isButtonLoading} color="secondary">
              Save Changes
            </ButtonWithLoader>
          </div>
        </Form>
      )}
    </FormProvider>
  );
};

export default LabAccountInfo;
