import React from 'react';

const RevenueIcon = (props) => (
  <svg
    {...props}
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="55" height="55" rx="10" fill="#78818F" />
    <path
      d="M27.2821 13.2996C27.9573 13.1854 28.6451 13.2257 29.326 13.2279C31.2168 13.3567 33.1177 13.7429 34.8235 14.5848C37.612 15.8757 39.8795 18.164 41.2686 20.8632C41.6772 21.5461 41.0099 22.4843 40.2188 22.3444C39.7036 22.3097 39.4312 21.8439 39.2179 21.4431C37.8015 18.8995 35.4409 16.8429 32.6331 15.9294C27.8802 14.268 22.1818 15.7939 18.9122 19.5746C17.0305 21.7152 16.0386 24.5566 16.0828 27.3801C16.025 29.3102 16.4199 31.2649 17.2995 32.9935C18.3402 35.1396 20.0471 36.9656 22.1239 38.1725C24.9419 39.8462 28.4578 40.3119 31.6276 39.4611C33.8373 38.8991 35.8631 37.6821 37.4304 36.0532C39.2598 34.1533 40.4197 31.6579 40.7329 29.0583C40.7625 28.7045 40.7613 28.3496 40.7466 27.9947C40.2359 28.3944 39.8398 28.9161 39.3257 29.3102C38.8013 29.7031 37.9502 29.4378 37.7572 28.8131C37.5722 28.4313 37.7629 27.9846 38.0557 27.7126C39.0817 26.7072 40.1008 25.6963 41.1313 24.6965C41.5092 24.2991 42.2095 24.3036 42.5851 24.7033C43.5668 25.6392 44.5099 26.6143 45.4905 27.5514C45.8389 27.8492 46.1067 28.3071 45.958 28.7728C45.8026 29.4367 44.898 29.7513 44.3567 29.3303C43.8494 28.8903 43.4034 28.3854 42.9097 27.9309C42.862 28.2668 42.7973 28.6015 42.7769 28.9407C42.3491 33.4267 39.5288 37.5836 35.5476 39.7723C32.9918 41.2132 29.9741 41.8468 27.0449 41.5557C23.4972 41.2277 20.0993 39.5372 17.7228 36.9186C15.7015 34.7243 14.4089 31.8862 14.0831 28.9396C13.7336 25.6694 14.4849 22.2559 16.3632 19.5198C18.7816 15.9384 22.957 13.6478 27.2821 13.2996Z"
      fill="#0047BA"
    />
    <path
      d="M24.1428 18.2412C25.8917 17.4161 27.8835 17.1776 29.7969 17.4004C31.5151 17.6098 33.163 18.2905 34.5556 19.2969C36.6415 20.7904 38.1033 23.0989 38.5742 25.5955C38.6355 25.8586 38.3246 25.9896 38.1861 26.1665C37.5619 26.7856 36.7141 27.4192 36.7414 28.3854C36.6676 29.3392 37.4076 30.1587 38.2837 30.4565C37.773 32.2064 36.7777 33.8342 35.4022 35.059C33.9007 36.4472 31.9169 37.2533 29.8911 37.5119C28.9832 37.56 28.0707 37.56 27.1628 37.5097C24.6036 37.176 22.117 35.9804 20.443 34.0144C17.6784 30.7946 17.3788 25.8317 19.6009 22.2514C20.6632 20.5083 22.2611 19.0775 24.1428 18.2412ZM27.6156 19.8534C27.4181 20.3661 27.5169 20.9236 27.4511 21.4565C26.6884 21.5338 25.97 21.8943 25.4116 22.4037C24.8079 23.0183 24.4856 23.8591 24.3993 24.701C24.3391 26.0926 25.0723 27.558 26.3786 28.1693C27.2615 28.5903 28.2636 28.4973 29.2136 28.5309C30.1953 28.6115 30.8115 29.7479 30.4313 30.62C30.1884 31.1775 29.604 31.5862 28.9809 31.5761C27.7677 31.5817 26.5545 31.5694 25.3413 31.5794C25.0575 31.5638 24.792 31.7071 24.6013 31.9052C24.2711 32.1762 24.3357 32.6564 24.4084 33.027C24.6093 33.3382 24.9463 33.6069 25.3401 33.589C26.0143 33.6013 26.6895 33.5901 27.3648 33.5913C27.3943 34.0895 27.3103 34.6235 27.5305 35.0903C27.829 35.6714 28.7403 35.7833 29.1648 35.2784C29.6289 34.806 29.4281 34.1007 29.4859 33.5129C30.1987 33.3819 30.9261 33.1322 31.438 32.6027C32.9054 31.2828 32.8566 28.756 31.4459 27.4136C30.6356 26.5997 29.4178 26.3568 28.3011 26.4374C27.6247 26.5169 26.9029 26.2269 26.5681 25.6235C26.3275 25.1342 26.3196 24.5062 26.5953 24.0292C26.9324 23.5031 27.5975 23.3642 28.1819 23.3195C29.268 23.2646 30.3553 23.3822 31.4402 23.315C31.6877 23.2959 31.9362 23.2333 32.1518 23.109C32.7806 22.753 32.7147 21.6144 31.9816 21.4162C31.1724 21.1834 30.3201 21.3244 29.4905 21.2976C29.4496 20.7714 29.6323 20.1612 29.2601 19.7156C28.8686 19.204 27.8653 19.2074 27.6156 19.8534Z"
      fill="#0047BA"
    />
  </svg>
);
export default RevenueIcon;
