import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import moment from 'moment';
import { pick } from 'lodash';

import ListingComponent from 'components/shared/listing-component';
import Filter from 'components/shared/filter/filter';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import ReviewCommentModal from 'components/reviews/review-comment-modal';

import { DotIcon, StarIcon } from 'assets/images/svg-icons';
import { getAllReview, reviewStatusUpdate, viewReview } from 'services/reviews';
import { filterOptions } from 'helpers/utils';
import HeaderText from 'header-context';
import { LookupContext } from 'context/lookup-context/lookup-context';

const Reviews = () => {
  const [, setHeader] = useContext(HeaderText);
  const { lookupDetails } = useContext(LookupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [showReviewCommentModal, setShowReviewCommentModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const confirmStatusUpdateHTML = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'approved'
          ? 'Disapprove'
          : 'Approve'}
        {' '}
        <span> Review</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'approved'
        ? 'Disapproving'
        : 'Approving'
    } ${updateStatusItem?.consumer} review of ${
      updateStatusItem?.product_name
    }.`,
  };

  const fetchAllReviews = useCallback((params) => {
    setIsLoading(true);
    getAllReview(params)
      .then((response) => {
        setReviews(response?.data?.data);
        setMeta(response?.data?.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const handleUpdateReviewStatus = (item) => {
    setIsModalLoading(true);
    viewReview(item?.product_variant_review_id)
      .then((response) => {
        setUpdateStatusItem(response?.data);
        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
      });
  };

  const confirmStatusUpdate = () => {
    setIsButtonLoading(true);
    reviewStatusUpdate(
      updateStatusItem?.product_variant_review_id,
      { status: updateStatusItem?.status_slug === 'approved' ? 'unapproved' : 'approved' },
    )
      .then(() => {
        setIsButtonLoading(false);
        setShowConfirmationModal(false);
        setShowReviewCommentModal(false);
        setUpdateStatusItem({});
        fetchAllReviews();
      })
      .catch(() => {
        setIsButtonLoading(false);
      });
  };

  useEffect(() => {
    fetchAllReviews(filter);
  }, [filter, fetchAllReviews]);

  useEffect(() => {
    setHeader('Reviews');
  }, [setHeader]);

  const mappings = [
    {
      key: 'product_name',
      label: 'PRODUCT',
      className: 'text-dark inline__list--regular text-start',
    },
    {
      key: 'consumer_name',
      label: 'CONSUMER',
      render: (item) => `${item.first_name} ${item.last_name}`,
    },
    {
      key: 'rating',
      label: 'REVIEW SOURCE',
      render: (item) => (
        <div className={`star-rateing active-${item.rating} small`}>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <p className="star-rateing__text">
            <b>{item.rating}</b>
            /5
          </p>
        </div>
      ),
    },
    {
      key: 'submitted_date',
      label: 'DATE / TIME',
      render: (item) => moment(item.date_time).format('LLL'),
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <span
          className={`inline__list inline__list--regular ${
            item.status_slug === 'unapproved' ? 'red' : ''
          }`}
        >
          {item.status}
        </span>
      ),
    },
    {
      key: 'action',
      label: 'ACTIONS',
      className: 'text-center',
      render: (item) => (
        <UncontrolledDropdown>
          <DropdownToggle className="button__action">
            <DotIcon />
          </DropdownToggle>
          <DropdownMenu className="table__dropdown">
            <DropdownItem tag="div">
              <Button
                onClick={() => {
                  setShowReviewCommentModal(true);
                  handleUpdateReviewStatus(item);
                }}
                className="inline__list inline__list--regular"
              >
                View Comment
              </Button>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag="div">
              <Button
                onClick={() => {
                  setShowConfirmationModal(true);
                  handleUpdateReviewStatus(item);
                }}
                className={`inline__list inline__list--regular ${item?.status_slug === 'approved' ? 'red' : 'blue'}`}
              >
                {item.status_slug === 'approved' ? 'Disapprove' : 'Approve'}
              </Button>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <Filter
        filters={[
          {
            filter_type: 'select',
            name: 'status',
            options: filterOptions(lookupDetails?.reviewStatus, 'All Status'),
            placeholder: 'All Status',
            inputProps: {
              className: 'selectbox selectbox--activeplaceholder',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        meta={meta}
      />
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={reviews}
        shouldPaginate
        primaryKey="product_variant_review_id"
      />
      {showReviewCommentModal ? (
        <ReviewCommentModal
          hideModal={() => {
            setShowReviewCommentModal(false);
            setUpdateStatusItem({});
          }}
          isModalLoading={isModalLoading}
          dataSource={updateStatusItem}
          successHandler={confirmStatusUpdate}
          successButtonText={updateStatusItem?.status_slug === 'approved' ? 'Disapprove' : 'Approve'}
          btnColor={updateStatusItem?.status_slug === 'approved' ? 'primary' : ''}
          isLoading={isButtonLoading}
        />
      ) : (
        ''
      )}
      {showConfirmationModal ? (
        <ConfirmationModal
          hideModal={() => {
            setShowConfirmationModal(false);
            setUpdateStatusItem({});
          }}
          successButtonText={updateStatusItem?.status_slug === 'approved' ? 'Disapprove' : 'Approve'}
          successHandler={confirmStatusUpdate}
          btnColor={updateStatusItem?.status_slug === 'approved' ? 'primary' : ''}
          renderHtml={confirmStatusUpdateHTML}
          isLoading={isButtonLoading}
          cancelButtonText="Close"
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Reviews;
