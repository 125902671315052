import { request } from 'helpers/api';

export const getAllKitDetails = (params) => request({
  method: 'GET',
  url: '/api/products',
  params,
});

export const getKitDetailsById = (productId) => request({
  method: 'GET',
  url: `/api/products/${productId}`,
});

export const updateKitDetails = (productId, params) => request({
  method: 'POST',
  url: `/api/products/${productId}`,
  params,
});

export const saveKit = (params) => request({
  method: 'POST',
  url: '/api/products',
  params,
});
export const deleteKit = (productId) => request({
  method: 'DELETE',
  url: `/api/products/${productId}`,
});
