import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import Loader from 'components/shared/loader';
import ListingCard from 'components/shared/listing-card';
import ExportCSV from 'components/reports/export-csv';
import AsyncSelectField from 'components/shared/form-fields/asyc-select';

import {
  SellingKitIcon,
  ExcelIcon,
  CategoryIcon,
  CovidIcon,
  SpermIcon,
  ComprehensiveIcon,
  MaleIcon,
  FemaleIcon,
  FoodIcon,
  DotIcon,
  LabFillBlueIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { getAllReport } from 'services/reports';
import { generateDropdownOptions, filterOptions } from 'helpers/utils';
import { getAllLabDetails } from 'services/lab-management';
import { LookupContext } from 'context/lookup-context/lookup-context';

Chart.register(ArcElement, Tooltip, Legend);
let getValue;

const Reports = () => {
  const [, setHeader] = useContext(HeaderText);
  const [exportToExcel, setExportToExcel] = useState(false);
  const [reportData, setReportData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { lookupDetails } = useContext(LookupContext);
  const [dateFilter, setDateFilter] = useState({
    label: 'This Month',
    value: 'this-month',
  });
  const [doughnutChartData, setDoughnutChartData] = useState([]);
  const [topSellingKitsLoading, setTopSellingKitsLoading] = useState(false);
  const [categoryFilterLoading, setCategoryFilterLoading] = useState(false);
  const [revenueByCategoryLoading, setRevenueByCategoryLoading] = useState(false);
  const [graphInnerText, setGraphInnerText] = useState({});

  const getStartDate = (preDefinedOption) => {
    switch (preDefinedOption) {
      case 'this-month':
        return moment().startOf('month').format('YYYY-MM-DD');
      case 'last-month':
        return moment().subtract(30, 'days').format('YYYY-MM-DD');
      case 'last-6-month':
        return moment().subtract(6, 'months').format('YYYY-MM-DD');
      case 'this-year':
        return moment().startOf('year').format('YYYY-MM-DD');
      case 'last-1-year':
        return moment().subtract(1, 'year').format('YYYY-MM-DD');
      default:
        return '';
    }
  };

  const revenueLabsMapping = [
    {
      key: 'lab_name',
    },
    {
      key: 'revenue',
      render: (item) => (
        <>
          $
          {item.revenue}
        </>
      ),
    },
  ];

  const revenueCategoryLogo = (category) => {
    switch (category) {
      case 'covid':
        return <CovidIcon className="category-tile__image" />;
      case 'sexual-health':
        return <SpermIcon className="category-tile__image" />;
      case 'over-all-health':
        return <ComprehensiveIcon className="category-tile__image" />;
      case 'mens-health':
        return <MaleIcon className="category-tile__image" />;
      case 'womens-health':
        return <FemaleIcon className="category-tile__image" />;
      case 'allergy':
        return <FoodIcon className="category-tile__image" />;
      default:
        return null;
    }
  };

  const fetchAllLabs = useCallback(async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const response = await getAllLabDetails(params);
      const labs = generateDropdownOptions(
        response?.data?.data,
        'lab_id',
        'lab_name',
      );
      setDefaultOptions(labs);
      if (callback) {
        return callback(filterOptions(labs, 'All'));
      }
      return filterOptions(labs, 'All');
    } catch (e) {
      return [];
    }
  }, []);

  const loadOptionsHandler = (searchKeyWord, callback) => {
    const currentLabs = fetchAllLabs(searchKeyWord, callback);
    return currentLabs;
  };

  const loadOptions = debounce(loadOptionsHandler, 400);

  const filterLabsForKits = (category) => (
    <AsyncSelectField
      defaultOptions={filterOptions(defaultOptions, 'All')}
      loadOptions={loadOptions}
      name="labFilterKits"
      className="selectbox"
      placeholder="All Labs"
      customizeOutputHandler={(selectedItem) => ({ ...selectedItem, category })}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
  const filterLabsForCategory = (category) => (
    <AsyncSelectField
      defaultOptions={filterOptions(defaultOptions, 'All')}
      loadOptions={loadOptions}
      name="labFilterCategory"
      className="selectbox"
      placeholder="All Labs"
      customizeOutputHandler={(selectedItem) => ({ ...selectedItem, category })}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
  const filterLabsForRevenue = (category) => (
    <AsyncSelectField
      defaultOptions={filterOptions(defaultOptions, 'All')}
      loadOptions={loadOptions}
      name="labFilterRevenue"
      className="selectbox"
      placeholder="All Labs"
      customizeOutputHandler={(selectedItem) => ({ ...selectedItem, category })}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );

  const methods = useForm({
    mode: 'all',
  });

  const { watch } = methods;
  const { labFilterKits, labFilterCategory, labFilterRevenue } = watch();

  const fetchAllReports = useCallback(
    (lab) => {
      const payload = {
        ...(lab?.lab_id ? { lab_id: lab?.lab_id } : {}),
        ...(lab?.category ? { report_type: lab?.category } : {}),
        from_date: getStartDate(dateFilter?.value),
        to_date: moment().format('YYYY-MM-DD'),
      };
      if (lab?.category === 'top-selling-kits') {
        setTopSellingKitsLoading(true);
      }
      if (lab?.category === 'top-selling-categories') {
        setCategoryFilterLoading(true);
      }
      if (lab?.category === 'revenue-by-category') {
        setRevenueByCategoryLoading(true);
      }

      getAllReport(payload)
        .then((response) => {
          if (isEmpty(lab)) {
            setReportData(response.data);
          } else {
            const filteredField = lab.category.replace(/-/g, '_');
            setReportData((prev) => ({
              ...prev,
              [filteredField]: response.data[filteredField],
            }));
          }

          if (response?.data?.five_top_selling_labs) {
            setDoughnutChartData([]);
            const topSellingLabGraphData = response?.data?.five_top_selling_labs;
            topSellingLabGraphData?.forEach((data) => {
              setDoughnutChartData((prevState) => prevState.concat([
                { name: data?.lab_name, value: data?.revenue }]));
            });
          }

          setIsLoading(false);
          setTopSellingKitsLoading(false);
          setCategoryFilterLoading(false);
          setRevenueByCategoryLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setTopSellingKitsLoading(false);
          setCategoryFilterLoading(false);
          setRevenueByCategoryLoading(false);
        });
    },
    [dateFilter],
  );

  const onDateChangeHandler = (date) => {
    setDateFilter({ label: date?.label, value: date?.value });
  };

  useEffect(() => {
    setHeader('Reports');
  }, [setHeader]);

  useEffect(() => {
    fetchAllLabs();
  }, [fetchAllLabs]);

  useEffect(() => {
    setIsLoading(true);
    fetchAllReports();
  }, [fetchAllReports]);

  useEffect(() => {
    if (!isEmpty(labFilterKits)) {
      fetchAllReports(labFilterKits);
    }
  }, [fetchAllReports, labFilterKits]);

  useEffect(() => {
    if (!isEmpty(labFilterRevenue)) {
      fetchAllReports(labFilterRevenue);
    }
  }, [fetchAllReports, labFilterRevenue]);

  useEffect(() => {
    if (!isEmpty(labFilterCategory)) {
      fetchAllReports(labFilterCategory);
    }
  }, [fetchAllReports, labFilterCategory]);

  const topSellingKitMappings = [
    {
      key: 'kit_name',
    },
    { key: 'count' },
  ];

  const topSellingCategories = [
    {
      key: 'category_name',
    },
    {
      key: 'count',
    },
  ];

  const data = {
    datasets: [
      {
        label: 'Top 5 selling Lab',
        data: doughnutChartData,
        backgroundColor: [
          '#FFC700',
          '#29B3A8',
          '#A5C5ED',
          '#0047BA',
          '#C10230',
        ],
        hoverBackgroundColor: [
          '#F0BC02',
          '#28AA9F',
          '#8DB0DA',
          '#0245B1',
          '#B4032C',
        ],
      },
    ],
  };

  const getInnerText = (chartResult) => {
    if (chartResult) {
      setGraphInnerText(chartResult);
    }
    return '';
  };
  const options = {
    events: ['click'],
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    cutout: 65,
    offset: 0,
    maintainAspectRatio: false,
    onClick: (evt, item) => {
      const gettingIndex = item[0]?.index;
      getValue = data.datasets[0].data[gettingIndex];
    },
  };

  const plugins = [
    {
      beforeDraw(chart) {
        const { ctx } = chart;
        ctx.restore();
        getInnerText(getValue);
        ctx.save();
      },
    },
  ];

  return (
    <div className="dashboard report">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <FormProvider {...methods}>
            <div className="report__filter">
              <Button
                className="btn--white"
                onClick={() => setExportToExcel(true)}
              >
                <ExcelIcon />
                <span>Export CSV</span>
              </Button>
              <Select
                options={lookupDetails?.reportFilter}
                className="selectbox selectbox--white"
                onChange={onDateChangeHandler}
                value={dateFilter}
                name="filterByDate"
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <Row className="dashboard__card-outer">
              <Col xs="12" xl="4">
                <ListingCard
                  icon={<SellingKitIcon />}
                  title="Top Selling Kits"
                  isLoading={topSellingKitsLoading}
                  actionDropDown={{
                    render: () => filterLabsForKits('top-selling-kits'),
                    label: 'Filter by Labs',
                  }}
                  dataSource={reportData.top_selling_kits}
                  mappings={topSellingKitMappings}
                  primaryKey="kit_name"
                />
              </Col>
              <Col xs="12" xl="4">
                <ListingCard
                  icon={<SellingKitIcon />}
                  title="Top Selling Categories"
                  isLoading={categoryFilterLoading}
                  actionDropDown={{
                    render: () => filterLabsForCategory('top-selling-categories'),
                    label: 'Filter by Labs',
                  }}
                  dataSource={reportData.top_selling_categories}
                  mappings={topSellingCategories}
                  primaryKey="category_name"
                />
              </Col>
              <Col xs="12" xl="4">
                <div className="dashboard__whitebox mhl-card">
                  <div className="mhl-card__header justify-content-center">
                    <div className="mhl-card__icon-text">
                      <h2>5 Top Selling Labs</h2>
                    </div>
                  </div>
                  <div className="report__graph-wrap">
                    <div className="report__graph">
                      <Doughnut data={data} options={options} plugins={plugins} />
                    </div>
                    {!isEmpty(graphInnerText)
                      ? (
                        <div className="report__graph-text">
                          <h2>
                            {`$${graphInnerText.value}`}
                          </h2>
                          <p>{graphInnerText.name}</p>
                        </div>
                      ) : ''}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="dashboard__card-outer">
              <Col xs="12" xl="8">
                <div className="dashboard__whitebox mhl-card">
                  <div className="mhl-card__header">
                    <div className="mhl-card__icon-text">
                      <CategoryIcon />
                      <h2>Revenue by Category</h2>
                    </div>
                    <UncontrolledDropdown>
                      <DropdownToggle className="button__action">
                        <DotIcon />
                      </DropdownToggle>
                      <DropdownMenu className="table__dropdown">
                        <DropdownItem tag="div">Filter by Labs</DropdownItem>
                        {filterLabsForRevenue('revenue-by-category')}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Row className="category-tile__outer">
                    {revenueByCategoryLoading ? (
                      <Loader />
                    ) : (
                      <React.Fragment>
                        {reportData.revenue_by_category?.length > 0 ? (
                          reportData.revenue_by_category?.map((result) => (
                            <Col className="col-12 col-md-6 col-lg-4 col-xl-6 col-xxl-4" key={result.slug}>
                              <div className="category-tile">
                                <div className="category-tile__main">
                                  {revenueCategoryLogo(result.slug)}
                                  <h2 className="category-tile__amount">
                                    $
                                    {' '}
                                    {result.revenue}
                                  </h2>
                                </div>
                                <div className="category-tile__text">
                                  {result.category_name}
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : (
                          <p className="navbar-brand small__table">
                            No data found
                          </p>
                        )}
                      </React.Fragment>
                    )}
                  </Row>
                </div>
              </Col>
              <Col xs="12" xl="4">
                <ListingCard
                  icon={<LabFillBlueIcon />}
                  title="Revenue by Labs"
                  dataSource={reportData.revenue_by_labs}
                  mappings={revenueLabsMapping}
                  primaryKey="lab_name"
                />
              </Col>
            </Row>
            {exportToExcel ? (
              <ExportCSV closeModal={() => setExportToExcel(false)} />
            ) : (
              ''
            )}
          </FormProvider>
        </React.Fragment>
      )}
    </div>
  );
};
export default Reports;
