import React, { useState } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import ButtonWithLoader from 'components/shared/loading-button';

import { sendPasswordResetLink } from 'services/auth';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid Email')
    .required('Email is required'),
});

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const { setError } = methods;

  const onSubmit = (formData) => {
    setIsLoading(true);
    sendPasswordResetLink(formData)
      .then((res) => {
        setIsLoading(false);
        toast.success(
          res?.data?.message || 'We have emailed your password reset link!',
        );
      })
      .catch((error) => {
        setIsLoading(false);
        catchHandler(error, setError, true);
        setSuccessMessage('');
      });
  };

  return (
    <div className="auth__innerwrap">
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form-signin auth__form"
        >
          <h2 className="auth__head">
            Forgot
            {' '}
            <span className="auth__head--reg">Password</span>
          </h2>
          {successMessage ? (
            <p className="text-success">{successMessage}</p>
          ) : (
            ''
          )}
          <FormGroup>
            <Label for="email">Email Address</Label>
            <CustomInputField
              className="form-control mt-2"
              type="email"
              name="email"
              placeholder="Enter email"
            />
          </FormGroup>
          <div className="auth__btn d-flex align-items-center justify-content-center">
            <ButtonWithLoader isLoading={isLoading}>
              Send Reset Password Link
            </ButtonWithLoader>
          </div>
          <div className="auth__forgot text-center mt-4">
            <Link className="inline_link " to="/auth/login">
              Back To Login
            </Link>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ForgotPassword;
