import Dashboard from 'components/dashboard/dashboard';
import LabManagement from 'components/lab-management/lab-management';
import LabManagementDetails from 'components/lab-management/lab-management-details/lab-management-details';
import KitManagement from 'components/kit-management/kit-management';
import Consumers from 'components/consumers/consumers';
import Orders from 'components/orders/orders';
import OrdersDetails from 'components/orders/orders-details';
import Reports from 'components/reports/reports';
import PayoutSystem from 'components/payout-system/payout-system';
import PayoutSystemDetails from 'components/payout-system/payout-system-details';
import Notifications from 'components/notifications/notifications';
import MyAccount from 'components/my-account/my-account';
import Phlebotomists from 'components/phlebotomists/phlebotomists';
import TestKits from 'components/test-kits/test-kits';
import AddEditKit from 'components/kit-management/add-edit-kit';
import RegisteredKits from 'components/registered-kits/registered-kits';
import RegisteredKitsDetails from 'components/registered-kits/registered-kits-details';
import TestResults from 'components/test-results/test-results';
import Reviews from 'components/reviews/reviews';

const portalRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    key: 'dashboard',
  },
  {
    path: '/lab-management',
    component: LabManagement,
    exact: true,
    key: 'lab-management',
  },
  {
    path: '/lab-management/:labId',
    component: LabManagementDetails,
    exact: true,
    key: 'lab-management-details',
  },
  {
    path: '/kit-management',
    component: KitManagement,
    exact: true,
    key: 'kit-management',
  },
  {
    path: '/consumers',
    component: Consumers,
    exact: true,
    key: 'consumers',
  },
  {
    path: '/orders',
    component: Orders,
    exact: true,
    key: 'orders',
  },
  {
    path: '/orders/:orderId',
    component: OrdersDetails,
    exact: true,
    key: 'orders-details',
  },
  {
    path: '/reports',
    component: Reports,
    exact: true,
    key: 'reports',
  },
  {
    path: '/payouts',
    component: PayoutSystem,
    exact: true,
    key: 'payouts',
  },
  {
    path: '/payouts/:payoutId',
    component: PayoutSystemDetails,
    exact: true,
    key: 'payouts-details',
  },
  {
    path: '/notifications',
    component: Notifications,
    exact: true,
    key: 'notifications',
  },
  {
    path: '/my-account',
    component: MyAccount,
    exact: true,
    key: 'my-account',
  },
  {
    path: '/phlebotomists',
    component: Phlebotomists,
    exact: true,
    key: 'phlebotomists',
  },
  {
    path: '/test-kits',
    component: TestKits,
    exact: true,
    key: 'test-kits',
  },
  {
    path: '/kit-management/edit-kit/:productId',
    component: AddEditKit,
    exact: true,
    key: 'edit-kit',
  },
  {
    path: '/kit-management/add-kit',
    component: AddEditKit,
    exact: true,
    key: 'add-kit',
  },
  {
    path: '/registered-kits/:kitId',
    component: RegisteredKitsDetails,
    exact: true,
    key: 'kit-details',
  },
  {
    path: '/registered-kits',
    component: RegisteredKits,
    exact: true,
    key: 'registered-kits',
  },
  {
    path: '/test-results',
    component: TestResults,
    exact: true,
    key: 'test-results',
  },
  {
    path: '/reviews',
    component: Reviews,
    exact: true,
    key: 'reviews',
  },
  {
    redirect: true,
    path: '/',
    to: '/auth/login',
    navbarName: 'Redirect',
    key: 'redirect',
  },
];

export default portalRoutes;
