import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isEmpty, debounce } from 'lodash';

import { SearchIcon } from 'assets/images/svg-icons';
import SelectField from 'components/shared/form-fields/select-field';
import Loader from 'components/shared/loader';
import CustomNumberField from 'components/shared/form-fields/custom-number-field';
import ButtonWithLoader from 'components/shared/loading-button';
import AsyncSelectField from 'components/shared/form-fields/async-select-field';

import { LookupContext } from 'context/lookup-context/lookup-context';
import { getAllLabDetails } from 'services/lab-management';
import { getAllKitDetails } from 'services/kit-management';
import {
  mergeSchema,
  convertArrayToObject,
  generateDropdownOptions,
} from 'helpers/utils';

const schema = Yup.object().shape({
  status: Yup.object().required('Status is required').nullable(),
  price: Yup.number()
    .typeError('Please enter the price')
    .required('Kit Price is required'),
  phlebotomy_cost: Yup.number()
    .typeError('Please enter the phlebotomy cost')
    .required('Phlebotomy cost is required'),
  bio_markers: Yup.array()
    .required('Kit bio markers is required')
    .min(1, 'Kit bio markers is required'),
  turnaround_time: Yup.object().required('Turn around time is required'),
});

const AddEditTestKit = ({
  onSuccessHandler,
  hideModal,
  modalInfo,
  editItem,
  headerSchema = {},
  isLabSelect,
  isKitSelect,
  isEditModal,
  isEditModalLoading,
  addEditButtonLoading,
}) => {
  const [defaultLabOptions, setDefaultLabOptions] = useState([]);
  const [defaultKitOptions, setDefaultKitOptions] = useState([]);
  const lookupData = useContext(LookupContext);

  const { lookupDetails } = lookupData;

  const handleSchema = () => {
    if (!isEmpty(headerSchema)) {
      return mergeSchema(schema, headerSchema);
    }
    return schema;
  };

  const formatTurnAroundTime = useCallback(
    (turnAroundTime) => {
      const turnAroundTimeObject = convertArrayToObject(
        generateDropdownOptions(
          lookupDetails?.turnAroundTimeOptions,
          'value',
          'label',
        ),
        'label',
      );
      const finalOption = turnAroundTimeObject[turnAroundTime];

      return finalOption;
    },
    [lookupDetails?.turnAroundTimeOptions],
  );

  const buildFormData = useCallback(() => {
    const formData = {
      ...(!isEmpty(editItem?.status_slug)
        ? {
          status: lookupDetails?.kitStatusObject[editItem?.status_slug],
        }
        : ''),
      ...(!isEmpty(editItem?.price) ? { price: editItem?.price } : ''),
      ...(!isEmpty(editItem?.phlebotomy_cost) ? { phlebotomy_cost: editItem?.phlebotomy_cost } : ''),
      ...(!isEmpty(editItem?.bio_markers)
        ? {
          bio_markers: editItem?.bio_markers.map((item) => ({
            value: item?.bio_marker_id,
            label: item?.bio_marker_name,
          })),
        }
        : ''),
      ...(!isEmpty(editItem?.turnaround_time)
        ? {
          turnaround_time: formatTurnAroundTime(editItem?.turnaround_time),
        }
        : ''),
    };
    return formData;
  }, [editItem, lookupDetails?.kitStatusObject, formatTurnAroundTime]);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(handleSchema()),
    defaultValues: buildFormData(editItem),
  });
  const { reset, setError } = methods;

  const onSubmit = (formData) => {
    onSuccessHandler(formData, setError);
  };

  const generateDropdownForKit = (
    data,
    valueParam,
    categoryName,
    productName,
    productType,
  ) => (data || []).map((item) => {
    const finalLabel = (
      <>
        {item[categoryName]}
          &nbsp; - &nbsp;
        {item[productName]}
        <span
          className={`inline__list inline__list--regular ${
            item[productType] === 'Diagnostic' ? 'red' : 'blue'
          }`}
        >
            &nbsp; (
          {item[productType]}
          )
        </span>
      </>
    );
    return {
      ...item,
      value: item[valueParam],
      label: finalLabel,
    };
  });

  useEffect(() => {
    if (!isEmpty(editItem)) {
      reset(buildFormData(editItem));
    }
  }, [reset, editItem, buildFormData]);

  const fetchAllLabs = async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllLabDetails(params);
      const lab = (res?.data?.data || []).map((data) => ({
        value: data.lab_id,
        label: data.lab_name,
      }));
      setDefaultLabOptions(lab);
      if (callback) {
        return callback(lab);
      }
      return lab;
    } catch (e) {
      return [];
    }
  };

  const loadLabOptionsHandler = (searchKeyWord, callback) => {
    const lab = fetchAllLabs(searchKeyWord, callback);
    return lab;
  };

  const loadLabOptions = debounce(loadLabOptionsHandler, 400);

  useEffect(() => {
    if (isLabSelect) {
      fetchAllLabs();
    }
  }, [isLabSelect]);

  const fetchAllKits = useCallback(async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllKitDetails(params);
      const lab = generateDropdownForKit(
        res?.data?.data,
        'product_id',
        'category_name',
        'product_name',
        'product_type',
      );
      setDefaultKitOptions(lab);
      if (callback) {
        return callback(lab);
      }
      return lab;
    } catch (e) {
      return [];
    }
  }, []);

  const loadKitOptionsHandler = (searchKeyWord, callback) => {
    const lab = fetchAllKits(searchKeyWord, callback);
    return lab;
  };

  const loadKitOptions = debounce(loadKitOptionsHandler, 400);

  useEffect(() => {
    if (isKitSelect) {
      fetchAllKits();
    }
  }, [isKitSelect, fetchAllKits]);

  return (
    <Modal
      isOpen
      toggle={() => hideModal()}
      centered
      className={modalInfo?.className || 'addkit'}
    >
      <FormProvider {...methods}>
        <ModalBody>
          <h2 className="modal__head">
            {!isEditModal ? 'ADD' : 'EDIT'}
            {' '}
            <span>{modalInfo?.title}</span>
          </h2>
          {isEditModalLoading ? (
            <Loader />
          ) : (
            <>
              {modalInfo?.lab?.labName ? (
                <h3 className="modal__subhead">{modalInfo.lab.labName}</h3>
              ) : (
                ''
              )}
              {modalInfo?.kit?.kitName ? (
                <h3 className="modal__subhead blue">
                  {modalInfo?.kit?.kitName}
                </h3>
              ) : (
                <div className="addkit__search-wrap">
                  <div className="addkit__search-head">
                    {modalInfo?.kit?.title}
                  </div>
                  {modalInfo?.kit?.description ? (
                    <p className="addkit__search-text">
                      {modalInfo?.kit?.description}
                    </p>
                  ) : (
                    ''
                  )}
                  <Form>
                    <FormGroup className="addkit__search">
                      <AsyncSelectField
                        name="product_id"
                        loadOptions={loadKitOptions}
                        defaultOptions={defaultKitOptions}
                        className="selectbox"
                        placeholder="Select Test Kit"
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }}
                      />
                      <Button className="addkit__search-button">
                        <SearchIcon />
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              )}
              {!modalInfo?.lab?.labName && (
                <div className="addkit__select">
                  <div className="addkit__search-head">
                    {modalInfo?.lab?.title}
                  </div>
                  <Form>
                    <FormGroup>
                      <AsyncSelectField
                        name="lab_id"
                        loadOptions={loadLabOptions}
                        defaultOptions={defaultLabOptions}
                        className="selectbox"
                        placeholder="Select Lab"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>
              )}
              <hr className="line" />
              <Form
                className="modal__form"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <Row className="tab__form-sec">
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label for="" className="fw-normal">
                        Status
                      </Label>
                      <SelectField
                        options={lookupDetails?.kitStatusOptions}
                        name="status"
                        className="selectbox selectbox--disable"
                        placeholder="Select status"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label for="" className="fw-normal">
                        Kit Price
                      </Label>
                      <CustomNumberField
                        autoComplete="off"
                        thousandSeparator
                        prefix="$ "
                        className="form-control"
                        name="price"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label for="" className="fw-normal">
                        Phlebotomy Cost
                      </Label>
                      <CustomNumberField
                        autoComplete="off"
                        thousandSeparator
                        prefix="$ "
                        className="form-control"
                        name="phlebotomy_cost"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label for="" className="fw-normal">
                        Kit Biomarkers
                      </Label>
                      <SelectField
                        name="bio_markers"
                        options={lookupDetails?.bioMarkerOptions}
                        isMulti
                        className="selectbox selectbox--disable"
                        classNamePrefix="multiselect"
                        placeholder="Select Biomarkers"
                        isClearable={false}
                        showOptionCheckBoxes
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label for="" className="fw-normal">
                        Turnaround Time
                      </Label>
                      <SelectField
                        name="turnaround_time"
                        options={lookupDetails?.turnAroundTimeOptions}
                        className="selectbox selectbox--disable"
                        placeholder="Select turnaround time"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="line" />
                <div className="modal__btn d-flex align-items-center justify-content-center">
                  <Button
                    color="primary outline"
                    onClick={() => hideModal()}
                    type="button"
                  >
                    {modalInfo?.cancelButtonText}
                  </Button>
                  <ButtonWithLoader
                    isLoading={addEditButtonLoading}
                    color="secondary"
                    type="submit"
                  >
                    {modalInfo?.successButtonText}
                  </ButtonWithLoader>
                </div>
              </Form>
            </>
          )}
        </ModalBody>
      </FormProvider>
    </Modal>
  );
};

export default AddEditTestKit;
