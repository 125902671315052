import axios from 'axios';

const { CancelToken } = axios;
const cancel = {};

// Common Request
export async function request(options) {
  if (options.cancel_token && cancel[options.cancel_token]) {
    cancel[options.cancel_token]();
  }
  try {
    const response = await axios(
      formatRequest(
        options.method,
        options.url,
        options.params,
        options.is_authenticated,
        options.content_type,
        options.cancel_token,
        options.baseURL,
      ),
    );
    return handleResponse(options.route, response, options.is_authenticated);
  } catch (error) {
    // if (axios.isCancel(error)) {
    //   return false;
    // }
    return handleError(options.route, error, options.hideModal);
  }
}

// Formate Request Options
export function formatRequest(
  method = 'GET',
  url = null,
  params = {},
  isAuthenticated = true,
  contentType = 'json',
  cancelToken = null,
  baseURL = null,
) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    credentials: 'include',
    method,
    url: baseURL
      ? `${baseURL}${url}`
      : `${process.env.REACT_APP_API_URL}${url}`,
  };
  if (isAuthenticated) {
    const token = localStorage.getItem('userAccessToken');
    if (token) {
      requestOptions.headers.Authorization = `Bearer ${token}`;
    }
  }

  if (method === 'GET' && params) {
    requestOptions.params = params;
  } else if (contentType === 'json') {
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.data = params;
  } else {
    requestOptions.data = params;
  }
  if (cancelToken) {
    requestOptions.cancelToken = new CancelToken((c) => {
      cancel[cancelToken] = c;
    });
  }

  return requestOptions;
}

// Handle Error  Response
export function handleError(route, error, hideModal) {
  if (error && error.response) {
    if (error.response.status === 401) {
      hideModal && hideModal();
      localStorage.removeItem('userAccessToken');
      window.location.assign('/auth');
    }
  }
  return Promise.reject(error);
}

// Handle Response
export function handleResponse(route, response) {
  return response;
}

export default request;
