import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { isEmpty, pick } from 'lodash';
import * as Yup from 'yup';

import Filter from 'components/shared/filter/filter';
import ListingComponent from 'components/shared/listing-component';
import AddEditConsumerPhlebotomistModal from 'components/shared/modals/add-edit-consumer-phlebotomist';

import AddGreenIcon from 'assets/images/add-green-icon';
import HeaderText from 'header-context';

import {
  getAllConsumers,
  addConsumer,
  updateConsumer,
  getConsumerDetails,
} from 'services/consumers';
import catchHandler from 'helpers/catch-handler';

const Consumers = () => {
  const [, setHeader] = useContext(HeaderText);
  const clearFilterRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [consumerData, setConsumerData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [filter, setFilter] = useState({});
  const [meta, setMeta] = useState({});
  const [showConsumerModal, setConsumerModal] = useState(false);
  const [consumerButtonLoading, setConsumerButtonLoading] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [isEditModalLoading, setEditModalLoading] = useState(false);

  useEffect(() => {
    setHeader('Consumers');
  }, [setHeader]);

  useEffect(() => {
    fetchAllConsumers(filter);
  }, [filter]);

  const fetchAllConsumers = (params) => {
    setIsLoading(true);
    getAllConsumers(params)
      .then((response) => {
        setConsumerData(response.data.data);
        setIsLoading(false);
        setMeta(response.data.meta);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const editConsumerHandler = (item) => {
    setEditItem({});
    setEditModal(true);
    setConsumerModal(true);
    setEditModalLoading(true);
    getConsumerDetails(item?.consumer_id)
      .then((response) => {
        setEditItem(response?.data);
        setEditModalLoading(false);
      })
      .catch(() => {
        setEditModalLoading(false);
      });
  };

  const formSubmitHandler = (data, setError) => {
    const payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone_number,
      password: data?.password,
      password_confirmation: data?.password_confirmation,
      state: data?.state?.value,
      city: data?.city,
      zip: data?.zip,
      street_address: data?.street_address,
    };
    setConsumerButtonLoading(true);
    if (isEmpty(editItem)) {
      addConsumer(payload)
        .then(() => {
          setConsumerButtonLoading(false);
          setConsumerModal(false);
          setFilter({});
          clearFilterRef.current();
        })
        .catch((error) => {
          setConsumerButtonLoading(false);
          catchHandler(error, setError, true);
        });
    } else {
      updateConsumer(payload, editItem?.consumer_id)
        .then(() => {
          setConsumerButtonLoading(false);
          setConsumerModal(false);
          setFilter({});
          clearFilterRef.current();
          setEditModal(false);
          setEditItem({});
        })
        .catch((error) => {
          setConsumerButtonLoading(false);
          setEditModal(false);
          catchHandler(error, setError, true);
        });
    }
  };

  const addButton = (
    <button
      className="button-icon"
      type="button"
      onClick={() => setConsumerModal(true)}
    >
      <AddGreenIcon />
      <span className="button-icon__text">Add Consumer</span>
    </button>
  );

  const mappings = [
    {
      key: 'consumer_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">
          {item?.consumer_id}
        </span>
      ),
    },
    {
      key: 'consumer_name',
      label: 'CONSUMER NAME',
      render: (item) => <b>{`${item?.first_name} ${item?.last_name}`}</b>,
    },
    {
      key: 'email',
      label: 'EMAIL ADDRESS',
    },
    {
      key: 'location',
      label: 'LOCATION',
      render: (item) => (
        <>
          {`${item?.city || ''}
            ${item?.state && item?.city ? ',' : ''}
            ${item?.state || ''}`}
        </>
      ),
    },
    {
      key: 'phone_number',
      label: 'PHONE NUMBER',
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div
          className="inline_link link-action"
          onClick={() => editConsumerHandler(item)}
          role="button"
          tabIndex={-1}
          onKeyDown={() => editConsumerHandler(item)}
        >
          Edit
        </div>
      ),
    },
  ];

  const headerSchema = Yup.object().shape({
    state: Yup.object().required('State is required').nullable(),
    city: Yup.string().required('City is required'),
    zip: Yup.string().required('Zip is required'),
    street_address: Yup.string().required('Street Address is required'),
  });

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <React.Fragment>
        <Filter
          filters={[
            {
              filter_type: 'custom-input',
              name: 'search',
              placeholder: 'Search by Consumer Name, Email',
              type: 'text',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'custom-input',
              name: 'location',
              type: 'text',
              placeholder: 'Search by Location',
              inputProps: {
                className: 'table__searchbox text-box',
              },
            },
            {
              filter_type: 'button',
              name: 'submit',
              inputProps: {
                className: 'table__button',
              },
            },
          ]}
          onFilter={(params) => setFilter((prev) => ({
            ...pick(prev, ['per_page', 'sort']),
            page: 1,
            ...params,
          }))}
          onPerPageChange={(perPage, page) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          addButton={addButton}
          meta={meta}
          clearFilter={clearFilterRef}
        />

        <ListingComponent
          isLoading={isLoading}
          mappings={mappings}
          meta={meta}
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          dataSource={consumerData}
          shouldPaginate
          primaryKey="consumer_id"
        />

        {showConsumerModal ? (
          <AddEditConsumerPhlebotomistModal
            hideModal={() => {
              setConsumerModal(false);
              setEditItem({});
              setEditModal(false);
            }}
            editItem={editItem}
            isEditModal={isEditModal}
            headerSchema={headerSchema}
            title="Consumer"
            onSuccessHandler={(formData, setError) => formSubmitHandler(formData, setError)}
            isLoading={consumerButtonLoading}
            isEditModalLoading={isEditModalLoading}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    </div>
  );
};

export default Consumers;
