import React from 'react';

const PendingDeliveryIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="5" fill="#FFC700" />
    <path
      d="M10.4859 3.84576L14.8496 6.17909C15.0313 6.27568 15.1843 6.42457 15.2912 6.60903C15.3982 6.7935 15.4549 7.00621 15.455 7.22326V12.7824C15.4549 12.9995 15.3982 13.2122 15.2912 13.3967C15.1843 13.5811 15.0313 13.73 14.8496 13.8266L10.4859 16.1599C10.3344 16.241 10.1672 16.2833 9.99774 16.2833C9.82827 16.2833 9.66112 16.241 9.50956 16.1599L5.14592 13.8266C4.96436 13.7288 4.81197 13.5786 4.70595 13.3931C4.59994 13.2075 4.54451 12.994 4.54592 12.7766V7.22326C4.54603 7.00621 4.60275 6.7935 4.70971 6.60903C4.81667 6.42457 4.96963 6.27568 5.15138 6.17909L9.51502 3.84576C9.66587 3.76559 9.83202 3.72388 10.0005 3.72388C10.1689 3.72388 10.3351 3.76559 10.4859 3.84576V3.84576Z"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.71973 6.59326L9.99973 9.4166L15.2797 6.59326"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 16.2767V9.41675"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.27246 5.04175L12.727 7.95841"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PendingDeliveryIcon;
