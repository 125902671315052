import React, {
  createContext, useEffect, useState,
} from 'react';

import { fetchLoggedInUserDetails } from 'services/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState('');

  const getLoggedInUserDetails = () => {
    fetchLoggedInUserDetails().then((res) => {
      setUserDetails(res?.data);
    });
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, []);

  return (
    <AuthContext.Provider
      value={{ userDetails, setUserDetails, getLoggedInUserDetails }}
    >
      {children}
    </AuthContext.Provider>
  );
};
