import React from 'react';

const HeaderBellNotificationIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90011 21.1292C9.04173 21.1292 8.2185 20.8008 7.61153 20.2163C7.00457 19.6318 6.66357 18.8391 6.66357 18.0125C6.66357 17.8059 6.74882 17.6077 6.90056 17.4615C7.05231 17.3154 7.25811 17.2333 7.47271 17.2333H12.3275C12.5421 17.2333 12.7479 17.3154 12.8997 17.4615C13.0514 17.6077 13.1367 17.8059 13.1367 18.0125C13.1367 18.8391 12.7957 19.6318 12.1887 20.2163C11.5817 20.8008 10.7585 21.1292 9.90011 21.1292ZM8.50031 18.7917C8.64256 19.0278 8.84675 19.2238 9.09241 19.3601C9.33808 19.4964 9.61661 19.5681 9.90011 19.5681C10.1836 19.5681 10.4621 19.4964 10.7078 19.3601C10.9535 19.2238 11.1577 19.0278 11.2999 18.7917H8.50031Z"
      fill="#444444"
    />
    <path
      d="M11.5181 4.2167H8.28155C8.06695 4.2167 7.86114 4.13461 7.7094 3.98849C7.55766 3.84237 7.47241 3.64419 7.47241 3.43754C7.47241 2.81759 7.72816 2.22304 8.18338 1.78467C8.63861 1.34631 9.25603 1.10004 9.89982 1.10004C10.5436 1.10004 11.161 1.34631 11.6163 1.78467C12.0715 2.22304 12.3272 2.81759 12.3272 3.43754C12.3272 3.64419 12.242 3.84237 12.0902 3.98849C11.9385 4.13461 11.7327 4.2167 11.5181 4.2167Z"
      fill="#444444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2225 9.85294V13.5322C17.9649 14.0393 18.5695 14.7113 18.985 15.4912C19.4005 16.2711 19.6147 17.1359 19.6094 18.0124C19.6094 18.2191 19.5242 18.4172 19.3724 18.5634C19.2207 18.7095 19.0149 18.7916 18.8003 18.7916H0.999323C0.784727 18.7916 0.578921 18.7095 0.427179 18.5634C0.275437 18.4172 0.190187 18.2191 0.190187 18.0124C0.189479 17.1325 0.409874 16.2654 0.832564 15.4853C1.25525 14.7051 1.86769 14.035 2.61759 13.5322V9.44158C2.61755 7.58982 3.37811 5.81321 4.73331 4.49945C6.08851 3.18568 7.92832 2.44142 9.85126 2.42908H9.94026C10.4056 2.42908 10.8663 2.47197 11.3168 2.55547C11.1443 3.04021 11.0378 3.55629 11.0083 4.09286C10.6459 4.02322 10.2748 3.98741 9.8998 3.98741C8.39763 3.98741 6.95699 4.56204 5.89479 5.5849C4.8326 6.60775 4.23586 7.99504 4.23586 9.44158V13.9607C4.23643 14.098 4.19931 14.233 4.12826 14.3521C4.05722 14.4711 3.95477 14.57 3.83129 14.6386C3.33538 14.9142 2.90585 15.2879 2.57109 15.7352C2.23633 16.1825 2.00397 16.6931 1.88937 17.2332H17.9102C17.7956 16.6931 17.5633 16.1825 17.2285 15.7352C16.8938 15.2879 16.4642 14.9142 15.9683 14.6386C15.8448 14.57 15.7424 14.4711 15.6713 14.3521C15.6003 14.233 15.5632 14.098 15.5637 13.9607V9.82065C15.868 9.87281 16.1807 9.89999 16.4999 9.89999C16.7448 9.89999 16.986 9.88398 17.2225 9.85294Z"
      fill="#444444"
    />
    <circle cx="16.5" cy="5.50003" r="3.3" fill="#C10230" />
  </svg>
);
export default HeaderBellNotificationIcon;
