import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import {
  MyHomeLabLogo,
  DashboardIcon,
  LabManagementIcon,
  KitManagementIcon,
  SlidebarConsumersIcon,
  SlidebarOrdersIcon,
  SlidebarReportsIcon,
  SlidebarPayoutSystemIcon,
  HeaderCloseIcon,
  SlidebarphlebotomistsIcon,
  SlidebarRegisteredKitsIcon,
  ReviewsIcon,
  TestResultsIcon,
} from 'assets/images/svg-icons';

import { getSidebarStatus } from 'services/notification';
import { SIDEBAR_STATUS_REFETCH_INTERVAL } from 'config';

const SlidebarClose = () => {
  const slideNav = document.querySelector('.global-sidebar');
  const gLoverlay = document.querySelector('.global-content__overlay');
  slideNav.classList.remove('global-sidebar--active');
  gLoverlay.classList.remove('global-content__overlay--active');
};

const Sidebar = () => {
  const [pendingRequests, setPendingRequests] = useState({});
  const history = useHistory();
  const location = useLocation();

  const fetchSidebarStatus = useCallback(() => {
    getSidebarStatus().then((response) => {
      setPendingRequests(response?.data);
    });
  }, []);

  useEffect(() => {
    fetchSidebarStatus();
    const interval = setInterval(() => {
      fetchSidebarStatus();
    }, SIDEBAR_STATUS_REFETCH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [fetchSidebarStatus]);

  return (
    <React.Fragment>
      <div
        className="global-content__overlay"
        role="button"
        tabIndex={-1}
        onKeyDown={SlidebarClose}
        onClick={SlidebarClose}
      >
        <HeaderCloseIcon className="global-sidebar__close" />
      </div>
      <div id="sidebarNav" className="global-sidebar">
        <div className="sidebar__logo-wrap">
          <MyHomeLabLogo
            className={`global-sidebar__logo ${
              location?.pathname !== '/dashboard' ? 'cursor-pointer' : ''
            }`}
            onClick={() => history.push('/dashboard')}
          />
          <div className="admin-sidebar__user">Administrator</div>
        </div>
        <ul className="admin-sidebar text-left">
          <li className="sidebar__list">
            <NavLink
              to="/dashboard"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <DashboardIcon />
              </i>
              Dashboard
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/test-kits"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <KitManagementIcon />
              </i>
              Test Kits
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/orders"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <SlidebarOrdersIcon />
              </i>
              Orders
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/test-results"
              className={`sidebar__list--text ${
                pendingRequests?.pending_test_results_count
                  ? 'notification'
                  : ''
              }`}
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <TestResultsIcon />
              </i>
              Test Results
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/registered-kits"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <SlidebarRegisteredKitsIcon />
              </i>
              Registered Kits
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/consumers"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <SlidebarConsumersIcon />
              </i>
              Consumers
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/lab-management"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <LabManagementIcon />
              </i>
              Labs
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/phlebotomists"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <SlidebarphlebotomistsIcon />
              </i>
              Phlebotomists
            </NavLink>
          </li>
          <li className="sidebar__list--divider">
            <hr />
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/kit-management"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <KitManagementIcon />
              </i>
              Kit Management
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/payouts"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <SlidebarPayoutSystemIcon />
              </i>
              Payout System
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/reviews"
              className={`sidebar__list--text ${
                pendingRequests?.new_reviews_count ? 'notification' : ''
              }`}
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <ReviewsIcon />
              </i>
              Reviews
            </NavLink>
          </li>
          <li className="sidebar__list">
            <NavLink
              to="/reports"
              className="sidebar__list--text"
              activeClassName="active"
              onClick={SlidebarClose}
            >
              <i>
                <SlidebarReportsIcon />
              </i>
              Reports
            </NavLink>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
