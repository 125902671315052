import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';

import Filter from 'components/shared/filter/filter';
import ListingComponent from 'components/shared/listing-component';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';

import {
  ApprovedIcon,
  DeactivateIcon,
  AddGreenIcon,
  DotIcon,
} from 'assets/images/svg-icons';
import HeaderText from 'header-context';

import {
  getAllKitDetails,
  deleteKit,
  updateKitDetails,
} from 'services/kit-management';
import { LookupContext } from 'context/lookup-context/lookup-context';
import { filterOptions } from 'helpers/utils';

const KitManagement = () => {
  const lookupData = useContext(LookupContext);
  const [, setHeader] = useContext(HeaderText);

  const [isLoading, setIsLoading] = useState(false);
  const [kitData, setKitData] = useState([]);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [deleteItem, setDeleteItem] = useState({});
  const [updateKitStatus, setUpdateKitStatus] = useState({});
  const [isConfirmationLoading, setConfirmationLoading] = useState(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState('');

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateKitStatus?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateKitStatus?.status_slug === 'active' ? 'Deactivating' : 'Activating'
    }
     ${updateKitStatus?.product_name} ${
  updateKitStatus.product_name?.includes('Kit') ? '' : ' Kit'
}.`,
  };

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Test Kit</span>
      </>
    ),
    confirmationText: `Please confirm deleting ${deleteItem.product_name}
    ${
  deleteItem.product_name?.includes('Kit') ? '' : ' Kit'
} and it's variants. This action cannot be undone.`,
  };

  const fetchAllKitManagementData = (params) => {
    getAllKitDetails(params)
      .then((response) => {
        setKitData(response.data.data);
        setIsLoading(false);
        setMeta(response.data.meta);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const deleteKitHandler = (item) => {
    setDeleteItem(item);
    setConfirmationModalStatus('delete-kit');
  };

  const confirmDeleteKitHandler = () => {
    setConfirmationLoading(true);
    deleteKit(deleteItem?.product_id)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchAllKitManagementData(filter);
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const statusUpdateHandler = (item) => {
    setUpdateKitStatus(item);
    setConfirmationModalStatus('status-update');
  };

  const kitStatusUpdateHandler = () => {
    setConfirmationLoading(true);
    const payload = new FormData();
    payload.append(
      'status',
      updateKitStatus.status_slug === 'active' ? 'deactivated' : 'active',
    );
    payload.append('_method', 'Put');
    updateKitDetails(updateKitStatus.product_id, payload)
      .then(() => {
        setConfirmationModalStatus('');
        setConfirmationLoading(false);
        fetchAllKitManagementData(filter);
        setUpdateKitStatus({});
      })
      .catch(() => {
        setConfirmationLoading(false);
      });
  };

  const getKitStatus = (kitStatus) => {
    switch (kitStatus) {
      case 'active':
        return <ApprovedIcon className="table__status-icon" />;
      case 'deactivated':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAllKitManagementData(filter);
  }, [filter]);

  useEffect(() => {
    setHeader('Kit Management');
  }, [setHeader]);

  const addButton = (
    <Link to="/kit-management/add-kit" className="button-icon">
      <AddGreenIcon />
      <span className="button-icon__text">Add Kits</span>
    </Link>
  );

  const mappings = [
    {
      key: 'product_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list--regular blue">{item.product_id}</span>
      ),
    },
    {
      key: 'product_name',
      label: 'KIT NAME',
      render: (item) => <b>{item.product_name}</b>,
    },
    {
      key: 'category_name',
      label: 'CATEGORY',
      className: 'text-center',
    },
    {
      key: 'product_type',
      label: 'TYPE',
      className: 'text-center',
      render: (item) => (
        <span
          className={`inline__list inline__list--regular ${
            item.product_type === 'Diagnostic' ? 'red' : 'blue'
          }`}
        >
          {item.product_type}
        </span>
      ),
    },
    {
      key: 'product_variant_count',
      label: 'VARIANTS',
      className: 'text-center',
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getKitStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <DropdownItem tag="div">
                  <Link
                    to={`/kit-management/edit-kit/${item.product_id}`}
                    className="inline_link"
                  >
                    Edit
                  </Link>
                </DropdownItem>
              </DropdownItem>
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => statusUpdateHandler(item)}
                >
                  {item?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  onClick={() => {
                    deleteKitHandler(item);
                  }}
                  className="inline__list inline__list--regular red"
                >
                  Delete Test Kit
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <React.Fragment>
        <Filter
          filters={[
            {
              filter_type: 'select',
              name: 'category',
              options: filterOptions(
                lookupData?.lookupDetails?.productCategoriesOptions,
                'All Category',
              ),
              placeholder: 'Category',
              inputProps: {
                className: 'selectbox',
                components: {
                  IndicatorSeparator: () => null,
                },
              },
            },
            {
              filter_type: 'select',
              name: 'kit_type',
              options: filterOptions(
                lookupData?.lookupDetails?.kitTypeOptions,
                'All',
              ),
              placeholder: 'Kit Type',
              inputProps: {
                className: 'selectbox',
                components: {
                  IndicatorSeparator: () => null,
                },
              },
            },
            {
              filter_type: 'select',
              name: 'status',
              options: filterOptions(
                lookupData?.lookupDetails?.statusOptions,
                'All Status',
              ),
              placeholder: 'All Status',
              inputProps: {
                className: 'selectbox',
                components: {
                  IndicatorSeparator: () => null,
                },
              },
            },
            {
              filter_type: 'button',
              name: 'submit',
              inputProps: {
                className: 'table__button',
              },
            },
          ]}
          onFilter={(params) => setFilter((prev) => ({
            ...pick(prev, ['per_page', 'sort']),
            page: 1,
            ...params,
          }))}
          onPerPageChange={(perPage, page) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          addButton={addButton}
          meta={meta}
        />

        <ListingComponent
          isLoading={isLoading}
          mappings={mappings}
          meta={meta}
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          dataSource={kitData}
          shouldPaginate
          primaryKey="product_id"
        />
        {confirmationModalStatus === 'delete-kit' ? (
          <ConfirmationModal
            hideModal={() => setConfirmationModalStatus('')}
            renderHtml={confirmDeleteHtml}
            successButtonText="Delete Kit"
            btnColor="primary"
            isLoading={isConfirmationLoading}
            successHandler={() => confirmDeleteKitHandler()}
            cancelButtonText="Close"
          />
        ) : (
          ''
        )}
        {confirmationModalStatus === 'status-update' ? (
          <ConfirmationModal
            hideModal={() => setConfirmationModalStatus('')}
            renderHtml={confirmDeactivateHtml}
            successButtonText="Confirm"
            successHandler={() => kitStatusUpdateHandler()}
            cancelButtonText="Cancel"
            isLoading={isConfirmationLoading}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    </div>
  );
};

export default KitManagement;
