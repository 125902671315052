import React from 'react';
import Button from 'reactstrap/lib/Button';

const ButtonWithLoader = (props) => {
  const {
    children,
    isLoading,
    disableWhenFormError,
    disabled,
    ...otherProps
  } = props;

  return (
    <Button
      className="d-flex align-items-center justify-content-center"
      {...otherProps}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <span className="spinner-border spinner-border-sm mx-2 d-block" />
      ) : (
        ''
      )}
      {children}
    </Button>
  );
};

export default ButtonWithLoader;
