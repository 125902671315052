import { request } from 'helpers/api';

export const getAllPayoutData = (params) => request({
  method: 'GET',
  url: '/api/payouts',
  params,
});

export const getPayoutDetails = (id) => request({
  method: 'GET',
  url: `/api/payouts/${id}`,
});
