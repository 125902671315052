import React, { useState, useEffect } from 'react';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';

import { generateRandomString } from 'helpers/utils';

const PaginationComponent = (props) => {
  const { meta, onPageChange } = props;

  const [perPage, setPerPage] = useState({});
  const delta = 1;

  useEffect(() => {
    setPerPage({ value: meta.per_page, label: meta.per_page });
  }, [meta?.per_page]);

  const pagination = (currentPage, lastPage) => {
    const range = [];
    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(lastPage - 1, currentPage + delta);
      i += 1
    ) {
      range.push({ value: i, type: 'page' });
    }
    if (currentPage - delta > 2) {
      range.unshift({ value: '...', type: 'separator' });
    }
    if (currentPage + delta < lastPage - 1) {
      range.push({ value: '...', type: 'separator' });
    }
    range.unshift({ value: 1, type: 'page' });
    if (lastPage !== 1) range.push({ value: lastPage, type: 'page' });
    return range;
  };

  return (
    <React.Fragment>
      {meta && meta.total > 0 ? (
        <div className="table__pagination">
          <Pagination aria-label="Page navigation example">
            {(pagination(meta?.current_page, meta?.last_page) || []).map(
              (page) => (
                <PaginationItem key={generateRandomString()}>
                  <PaginationLink
                    onClick={() => {
                      if (page.type === 'page') {
                        onPageChange(page.value, perPage?.value || 1);
                      }
                    }}
                    disabled={page.type === 'separator'}
                    className={`${
                      page.value === meta.current_page ? 'active' : ''
                    }`}
                  >
                    {page.value}
                  </PaginationLink>
                </PaginationItem>
              ),
            )}
          </Pagination>
          <span className="table__pagination-text">
            Showing
            {' '}
            {meta?.from}
            {' '}
            -
            {meta?.to}
            {' '}
            from
            {' '}
            {meta.total}
          </span>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default PaginationComponent;
