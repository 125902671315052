import React, { useContext, useEffect, useState } from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  Label,
} from 'reactstrap';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddEditTestKit from 'components/shared/modals/add-edit-test-kit-modal';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import AddEditConsumerPhlebotomistModal from 'components/shared/modals/add-edit-consumer-phlebotomist';
import ButtonWithLoader from 'components/shared/loading-button';
import AddLabModal from 'components/lab-management/add-lab-modal';

import {
  AddGreenIcon,
  HeaderBellIcon,
  HeaderBellNotificationIcon,
  MyHomeLabIcon,
  MyAccountIcon,
  AddTestKitGreenIcon,
  AddConsumerGreenIcon,
  ProfileUploadIcon,
  AddLabGreenIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { AuthContext } from 'context/auth-context/auth-context';

import { logout } from 'services/auth';
import { addKitVariant } from 'services/test-kit-variants';
import { updateProfilePicture } from 'services/my-account';
import { addConsumer } from 'services/consumers';
import catchHandler from 'helpers/catch-handler';
import { getLatestNotification } from 'services/notification';
import { NOTIFICATION_REFETCH_INTERVAL, REFETCH_LATEST_NOTIFICATION_EVENT_LISTENER } from 'config';

const Header = () => {
  const [headerText] = useContext(HeaderText);
  const { userDetails, getLoggedInUserDetails } = useContext(AuthContext);
  const history = useHistory();

  const [dropdownAdd, setDropdownAdd] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownNotification, setDropdownNotification] = useState(false);
  const [showAddTestKitModal, setShowAddTestKitModal] = useState(false);
  const [consumerModal, setConsumerModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [addButtonLoading, setAddButtonLoading] = useState(false);
  const [addKitSuccessMessage, setAddKitSuccessMessage] = useState({});
  const [confirmationModalStatus, setConfirmationModalStatus] = useState('');
  const [isLogoutButtonLoading, setIsLogoutButtonLoading] = useState(false);
  const [latestNotifications, setLatestNotifications] = useState([]);
  const [showAddLabModal, setShowAddLabModal] = useState(false);

  const confirmSuccessHtml = {
    title: (
      <>
        Add
        {' '}
        <span>Test Kit</span>
      </>
    ),
    confirmationText: addKitSuccessMessage?.message,
  };

  const sideMenuToggle = () => {
    const slideNav = document.querySelector('.global-sidebar');
    const gLoverlay = document.querySelector('.global-content__overlay');
    slideNav.classList.add('global-sidebar--active');
    gLoverlay.classList.add('global-content__overlay--active');
  };

  const confirmSuccessHandler = () => {
    setConfirmationModalStatus('');
    setShowAddTestKitModal(true);
  };

  const addKitVariantHandler = (formData, setError) => {
    const payload = {
      ...(formData?.product_id?.value
        ? { product_id: formData?.product_id?.value }
        : {}),
      ...(formData?.lab_id?.value ? { lab_id: formData?.lab_id?.value } : {}),
      price: formData?.price,
      phlebotomy_cost: formData?.phlebotomy_cost,
      turnaround_time: formData?.turnaround_time?.value,
      bio_markers: (formData?.bio_markers || []).map(
        (kitBioMarker) => kitBioMarker?.value,
      ),
      status: formData?.status?.value,
    };
    setAddButtonLoading(true);
    addKitVariant(payload)
      .then((response) => {
        setShowAddTestKitModal(false);
        setAddKitSuccessMessage(response?.data);
        setConfirmationModalStatus('add-kit-success');
        setAddButtonLoading(false);
      })
      .catch((error) => {
        setAddButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  const addConsumerHandler = (data, setError) => {
    const payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone_number,
      password: data?.password,
      password_confirmation: data?.password_confirmation,
      state: data?.state?.value,
      city: data?.city,
      zip: data?.zip,
      street_address: data?.street_address,
    };
    setAddButtonLoading(true);
    addConsumer(payload)
      .then((res) => {
        toast.success(res?.data?.message || 'Consumer added successfully');
        setAddButtonLoading(false);
        setConsumerModal(false);
      })
      .catch((error) => {
        setAddButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    setIsLogoutButtonLoading(true);
    logout()
      .then(() => {
        setIsLogoutButtonLoading(false);
        localStorage.clear();
        history.push('/');
      })
      .catch(() => {
        setIsLogoutButtonLoading(false);
        localStorage.clear();
        history.push('/');
      });
  };

  const handleImageUpload = (event) => {
    const formData = new FormData();
    formData.append('image', event.target.files[0]);
    updateProfilePicture(formData)
      .then((res) => {
        toast.success(res?.data?.message || 'Profile Picture Updated Successfully');
        getLoggedInUserDetails();
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || 'Something went wrong!');
      });
  };

  useEffect(() => {
    setModalInfo({
      title: 'Test Kit',
      lab: {
        labName: '',
        description: '',
        title: 'Select Laboratory',
      },
      kit: {
        kitName: '',
        description:
          'Please select kit type (diagnostic/non-diagnostic) and kit name from our pre-defined list below.',
        title: 'Select Kit Type and Name',
      },
      cancelButtonText: 'Close',
      successButtonText: 'Add Kit',
    });
  }, []);

  const fetchNotifications = () => {
    getLatestNotification().then((res) => {
      setLatestNotifications(res?.data);
    });
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(() => {
      fetchNotifications();
    }, NOTIFICATION_REFETCH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const reloadNotificationEvent = new Event(REFETCH_LATEST_NOTIFICATION_EVENT_LISTENER);
    document.addEventListener(REFETCH_LATEST_NOTIFICATION_EVENT_LISTENER, () => {
      fetchNotifications();
    });
    return () => {
      document.removeEventListener(reloadNotificationEvent, null);
    };
  }, []);

  const getUrlLocation = (url, uniqueId) => {
    switch (url) {
      case 'phlebotomist':
        return '/phlebotomist';
      case 'phlebotomist_schedule_request':
        return '/registered-kits';
      case 'consumer':
        return '/consumers';
      case 'test_kit':
        return '/test-kits';
      case 'lab':
        return `/lab-management/${uniqueId}`;
      case 'product':
        return `/kit-management/${uniqueId}`;
      case 'purchase_order_item':
        return `/orders/${uniqueId}`;
      case 'purchase_order':
        return '/orders';
      case 'payouts':
        return `/payouts/${uniqueId}`;
      case 'test_report':
        return '/test-results';
      default:
        return '/dashboard';
    }
  };

  const headerSchema = Yup.object().shape({
    product_id: Yup.object().required('Test Kit is required'),
    lab_id: Yup.object().required('Lab name is required'),
  });

  const consumerHeaderSchema = Yup.object().shape({
    state: Yup.object().required('State is required').nullable(),
    city: Yup.string().required('City is required'),
    zip: Yup.string().required('Zip is required'),
    street_address: Yup.string().required('Street Address is required'),
  });

  return (
    <React.Fragment>
      <div className="global-header">
        <Navbar expand="lg">
          <NavbarBrand href="#">{headerText}</NavbarBrand>
          <div className="navbar-brand--icon">
            <MyHomeLabIcon />
          </div>
          <div className="d-flex ms-sm-auto">
            <Dropdown
              isOpen={dropdownAdd}
              toggle={() => setDropdownAdd((prevState) => !prevState)}
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownAdd}
                className="button-icon"
              >
                <AddGreenIcon />
                <span className="button-icon__text">Add</span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu--add">
                <DropdownItem tag="div">
                  <Button
                    onClick={() => setShowAddTestKitModal(true)}
                    className="button-icon"
                  >
                    <AddTestKitGreenIcon />
                    <span className="button-icon__text">Add Kit Variant</span>
                  </Button>
                  <Button
                    className="button-icon"
                    onClick={() => setConsumerModal(true)}
                  >
                    <AddConsumerGreenIcon />
                    <span className="button-icon__text">Add Consumer</span>
                  </Button>
                  <Button
                    className="button-icon"
                    onClick={() => setShowAddLabModal(true)}
                  >
                    <AddLabGreenIcon />
                    <span className="button-icon__text">Add Lab</span>
                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropdownNotification}
              toggle={() => setDropdownNotification((prevState) => !prevState)}
              className="dropdown-notification"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownNotification}
                className="button-icon button-icon--blue button-icon--icononly"
                activenotification="button-icon--notification"
              >
                {(latestNotifications ?? [])
                  .map((notification) => notification?.read_at)
                  .includes('') ? (
                    <HeaderBellNotificationIcon className="button-icon__bellred" />
                  ) : (
                    <HeaderBellIcon className="button-icon__bell" />
                  )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Latest Notifications</DropdownItem>
                <div className="dropdown-notification-wrap">
                  {latestNotifications?.map((item) => (
                    <DropdownItem
                      key={item?.notification_id}
                      onClick={() => history.push(
                        getUrlLocation(
                          item?.data?.entity,
                          item?.data?.entity_id,
                        ),
                      )}
                    >
                      <div className="dropdown-item__time">
                        {item?.created_at}
                      </div>
                      <div className="dropdown-item__text">
                        {item?.notification}
                        {' '}
                        <span className="inline_link">
                          {item?.data?.link_text}
                        </span>
                      </div>
                    </DropdownItem>
                  ))}
                </div>
                <DropdownItem className="dropdown-button ">
                  <Link to="/notifications" className="inline_link ">
                    View All Notification
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen((prevState) => !prevState)}
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                className="global-header__profile"
              >
                <div className="global-header__profile-img">
                  <img
                    src={
                      userDetails?.profile_image
                        ? userDetails?.profile_image
                        : '/img/header_profile_icon.png'
                    }
                    alt=""
                  />
                </div>
                <span className="global-header__profile-txt">
                  {userDetails && userDetails.first_name}
                  {' '}
                  {userDetails && userDetails.last_name}
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu--profile">
                <div className="dropdown-profile">
                  <div className="profile-img">
                    <img
                      src={
                        userDetails?.profile_image
                          ? userDetails?.profile_image
                          : 'img/header_profile_icon.png'
                      }
                      alt=""
                    />
                    <div className="profile-img__upload">
                      <Input
                        type="file"
                        name="profileImage"
                        id="file"
                        className="profile-img__file"
                        onChange={handleImageUpload}
                      />
                      <Label for="file">
                        <ProfileUploadIcon />
                      </Label>
                    </div>
                  </div>
                  <div className="global-header__profile-txt">
                    {userDetails ? userDetails.first_name : ''}
                    {' '}
                    {userDetails ? userDetails.last_name : ''}
                    <div className="dropdown-profile__email">
                      {userDetails ? userDetails.email : ''}
                    </div>
                  </div>
                </div>
                <DropdownItem tag="div" className="dropdown-profile__btn">
                  <Link
                    to="/my-account"
                    className="button-icon button-icon--blue"
                  >
                    <MyAccountIcon />
                    <span className="button-icon__text">My Account</span>
                  </Link>
                  <ButtonWithLoader
                    isLoading={isLogoutButtonLoading}
                    color="primary outline danger"
                    onClick={(e) => handleLogout(e)}
                  >
                    Logout
                  </ButtonWithLoader>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <NavbarToggler onClick={sideMenuToggle} />
          </div>
        </Navbar>
        <h1 className="navbar-brand navbar-brand--mtext">{headerText}</h1>
      </div>
      {showAddTestKitModal ? (
        <AddEditTestKit
          hideModal={() => {
            setShowAddTestKitModal(false);
          }}
          modalInfo={modalInfo}
          onSuccessHandler={(formData, setError) => addKitVariantHandler(formData, setError)}
          headerSchema={headerSchema}
          addEditButtonLoading={addButtonLoading}
          isKitSelect
          isLabSelect
        />
      ) : (
        ''
      )}
      {confirmationModalStatus === 'add-kit-success' ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModalStatus('')}
          renderHtml={confirmSuccessHtml}
          successButtonText="Add Another Test Kit"
          successHandler={() => confirmSuccessHandler()}
          cancelButtonText="Done"
        />
      ) : (
        ''
      )}

      {consumerModal ? (
        <AddEditConsumerPhlebotomistModal
          hideModal={() => setConsumerModal(false)}
          headerSchema={consumerHeaderSchema}
          title="Consumer"
          onSuccessHandler={(formData, setError) => addConsumerHandler(formData, setError)}
          isLoading={addButtonLoading}
        />
      ) : (
        ''
      )}
      {showAddLabModal ? (
        <AddLabModal hideModal={() => setShowAddLabModal(false)} />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default Header;
