import React from 'react';

const LabBlueIcon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path
      d="M15.0695 10.1775C15.2256 10.058 15.4237 9.99843 15.62 10.0003C18.2483 9.99991 20.8766 9.99991 23.5045 10.0003C23.8264 9.99288 24.1588 10.1693 24.2885 10.4705C24.4104 10.7565 24.4004 11.1057 24.235 11.3728C24.048 11.6732 23.6658 11.7787 23.329 11.7446C23.3272 13.6377 23.3286 15.5307 23.3283 17.4234C23.3193 17.5045 23.3725 17.5699 23.4075 17.638C24.1788 19.059 24.9498 20.4804 25.7209 21.9014C26.4409 23.1659 27.145 24.4393 27.8699 25.7008C27.9792 25.9124 28.0558 26.1407 28.1004 26.3745C28.1595 26.6424 28.1361 26.918 28.125 27.1895C28.1116 27.8473 27.8822 28.5051 27.4461 29.0045C27.2495 29.2295 27.0212 29.4292 26.7625 29.5813C26.5941 29.6778 26.4372 29.7977 26.2528 29.865C25.9699 29.9731 25.664 30.0034 25.3629 29.9997C21.5208 29.9997 17.6784 29.9997 13.8364 29.9997C13.6419 29.9986 13.4468 29.993 13.255 29.9579C12.5141 29.8436 11.8226 29.4074 11.4271 28.7696C11.101 28.272 10.9821 27.6638 11.0021 27.0767C11.0207 26.7748 11.0806 26.4729 11.2014 26.1943C12.7304 23.2687 14.2631 20.3442 15.7895 17.4175C15.807 16.4701 15.7922 15.5208 15.797 14.5726C15.7966 13.6299 15.7981 12.6873 15.7962 11.7446C15.4847 11.7735 15.1416 11.6925 14.9375 11.4405C14.6576 11.0724 14.6921 10.4686 15.0695 10.1775ZM17.5528 11.7457C17.5509 13.7368 17.5528 15.7279 17.5516 17.719C17.5476 17.8237 17.5557 17.9358 17.5048 18.0316C17.1699 18.6217 16.8453 19.2177 16.5178 19.8122C16.8498 19.8037 17.1821 19.7908 17.5137 19.8126C17.9888 19.8722 18.4606 19.9772 18.9085 20.1474C19.6751 20.4245 20.3888 20.8289 21.1665 21.0764C21.8234 21.2828 22.5524 21.2525 23.184 20.9746C23.1896 20.9643 23.2004 20.9428 23.206 20.9321C23.0941 20.6731 22.9309 20.4386 22.8067 20.1852C22.429 19.5 22.0558 18.8122 21.677 18.1275C21.6141 18.0128 21.5714 17.8851 21.5744 17.7531C21.5721 15.7505 21.5751 13.7483 21.5729 11.7457C20.2327 11.7428 18.8929 11.7428 17.5528 11.7457Z"
      fill="#0047BA"
    />
  </svg>
);
export default LabBlueIcon;
