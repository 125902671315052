import { request } from 'helpers/api';

export const getAllLabDetails = (params) => request({
  method: 'GET',
  url: '/api/labs',
  params,
});
export const updateLabStatus = (labId, params) => request({
  method: 'PUT',
  url: `/api/labs/${labId}/status`,
  params,
});

export const getSelectedLabDetails = (labId) => request({
  method: 'GET',
  url: `/api/labs/${labId}`,
});

export const getLabAccountInfo = (labId) => request({
  method: 'GET',
  url: `/api/labs/${labId}/account-info`,
});

export const updateLabAccountInfo = (labUserId, params) => request({
  method: 'PUT',
  url: `/api/labs/${labUserId}/account-info`,
  params,
});

export const getLabGeneralInfo = (labId) => request({
  method: 'GET',
  url: `/api/labs/${labId}/general-info`,
});

export const updateLabGeneralInfo = (labId, params) => request({
  method: 'PUT',
  url: `/api/labs/${labId}/general-info`,
  params,
});

export const getLabPayoutInfo = (labId) => request({
  method: 'GET',
  url: `/api/labs/${labId}/payout-info`,
});

export const updateLabPayoutInfo = (labId, params) => request({
  method: 'PUT',
  url: `/api/labs/${labId}/payout-info`,
  params,
});

export const getLabTestKits = (labId, params) => request({
  method: 'GET',
  url: `/api/labs/${labId}/product-variants`,
  params,
});

export const deleteSelectedLab = (labId) => request({
  method: 'DELETE',
  url: `/api/labs/${labId}`,
  params: {},
});

// lab managers

export const getLabManagers = (labId) => request({
  method: 'GET',
  url: `/api/labs/${labId}/users`,
});

export const getLabMangerDetails = (labId, userId) => request({
  method: 'GET',
  url: `/api/labs/${labId}/users/${userId}`,
});

export const addLabManager = (labId, params) => request({
  method: 'POST',
  url: `/api/labs/${labId}/users`,
  params,
});

export const updateLabManager = (labId, userId, params) => request({
  method: 'PUT',
  url: `/api/labs/${labId}/users/${userId}`,
  params,
});

export const deleteManager = (labId, userId, params) => request({
  method: 'DELETE',
  url: `/api/labs/${labId}/users/${userId}`,
  params,
});

// lab Phlebotomist

export const getLabPhlebotomist = (labId) => request({
  method: 'GET',
  url: `/api/labs/${labId}/phlebotomists`,
});

export const deleteLabPhlebotomist = (labId, phlebotomistId) => request({
  method: 'DELETE',
  url: `/api/labs/${labId}/phlebotomists/${phlebotomistId}`,
});

export const addNewLab = (params) => request({
  method: 'POST',
  url: '/api/labs',
  params,
});
