import React from 'react';

const AddLabGreenIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.06947 0.177486C4.2256 0.0579906 4.42375 -0.00157231 4.62003 0.000277467C7.2483 -9.24889e-05 9.87658 -9.24889e-05 12.5045 0.000277467C12.8264 -0.00712164 13.1588 0.169347 13.2885 0.470491C13.4104 0.756467 13.4004 1.1057 13.235 1.37281C13.048 1.67322 12.6658 1.77865 12.329 1.74462C12.3272 3.63768 12.3286 5.53074 12.3283 7.42344C12.3193 7.50446 12.3725 7.56994 12.4075 7.63801C13.1788 9.05901 13.9498 10.4804 14.7209 11.9014C15.4409 13.1659 16.145 14.4393 16.8699 15.7008C16.9792 15.9124 17.0558 16.1407 17.1004 16.3745C17.1595 16.6424 17.1361 16.918 17.125 17.1895C17.1116 17.8473 16.8822 18.5051 16.4461 19.0045C16.2495 19.2295 16.0212 19.4292 15.7625 19.5813C15.5941 19.6778 15.4372 19.7977 15.2528 19.865C14.9699 19.9731 14.664 20.0034 14.3629 19.9997C10.5208 19.9997 6.67841 19.9997 2.83637 19.9997C2.64195 19.9986 2.44678 19.993 2.25496 19.9579C1.51406 19.8436 0.822602 19.4074 0.427059 18.7696C0.101034 18.272 -0.0179256 17.6638 0.0021489 17.0767C0.0207364 16.7748 0.0805882 16.4729 0.201407 16.1943C1.73042 13.2687 3.26314 10.3442 4.78955 7.41752C4.80702 6.47006 4.79215 5.52075 4.79698 4.57256C4.79661 3.62991 4.7981 2.68726 4.79624 1.74462C4.48471 1.77347 4.14159 1.69245 3.9375 1.44051C3.65757 1.07241 3.69214 0.468641 4.06947 0.177486ZM6.55276 1.74573C6.5509 3.73683 6.55276 5.72793 6.55164 7.71903C6.54755 7.82373 6.55573 7.93582 6.5048 8.03164C6.16986 8.62172 5.84532 9.21772 5.51781 9.81224C5.84978 9.80373 6.18212 9.79078 6.51373 9.81261C6.98882 9.87217 7.46057 9.97724 7.90853 10.1474C8.67508 10.4245 9.38884 10.8289 10.1665 11.0764C10.8234 11.2828 11.5524 11.2525 12.184 10.9746C12.1896 10.9643 12.2004 10.9428 12.206 10.9321C12.0941 10.6731 11.9309 10.4386 11.8067 10.1852C11.429 9.5 11.0558 8.81225 10.677 8.12746C10.6141 8.01278 10.5714 7.88514 10.5744 7.75307C10.5721 5.7505 10.5751 3.7483 10.5729 1.74573C9.23271 1.74277 7.89292 1.74277 6.55276 1.74573Z"
      fill="#19D3C5"
    />
  </svg>
);
export default AddLabGreenIcon;
