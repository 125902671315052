import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalBody,
  Form,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';

import CustomNumberField from 'components/shared/form-fields/custom-number-field';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import SelectField from 'components/shared/form-fields/select-field';
import ButtonWithLoader from 'components/shared/loading-button';

import { ShowPasswordIcon, HidePasswordIcon } from 'assets/images/svg-icons';

import { LookupContext } from 'context/lookup-context/lookup-context';
import { addNewLab } from 'services/lab-management';
import { toast } from 'react-toastify';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name  is required'),
  email: Yup.string().email('Not a valid email').required('Email is required'),
  phone_number: Yup.string().required('Phone number is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Special Case Character',
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
  lab_name: Yup.string().required('Lab Name is required'),
  lab_license_number: Yup.string().required('Lab License is required'),
  lab_service_radius: Yup.string().required('Lab Service Radius is required'),
  street_address: Yup.string().required('Street Address is required'),
  state: Yup.object().required('State is required').nullable(),
  city: Yup.string().required('City is required'),
  zip: Yup.string().required('Zip is required'),
});

const AddLabModal = (props) => {
  const { hideModal, reloadPage } = props;
  const { lookupDetails } = useContext(LookupContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      state: formData.state.value,
    };
    setIsSubmitButtonLoading(true);
    addNewLab(payload)
      .then((res) => {
        setIsSubmitButtonLoading(false);
        hideModal();
        if (reloadPage) {
          reloadPage();
        }
        toast.success(res?.data?.message || 'Successfully added new lab');
      })
      .catch((error) => {
        setIsSubmitButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  return (
    <Modal isOpen toggle={() => hideModal()} centered className="phlebotomist">
      <ModalBody>
        <div className="modal__head">
          Add
          {' '}
          <span>Lab</span>
        </div>
        <FormProvider {...methods}>
          <Form
            className="modal__form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Row className="tab__form-sec">
              <h2 className="modal__sub-head">
                Account
                {' '}
                <span>Info</span>
              </h2>

              <Col xs="12" md="4">
                <FormGroup>
                  <Label>First Name</Label>
                  <CustomInputField
                    type="text"
                    name="first_name"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>Last Name</Label>
                  <CustomInputField
                    type="text"
                    className="form-control"
                    name="last_name"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>Email Address</Label>
                  <CustomInputField
                    type="email"
                    name="email"
                    className="form-control--regular form-control"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <CustomNumberField
                    format="(###) ###-####"
                    className="form-control"
                    name="phone_number"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>Password</Label>
                  <div
                    className={`view-password ${
                      !isEmpty(errors?.password) ? 'view-password--error' : ''
                    }`}
                  >
                    <CustomInputField
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      name="password"
                    />
                    <span
                      role="presentation"
                      onKeyDown={() => setShowPassword(!showPassword)}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <HidePasswordIcon />
                      ) : (
                        <ShowPasswordIcon />
                      )}
                    </span>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <div
                    className={`view-password ${
                      !isEmpty(errors?.password_confirmation)
                        ? 'view-password--error'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      autoComplete="new-password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      name="password_confirmation"
                    />
                    <span
                      role="presentation"
                      onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? (
                        <HidePasswordIcon />
                      ) : (
                        <ShowPasswordIcon />
                      )}
                    </span>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="tab__form-sec">
              <h2 className="modal__sub-head">
                General
                {' '}
                <span>Info</span>
              </h2>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label>Lab Name</Label>
                  <CustomInputField
                    type="text"
                    className="form-control"
                    name="lab_name"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label>Lab License Number (CLIA / CAP)</Label>
                  <CustomInputField
                    type="text"
                    className="form-control"
                    name="lab_license_number"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label>Street Address</Label>
                  <CustomInputField
                    type="text"
                    className="form-control"
                    name="street_address"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup className="form-group">
                  <Label>Lab Service Radius</Label>
                  <CustomNumberField
                    className="form-control"
                    name="lab_service_radius"
                    suffix=" Miles"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>State</Label>
                  <SelectField
                    name="state"
                    options={lookupDetails?.locationOptions}
                    className="selectbox"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>City</Label>
                  <CustomInputField
                    type="text"
                    className="form-control"
                    name="city"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup>
                  <Label>Zip</Label>
                  <CustomInputField
                    type="text"
                    className="form-control"
                    name="zip"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="modal__btn d-flex align-items-center justify-content-center">
              <Button color="primary outline" onClick={() => hideModal()}>
                Close
              </Button>

              <ButtonWithLoader
                color="secondary"
                isLoading={isSubmitButtonLoading}
              >
                Add Lab
              </ButtonWithLoader>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AddLabModal;
