import React from 'react';

const LocationIcon = (props) => (
  <svg
    {...props}
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="50" height="50" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path
      d="M23.8908 10.786C25.0803 10.6907 26.2887 10.8006 27.4387 11.1449C29.7389 11.8085 31.7975 13.3706 33.1393 15.4674C34.1699 17.0636 34.7893 18.9597 34.8991 20.8937C34.9159 21.2893 34.947 21.687 34.9069 22.0823C34.8481 23.5259 34.4973 24.939 34.0197 26.2855C33.1278 28.7409 31.8012 30.989 30.3251 33.0908C29.0846 34.8406 27.7251 36.4931 26.2812 38.0556C25.8645 38.4803 25.2939 38.7323 24.7152 38.7263C24.1216 38.7356 23.5292 38.4794 23.1106 38.0307C22.7434 37.638 22.3851 37.2358 22.0309 36.8295C20.4232 34.9734 18.9262 33.0017 17.6357 30.8808C16.9202 29.6951 16.2678 28.4634 15.7357 27.1719C15.2223 25.9273 14.8199 24.6238 14.6174 23.2792C14.3509 21.4751 14.5083 19.5974 15.1029 17.882C15.7158 16.0796 16.7952 14.4618 18.1836 13.2424C19.787 11.8224 21.8105 10.9493 23.8908 10.786ZM24.2341 16.7861C23.0693 16.9066 21.9597 17.5281 21.21 18.4856C20.6005 19.2498 20.2264 20.2229 20.1598 21.2231C20.149 21.4979 20.1493 21.7737 20.1648 22.0485C20.2139 22.5005 20.3029 22.9507 20.4664 23.3722C20.8726 24.4622 21.6668 25.381 22.6535 25.9018C23.2238 26.21 23.8594 26.3772 24.4972 26.408C25.599 26.459 26.7132 26.0657 27.5634 25.3164C28.5342 24.4741 29.1593 23.1878 29.2118 21.8525C29.2165 21.5439 29.2258 21.2327 29.1779 20.9274C29.047 19.8606 28.5588 18.8501 27.8261 18.1105C26.8889 17.1354 25.5365 16.6427 24.2341 16.7861Z"
      fill="#0047BA"
    />
  </svg>
);
export default LocationIcon;
