import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import NumberFormat from 'react-number-format';
import { get } from 'lodash';

const CustomNumberField = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    name: propsName,
    placeholder = '',
    type = 'text',
    defaultValue,
  } = props;
  const { message: errorMessage = '' } = get(errors, propsName, {});
  return (
    <FormGroup>
      <Controller
        control={control}
        name={propsName}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange, onBlur, value, name,
          },
        }) => (
          <NumberFormat
            customInput={Input}
            invalid={!!errorMessage}
            name={name}
            onBlur={onBlur}
            onValueChange={({ value: phoneNumber }) => {
              onChange(phoneNumber);
            }}
            placeholder={placeholder}
            type={type}
            value={value}
            {...props}
          />
        )}
      />
      {errorMessage && (
        <FormFeedback className="ml-2">{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default CustomNumberField;
