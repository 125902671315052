import React from 'react';

const OrderIcon = (props) => (
  <svg
    {...props}
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="55" height="55" rx="10" fill="#78818F" />
    <path
      d="M27.636 14.0533C27.9562 13.9541 28.3113 13.997 28.6081 14.1519C32.1696 15.7062 35.7325 17.2577 39.2911 18.8192C39.5266 18.8895 39.5429 19.2595 39.3155 19.3505C37.9935 19.9611 36.6687 20.566 35.3438 21.1714C35.183 21.2537 34.9834 21.2696 34.8226 21.1791C31.2616 19.5502 27.7025 17.9169 24.1425 16.2851C24.0458 16.2404 23.9424 16.2057 23.8567 16.1408C23.7074 16.0185 23.7423 15.7443 23.9257 15.6735C24.5431 15.3862 25.1754 15.1321 25.7947 14.8487C26.4126 14.5932 27.0152 14.3025 27.636 14.0533Z"
      fill="#C10230"
    />
    <path
      d="M20.9901 16.9511C21.1557 16.8727 21.3563 16.8664 21.5233 16.9439C24.9972 18.5396 28.473 20.13 31.9484 21.7233C32.1302 21.8128 32.3202 21.886 32.4983 21.9846C32.6314 22.052 32.6629 22.2286 32.6041 22.3571C32.5399 22.4677 32.4121 22.5082 32.304 22.5601C31.1433 23.0895 29.985 23.6236 28.8224 24.1491C28.6377 24.2473 28.4165 24.2362 28.2323 24.1438C24.4051 22.5419 20.5785 20.9375 16.7519 19.3336C16.5221 19.2571 16.5154 18.8865 16.749 18.8096C18.1581 18.1796 19.581 17.5805 20.9901 16.9511Z"
      fill="#C10230"
    />
    <path
      d="M40.6152 20.6305C40.8018 20.59 40.9631 20.7868 40.9397 20.9683C40.9397 25.1399 40.9406 29.3121 40.9392 33.4837C40.9521 33.9346 40.6783 34.3749 40.2653 34.553C36.8981 36.0977 33.5271 37.6332 30.1585 39.1741C29.9996 39.2362 29.8498 39.3444 29.6742 39.3459C29.5286 39.3545 29.3946 39.2155 29.4104 39.0697C29.4076 34.7041 29.4109 30.338 29.4109 25.9724C29.3918 25.8377 29.4674 25.7054 29.5957 25.6587C32.6024 24.281 35.6116 22.909 38.6183 21.5318C39.286 21.2363 39.9417 20.911 40.6152 20.6305Z"
      fill="#C10230"
    />
    <path
      d="M15.2296 20.6242C15.2995 20.5915 15.3847 20.5968 15.4565 20.6208C19.4349 22.2901 23.4147 23.9556 27.3935 25.6245C27.5807 25.6721 27.742 25.817 27.7281 26.0253C27.7286 30.3717 27.7276 34.7185 27.7286 39.0648C27.7219 39.1822 27.6745 39.3107 27.5682 39.3737C27.4462 39.4113 27.315 39.3891 27.2016 39.3338C23.5473 37.8007 19.892 36.2704 16.2376 34.7377C15.9485 34.5962 15.6092 34.5279 15.3847 34.2801C15.1612 34.0621 15.054 33.7464 15.0602 33.4365C15.0593 29.2803 15.0612 25.124 15.0593 20.9678C15.0497 20.8321 15.0995 20.6843 15.2296 20.6242Z"
      fill="#C10230"
    />
  </svg>
);
export default OrderIcon;
