import React, { useState, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import portalRoutes from 'routes/portal';
import HeaderText from 'header-context';
import { AuthProvider } from 'context/auth-context/auth-context';
import { LookupProvider } from 'context/lookup-context/lookup-context';

import Loader from 'components/shared/loader';
import Header from './header';
import Sidebar from './sidebar';

const renderComponents = (
  <Switch>
    {portalRoutes.map((prop) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={prop.key} />;
      }
      return (
        <Route
          path={prop.path}
          exact={prop.exact}
          component={prop.component}
          key={prop.key}
        />
      );
    })}
  </Switch>
);

const Portal = () => {
  const history = useHistory();
  const [context, setHeader] = useState('Dashboard');
  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('userAccessToken');
    if (token) {
      setIsAuthorizedUser(true);
    } else {
      history.push('/auth/login');
    }
  }, [history]);

  return (
    <div className="base-container">
      {isAuthorizedUser ? (
        <AuthProvider>
          <LookupProvider>
            <div className="global-content">
              <Sidebar />
              <div className="global-datawrap">
                <HeaderText.Provider value={[context, setHeader]}>
                  <Header />
                  <div className="global-datawrap__inner">
                    {renderComponents}
                  </div>
                </HeaderText.Provider>
              </div>
            </div>
          </LookupProvider>
        </AuthProvider>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default Portal;
