import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { debounce, pick } from 'lodash';
import moment from 'moment';

import ListingComponent from 'components/shared/listing-component';
import Filter from 'components/shared/filter/filter';

import {
  ApprovedIcon,
  DeactivateIcon,
  PendingDeliveryIcon,
  OrderSubmittedIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import { LookupContext } from 'context/lookup-context/lookup-context';
import { getAllOrders } from 'services/orders';
import { filterOptions } from 'helpers/utils';
import { getAllConsumers } from 'services/consumers';

const Orders = () => {
  const [, setHeader] = useContext(HeaderText);
  const lookupData = useContext(LookupContext);

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [defaultOptions, setDefaultOptions] = useState([]);

  const fetchAllOrders = (params) => {
    setIsLoading(true);
    getAllOrders(params)
      .then((response) => {
        setOrders(response?.data?.data);
        setMeta(response?.data?.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const getOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case 'order-placed':
        return <OrderSubmittedIcon className="table__status-icon" />;
      case 'delivered':
        return <ApprovedIcon className="table__status-icon" />;
      case 'cancelled':
        return <DeactivateIcon className="table__status-icon" />;
      case 'pending-delivery':
        return <PendingDeliveryIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setHeader('Orders');
  }, [setHeader]);

  useEffect(() => {
    fetchAllOrders(filter);
  }, [filter]);

  const mappings = [
    {
      key: 'order_id',
      label: 'ID',
      render: (item) => (
        <Link
          className="inline_link"
          to={`/orders/${item?.purchase_order_item_id}`}
        >
          {item?.purchase_order_item_id}
        </Link>
      ),
    },
    {
      key: 'test_kit_and_lab_name',
      label: 'TEST KIT',
      render: (item) => (
        <div className="table__main-content">
          <div className="table__main-text">{item?.product_name}</div>
          <div className="table__main-title">{item?.lab_name}</div>
        </div>
      ),
    },
    {
      key: 'ordered_by',
      label: 'ORDERED BY',
      render: (item) => item?.ordered_by,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getOrderStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'submit_date',
      label: 'SUBMIT DATE',
      render: (item) => moment(item?.submit_date).format('MMMM DD, YYYY'),
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <Link
          className="inline_link link-action"
          to={`/orders/${item?.purchase_order_item_id}`}
        >
          View
        </Link>
      ),
    },
  ];

  const fetchAllConsumers = async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllConsumers(params);
      const consumers = (res?.data?.data || []).map((data) => ({
        value: data.consumer_id,
        label: data.first_name,
      }));
      setDefaultOptions(consumers);
      if (callback) {
        return callback(consumers);
      }
      return filterOptions(consumers, 'All');
    } catch (e) {
      return [];
    }
  };

  const loadOptionsHandler = (searchKeyWord, callback) => {
    const consumers = fetchAllConsumers(searchKeyWord, callback);
    return consumers;
  };

  const loadOptions = debounce(loadOptionsHandler, 400);

  useEffect(() => {
    fetchAllConsumers();
  }, []);

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <Filter
        filters={[
          {
            filter_type: 'custom-input',
            name: 'search',
            placeholder: 'Search by Order ID, Test Kit, Lab',
            type: 'text',
            inputProps: {
              className: 'table__searchbox text-box',
            },
          },
          {
            filter_type: 'async-select',
            name: 'ordered_by',
            loadOptions,
            defaultOptions: filterOptions(defaultOptions, 'All'),
            placeholder: 'Ordered by',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'select',
            name: 'status',
            options: filterOptions(
              lookupData?.lookupDetails?.orderStatusOptions,
              'All Status',
            ),
            placeholder: 'All Status',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
              isSearchable: false,
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        meta={meta}
      />
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={orders}
        shouldPaginate
        primaryKey="purchase_order_item_id"
      />
    </div>
  );
};

export default Orders;
