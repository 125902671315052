import React from 'react';

const SlidebarOrdersIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9.71858 0.0411552C9.96599 -0.0354409 10.2404 -0.00234844 10.4697 0.117379C13.2216 1.31838 15.9746 2.51714 18.7243 3.72372C18.9062 3.778 18.9188 4.06394 18.7431 4.13421C17.7217 4.60606 16.698 5.07344 15.6743 5.5412C15.55 5.60478 15.3958 5.61705 15.2715 5.54715C12.52 4.28852 9.76998 3.02654 7.0192 1.76568C6.9445 1.7311 6.86461 1.70433 6.79841 1.65413C6.68303 1.55969 6.71002 1.34775 6.85167 1.29309C7.32875 1.07111 7.81729 0.874788 8.29585 0.655783C8.77329 0.458344 9.23891 0.233761 9.71858 0.0411552Z"
        fill="#BBBFC8"
      />
      <path
        d="M4.58354 2.28029C4.7115 2.21968 4.86646 2.21485 4.99553 2.27471C7.67974 3.50769 10.3654 4.73657 13.0508 5.96768C13.1913 6.03684 13.3381 6.09336 13.4757 6.16958C13.5785 6.22164 13.6029 6.3581 13.5574 6.45738C13.5079 6.5429 13.4091 6.57413 13.3255 6.61429C12.4287 7.0233 11.5337 7.43602 10.6354 7.84206C10.4927 7.91791 10.3218 7.90936 10.1794 7.83797C7.22226 6.60016 4.26549 5.36049 1.30871 4.1212C1.13119 4.06208 1.12602 3.77577 1.30649 3.71628C2.39527 3.22956 3.49477 2.76664 4.58354 2.28029Z"
        fill="#BBBFC8"
      />
      <path
        d="M19.7475 5.12326C19.8917 5.09203 20.0163 5.2441 19.9982 5.38428C19.9982 8.60764 19.999 11.8314 19.9979 15.0547C20.0078 15.4031 19.7963 15.7434 19.4771 15.8809C16.8754 17.0745 14.2707 18.261 11.6678 19.4516C11.5451 19.4995 11.4293 19.5832 11.2936 19.5843C11.1811 19.591 11.0776 19.4836 11.0898 19.3709C11.0876 15.9977 11.0902 12.6241 11.0902 9.2509C11.0754 9.14679 11.1338 9.04454 11.2329 9.00847C13.5562 7.94393 15.8813 6.88386 18.2046 5.81969C18.7205 5.59139 19.2271 5.34004 19.7475 5.12326Z"
        fill="#BBBFC8"
      />
      <path
        d="M0.132545 5.11841C0.18654 5.09312 0.252369 5.09721 0.307844 5.1158C3.38185 6.40567 6.45697 7.69255 9.53135 8.98204C9.67595 9.01886 9.80059 9.13077 9.78986 9.29177C9.79023 12.6501 9.78949 16.0088 9.79023 19.3671C9.78505 19.4579 9.74844 19.5571 9.66634 19.6058C9.57203 19.6348 9.4707 19.6177 9.38305 19.575C6.55941 18.3903 3.73504 17.2079 0.911403 16.0237C0.688027 15.9144 0.425819 15.8616 0.252369 15.6701C0.0796598 15.5016 -0.0031817 15.2577 0.00162606 15.0183C0.000886404 11.8068 0.0023657 8.59535 0.000886387 5.38389C-0.00651017 5.27903 0.031952 5.16488 0.132545 5.11841Z"
        fill="#BBBFC8"
      />
    </g>
  </svg>
);
export default SlidebarOrdersIcon;
