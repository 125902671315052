import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { isEmpty, debounce } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import AsyncSelectField from 'components/shared/form-fields/async-select-field';
import CustomNumberField from 'components/shared/form-fields/custom-number-field';
import SelectField from 'components/shared/form-fields/select-field';
import ButtonWithLoader from 'components/shared/loading-button';
import Loader from 'components/shared/loader';

import { ShowPasswordIcon, HidePasswordIcon } from 'assets/images/svg-icons';
import { getAllLabDetails } from 'services/lab-management';
import { mergeSchema } from 'helpers/utils';
import { LookupContext } from 'context/lookup-context/lookup-context';

const schema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name  is required'),
  email: Yup.string().email('Not a valid email').required('Email is required'),
  phone_number: Yup.string().required('Phone number is required'),
  password: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase and One Special Case Character',
      ),
  }),
  password_confirmation: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
});

const AddEditConsumerPhlebotomistModal = ({
  hideModal,
  editItem,
  title,
  onSuccessHandler,
  type,
  isLoading,
  isEditModal,
  isEditModalLoading,
  headerSchema = {},
  labName,
}) => {
  const { lookupDetails } = useContext(LookupContext);
  const [defaultLabOptions, setDefaultLabOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const buildFormData = useCallback(() => {
    const formData = {
      ...(!isEmpty(editItem?.lab_name)
        ? {
          lab_id: {
            label: editItem?.lab_name,
            value: editItem?.lab_id,
          },
        }
        : {}),
      first_name: editItem?.first_name || '',
      last_name: editItem?.last_name || '',
      email: editItem?.email || '',
      phone_number: editItem?.phone_number || '',
      ...(!isEmpty(editItem?.state)
        ? {
          state: {
            label: editItem?.state,
            value: editItem?.state,
          },
        }
        : {}),
      city: editItem?.city || '',
      zip: editItem?.zip || '',
      street_address: editItem?.street_address || '',
    };
    return formData;
  }, [editItem]);

  const handleSchema = () => {
    if (!isEmpty(headerSchema)) {
      return mergeSchema(schema, headerSchema);
    }
    return schema;
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(handleSchema()),
    defaultValues: buildFormData(editItem),
  });

  const {
    register,
    setValue,
    watch,
    setError,
    reset,
    formState: { errors },
  } = methods;

  const { password } = watch();

  const onSubmit = (formData) => {
    onSuccessHandler(formData, setError);
  };

  useEffect(() => {
    register('isPasswordRequired', { value: true });
  }, [register]);

  useEffect(() => {
    if (!isEmpty(editItem)) {
      reset(buildFormData(editItem));
    }
  }, [reset, editItem, buildFormData]);

  useEffect(() => {
    if (isEditModal && !password) {
      setValue('isPasswordRequired', false);
    } else if (isEditModal && password) {
      setValue('isPasswordRequired', true);
    }
  }, [setValue, password, isEditModal]);

  const fetchAllLabs = useCallback(async (searchKeyWord, callback) => {
    try {
      const params = { search: searchKeyWord };
      const res = await getAllLabDetails(params);
      const lab = (res?.data?.data || []).map((data) => ({
        value: data.lab_id,
        label: data.lab_name,
      }));
      setDefaultLabOptions(lab);
      if (callback) {
        return callback(lab);
      }
      return lab;
    } catch (e) {
      return [];
    }
  }, []);

  const loadLabOptionsHandler = (searchKeyWord, callback) => {
    const lab = fetchAllLabs(searchKeyWord, callback);
    return lab;
  };

  const loadLabOptions = debounce(loadLabOptionsHandler, 400);

  useEffect(() => {
    fetchAllLabs();
  }, [fetchAllLabs]);

  return (
    <Modal isOpen toggle={() => hideModal()} centered className="phlebotomist">
      <ModalBody>
        <div className="modal__head">
          {!isEditModal ? 'Add' : 'Edit'}
          {' '}
          <span>{title}</span>
        </div>
        {labName ? <h3 className="modal__subhead">{labName}</h3> : ''}

        {isEditModalLoading ? (
          <Loader />
        ) : (
          <FormProvider {...methods}>
            <Form
              className="modal__form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {type === 'phlebotomist' ? (
                <Row className="tab__form-sec">
                  <h2 className="modal__sub-head">
                    {isEditModal ? (
                      <>
                        Assigned
                        {' '}
                        <span>Lab</span>
                      </>
                    ) : (
                      <>
                        {' '}
                        Assign
                        {' '}
                        <span>to Lab</span>
                      </>
                    )}
                  </h2>
                  <Col xs="12" md="8">
                    <FormGroup>
                      <AsyncSelectField
                        name="lab_id"
                        isDisabled={isEditModal}
                        loadOptions={loadLabOptions}
                        defaultOptions={defaultLabOptions}
                        className={`selectbox ${isEditModal ? 'selectbox--disabled ' : ''}`}
                        placeholder="Select Lab"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Row className="tab__form-sec">
                {type === 'phlebotomist' ? (
                  <h2 className="modal__sub-head">
                    Basic
                    {' '}
                    <span>Information</span>
                  </h2>
                ) : (
                  ''
                )}
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>First Name</Label>
                    <CustomInputField
                      type="text"
                      name="first_name"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>Last Name</Label>
                    <CustomInputField
                      type="text"
                      className="form-control"
                      name="last_name"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>Email Address</Label>
                    <CustomInputField
                      type="email"
                      name="email"
                      className="form-control--regular form-control"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <CustomNumberField
                      format="(###) ###-####"
                      className="form-control"
                      name="phone_number"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>Password</Label>
                    <div
                      className={`view-password ${
                        !isEmpty(errors?.password) ? 'view-password--error' : ''
                      }`}
                    >
                      <CustomInputField
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        name="password"
                      />
                      <span
                        role="presentation"
                        onKeyDown={() => setShowPassword(!showPassword)}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </span>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>Confirm Password</Label>
                    <div
                      className={`view-password ${
                        !isEmpty(errors?.password_confirmation)
                          ? 'view-password--error'
                          : ''
                      }`}
                    >
                      <CustomInputField
                        autoComplete="new-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        name="password_confirmation"
                      />
                      <span
                        role="presentation"
                        onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="tab__form-sec">
                <h2 className="modal__sub-head">
                  {type === 'phlebotomist' || type === 'lab' ? (
                    <>
                      Address
                      {' '}
                      <span>info (Optional)</span>
                    </>
                  ) : (
                    <>
                      Shipping
                      {' '}
                      <span>Address</span>
                    </>
                  )}
                </h2>
                <Col xs="12">
                  <FormGroup>
                    <Label>Street Address</Label>
                    <CustomInputField
                      type="text"
                      className="form-control"
                      name="street_address"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>State</Label>
                    <SelectField
                      name="state"
                      options={lookupDetails?.locationOptions}
                      className="selectbox"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>City</Label>
                    <CustomInputField
                      type="text"
                      className="form-control"
                      name="city"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <FormGroup>
                    <Label>Zip</Label>
                    <CustomInputField
                      type="text"
                      className="form-control"
                      name="zip"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal__btn d-flex align-items-center justify-content-center">
                <Button color="primary outline" onClick={() => hideModal()}>
                  Close
                </Button>

                <ButtonWithLoader color="secondary" isLoading={isLoading}>
                  {!isEditModal ? `Add ${title}` : 'Save Changes'}
                </ButtonWithLoader>
              </div>
            </Form>
          </FormProvider>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AddEditConsumerPhlebotomistModal;
