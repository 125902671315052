import React from 'react';

const LogoGray = (props) => (
  <svg
    {...props}
    width="162"
    height="28"
    viewBox="0 0 162 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5588 0.227311C14.0849 0.0767036 15.6262 0.220023 17.1499 0.0240723C17.6051 -0.0415146 18.1156 0.0159751 18.4737 0.333383C18.6768 0.504233 18.7715 0.764152 18.8157 1.02002C19.2516 3.35119 19.791 5.66212 20.4156 7.94794C21.5339 6.75038 22.5919 5.49451 23.5754 4.18115C23.8491 3.81678 24.1052 3.40058 24.5331 3.2022C25.0701 2.93014 25.7878 3.10747 26.1298 3.60949C26.4028 3.9852 26.4317 4.46455 26.4983 4.90908C26.8579 7.26292 27.6551 9.54146 28.7806 11.6321C29.068 12.1366 29.0271 12.8184 28.6401 13.2581C28.091 13.9398 26.8941 13.841 26.4638 13.0767C25.5197 11.3609 24.8141 9.51555 24.3179 7.6192C18.5379 14.6589 10.7678 19.9876 2.20046 22.9431C1.94196 23.0273 1.68748 23.1414 1.41293 23.1609C0.665539 23.1916 -0.0385014 22.509 0.00163777 21.7463C0.0144823 21.1236 0.496955 20.5916 1.07737 20.4281C5.80817 18.8605 10.2572 16.4678 14.2518 13.4759C12.9272 9.6945 11.9382 5.79167 11.3289 1.82812C11.2727 1.45079 11.3257 1.03945 11.5641 0.731763C11.7969 0.419213 12.1815 0.261319 12.5588 0.227311ZM14.0255 2.68723C14.577 5.77872 15.3589 8.82891 16.3495 11.807C17.0648 11.207 17.7608 10.5844 18.4375 9.94065C18.33 9.75604 18.228 9.56494 18.1726 9.35766C17.5449 7.12932 17.0046 4.87588 16.5574 2.60382C15.7137 2.64188 14.8692 2.66131 14.0255 2.68723Z"
      fill="#9EA9BB"
    />
    <path
      d="M44.1612 7.28399C44.9367 7.0095 45.831 7.65403 45.8944 8.46375C45.969 9.12852 45.4842 9.75848 44.862 9.93905C38.9904 11.8897 33.5411 15.0783 28.8714 19.1544C26.463 21.2613 24.2658 23.6151 22.3423 26.1795C22.0662 26.5439 21.8157 26.9617 21.391 27.1682C20.8885 27.433 20.2182 27.3042 19.8505 26.8694C19.6088 26.5949 19.5077 26.2289 19.4619 25.8734C19.1376 23.4232 18.3437 21.0427 17.1836 18.8678C16.95 18.4807 16.8513 17.9957 17.0118 17.5609C17.1852 17.033 17.7143 16.6516 18.2674 16.6645C18.7579 16.6548 19.2516 16.9245 19.4772 17.3706C20.398 19.071 21.0964 20.8905 21.5845 22.7641C27.4665 15.5982 35.4124 10.2046 44.1612 7.28399Z"
      fill="#9EA9BB"
    />
    <path
      d="M79.3696 7.06161C79.3431 6.87497 79.4086 6.58929 79.6654 6.64643C80.432 6.64008 81.2011 6.6185 81.9677 6.65913C82.0017 6.69087 82.0685 6.75308 82.1012 6.78482C82.149 8.30209 82.0961 9.82316 82.1251 11.3417C82.5254 10.8808 82.9886 10.4351 83.5954 10.279C84.7988 9.95267 86.2225 10.1863 87.115 11.1132C87.7822 11.7747 88.0654 12.7371 88.0629 13.6639C88.0591 15.8224 88.0742 17.9834 88.0566 20.1431C88.1132 20.4148 87.7897 20.3996 87.6135 20.4059C86.8456 20.3882 86.0715 20.4681 85.3111 20.3386C85.3111 18.3249 85.3137 16.3112 85.3111 14.2975C85.3149 13.8645 85.2104 13.4151 84.931 13.0761C84.3519 12.3257 83.0654 12.332 82.4952 13.0888C82.2183 13.4252 82.1188 13.8722 82.1188 14.3013C82.115 16.3163 82.1238 18.3313 82.115 20.3463C81.2074 20.444 80.2797 20.444 79.3721 20.3463C79.3658 15.9176 79.3721 11.4903 79.3696 7.06161Z"
      fill="#9EA9BB"
    />
    <path
      d="M93.4631 10.2193C94.737 10.0149 96.1343 10.1927 97.1904 10.9888C98.1069 11.649 98.6721 12.7066 98.9024 13.8036C99.1743 15.1533 99.0988 16.5982 98.5789 17.8806C98.1811 18.8773 97.3956 19.7089 96.4251 20.1508C94.6703 20.9164 92.3981 20.6497 91.0499 19.215C90.1184 18.2577 89.7685 16.8813 89.7206 15.5748C89.698 14.4258 89.8503 13.2234 90.4495 12.2229C91.0877 11.1398 92.237 10.4123 93.4631 10.2193ZM93.8231 12.5987C92.9457 12.8641 92.5605 13.843 92.5114 14.6899C92.4623 15.6345 92.4208 16.696 93.0049 17.4959C93.5651 18.2996 94.8453 18.4291 95.5452 17.7435C96.128 17.1937 96.2627 16.3443 96.2791 15.5774C96.2954 14.7622 96.2803 13.8671 95.7604 13.1929C95.3312 12.5911 94.5079 12.3854 93.8231 12.5987Z"
      fill="#9EA9BB"
    />
    <path
      d="M105.032 10.3158C106.06 9.99968 107.26 10.0936 108.155 10.7272C108.553 11.0002 108.814 11.4154 109.086 11.8064C109.465 11.1195 110.059 10.5342 110.824 10.3133C112.008 9.95524 113.466 10.1216 114.341 11.0764C115.013 11.8039 115.2 12.8374 115.19 13.7998C115.189 15.7882 115.185 17.7765 115.193 19.7648C115.182 19.9616 115.193 20.1698 115.125 20.359C114.601 20.4568 114.064 20.3882 113.535 20.4085C113.18 20.3945 112.811 20.4568 112.473 20.3349C112.395 18.6691 112.461 16.9956 112.44 15.3273C112.412 14.5807 112.569 13.7376 112.104 13.0901C111.565 12.3092 110.262 12.3384 109.73 13.1117C109.443 13.485 109.388 13.9712 109.396 14.4283C109.401 16.2084 109.394 17.9885 109.401 19.7686C109.394 19.9667 109.395 20.1711 109.336 20.3628C108.456 20.4441 107.558 20.4301 106.677 20.3565C106.651 18.2958 106.682 16.2338 106.662 14.1731C106.683 13.3669 106.074 12.539 105.236 12.5238C104.416 12.4235 103.616 13.0926 103.593 13.9306C103.529 16.0688 103.637 18.2145 103.54 20.3501C103.062 20.4593 102.564 20.3895 102.079 20.4085C101.678 20.3933 101.267 20.458 100.878 20.3438C100.782 17.3219 100.869 14.2899 100.835 11.2656C100.85 10.9558 100.785 10.6244 100.914 10.3323C101.766 10.279 102.635 10.2701 103.488 10.3349C103.663 10.651 103.543 11.0738 103.585 11.4306C103.981 10.9608 104.426 10.4936 105.032 10.3158Z"
      fill="#9EA9BB"
    />
    <path
      d="M118.676 10.9722C119.811 10.1012 121.352 9.95388 122.709 10.2802C123.73 10.5227 124.622 11.202 125.172 12.0984C125.909 13.3096 126.112 14.8028 125.962 16.1969C123.849 16.249 121.737 16.1969 119.625 16.2248C119.733 16.8013 119.902 17.4323 120.408 17.7853C121.098 18.2614 122.029 18.3135 122.808 18.0468C123.343 17.8691 123.702 17.412 124.084 17.0209C124.646 17.5859 125.245 18.1154 125.758 18.7299C124.486 20.4326 122.11 20.8934 120.15 20.3526C118.984 20.0364 117.963 19.2035 117.445 18.0989C116.897 17.0082 116.798 15.755 116.864 14.5527C116.951 13.1827 117.554 11.7924 118.676 10.9722ZM120.438 12.765C119.879 13.1052 119.699 13.7896 119.617 14.4003C120.813 14.4143 122.009 14.4117 123.205 14.4016C123.118 13.9915 123.078 13.5483 122.82 13.203C122.301 12.4602 121.19 12.3256 120.438 12.765Z"
      fill="#9EA9BB"
    />
    <path
      d="M127.728 6.66176C128.585 6.6351 129.446 6.61859 130.303 6.65922C130.515 6.75445 130.433 7.00838 130.457 7.19376C130.447 11.5792 130.462 15.9647 130.449 20.3502C129.542 20.4416 128.616 20.4429 127.709 20.3502C127.714 15.787 127.679 11.2237 127.728 6.66176Z"
      fill="#9EA9BB"
    />
    <path
      d="M142.855 6.67313C143.613 6.60203 144.378 6.65282 145.141 6.64012C145.315 6.61346 145.582 6.68456 145.54 6.92072C145.559 8.34784 145.532 9.77496 145.551 11.2021C145.955 10.778 146.414 10.3679 147.001 10.2397C148.255 9.96033 149.676 10.2739 150.597 11.2097C151.546 12.148 151.932 13.5154 151.988 14.8232C152.051 16.2808 151.867 17.8501 150.969 19.0474C150.074 20.2943 148.376 20.8694 146.92 20.4098C146.363 20.2435 145.945 19.8207 145.555 19.4106C145.548 19.7229 145.543 20.0365 145.54 20.3489C144.632 20.4466 143.711 20.4352 142.805 20.3539C142.786 16.6439 142.801 12.9339 142.796 9.22392C142.834 8.37577 142.715 7.51239 142.855 6.67313ZM146.809 12.5962C145.955 12.8857 145.671 13.8798 145.594 14.6937C145.535 15.679 145.517 16.7849 146.135 17.6127C146.694 18.3847 147.98 18.3707 148.552 17.6216C149.051 16.974 149.151 16.1157 149.144 15.3209C149.144 14.5325 149.031 13.6818 148.516 13.0558C148.113 12.5606 147.399 12.4134 146.809 12.5962Z"
      fill="#9EA9BB"
    />
    <path
      d="M57.7784 10.3551C58.7602 10.0059 59.912 10.0682 60.8184 10.6014C61.3055 10.8795 61.6442 11.3417 61.9261 11.8178C62.3227 11.1131 62.937 10.5087 63.73 10.2967C64.9183 9.95643 66.3735 10.1393 67.2257 11.1157C67.9067 11.8724 68.0679 12.9414 68.0439 13.928C68.02 16.056 68.0817 18.1878 68.0137 20.3119C67.9735 20.331 67.8904 20.3678 67.8501 20.3868C67.0482 20.4237 66.2413 20.4237 65.4407 20.3856C65.408 20.3589 65.3413 20.3043 65.3073 20.2777C65.2544 18.2068 65.3161 16.1309 65.2796 14.0588C65.2733 13.3147 64.7257 12.5593 63.949 12.5288C63.0654 12.3917 62.2559 13.1763 62.261 14.0575C62.2169 16.1398 62.2962 18.2271 62.2245 20.3081C62.1829 20.3272 62.1011 20.3665 62.0608 20.3868C61.2388 20.4173 60.4093 20.4389 59.5898 20.3716C59.5092 20.1761 59.5143 19.9628 59.5105 19.7571C59.518 17.9389 59.5143 16.1207 59.5143 14.3025C59.5231 13.8264 59.3984 13.3287 59.0674 12.9757C58.4392 12.2825 57.1653 12.3929 56.6807 13.2017C56.3672 13.6766 56.4478 14.2708 56.439 14.8104C56.4352 16.5943 56.4528 18.3782 56.4314 20.1608C56.4742 20.4122 56.1734 20.3995 56.016 20.4059C55.2419 20.3907 54.4601 20.463 53.6923 20.3437C53.6986 17.007 53.6721 13.669 53.7061 10.331C54.6087 10.2789 55.5175 10.2789 56.4201 10.331C56.4377 10.6979 56.4415 11.0661 56.4553 11.4331C56.8141 10.9861 57.222 10.5443 57.7784 10.3551Z"
      fill="#9EA9BB"
    />
    <path
      d="M133.364 11.0548C134.511 10.2104 136.014 10.0225 137.392 10.2066C138.589 10.3793 139.809 10.9722 140.44 12.0553C140.703 12.5047 140.832 13.0266 140.822 13.5472C140.822 15.6675 140.821 17.7879 140.825 19.907C140.796 20.0847 140.889 20.406 140.612 20.3895C139.789 20.4364 138.963 20.392 138.138 20.406C138.086 20.0771 138.064 19.7432 138.053 19.4105C137.558 20.0111 136.856 20.425 136.082 20.5101C134.902 20.6701 133.569 20.4999 132.674 19.6429C131.919 18.895 131.812 17.7117 132.041 16.7188C132.26 15.7996 133.005 15.0847 133.871 14.7686C135.212 14.2569 136.669 14.4804 138.069 14.422C138.073 13.8785 138.122 13.2208 137.645 12.8488C137.067 12.4197 136.249 12.4425 135.605 12.6927C135.231 12.8234 135.081 13.2082 134.879 13.5116C133.967 13.5002 133.055 13.3046 132.149 13.1789C132.207 12.3257 132.68 11.5487 133.364 11.0548ZM135.582 16.2287C135.049 16.3836 134.618 16.9055 134.711 17.4844C134.731 18.005 135.198 18.3973 135.686 18.4646C136.453 18.5903 137.33 18.3643 137.815 17.7193C138.174 17.2534 138.048 16.6274 138.063 16.0814C137.236 16.1094 136.382 15.9799 135.582 16.2287Z"
      fill="#9EA9BB"
    />
    <path
      d="M68.8623 10.3946C68.9404 10.213 69.1921 10.3133 69.3457 10.2765C70.1249 10.2994 70.9091 10.2321 71.6858 10.3133C71.7538 10.4301 71.8054 10.5546 71.8406 10.6866C72.3807 12.7689 72.8943 14.86 73.4582 16.936C73.5325 16.79 73.5791 16.6338 73.6281 16.4776C74.1871 14.441 74.6427 12.3715 75.2394 10.3463C76.1546 10.199 77.1075 10.2968 78.0365 10.3032C77.9345 10.8529 77.7293 11.3735 77.5594 11.9017C76.6354 14.7077 75.7077 17.5124 74.785 20.3184C74.4262 21.3532 74.1002 22.4616 73.3424 23.2806C72.3064 24.3065 70.7417 24.3103 69.3847 24.3166C69.318 23.5726 69.318 22.8209 69.3835 22.0756C70.1211 21.9652 71.0073 22.0883 71.5826 21.5081C71.9086 21.1119 72.207 20.5419 71.9753 20.0289C71.0224 17.1353 70.0859 14.2354 69.1342 11.3405C69.0348 11.0282 68.9076 10.7222 68.8623 10.3946Z"
      fill="#9EA9BB"
    />
    <path
      d="M153.846 12.0491C154.348 10.9978 155.481 10.415 156.585 10.2461C157.867 10.0582 159.262 10.1496 160.393 10.8441C161.166 11.3114 161.735 12.1367 161.814 13.0521C161.811 13.2197 161.899 13.5536 161.64 13.5625C160.87 13.6006 160.093 13.612 159.325 13.5371C159.245 13.2375 159.211 12.882 158.931 12.6941C158.297 12.2243 157.389 12.2256 156.684 12.5252C156.286 12.675 156.072 13.1943 156.317 13.5625C156.571 13.866 156.984 13.9422 157.343 14.059C158.658 14.4056 160.057 14.6798 161.158 15.5369C162.082 16.239 162.178 17.6179 161.772 18.6324C161.268 19.77 160.015 20.321 158.854 20.4747C157.395 20.6486 155.792 20.5381 154.556 19.6608C153.779 19.1224 153.307 18.141 153.504 17.1913C154.325 17.1862 155.147 17.1544 155.969 17.2002C156.132 17.4719 156.132 17.8452 156.401 18.0508C156.956 18.5028 157.738 18.5574 158.414 18.4025C158.834 18.2959 159.309 17.9632 159.251 17.4681C159.175 17.0516 158.713 16.9411 158.379 16.8231C157.014 16.4472 155.583 16.1044 154.433 15.2296C153.467 14.5161 153.381 13.08 153.846 12.0491Z"
      fill="#9EA9BB"
    />
  </svg>
);
export default LogoGray;
