import React, { useEffect } from 'react';
import {
  Switch,
  Redirect,
  Route,
  useHistory,
} from 'react-router-dom';
import { Container } from 'reactstrap';

import { MyHomeLabLogoGray } from 'assets/images/svg-icons';
import authRoutes from 'routes/auth';
import { MHL_PUBLIC_WEBSITE_URL } from 'config';

const renderComponents = (
  <Switch>
    {authRoutes.map((prop) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={prop.key} />;
      }
      return (
        <Route path={prop.path} component={prop.component} key={prop.key} />
      );
    })}
  </Switch>
);

const Authentication = () => {
  const history = useHistory();

  useEffect(() => {
    const user = localStorage.getItem('userAccessToken');
    if (user) {
      history.push('/dashboard');
    }
  }, [history]);

  return (
    <React.Fragment>
      <div className="auth">
        <Container className="auth__container">
          {renderComponents}
          <MyHomeLabLogoGray
            className="auth__logo cursor-pointer"
            onClick={() => window.open(MHL_PUBLIC_WEBSITE_URL)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Authentication;
