import React, { useState, useEffect } from 'react';
import { ModalBody, Modal } from 'reactstrap';
import Loader from 'components/shared/loader';
import ButtonWithLoader from 'components/shared/loading-button';
import { viewTestResult } from 'services/test-result';
import { CloseModalIcon } from 'assets/images/svg-icons';

const ResultPreviewModal = ({
  closeModal,
  testResultId,
  updateResultStatusHandler,
}) => {
  const [testResultData, setTestResultData] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState({ reject: false, approved: false });

  useEffect(() => {
    if (testResultId) {
      setIsModalLoading(true);
      viewTestResult(testResultId)
        .then((response) => {
          setTestResultData(response?.data);
          setIsModalLoading(false);
        })
        .catch(() => {
          setIsModalLoading(false);
        });
    }
    return (() => {
      setButtonLoading({ reject: false, approved: false });
    });
  }, [testResultId]);

  const handleUpdateStatus = (status) => {
    if (status === 'approve') {
      setButtonLoading((prev) => ({ ...prev, approved: true }));
      updateResultStatusHandler(testResultData?.test_result_id, { status: 'published' });
    } else {
      setButtonLoading((prev) => ({ ...prev, reject: true }));
      updateResultStatusHandler(testResultData?.test_result_id, { status: 'rejected' });
    }
  };

  return (
    <Modal isOpen toggle={closeModal} centered className="test-result__modal">
      <ModalBody>
        {isModalLoading ? (
          <Loader />
        ) : (
          <>
            <CloseModalIcon className="modal__close" onClick={closeModal} />
            <div className="modal__head">{testResultData?.title}</div>
            <div className="modal__head-text">
              {testResultData?.lab_name}
              . on
              {' '}
              {testResultData?.submitted_date}
              .
            </div>
            <div className="test-result__modal-preview">
              <iframe
                src={testResultData?.media_url}
                title="pdf result"
                width="100%"
                height="700px"
              />
            </div>
            <div className="modal__btn d-flex align-items-center justify-content-center">
              <ButtonWithLoader
                color="primary"
                isLoading={buttonLoading?.reject}
                onClick={() => handleUpdateStatus('reject')}
              >
                Reject
              </ButtonWithLoader>
              <ButtonWithLoader
                color="secondary"
                isLoading={buttonLoading?.approved}
                onClick={() => handleUpdateStatus('approve')}
              >
                Approve
              </ButtonWithLoader>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ResultPreviewModal;
