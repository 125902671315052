import React from 'react';

const SearchIcon = (props) => (

  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path d="M7.875 13.5C10.9816 13.5 13.5 10.9816 13.5 7.875C13.5 4.7684 10.9816 2.25 7.875 2.25C4.7684 2.25 2.25 4.7684 2.25 7.875C2.25 10.9816 4.7684 13.5 7.875 13.5Z" stroke="#444444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.7501 15.75L11.8501 11.85" stroke="#444444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>

);
export default SearchIcon;
