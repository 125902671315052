import React from 'react';

// Global
import Approved from 'assets/images/approved-icon';
import Category from 'assets/images/category-icon';
import Consumer from 'assets/images/consumer-icon';
import ConsumerYellow from 'assets/images/consumer-icon-yellow';
import Covid from 'assets/images/covid-icon';
import Email from 'assets/images/email-icon';
import Deactivate from 'assets/images/deactivate-icon';
import HeaderBell from 'assets/images/header-bell';
import HeaderBellNotification from 'assets/images/header-bell-notification';
import LabFillBlue from 'assets/images/lab-fill-blue-icon';
import Lab from 'assets/images/lab-icon';
import LabBlue from 'assets/images/lab-icon-blue';
import LatestOrders from 'assets/images/latest-orders-icon';
import Location from 'assets/images/location-icon';
import Logo from 'assets/images/logo';
import LogoGray from 'assets/images/logo-gray';
import OrderDate from 'assets/images/order-date-icon';
import Order from 'assets/images/order-icon';
import OrderSubmitted from 'assets/images/order-submitted-icon';
import PendingLab from 'assets/images/pending-lab-icon';
import PendingDelivery from 'assets/images/pending-delivery-icon';
import Pending from 'assets/images/pending-icon';
import PhoneNumber from 'assets/images/phone-number-icon';
import Revenue from 'assets/images/revenue-icon';
import SellingKit from 'assets/images/selling-kit-icon';
import TestKit from 'assets/images/test-kit-icon';
import HeaderClose from 'assets/images/header-close';
import PendingScheduling from 'assets/images/pending-scheduling-icon';
import Dashboard from 'assets/images/dashboard-icon';
import LabManagement from 'assets/images/lab-management-icon';
import KitManagement from 'assets/images/kit-management-icon';
import SlidebarConsumers from 'assets/images/slidebar-consumers-icon';
import SlidebarOrders from 'assets/images/slidebar-orders-icon';
import SlidebarReports from 'assets/images/slidebar-reports-icon';
import SlidebarPayoutSystem from 'assets/images/slidebar-payout-System-icon';
import Slidebarphlebotomists from 'assets/images/slidebar-Phlebotomists-icon';
import AddGreen from 'assets/images/add-green-icon';
import Filter from 'assets/images/filter-icon';
import ChevronLeft from 'assets/images/chevron-left-icon';
import Dot from 'assets/images/dot-icon';
import Excel from 'assets/images/excel-icon';
import Sperm from 'assets/images/sperm-icon';
import Comprehensive from 'assets/images/comprehensive-icon';
import Male from 'assets/images/male-icon';
import Female from 'assets/images/female-icon';
import Food from 'assets/images/food-icon.svg';
import Id from 'assets/images/id-icon';
import Money from 'assets/images/money-icon';
import MyHomeLab from 'assets/images/my-home-lab-icon';
import MyAccount from 'assets/images/my-account-icon';
import AddConsumerGreen from 'assets/images/add-consumer-green-icon';
import AddTestKitGreen from 'assets/images/add-test-kit-green-icon';
import Star from 'assets/images/star-icon';
import AlertYellow from 'assets/images/alert-yellow-icon';
import Search from 'assets/images/search-icon';
import Delete from 'assets/images/delete-icon';
import Pluse from 'assets/images/pluse-icon';
import TrackingCode from 'assets/images/tracking-code-icon';
import ProfileUpload from 'assets/images/profile-upload-icon';
import InputClose from 'assets/images/input-close-icon';
import SlidebarRegisteredKits from 'assets/images/slidebar-registered-kits-icon';
import ShowPassword from 'assets/images/show-password-icon';
import HidePassword from 'assets/images/hide-password-icon';
import Reviews from 'assets/images/slidebar-reviews';
import AddLabGreen from 'assets/images/add-lab-green-icon';
import TestResults from 'assets/images/slidebar-test-results';
import CloseModal from 'assets/images/close-modal-icon';
import ScheduleTime from 'assets/images/schedule-time-icon';
import Timezone from 'assets/images/timezone-icon';

export const ApprovedIcon = (props) => <Approved {...props} />;
export const CategoryIcon = (props) => <Category {...props} />;
export const ConsumerIcon = (props) => <Consumer {...props} />;
export const ConsumerIconYellow = (props) => <ConsumerYellow {...props} />;
export const CovidIcon = (props) => <Covid {...props} />;
export const EmailIcon = (props) => <Email {...props} />;
export const DeactivateIcon = (props) => <Deactivate {...props} />;
export const HeaderBellIcon = (props) => <HeaderBell {...props} />;
export const HeaderBellNotificationIcon = (props) => (
  <HeaderBellNotification {...props} />
);
export const LabFillBlueIcon = (props) => <LabFillBlue {...props} />;
export const LabIcon = (props) => <Lab {...props} />;
export const LabBlueIcon = (props) => <LabBlue {...props} />;
export const LatestOrdersIcon = (props) => <LatestOrders {...props} />;
export const LocationIcon = (props) => <Location {...props} />;
export const MyHomeLabLogo = (props) => <Logo {...props} />;
export const MyHomeLabLogoGray = (props) => <LogoGray {...props} />;
export const OrderDateIcon = (props) => <OrderDate {...props} />;
export const OrderIcon = (props) => <Order {...props} />;
export const OrderSubmittedIcon = (props) => <OrderSubmitted {...props} />;
export const PendingLabIcon = (props) => <PendingLab {...props} />;
export const PendingDeliveryIcon = (props) => <PendingDelivery {...props} />;
export const PendingIcon = (props) => <Pending {...props} />;
export const PhoneNumberIcon = (props) => <PhoneNumber {...props} />;
export const RevenueIcon = (props) => <Revenue {...props} />;
export const SellingKitIcon = (props) => <SellingKit {...props} />;
export const TestKitIcon = (props) => <TestKit {...props} />;
export const HeaderCloseIcon = (props) => <HeaderClose {...props} />;
export const PendingSchedulingIcon = (props) => (
  <PendingScheduling {...props} />
);
export const DashboardIcon = (props) => <Dashboard {...props} />;
export const LabManagementIcon = (props) => <LabManagement {...props} />;
export const KitManagementIcon = (props) => <KitManagement {...props} />;
export const SlidebarConsumersIcon = (props) => (
  <SlidebarConsumers {...props} />
);
export const SlidebarOrdersIcon = (props) => <SlidebarOrders {...props} />;
export const SlidebarReportsIcon = (props) => <SlidebarReports {...props} />;
export const SlidebarPayoutSystemIcon = (props) => (
  <SlidebarPayoutSystem {...props} />
);
export const SlidebarphlebotomistsIcon = (props) => (
  <Slidebarphlebotomists {...props} />
);
export const AddGreenIcon = (props) => <AddGreen {...props} />;
export const FilterIcon = (props) => <Filter {...props} />;
export const ChevronLeftIcon = (props) => <ChevronLeft {...props} />;
export const DotIcon = (props) => <Dot {...props} />;
export const ExcelIcon = (props) => <Excel {...props} />;
export const SpermIcon = (props) => <Sperm {...props} />;
export const ComprehensiveIcon = (props) => <Comprehensive {...props} />;
export const MaleIcon = (props) => <Male {...props} />;
export const FemaleIcon = (props) => <Female {...props} />;
export const FoodIcon = (props) => <Food {...props} />;
export const IdIcon = (props) => <Id {...props} />;
export const MoneyIcon = (props) => <Money {...props} />;
export const MyHomeLabIcon = (props) => <MyHomeLab {...props} />;
export const MyAccountIcon = (props) => <MyAccount {...props} />;
export const AddConsumerGreenIcon = (props) => <AddConsumerGreen {...props} />;
export const AddTestKitGreenIcon = (props) => <AddTestKitGreen {...props} />;
export const StarIcon = (props) => <Star {...props} />;
export const AlertYellowIcon = (props) => <AlertYellow {...props} />;
export const SearchIcon = (props) => <Search {...props} />;
export const DeleteIcon = (props) => <Delete {...props} />;
export const PluseIcon = (props) => <Pluse {...props} />;
export const TrackingCodeIcon = (props) => <TrackingCode {...props} />;
export const ProfileUploadIcon = (props) => <ProfileUpload {...props} />;
export const InputCloseIcon = (props) => <InputClose {...props} />;
export const SlidebarRegisteredKitsIcon = (props) => (
  <SlidebarRegisteredKits {...props} />
);
export const ReviewsIcon = (props) => <Reviews {...props} />;

export const TestResultsIcon = (props) => <TestResults {...props} />;
export const CloseModalIcon = (props) => <CloseModal {...props} />;
export const HidePasswordIcon = (props) => <HidePassword {...props} />;
export const ShowPasswordIcon = (props) => <ShowPassword {...props} />;
export const AddLabGreenIcon = (props) => <AddLabGreen {...props} />;
export const ScheduleTimeIcon = (props) => <ScheduleTime {...props} />;
export const TimezoneIcon = (props) => <Timezone {...props} />;
