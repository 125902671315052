import React from 'react';

const ComprehensiveIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="25.953"
    height="30.95"
    viewBox="0 0 25.953 30.95"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#0052d7" />
        <stop offset="1" stopColor="#0047ba" />
      </linearGradient>
    </defs>
    <g id="comprehensive-icon" transform="translate(-0.024 -0.025)">
      <path
        id="Path_11"
        data-name="Path 11"
        d="M2.177.684A4.541,4.541,0,0,1,4.642.027q6.005,0,12.011,0a1.008,1.008,0,0,1,.775.3L25.7,8.384a.723.723,0,0,1,.211.422c0-.086.008-.171.017-.256a2.519,2.519,0,0,1,.051.558V20.144c-.006,2.313.012,4.627-.01,6.94a4.542,4.542,0,0,1-3.183,3.7,5.015,5.015,0,0,1-1.548.192q-8.359,0-16.718,0A4.584,4.584,0,0,1,.443,28.43a3.729,3.729,0,0,1-.415-1.712Q.02,15.651.025,4.584a3.722,3.722,0,0,1,.1-1.133A4.475,4.475,0,0,1,2.177.684ZM3.462,2.316a2.489,2.489,0,0,0-1.38,2.023q0,11.13,0,22.26a2.508,2.508,0,0,0,.146.7,2.454,2.454,0,0,0,1.627,1.541,4.053,4.053,0,0,0,1.1.1,4.116,4.116,0,0,0,.679.025q7.552,0,15.1,0a10.291,10.291,0,0,1,1.073-.043,2.533,2.533,0,0,0,1.854-1.375,3.416,3.416,0,0,0,.22-1.909q-.006-7.178,0-14.355a4.688,4.688,0,0,0,.012-1.125c-1.114,0-2.228,0-3.342,0-.164-.007-.324-.057-.488-.05a4.566,4.566,0,0,1-4.086-2.776,4.982,4.982,0,0,1-.346-1.471c-.007-1.258,0-2.517,0-3.775q-5.614-.012-11.229,0A2,2,0,0,0,3.462,2.316ZM17.749,3.561c-.02.683,0,1.367-.01,2.051a2.356,2.356,0,0,0,.515,1.519,2.577,2.577,0,0,0,1.615.922c.849,0,1.7.027,2.547-.016-1.408-1.347-2.8-2.715-4.193-4.075A2.876,2.876,0,0,0,17.749,3.561Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M8.075,4.864a1.219,1.219,0,0,1,.83-.123,1.115,1.115,0,0,1,.781,1.045c0,.534,0,1.068,0,1.6.628.014,1.259-.008,1.887.011a1.145,1.145,0,0,1,.746.572.977.977,0,0,1-.213,1.172,1.066,1.066,0,0,1-.779.294q-.65,0-1.3,0a.835.835,0,0,0-.341.043c0,.521,0,1.042,0,1.563a1.147,1.147,0,0,1-.365.817,1.3,1.3,0,0,1-.657.285,1.123,1.123,0,0,1-1.08-.874c-.021-.624.011-1.25-.017-1.874-.615-.008-1.23,0-1.845-.006a.984.984,0,0,1-.9-.77,1.062,1.062,0,0,1,.93-1.226c.594-.008,1.189,0,1.783,0,0-.473,0-.947,0-1.42A1.248,1.248,0,0,1,8.075,4.864Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M9.011,14.976a3.464,3.464,0,0,1,.771-.036H20.123a1.086,1.086,0,0,1,1.071,1.106,1.043,1.043,0,0,1-1.073.932H9.221a1.052,1.052,0,0,1-.979-.712A1.035,1.035,0,0,1,9.011,14.976Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M5.577,14.986A2.449,2.449,0,0,1,6.225,15a.942.942,0,0,1,.632.855,3.393,3.393,0,0,1-.017.476,1.052,1.052,0,0,1-.958.644,1.022,1.022,0,0,1-.922-.492,1.239,1.239,0,0,1-.1-.844A1.125,1.125,0,0,1,5.577,14.986Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_15"
        data-name="Path 15"
        d="M5.507,19.278a1.025,1.025,0,1,1,.322,1.979,1.014,1.014,0,0,1-.322-1.979Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_16"
        data-name="Path 16"
        d="M8.956,19.258a.621.621,0,0,1,.261-.044q5.421,0,10.842,0a1.083,1.083,0,0,1,1.065.693,1.023,1.023,0,0,1-.948,1.35q-5.446.01-10.893,0a1.019,1.019,0,0,1-.327-2Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_17"
        data-name="Path 17"
        d="M5.56,23.537A1.043,1.043,0,0,1,6.9,24.55a1.032,1.032,0,0,1-1.327.938,1.07,1.07,0,0,1-.763-.817A1.054,1.054,0,0,1,5.56,23.537Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M9.052,23.5c1.233-.03,2.468-.005,3.7-.013q3.654,0,7.308,0a1.077,1.077,0,0,1,1.085.768,1.155,1.155,0,0,1-.094.739,1.11,1.11,0,0,1-.928.545q-5.421,0-10.842,0a1.068,1.068,0,0,1-.957-.569.987.987,0,0,1,.251-1.239A1.557,1.557,0,0,1,9.052,23.5Z"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
);
export default ComprehensiveIcon;
