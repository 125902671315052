import { request } from 'helpers/api';

export const getAllPhlebotomistsData = (params) => request({
  method: 'GET',
  url: '/api/phlebotomists',
  params,
});

export const getPhlebotomistDetailsById = (phlebotomistId) => request({
  method: 'GET',
  url: `/api/phlebotomists/${phlebotomistId}`,
});

export const savePhlebotomist = (params) => request({
  method: 'POST',
  url: '/api/phlebotomists',
  params,
});

export const updatePhlebotomist = (params, phlebotomistId) => request({
  method: 'PUT',
  url: `/api/phlebotomists/${phlebotomistId}`,
  params,
});
