import React from 'react';

const IdIcon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path
      d="M12.46 28C12.3933 28 12.3333 27.98 12.28 27.94C12.24 27.8867 12.22 27.8267 12.22 27.76V14.24C12.22 14.1733 12.24 14.12 12.28 14.08C12.3333 14.0267 12.3933 14 12.46 14H14.8C14.8667 14 14.92 14.0267 14.96 14.08C15.0133 14.12 15.04 14.1733 15.04 14.24V27.76C15.04 27.8267 15.0133 27.8867 14.96 27.94C14.92 27.98 14.8667 28 14.8 28H12.46ZM17.7139 28C17.6472 28 17.5872 27.98 17.5339 27.94C17.4939 27.8867 17.4739 27.8267 17.4739 27.76V14.24C17.4739 14.1733 17.4939 14.12 17.5339 14.08C17.5872 14.0267 17.6472 14 17.7139 14H22.7139C23.7139 14 24.5872 14.18 25.3339 14.54C26.0939 14.8867 26.6806 15.38 27.0939 16.02C27.5072 16.66 27.7139 17.4 27.7139 18.24V23.76C27.7139 24.6 27.5072 25.34 27.0939 25.98C26.6806 26.62 26.0939 27.12 25.3339 27.48C24.5872 27.8267 23.7139 28 22.7139 28H17.7139ZM20.2939 25.48C20.2939 25.5467 20.3272 25.58 20.3939 25.58H22.8139C23.4272 25.58 23.9206 25.3733 24.2939 24.96C24.6806 24.5467 24.8806 23.9933 24.8939 23.3V18.7C24.8939 18.0067 24.7006 17.4533 24.3139 17.04C23.9406 16.6267 23.4339 16.42 22.7939 16.42H20.3939C20.3272 16.42 20.2939 16.4533 20.2939 16.52V25.48Z"
      fill="#0047BA"
    />
  </svg>
);
export default IdIcon;
