import React from 'react';

const SlidebarReportsIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M5.68364 0.950025C6.78953 0.416804 7.9979 0.0955774 9.22214 0C9.22178 1.86268 9.22323 3.725 9.22142 5.58769C7.85754 5.82232 6.63186 6.73462 6.02894 7.97604C5.50251 9.01877 5.43179 10.2692 5.80487 11.3723C6.04806 12.074 6.46516 12.7175 7.02081 13.2145C7.99574 14.1164 9.39894 14.5332 10.7105 14.3129C11.3697 14.2162 11.9982 13.9514 12.547 13.5781C13.8644 14.8846 15.181 16.1921 16.4965 17.5007C16.4969 17.5112 16.498 17.532 16.4983 17.5424C15.4801 18.4102 14.2811 19.0663 13.0017 19.4637C11.0572 20.0698 8.92952 20.068 6.98762 19.4507C5.61941 19.0203 4.34898 18.2876 3.28819 17.3257C1.96184 16.1332 0.968515 14.5792 0.442086 12.8785C-0.0193953 11.3752 -0.126918 9.76327 0.154156 8.21571C0.393376 6.79678 0.963824 5.43785 1.78612 4.25643C2.77295 2.84684 4.12564 1.69057 5.68364 0.950025Z"
        fill="#BBBFC8"
      />
      <path
        d="M10.8157 0.000366211C13.305 0.174993 15.7005 1.34672 17.3829 3.17922C18.8951 4.80044 19.8397 6.93908 19.9999 9.1467C18.1341 9.14598 16.268 9.14993 14.4019 9.1449C14.2521 8.30519 13.8462 7.51434 13.2581 6.89524C12.613 6.21254 11.7456 5.73789 10.8176 5.57512C10.8125 3.71675 10.8165 1.85874 10.8157 0.000366211Z"
        fill="#BBBFC8"
      />
      <path
        d="M14.3947 10.7137C16.2619 10.7176 18.1295 10.7105 19.9971 10.7173C19.9372 11.2333 19.8719 11.7499 19.7524 12.2566C19.3916 13.7804 18.6588 15.2155 17.6327 16.402C16.3124 15.0873 14.9915 13.7732 13.6724 12.4574C14.0343 11.9357 14.2764 11.336 14.3947 10.7137Z"
        fill="#BBBFC8"
      />
    </g>
  </svg>
);
export default SlidebarReportsIcon;
