import React from 'react';

const AddConsumerGreenIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.69128 0.00576163C11.9091 -0.0711484 14.1403 0.624554 15.9205 1.93519C17.0039 2.72922 17.927 3.73818 18.6128 4.88762C19.3529 6.12345 19.816 7.52188 19.9533 8.95261C20.1089 10.5277 19.8742 12.1393 19.271 13.6051C18.3022 15.9855 16.3635 17.9567 13.9875 18.9811C12.4463 19.6529 10.7307 19.9223 9.05474 19.7625C7.57917 19.6248 6.13728 19.1522 4.86845 18.3936C2.92195 17.2375 1.38928 15.4117 0.609471 13.2996C0.089953 11.9061 -0.100478 10.3949 0.0498809 8.9175C0.198821 7.42214 0.704509 5.96471 1.50808 4.69095C2.40597 3.26338 3.67374 2.06583 5.15818 1.24791C6.53978 0.482323 8.10862 0.051416 9.69128 0.00576163ZM9.66043 3.32308C9.17886 3.38313 8.71714 3.57348 8.32529 3.85583C7.65825 4.33415 7.18802 5.04776 6.92809 5.81511C6.68623 6.51678 6.62453 7.27148 6.69936 8.00687C6.80858 8.99651 7.21675 9.97597 7.94159 10.6787C8.4838 11.21 9.22886 11.5623 10.0002 11.5503C10.6349 11.5605 11.2583 11.3228 11.7562 10.94C12.5165 10.357 13.002 9.47518 13.2088 8.55507C13.419 7.59668 13.3637 6.57437 13.0141 5.65426C12.6953 4.81633 12.1123 4.04933 11.3023 3.62615C10.8034 3.35995 10.2225 3.25635 9.66043 3.32308ZM7.85364 12.731C6.49758 13.1595 5.23584 13.9384 4.33333 15.038C4.17553 15.2491 4.17092 15.5416 4.27837 15.7755C4.35709 15.9658 4.50993 16.1109 4.66916 16.2369C5.55606 16.9657 6.59864 17.5051 7.70399 17.8257C9.11574 18.2401 10.6417 18.2644 12.0648 17.8896C13.3531 17.5546 14.567 16.9239 15.5592 16.0413C15.7599 15.865 15.873 15.5816 15.8035 15.3189C15.7649 15.1304 15.6308 14.9853 15.5042 14.848C14.6712 13.8938 13.5652 13.1862 12.3566 12.795C12.2382 12.7665 12.1087 12.6759 11.9913 12.7532C10.7743 13.3467 9.28347 13.3562 8.05968 12.776C7.99478 12.75 7.92599 12.6963 7.85364 12.731Z"
      fill="#19D3C5"
    />
  </svg>
);
export default AddConsumerGreenIcon;
