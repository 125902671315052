import React, { useContext, useEffect, useState } from 'react';
import { pick } from 'lodash';

import ListingComponent from 'components/shared/listing-component';
import Filter from 'components/shared/filter/filter';

import { getAllNotifications } from 'services/notification';
import HeaderText from 'header-context';
import { LookupContext } from 'context/lookup-context/lookup-context';
import { filterOptions } from 'helpers/utils';
import { REFETCH_LATEST_NOTIFICATION_EVENT_LISTENER } from 'config';

const Notifications = () => {
  const [, setHeader] = useContext(HeaderText);
  const lookupData = useContext(LookupContext);

  const [notification, setNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});

  const fetchAllNotifications = (params) => {
    setIsLoading(true);
    getAllNotifications(params)
      .then((response) => {
        document.dispatchEvent(new Event(REFETCH_LATEST_NOTIFICATION_EVENT_LISTENER));
        setNotification(response.data.data);
        setMeta(response.data.meta);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setHeader('Notifications');
  }, [setHeader]);

  useEffect(() => {
    fetchAllNotifications(filter);
  }, [filter]);

  const mappings = [
    {
      key: 'notification',
      label: 'Notifications',
      className: 'text-dark text-start',
    },
    {
      key: 'category',
      label: 'Category',
    },
    {
      key: 'created_at',
      label: 'DATE / TIME',
    },
  ];

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap">
      <Filter
        filters={[
          {
            filter_type: 'select',
            name: 'category',
            options: filterOptions(
              lookupData?.lookupDetails?.notificationCategory,
              'All Category',
            ),
            placeholder: 'Category',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        meta={meta}
      />
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={notification}
        shouldPaginate
        primaryKey="notification_id"
      />
    </div>
  );
};

export default Notifications;
