import React from 'react';

const LabFillblueIcon = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#0047BA" />
    <path
      opacity="0.8"
      d="M8.84926 5.12424C8.95858 5.04059 9.09731 4.9989 9.23474 5.00019C11.0749 4.99994 12.9151 4.99994 14.7551 5.00019C14.9805 4.99501 15.2132 5.11854 15.304 5.32934C15.3894 5.52953 15.3824 5.77399 15.2665 5.96097C15.1356 6.17125 14.868 6.24506 14.6322 6.22123C14.6309 7.54638 14.632 8.87152 14.6317 10.1964C14.6255 10.2531 14.6627 10.299 14.6871 10.3466C15.2272 11.3413 15.7671 12.3363 16.3069 13.331C16.8111 14.2161 17.304 15.1075 17.8116 15.9906C17.8881 16.1387 17.9417 16.2985 17.973 16.4622C18.0143 16.6497 17.9979 16.8426 17.9901 17.0327C17.9808 17.4931 17.8202 17.9536 17.5149 18.3032C17.3772 18.4606 17.2174 18.6005 17.0362 18.7069C16.9183 18.7745 16.8085 18.8584 16.6794 18.9055C16.4813 18.9811 16.2671 19.0024 16.0562 18.9998C13.3662 18.9998 10.6759 18.9998 7.9859 18.9998C7.84977 18.999 7.71313 18.9951 7.57882 18.9705C7.06008 18.8905 6.57595 18.5852 6.29901 18.1387C6.07074 17.7904 5.98745 17.3647 6.0015 16.9537C6.01452 16.7424 6.05642 16.531 6.14102 16.336C7.21156 14.2881 8.28471 12.241 9.35343 10.1923C9.36566 9.52904 9.35525 8.86453 9.35863 8.20079C9.35837 7.54094 9.35942 6.88109 9.35811 6.22123C9.14 6.24143 8.89976 6.18472 8.75686 6.00836C8.56087 5.75069 8.58507 5.32805 8.84926 5.12424ZM10.588 6.22201C10.5867 7.61578 10.588 9.00955 10.5872 10.4033C10.5843 10.4766 10.59 10.5551 10.5544 10.6222C10.3199 11.0352 10.0926 11.4524 9.86332 11.8686C10.0958 11.8626 10.3284 11.8535 10.5606 11.8688C10.8933 11.9105 11.2236 11.9841 11.5372 12.1032C12.0739 12.2972 12.5737 12.5802 13.1182 12.7535C13.5781 12.898 14.0885 12.8767 14.5307 12.6822C14.5346 12.675 14.5422 12.66 14.5461 12.6525C14.4677 12.4712 14.3535 12.307 14.2665 12.1296C14.0021 11.65 13.7408 11.1686 13.4755 10.6892C13.4315 10.6089 13.4016 10.5196 13.4037 10.4271C13.4021 9.02535 13.4042 7.62381 13.4027 6.22201C12.4643 6.21994 11.5263 6.21994 10.588 6.22201Z"
      fill="white"
    />
  </svg>
);
export default LabFillblueIcon;
