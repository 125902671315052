import { request } from 'helpers/api';

export const getAllConsumers = (params) => request({
  method: 'GET',
  url: '/api/consumers',
  params,
});

export const addConsumer = (params) => request({
  method: 'POST',
  url: '/api/consumers',
  params,
});

export const updateConsumer = (params, consumerId) => request({
  method: 'PUT',
  url: `/api/consumers/${consumerId}`,
  params,
});

export const getConsumerDetails = (consumerId, params = {}) => request({
  method: 'GET',
  url: `/api/consumers/${consumerId}`,
  params,
});
