import { request } from 'helpers/api';

export const login = (params) => request({
  method: 'POST',
  url: '/api/login',
  params,
  contentType: 'json',
});

export const logout = () => request({
  method: 'DELETE',
  url: '/api/logout',
  params: {},
  contentType: 'json',
});

export const fetchLoggedInUserDetails = () => request({
  method: 'GET',
  url: '/api/profile',
});

export const sendPasswordResetLink = (params) => request({
  method: 'POST',
  url: '/api/send-reset-password-email',
  params,
});
export const resetPassword = (params) => request({
  method: 'PUT',
  url: '/api/reset-password',
  params,
});

export const twoFactorLogin = (params) => request({
  method: 'POST',
  url: '/api/2fa',
  params,
});
