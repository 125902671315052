import React, { useEffect, useState } from 'react';
import Table from 'reactstrap/lib/Table';
import PaginationComponent from 'components/shared/pagination';

import Loader from 'components/shared/loader';

/* Sample Structure
  <ListingComponent
    mappings={[
      {
        key: 'id',
        label: 'User Id',
        sortable: true,
      },
      {
        key: 'name',
        label: 'User Name',
      },
      {
        key: 'actions',
        label: 'Actions',
        render: (data) => (
          <React.Fragment>
            <button onClick={() => alert(data)} type="button">
              Click Me
            </button>
            <button onClick={() => alert(data)} type="button">
              Click Me
            </button>
          </React.Fragment>
        ),
      },
    ]}
    className="tableClassName"
    dataSource={dataSource}
    onSortChange={alert}
  />;
*/

const ListingComponent = (props) => {
  const {
    mappings = [],
    dataSource = [],
    isLoading = false,
    meta = null,
    shouldPaginate = false,
    defaultSort = '',
    onPageChange,
    onSortChange,
    primaryKey = null,
    renderExpanded,
    reset,
  } = props;

  const [sort, setSort] = useState(defaultSort);
  const [expanded, setExpanded] = useState({});

  const setExpand = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    if (reset) {
      setSort('');
    }
  }, [reset]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Table responsive borderless className="table__global">
            <thead>
              <tr>
                {mappings
                  && mappings.map((cell) => (
                    <React.Fragment key={cell.id ? cell.id : cell.key}>
                      {cell.sortable ? (
                        <th
                          onClick={() => {
                            const key = sort === cell.sortKey
                              ? `-${cell.sortKey}`
                              : cell.sortKey;
                            setSort(key);
                            onSortChange && onSortChange(key);
                          }}
                          className={`${cell.className ?? ''} sortable ${
                            sort === cell.sortKey ? 'active' : ''
                          } ${
                            sort === `-${cell.sortKey}` ? 'active reverse' : ''
                          }`}
                        >
                          {cell.label}
                        </th>
                      ) : (
                        <th className={cell.className}>{cell.label}</th>
                      )}
                    </React.Fragment>
                  ))}
              </tr>
            </thead>
            {dataSource && dataSource.length === 0 ? (
              <caption className="navbar-brand">
                <div className="no__data__message">
                  No Data Found
                </div>
              </caption>
            ) : (
              <tbody>
                {dataSource.length > 0
                  && dataSource.map((data) => (
                    <React.Fragment key={data[primaryKey]}>
                      <tr
                        className={`${
                          expanded[data[primaryKey]] ? 'expanded' : ''
                        }`}
                      >
                        {mappings
                          && mappings.map((cell) => (
                            <td
                              key={cell.id ? cell.id : cell.key}
                              className={cell.className}
                            >
                              {cell.type === 'expand' ? (
                                <React.Fragment>
                                  {primaryKey
                                    && data[primaryKey]
                                    && renderExpanded && (
                                      <div
                                        className={`row-detail ${
                                          expanded[data[primaryKey]]
                                            ? 'expanded'
                                            : ''
                                        }`}
                                        onClick={() => setExpand(data[primaryKey])}
                                        role="presentation"
                                      />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {cell.render
                                    ? cell.render(data)
                                    : data[cell.key]}
                                </React.Fragment>
                              )}
                            </td>
                          ))}
                      </tr>
                      {data[primaryKey]
                        && expanded[data[primaryKey]]
                        && renderExpanded && (
                          <tr className="expanded-row">
                            <td colSpan={mappings.length}>
                              {renderExpanded(data)}
                            </td>
                          </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            )}
          </Table>
          {shouldPaginate && meta && meta.total > 0 ? (
            <PaginationComponent onPageChange={onPageChange} meta={meta} />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default ListingComponent;
