import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { pick, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import ListingComponent from 'components/shared/listing-component';
import Filter from 'components/shared/filter/filter';
import AddEditTestKit from 'components/shared/modals/add-edit-test-kit-modal';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';

import { ApprovedIcon, DotIcon, DeactivateIcon } from 'assets/images/svg-icons';

import { getLabTestKits } from 'services/lab-management';
import { getKitVariantDetails, updateKitVariant } from 'services/test-kit-variants';
import { LookupContext } from 'context/lookup-context/lookup-context';
import { filterOptions } from 'helpers/utils';
import catchHandler from 'helpers/catch-handler';
import { LAB_ADD_KIT_EVENT_LISTENER } from 'config';

const LabTestKits = () => {
  const { labId } = useParams();
  const lookupData = useContext(LookupContext);

  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState({});
  const [labTestKits, setLabTestKits] = useState({});
  const [modalInfo, setModalInfo] = useState({});
  const [editItem, setEditItem] = useState({});
  const [isEditModalLoading, setIsEditModalLoading] = useState({});
  const [showEditTestKitModal, setShowEditTestKitModal] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'active' ? 'Deactivate' : 'Activate'}
        {' '}
        <span>Kit Variant</span>
      </>
    ),
    confirmationText: `Please confirm ${
      updateStatusItem?.status_slug === 'active' ? 'deactivating' : 'activating'
    }
     ${updateStatusItem?.product_name}.`,
  };

  const fetchLabTestKits = useCallback(
    (params) => {
      setIsLoading(true);
      getLabTestKits(labId, params)
        .then((response) => {
          setLabTestKits(response.data.data);
          setMeta(response.data.meta);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [labId],
  );

  const getKitVariantStatus = (kitVariantStatus) => {
    switch (kitVariantStatus) {
      case 'active':
        return <ApprovedIcon className="table__status-icon" />;
      case 'deactivate':
        return <DeactivateIcon className="table__status-icon" />;
      default:
        return null;
    }
  };

  const editKitHandler = (item) => {
    setEditItem({});
    setIsEditMode(true);
    setShowEditTestKitModal(true);
    setIsEditModalLoading(true);
    getKitVariantDetails(item?.product_variant_id)
      .then((res) => {
        setEditItem(res.data);
        setIsEditModalLoading(false);
      })
      .catch(() => {
        setIsEditModalLoading(false);
      });
  };

  const formSubmitHandler = (formData, setError) => {
    const payload = {
      price: formData?.price,
      phlebotomy_cost: formData?.phlebotomy_cost,
      turnaround_time: formData?.turnaround_time?.value,
      bio_markers: (formData?.bio_markers || []).map(
        (kitBioMarker) => kitBioMarker?.value,
      ),
      status: formData?.status?.value,
    };
    setEditButtonLoading(true);
    updateKitVariant(payload, editItem?.product_variant_id)
      .then(() => {
        fetchLabTestKits(filter);
        setShowEditTestKitModal(false);
        setEditButtonLoading(false);
        setEditItem({});
        setIsEditMode(false);
      })
      .catch((error) => {
        setEditButtonLoading(false);
        setIsEditMode(false);
        catchHandler(error, setError, true);
      });
  };

  const kitStatusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModal('kit-status-update');
  };

  const confirmStatusUpdateHandler = () => {
    setIsConfirmationLoading(true);
    updateKitVariant(
      {
        status:
          updateStatusItem?.status_slug === 'active' ? 'deactivated' : 'active',
      },
      updateStatusItem?.product_variant_id,
    )
      .then(() => {
        setUpdateStatusItem({});
        setIsConfirmationLoading(false);
        setConfirmationModal('');
        fetchLabTestKits(filter);
      })
      .catch(() => {
        setIsConfirmationLoading(false);
      });
  };

  useEffect(() => {
    setModalInfo({
      title: 'Kit Variant',
      lab: {
        labName: !isEmpty(editItem) ? editItem?.lab_name : '',
        description: '',
        title: '',
      },
      kit: {
        kitName: !isEmpty(editItem)
          ? `${editItem?.category_name} / ${editItem?.product_name}`
          : '',
        title: '',
        description: '',
      },
      className: 'addkit edit-textkit',
      cancelButtonText: 'Close',
      successButtonText: 'Save Changes',
    });
  }, [editItem]);

  useEffect(() => {
    fetchLabTestKits(filter);
    const addKitEvent = new Event(LAB_ADD_KIT_EVENT_LISTENER);
    document.addEventListener(LAB_ADD_KIT_EVENT_LISTENER, () => {
      fetchLabTestKits(filter);
    });
    return () => {
      document.removeEventListener(addKitEvent, null);
    };
  }, [fetchLabTestKits, filter]);

  const mappings = [
    {
      key: 'product_variant_id',
      label: 'ID',
      render: (item) => (
        <span className="inline__list inline__list--regular blue">
          {item.product_variant_id}
        </span>
      ),
    },
    {
      key: 'kit_category',
      label: 'KIT CATEGORY / NAME',
      render: (item) => (
        <div className="table__main-content">
          <div className="table__main-title">{item.category_name}</div>
          <span className="table__main-text inline__list inline__list--regular blue">
            {item.product_name}
          </span>
        </div>
      ),
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <div className="table__status">
          {getKitVariantStatus(item.status_slug)}
          {item?.status}
        </div>
      ),
    },
    {
      key: 'price',
      label: 'PRICE',
      className: 'text-center',
      render: (item) => (
        <>
          $
          {' '}
          {item.price}
        </>
      ),
    },
    {
      key: 'bio_markers_count',
      label: 'BIO',
      className: 'text-center',
    },
    {
      key: 'turnaround_time',
      label: 'TA TIME',
      className: 'text-center',
    },
    {
      key: 'sold',
      label: 'SOLD',
      className: 'text-center',
    },
    {
      key: 'action',
      label: 'ACTION',
      className: 'text-center',
      render: (item) => (
        <div className="table__action">
          <UncontrolledDropdown>
            <DropdownToggle className="button__action">
              <DotIcon />
            </DropdownToggle>
            <DropdownMenu className="table__dropdown">
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular"
                  onClick={() => editKitHandler(item)}
                >
                  Edit Test Kit
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="div">
                <Button
                  className="inline__list inline__list--regular red"
                  onClick={() => kitStatusUpdateHandler(item)}
                >
                  {item?.status_slug === 'active'
                    ? 'Deactivate Test Kit'
                    : 'Activate Test Kit'}
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  return (
    <div className="tab__table">
      <Filter
        filters={[
          {
            filter_type: 'select',
            name: 'category',
            options: filterOptions(
              lookupData?.lookupDetails?.productCategoriesOptions,
              'All',
            ),
            placeholder: 'Category',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'select',
            name: 'status',
            options: filterOptions(
              lookupData?.lookupDetails?.kitStatusOptions,
              'All Status',
            ),
            placeholder: 'All Status',
            inputProps: {
              className: 'selectbox',
              components: {
                IndicatorSeparator: () => null,
              },
            },
          },
          {
            filter_type: 'button',
            name: 'submit',
            inputProps: {
              className: 'table__button',
            },
          },
        ]}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
        onPerPageChange={(perPage, page) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        meta={meta}
      />
      <ListingComponent
        isLoading={isLoading}
        mappings={mappings}
        meta={meta}
        onPageChange={(page, perPage) => setFilter((prev) => ({
          ...prev,
          page,
          per_page: perPage,
        }))}
        dataSource={labTestKits}
        shouldPaginate
        primaryKey="product_variant_id"
      />
      {showEditTestKitModal ? (
        <AddEditTestKit
          hideModal={() => {
            setShowEditTestKitModal(false);
            setEditItem({});
            setIsEditMode(false);
          }}
          modalInfo={modalInfo}
          editItem={editItem}
          onSuccessHandler={(formData, setError) => formSubmitHandler(formData, setError)}
          isEditModal={isEditMode}
          isEditModalLoading={isEditModalLoading}
          addEditButtonLoading={editButtonLoading}
        />
      ) : (
        ''
      )}
      {confirmationModal ? (
        <ConfirmationModal
          hideModal={() => setConfirmationModal('')}
          renderHtml={confirmDeactivateHtml}
          successButtonText="Confirm"
          successHandler={() => confirmStatusUpdateHandler()}
          cancelButtonText="Cancel"
          isLoading={isConfirmationLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default LabTestKits;
