import React from 'react';

const ScheduleTimeIcon = (props) => (
  <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="7" fill="#0047BA" fillOpacity="0.05" />
    <path d="M27.6126 12.3955C26.1083 10.8903 24.1914 9.86505 22.1044 9.4494C20.0174 9.03375 17.8539 9.2464 15.8878 10.0605C13.9216 10.8745 12.241 12.2534 11.0586 14.0227C9.87623 15.792 9.24512 17.8722 9.24512 20.0002C9.24512 22.1282 9.87623 24.2084 11.0586 25.9777C12.241 27.747 13.9216 29.1259 15.8878 29.9399C17.8539 30.754 20.0174 30.9666 22.1044 30.551C24.1914 30.1353 26.1083 29.1101 27.6126 27.6049C28.6179 26.6099 29.4159 25.4256 29.9605 24.1202C30.5052 22.8149 30.7856 21.4146 30.7856 20.0002C30.7856 18.5858 30.5052 17.1855 29.9605 15.8801C29.4159 14.5748 28.6179 13.3905 27.6126 12.3955ZM26.2412 26.2409C25.0093 27.4685 23.4413 28.3036 21.7351 28.6407C20.0289 28.9777 18.261 28.8017 16.6548 28.1347C15.0486 27.4678 13.6761 26.3399 12.7104 24.8934C11.7448 23.4469 11.2295 21.7467 11.2295 20.0075C11.2295 18.2684 11.7448 16.5682 12.7104 15.1217C13.6761 13.6752 15.0486 12.5473 16.6548 11.8803C18.261 11.2134 20.0289 11.0373 21.7351 11.3744C23.4413 11.7115 25.0093 12.5465 26.2412 13.7742C27.0616 14.5917 27.7126 15.5632 28.1567 16.6329C28.6009 17.7025 28.8295 18.8493 28.8295 20.0075C28.8295 21.1657 28.6009 22.3125 28.1567 23.3822C27.7126 24.4519 27.0616 25.4233 26.2412 26.2409ZM20.9832 20.4182V15.0502C20.9832 14.7935 20.8812 14.5472 20.6997 14.3657C20.5182 14.1842 20.272 14.0822 20.0152 14.0822C19.7585 14.0822 19.5123 14.1842 19.3307 14.3657C19.1492 14.5472 19.0472 14.7935 19.0472 15.0502V20.2349L16.4732 22.8089C16.3823 22.8977 16.3101 23.0037 16.2607 23.1209C16.2114 23.238 16.186 23.3638 16.186 23.4909C16.186 23.6179 16.2114 23.7437 16.2607 23.8609C16.3101 23.978 16.3823 24.0841 16.4732 24.1729C16.5619 24.2639 16.6679 24.3361 16.7851 24.3853C16.9023 24.4344 17.0282 24.4594 17.1552 24.4589C17.4115 24.4575 17.6567 24.3547 17.8372 24.1729L20.6972 21.3129C20.7984 21.2093 20.8753 21.0844 20.9222 20.9475C20.9691 20.8105 20.985 20.6647 20.9686 20.5209C20.9775 20.4873 20.9825 20.4529 20.9832 20.4182Z" fill="#0047BA" stroke="#0047BA" />
  </svg>

);
export default ScheduleTimeIcon;
