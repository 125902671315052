import React from 'react';

const PendingIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="5" fill="#FFC700" />
    <path
      d="M14.0186 5.72C13.1981 4.89898 12.1525 4.33974 11.0141 4.11302C9.87576 3.88631 8.69572 4.0023 7.62326 4.44633C6.55081 4.89035 5.63414 5.64247 4.98919 6.60753C4.34424 7.5726 4 8.70726 4 9.868C4 11.0287 4.34424 12.1634 4.98919 13.1285C5.63414 14.0935 6.55081 14.8457 7.62326 15.2897C8.69572 15.7337 9.87576 15.8497 11.0141 15.623C12.1525 15.3963 13.1981 14.837 14.0186 14.016C14.5669 13.4733 15.0022 12.8273 15.2993 12.1153C15.5964 11.4033 15.7494 10.6395 15.7494 9.868C15.7494 9.09652 15.5964 8.33269 15.2993 7.6207C15.0022 6.90871 14.5669 6.26269 14.0186 5.72ZM13.2706 13.272C12.5986 13.9416 11.7433 14.3971 10.8127 14.581C9.88204 14.7648 8.91777 14.6688 8.04166 14.305C7.16555 13.9412 6.41687 13.326 5.89018 12.537C5.36348 11.748 5.08239 10.8206 5.08239 9.872C5.08239 8.92336 5.36348 7.99599 5.89018 7.207C6.41687 6.418 7.16555 5.80277 8.04166 5.43898C8.91777 5.07519 9.88204 4.97916 10.8127 5.16302C11.7433 5.34688 12.5986 5.80238 13.2706 6.472C13.7181 6.91794 14.0732 7.44782 14.3154 8.03127C14.5577 8.61472 14.6824 9.24025 14.6824 9.872C14.6824 10.5037 14.5577 11.1293 14.3154 11.7127C14.0732 12.2962 13.7181 12.8261 13.2706 13.272ZM10.4026 10.096V7.168C10.4026 7.02797 10.347 6.89367 10.248 6.79465C10.1489 6.69563 10.0146 6.64 9.8746 6.64C9.73457 6.64 9.60027 6.69563 9.50125 6.79465C9.40223 6.89367 9.3466 7.02797 9.3466 7.168V9.996L7.9426 11.4C7.89302 11.4484 7.85361 11.5063 7.82671 11.5702C7.7998 11.6341 7.78594 11.7027 7.78594 11.772C7.78594 11.8413 7.7998 11.9099 7.82671 11.9738C7.85361 12.0377 7.89302 12.0956 7.9426 12.144C7.99096 12.1937 8.04882 12.2331 8.11273 12.2599C8.17664 12.2867 8.2453 12.3003 8.31461 12.3C8.45436 12.2993 8.58814 12.2432 8.6866 12.144L10.2466 10.584C10.3018 10.5275 10.3437 10.4594 10.3693 10.3847C10.3949 10.31 10.4036 10.2305 10.3946 10.152C10.3995 10.1337 10.4022 10.1149 10.4026 10.096Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
  </svg>
);
export default PendingIcon;
