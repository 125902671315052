import { request } from 'helpers/api';

export const getAllTestResults = (params) => request({
  method: 'GET',
  url: '/api/test-results',
  params,
});

export const viewTestResult = (id) => request({
  method: 'GET',
  url: `/api/test-results/${id}`,
});

export const updateTestResult = (id, params) => request({
  method: 'PUT',
  url: `/api/test-results/${id}`,
  params,
});
