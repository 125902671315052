import React from 'react';

const MyHomeLabIcon = (props) => (
  <svg
    {...props}
    width="54"
    height="32"
    viewBox="0 0 54 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7062 0.266179C16.4933 0.0898195 18.2982 0.257646 20.0824 0.0281885C20.6154 -0.0486134 21.2133 0.0187068 21.6326 0.39039C21.8704 0.590454 21.9813 0.894817 22.033 1.19444C22.5435 3.92423 23.1752 6.63031 23.9066 9.307C25.216 7.90465 26.455 6.43404 27.6066 4.8961C27.9272 4.46942 28.227 3.98206 28.7281 3.74976C29.357 3.43118 30.1974 3.63883 30.5979 4.22669C30.9175 4.66664 30.9513 5.22796 31.0293 5.74851C31.4505 8.50484 32.384 11.173 33.7019 13.6212C34.0385 14.2119 33.9905 15.0103 33.5374 15.5251C32.8944 16.3235 31.4928 16.2078 30.9889 15.3127C29.8834 13.3035 29.0571 11.1427 28.4762 8.92204C21.7078 17.1654 12.609 23.4054 2.57673 26.8662C2.27403 26.9648 1.97603 27.0985 1.65453 27.1212C0.779342 27.1573 -0.0450849 26.358 0.00191782 25.4648C0.0169587 24.7356 0.581931 24.1127 1.26159 23.9212C6.80133 22.0855 12.0111 19.2837 16.6888 15.7802C15.1377 11.3522 13.9796 6.78202 13.2661 2.14072C13.2003 1.69887 13.2623 1.2172 13.5415 0.85689C13.8141 0.490896 14.2644 0.306003 14.7062 0.266179ZM16.4237 3.14673C17.0695 6.76685 17.9852 10.3386 19.1452 13.826C19.9828 13.1234 20.7978 12.3942 21.5903 11.6404C21.4643 11.4243 21.3449 11.2005 21.28 10.9578C20.5449 8.3484 19.9123 5.70963 19.3887 3.04906C18.4007 3.09363 17.4117 3.11638 16.4237 3.14673Z"
      fill="#294F9D"
    />
    <path
      d="M51.7125 8.52954C52.6206 8.20811 53.6678 8.96285 53.7421 9.91102C53.8295 10.6895 53.2617 11.4272 52.5332 11.6386C45.6576 13.9227 39.2765 17.6566 33.8082 22.4297C30.9881 24.8969 28.4151 27.6532 26.1628 30.6561C25.8394 31.0828 25.5461 31.572 25.0488 31.8138C24.4603 32.1239 23.6754 31.9731 23.2448 31.4639C22.9619 31.1425 22.8434 30.7139 22.7899 30.2977C22.4101 27.4285 21.4804 24.6409 20.122 22.0941C19.8484 21.6409 19.7328 21.0729 19.9208 20.5637C20.1239 19.9455 20.7434 19.4989 21.3911 19.5141C21.9654 19.5027 22.5436 19.8185 22.8077 20.3409C23.886 22.3321 24.7038 24.4626 25.2754 26.6567C32.1631 18.2654 41.4678 11.9496 51.7125 8.52954Z"
      fill="#B23035"
    />
  </svg>
);
export default MyHomeLabIcon;
