import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

const DropZone = (props) => {
  const { onChange, uploadBoundary, customizeOutputHandler } = props;
  const onDrop = (acceptedFiles) => {
    if (customizeOutputHandler) {
      customizeOutputHandler(acceptedFiles);
    } else {
      onChange(acceptedFiles);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });
  return (
    <React.Fragment>
      <div {...getRootProps()}>
        <input {...getInputProps({ onChange })} />
        {uploadBoundary}
      </div>
    </React.Fragment>
  );
};

const FileUploader = (props) => {
  const {
    name: propName,
    uploadBoundary,
    customizeOutputHandler,
  } = props;
  const {
    control,
  } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propName}
        render={({ field: { onChange } }) => (
          <DropZone
            onChange={onChange}
            uploadBoundary={uploadBoundary}
            customizeOutputHandler={customizeOutputHandler}
          />
        )}
      />
    </React.Fragment>
  );
};
export default FileUploader;
