import React from 'react';

const SellingKitIcon = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#0047BA" />
    <g opacity="0.8">
      <path
        d="M6.11848 6.01266C6.3265 5.98797 6.53848 6.00895 6.74824 6.00204C6.75469 8.82753 6.74898 11.653 6.75122 14.4785C6.74898 15.5256 6.75469 16.5729 6.74824 17.6198C6.53873 17.6116 6.3265 17.6344 6.11848 17.6089C6.04401 17.6045 5.9683 17.6052 5.89632 17.5832C5.85288 17.5724 5.81018 17.5593 5.76749 17.5457C5.73621 17.5312 5.70444 17.5178 5.67291 17.5042C5.56592 17.4534 5.46564 17.3882 5.37801 17.3087C5.37181 17.3015 5.3594 17.287 5.35319 17.2796L5.34252 17.2806C5.28791 17.2104 5.22436 17.147 5.17819 17.0704C5.16255 17.0433 5.14642 17.0164 5.13028 16.9897C5.11067 16.9509 5.0923 16.9117 5.07642 16.8714C5.02032 16.7307 4.99822 16.5789 5.00021 16.4283C4.99996 13.3502 4.99996 10.2721 5.00021 7.19406C4.99748 7.04296 5.02131 6.8921 5.07518 6.75063C5.09826 6.69878 5.12259 6.64768 5.14468 6.59533C5.14964 6.5941 5.15982 6.59138 5.16479 6.59015L5.16156 6.57114C5.21642 6.4904 5.28021 6.41633 5.34202 6.34103L5.35319 6.34177C5.35915 6.33461 5.37156 6.32004 5.37777 6.31288C5.48997 6.20845 5.626 6.13438 5.76724 6.07636C5.81018 6.06253 5.85337 6.0487 5.89706 6.0371C5.96855 6.01513 6.04451 6.01735 6.11848 6.01266Z"
        fill="white"
      />
      <path
        d="M7.91553 6.01403C7.98478 5.99452 8.05727 6.00292 8.12826 6.00168C10.7791 6.00168 13.4299 6.00218 16.0805 6.00143C16.086 9.86982 16.0818 13.7385 16.0828 17.6068C13.3609 17.6071 10.6388 17.6071 7.91702 17.6071C7.91578 13.7427 7.91851 9.87822 7.91553 6.01403ZM10.8412 8.9084C10.8382 9.48639 10.8404 10.0641 10.8399 10.6419C10.83 10.6409 10.8099 10.6387 10.7999 10.6374L10.7965 10.6582C10.6575 10.6527 10.5182 10.6621 10.3794 10.6532L10.3539 10.6241L10.3524 10.6579C9.98079 10.6527 9.6087 10.6619 9.23735 10.6535L9.21203 10.6234L9.21103 10.6577C9.16784 10.6569 9.12515 10.6572 9.08245 10.6574C9.08543 11.4305 9.07947 12.2037 9.08543 12.977C9.66604 12.9755 10.2469 12.9765 10.8275 12.9763C10.8273 13.554 10.8287 14.1315 10.8268 14.7093C11.6087 14.7135 12.3908 14.7135 13.1725 14.7093C13.1713 14.1315 13.1723 13.554 13.172 12.9763C13.7529 12.9765 14.3335 12.9758 14.9143 12.9768C14.9205 12.2037 14.9143 11.4307 14.9176 10.6579C14.4492 10.6557 13.9808 10.6562 13.5126 10.6577C13.4977 10.6525 13.4679 10.6426 13.453 10.6377L13.4523 10.6587C13.3723 10.6562 13.2927 10.6562 13.213 10.6567C13.1988 10.6508 13.1703 10.6392 13.1561 10.6332C13.1579 10.6169 13.1618 10.5846 13.1638 10.5683L13.1909 10.551C13.1348 10.5369 13.169 10.4678 13.1594 10.4281C13.1599 9.92142 13.1609 9.41504 13.1586 8.9084C12.3861 8.90964 11.6136 8.90939 10.8412 8.9084Z"
        fill="white"
      />
      <path
        d="M17.2518 6.00183C17.4613 6.00899 17.6736 5.98751 17.8818 6.01245C17.9563 6.01739 18.0325 6.01517 18.1048 6.03763C18.1479 6.04899 18.1906 6.06257 18.2336 6.07516C18.3646 6.12973 18.4935 6.19639 18.5977 6.29367L18.6171 6.29268L18.6139 6.31144C18.6221 6.31712 18.6387 6.32798 18.6471 6.33342C18.6983 6.3865 18.7442 6.44427 18.7904 6.5018C18.8008 6.51809 18.8112 6.53439 18.8219 6.55069C18.8373 6.57735 18.8532 6.60401 18.8693 6.63068C18.8892 6.66994 18.9078 6.70993 18.9247 6.75067C18.9328 6.77536 18.9413 6.80005 18.9502 6.82449C19.0138 7.02374 18.9984 7.23459 19.0001 7.4405C18.9999 10.4366 19.0004 13.4327 18.9999 16.4288C19.0018 16.5789 18.9783 16.7295 18.9242 16.8702C18.8966 16.9399 18.8623 17.0065 18.8214 17.0695C18.7752 17.146 18.7129 17.2105 18.6578 17.2803L18.6471 17.2796C18.6409 17.2868 18.6288 17.3011 18.6226 17.3082C18.5342 17.3882 18.4337 17.4544 18.3252 17.5045C18.2944 17.5188 18.2634 17.5329 18.2331 17.5482C18.2172 17.5497 18.1854 17.5529 18.1695 17.5544L18.1703 17.5702C17.8694 17.644 17.5582 17.6119 17.2516 17.6203C17.2456 14.7455 17.2508 11.8703 17.2489 8.99546C17.2511 7.9975 17.2451 6.99954 17.2518 6.00183Z"
        fill="white"
      />
    </g>
  </svg>
);
export default SellingKitIcon;
