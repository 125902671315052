import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Form,
  ModalBody,
  Modal,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import SelectField from 'components/shared/form-fields/select-field';
import CustomRadio from 'components/shared/form-fields/custom-radio';
import DatePicker from 'components/shared/form-fields/date-picker';
import ButtonWithLoader from 'components/shared/loading-button';
import { exportCsvReport } from 'services/reports';
import { LookupContext } from 'context/lookup-context/lookup-context';

const ExportCSV = ({ closeModal }) => {
  const schema = Yup.object().shape(
    {
      startDate: Yup.date()
        .when('isCustomRageValidation', {
          is: true,
          then: Yup.date().max(
            Yup.ref('endDate'),
            'Start date cannot be after end date',
          ),
        })
        .when('dateRange', {
          is: 'custom_range',
          then: Yup.date().required('This field is required').nullable(),
        }),
      endDate: Yup.date()
        .when('isCustomRageValidation', {
          is: true,
          then: Yup.date().min(
            Yup.ref('startDate'),
            "End date can't be before Start date",
          ),
        })
        .when('dateRange', {
          is: 'custom_range',
          then: Yup.date().required('This field is required').nullable(),
        }),
      preDefinedOptions: Yup.object()
        .when('dateRange', {
          is: 'pre_defined_options',
          then: Yup.object().required('This field is required').nullable(),
        })
        .nullable(),
    },
  );

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      reportType: 'sales-by-testkit',
      dateRange: 'pre_defined_options',
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    clearErrors,
    watch,
    register,
    setValue,
  } = methods;

  const reportType = watch('reportType');
  const dateRange = watch('dateRange');
  const { startDate, endDate } = watch();
  const [isLoading, setLoading] = useState();

  const { lookupDetails } = useContext(LookupContext);

  useEffect(() => {
    register('isCustomRageValidation', { value: true });
  }, [register]);

  useEffect(() => {
    if (dateRange === 'custom_range' && (startDate || endDate)) {
      setValue('isCustomRageValidation', true);
    } else if (dateRange === 'pre_defined_options') {
      setValue('isCustomRageValidation', false);
    }
  }, [setValue, dateRange, startDate, endDate]);

  useEffect(() => {
    if (
      dateRange === 'pre_defined_options'
      && (!isEmpty(errors.startDate) || !isEmpty(errors.endDate))
    ) {
      clearErrors(['startDate', 'endDate']);
    } else if (
      dateRange === 'custom_range'
      && !isEmpty(errors.preDefinedOptions)
    ) {
      clearErrors('preDefinedOptions');
    }
  }, [clearErrors, dateRange, errors.startDate, errors.endDate, errors]);

  const getStartDate = (preDefinedOption) => {
    switch (preDefinedOption) {
      case 'this-month':
        return moment().startOf('month').format('YYYY-MM-DD');
      case 'last-month':
        return moment().subtract(30, 'days').format('YYYY-MM-DD');
      case 'last-6-month':
        return moment().subtract(6, 'months').format('YYYY-MM-DD');
      case 'this-year':
        return moment().startOf('year').format('YYYY-MM-DD');
      case 'last-1-year':
        return moment().subtract(1, 'year').format('YYYY-MM-DD');
      default:
        return '';
    }
  };

  const submitForm = (formData) => {
    const payload = {
      report_type: formData?.reportType,
    };

    if (formData?.dateRange === 'custom_range') {
      payload.from_date = moment(formData?.startDate).format('YYYY-MM-DD');
      payload.to_date = moment(formData?.endDate).format('YYYY-MM-DD');
    } else {
      payload.from_date = getStartDate(formData?.preDefinedOptions?.value);
      payload.to_date = moment().format('YYYY-MM-DD');
    }
    setLoading(true);
    exportCsvReport(payload)
      .then((res) => {
        setLoading(false);
        if (res?.data) {
          const blob = new Blob([res?.data], { type: 'image/svg+xml' });
          const convertedSvg = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = convertedSvg;
          a.setAttribute('download', 'MHL_Report.csv');
          a.setAttribute('target', '_blank');
          a.click();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data?.message || 'Something went wrong!');
      });
  };

  return (
    <Modal
      isOpen
      toggle={() => closeModal()}
      centered
      className="report__modal"
    >
      <ModalBody>
        <div className="modal__head">
          Export
          {' '}
          <span>CSV Report</span>
        </div>
        <FormProvider {...methods}>
          <Form
            className="modal__form"
            onSubmit={methods.handleSubmit(submitForm)}
          >
            <div className="report__modal-section">
              <h2 className="report__modal-head">Select Report Type</h2>
              <Row className="report__modal-form">
                {lookupDetails?.report_types?.map((type) => (
                  <Col xs="12" md="6" key={type?.slug}>
                    <FormGroup check>
                      <Label check>
                        <CustomRadio
                          type="radio"
                          name="reportType"
                          value={type?.slug}
                          checked={reportType === type?.slug}
                          label={type?.label}
                        />
                      </Label>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="report__modal-section">
              <h2 className="report__modal-head">Select Date Range</h2>
              <Row className="report__modal-form">
                <Col xs="12" md="8">
                  <FormGroup check>
                    <Label check>
                      <CustomRadio
                        type="radio"
                        name="dateRange"
                        value="pre_defined_options"
                        checked={dateRange === 'pre_defined_options'}
                        label="Pre-defined Options"
                      />
                    </Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <SelectField
                    options={lookupDetails?.reportFilter}
                    className="selectbox"
                    name="preDefinedOptions"
                    placeholder="Select Options"
                    classNamePrefix="selectbox"
                    isDisabled={dateRange === 'custom_range'}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Col>
                <Col xs="12" md="4">
                  <FormGroup check>
                    <Label check>
                      <CustomRadio
                        type="radio"
                        name="dateRange"
                        value="custom_range"
                        checked={dateRange === 'custom_range'}
                        label="Select Custom Range"
                      />
                    </Label>
                  </FormGroup>
                </Col>
                <Col xs="12" md="4">
                  <DatePicker
                    name="startDate"
                    placeholder="Start Date"
                    disabled={dateRange === 'pre_defined_options'}
                  />
                </Col>
                <Col xs="12" md="4">
                  <DatePicker
                    name="endDate"
                    placeholder="End Date"
                    disabled={dateRange === 'pre_defined_options'}
                  />
                </Col>
              </Row>
            </div>
            <div className="modal__btn d-flex justify-content-center align-items-center">
              <Button color="primary outline" onClick={() => closeModal()}>
                Close
              </Button>
              <ButtonWithLoader
                isLoading={isLoading}
                color="secondary"
                type="submit"
              >
                Download CSV
              </ButtonWithLoader>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default ExportCSV;
