import React from 'react';

const ChevronLeftIcon = (props) => (
  <svg
    {...props}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75546 1.39968L2.06293 6L6.75546 10.6003C6.91209 10.7545 7 10.9631 7 11.1805C7 11.3979 6.91209 11.6065 6.75546 11.7607C6.59789 11.914 6.38475 12 6.16258 12C5.94042 12 5.72727 11.914 5.56971 11.7607L0.221242 6.52669C0.0795015 6.38649 -2.00502e-08 6.19722 -1.94121e-08 6C-1.8774e-08 5.80278 0.0795016 5.61351 0.221242 5.47331L5.56971 0.239309C5.72727 0.086033 5.94042 0 6.16258 0C6.38475 0 6.59789 0.086033 6.75546 0.239309C6.91209 0.3935 7 0.60208 7 0.819493C7 1.03691 6.91209 1.24549 6.75546 1.39968Z"
      fill="#444444"
    />
  </svg>
);
export default ChevronLeftIcon;
