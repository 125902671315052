import React from 'react';

const DeactivateIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="5" fill="#C10230" />
    <path
      d="M13.7798 12.7198L11.06 10L13.7798 7.28023C14.0734 6.98659 14.0734 6.51386 13.7798 6.22023C13.4861 5.92659 13.0134 5.92659 12.7198 6.22023L10 8.94L7.28023 6.22023C6.98659 5.92659 6.51386 5.92659 6.22023 6.22023C5.92659 6.51386 5.92659 6.98659 6.22023 7.28023L8.94 10L6.22023 12.7198C5.92659 13.0134 5.92659 13.4861 6.22023 13.7798C6.51386 14.0734 6.98659 14.0734 7.28023 13.7798L10 11.06L12.7198 13.7798C13.0134 14.0734 13.4861 14.0734 13.7798 13.7798C14.0713 13.4861 14.0713 13.0113 13.7798 12.7198Z"
      fill="white"
    />
  </svg>
);
export default DeactivateIcon;
