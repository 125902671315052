import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  Label,
  Form,
  FormGroup,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import CustomNumberField from 'components/shared/form-fields/custom-number-field';
import ButtonWithLoader from 'components/shared/loading-button';
import Loader from 'components/shared/loader';

import { ShowPasswordIcon, HidePasswordIcon } from 'assets/images/svg-icons';

import { updateUserProfile } from 'services/my-account';
import { fetchLoggedInUserDetails } from 'services/auth';
import { AuthContext } from 'context/auth-context/auth-context';
import HeaderText from 'header-context';
import catchHandler from 'helpers/catch-handler';

const schema = Yup.object().shape({
  first_name: Yup.string().required('FirstName is required'),
  last_name: Yup.string().required('LastName is required'),
  phone_number: Yup.string().required('Phone Number is required'),
  email: Yup.string()
    .email('Enter a valid Email')
    .required('Email is required'),
  current_password: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string().required('Password is required'),
  }),
  new_password: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase and One Special Case Character',
      ),
  }),
  password_confirmation: Yup.string().when('isPasswordRequired', {
    is: true,
    then: Yup.string()
      .oneOf([Yup.ref('new_password')], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
});

const MyAccount = () => {
  const [, setHeader] = useContext(HeaderText);
  const { getLoggedInUserDetails } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const buildFormData = (currentUser) => {
    const formData = {
      first_name: currentUser?.first_name || '',
      last_name: currentUser?.last_name || '',
      email: currentUser?.email || '',
      phone_number: currentUser?.phone_number || '',
    };
    return formData;
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(userDetails),
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit, watch, reset, setValue, register, setError, formState: { errors },
  } = methods;

  const formFields = watch();

  const fetchUserDetails = useCallback(() => {
    setIsLoading(true);
    fetchLoggedInUserDetails()
      .then((res) => {
        setUserDetails(res?.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (formData) => {
    setIsButtonLoading(true);
    const payload = {
      ...(formData?.first_name ? { first_name: formData?.first_name } : ''),
      ...(formData?.last_name ? { last_name: formData?.last_name } : ''),
      ...(formData?.email ? { email: formData?.email } : ''),
      ...(formData?.phone_number
        ? { phone_number: formData?.phone_number }
        : ''),
      ...(formData?.current_password
        ? { current_password: formData.current_password }
        : ''),
      ...(formData?.new_password
        ? { new_password: formData.new_password }
        : ''),
      ...(formData?.password_confirmation
        ? { password_confirmation: formData.password_confirmation }
        : ''),
    };
    updateUserProfile(payload)
      .then(() => {
        toast.success('Account Info updated successfully');
        getLoggedInUserDetails();
        setIsButtonLoading(false);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  useEffect(() => {
    register('isPasswordRequired', { value: true });
  }, [register]);

  useEffect(() => {
    if (
      formFields.current_password
      || formFields.new_password
      || formFields.password_confirmation
    ) {
      setValue('isPasswordRequired', true);
    } else if (
      !formFields.current_password
      || !formFields.new_password
      || !formFields.password_confirmation
    ) {
      setValue('isPasswordRequired', false);
    }
  }, [
    setValue,
    formFields.current_password,
    formFields.new_password,
    formFields.password_confirmation,
  ]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      reset(buildFormData(userDetails));
    }
  }, [reset, userDetails]);

  useEffect(() => {
    setHeader('My account');
  }, [setHeader]);

  return (
    <div className="order-detail my-account">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboard__whitebox dashboard__whitebox--wrap order-detail__wraper">
          <div className="pay-out">
            <h2 className="details__sub-head">
              General
              {' '}
              <span>Information</span>
            </h2>
            <FormProvider {...methods}>
              <Form className="tab__form" onSubmit={handleSubmit(onSubmit)}>
                <Row className="tab__form-sec">
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>First Name</Label>
                      <CustomInputField
                        className="form-control"
                        type="text"
                        name="first_name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Last Name</Label>
                      <CustomInputField
                        className="form-control"
                        type="text"
                        name="last_name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <CustomNumberField
                        format="(###) ### ####"
                        className="form-control"
                        type="text"
                        name="phone_number"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Email Address</Label>
                      <CustomInputField
                        className="form-control"
                        type="email"
                        name="email"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="tab__form-sec">
                  <h2 className="tab__head">
                    Change
                    {' '}
                    <span>Password</span>
                  </h2>
                  <Col xs="12">
                    <FormGroup>
                      <Label>Current Password</Label>
                      <div
                        className={`view-password ${
                          !isEmpty(errors?.current_password)
                            ? 'view-password--error'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          className="form-control"
                          name="current_password"
                          type={showCurrentPassword ? 'text' : 'password'}
                        />
                        <span
                          role="presentation"
                          onKeyDown={() => setShowCurrentPassword(!showCurrentPassword)}
                          onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        >
                          {showCurrentPassword ? (
                            <HidePasswordIcon />
                          ) : (
                            <ShowPasswordIcon />
                          )}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>New Password</Label>
                      <div
                        className={`view-password ${
                          !isEmpty(errors?.new_password)
                            ? 'view-password--error'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          autoComplete="new-password"
                          className="form-control"
                          type={showNewPassword ? 'text' : 'password'}
                          name="new_password"
                        />
                        <span
                          role="presentation"
                          onKeyDown={() => setShowNewPassword(!showNewPassword)}
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? (
                            <HidePasswordIcon />
                          ) : (
                            <ShowPasswordIcon />
                          )}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Confirm Password</Label>
                      <div
                        className={`view-password ${
                          !isEmpty(errors?.password_confirmation)
                            ? 'view-password--error'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          className="form-control"
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="password_confirmation"
                        />
                        <span
                          role="presentation"
                          onKeyDown={() => setShowConfirmPassword(!showConfirmPassword)}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? (
                            <HidePasswordIcon />
                          ) : (
                            <ShowPasswordIcon />
                          )}
                        </span>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center">
                  <ButtonWithLoader
                    isLoading={isButtonLoading}
                    color="secondary"
                  >
                    Save Changes
                  </ButtonWithLoader>
                </div>
              </Form>
            </FormProvider>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
