import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';

const SelectField = (props) => {
  const { control } = useFormContext();
  const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) };
  const {
    name: propsName,
    options,
    placeholder,
    defaultValue,
    showOptionCheckBoxes,
    ...otherProps
  } = props;

  const Option = (optionProps) => {
    const {
      isSelected,
      label,
    } = optionProps;
    return (
      <div>
        <components.Option {...optionProps}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            autoComplete="off"
            className="form-check-input form-check-input--rounded"
          />
          {' '}
          <label>{label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={propsName}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange, onBlur, value, name,
          },
          fieldState: {
            error,
          },
        }) => (
          <React.Fragment>
            <Select
              // closeMenuOnSelect
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              options={options}
              placeholder={placeholder || ''}
              value={value || placeholder}
              styles={showOptionCheckBoxes ? customStyles : ''}
              components={{
                DropdownIndicator: null,
                IndicatorSeparator: null,
                ...(showOptionCheckBoxes ? { Option } : {}),
              }}
              {...otherProps}
            />
            {error && error.message ? (
              <small className="error-color ml-2">
                {error.message}
              </small>
            ) : ''}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default SelectField;
