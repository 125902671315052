import React from 'react';

const CategoryIcon = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="5" fill="#0047BA" />
    <g opacity="0.8">
      <rect
        x="8.26855"
        y="7"
        width="10.2307"
        height="2.04615"
        rx="1"
        fill="white"
      />
      <rect
        x="5.2002"
        y="7"
        width="2.04615"
        height="2.04615"
        rx="1"
        fill="white"
      />
      <rect
        x="8.26855"
        y="11.0923"
        width="10.2307"
        height="2.04615"
        rx="1"
        fill="white"
      />
      <rect
        x="5.2002"
        y="11.0923"
        width="2.04615"
        height="2.04615"
        rx="1"
        fill="white"
      />
      <rect
        x="8.26855"
        y="15.1846"
        width="10.2307"
        height="2.04615"
        rx="1"
        fill="white"
      />
      <rect
        x="5.2002"
        y="15.1846"
        width="2.04615"
        height="2.04615"
        rx="1"
        fill="white"
      />
    </g>
  </svg>
);
export default CategoryIcon;
