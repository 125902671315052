import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import AddEditTestKit from 'components/shared/modals/add-edit-test-kit-modal';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import LabGeneralInfo from 'components/lab-management/lab-management-details/lab-general-info';
import LabAccountInfo from 'components/lab-management/lab-management-details/lab-account-info';
import LabManagers from 'components/lab-management/lab-management-details/lab-mangers';
import LabPhlebotomist from 'components/lab-management/lab-management-details/lab-phlebotomist';
import LabTestKits from 'components/lab-management/lab-management-details/lab-test-kits';
import LabPayoutInfo from 'components/lab-management/lab-management-details/lab-payout-Info';

import {
  AddGreenIcon,
  PendingIcon,
  DotIcon,
  ChevronLeftIcon,
  ApprovedIcon,
  DeactivateIcon,
} from 'assets/images/svg-icons';

import HeaderText from 'header-context';
import {
  getSelectedLabDetails,
  updateLabStatus,
  deleteSelectedLab,
} from 'services/lab-management';
import { addKitVariant } from 'services/test-kit-variants';
import catchHandler from 'helpers/catch-handler';
import { LAB_ADD_KIT_EVENT_LISTENER } from 'config';

const LabManagementDetails = () => {
  const history = useHistory();
  const { labId } = useParams();

  const [, setHeader] = useContext(HeaderText);
  const [activeTab, setActiveTab] = useState('1');
  const [modalInfo, setModalInfo] = useState({});
  const [showAddTestKitModal, setAddTestKitModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [selectedLab, setSelectedLab] = useState({});
  const [addKitButtonLoading, setAddKitButtonLoading] = useState(false);
  const [addKitSuccessMessage, setAddKitSuccessMessage] = useState({});
  const [updateStatusItem, setUpdateStatusItem] = useState({});
  const [deleteLab, setDeleteLab] = useState({});
  const [confirmationButtonLoading, setConfirmationButtonLoading] = useState(false);

  const confirmSuccessHtml = {
    title: (
      <>
        Add
        {' '}
        <span>Test Kit</span>
      </>
    ),
    confirmationText: addKitSuccessMessage,
  };

  const confirmDeleteHtml = {
    title: (
      <>
        Delete
        {' '}
        <span>Lab</span>
      </>
    ),
    confirmationText: `Please confirm deleting ${deleteLab?.lab_name} and it's other details. This action cannot be undone`,
  };

  const confirmDeactivateHtml = {
    title: (
      <>
        {updateStatusItem?.status_slug === 'pending'
        || updateStatusItem?.status_slug === 'deactivated'
          ? 'Activate'
          : 'Deactivate'}
        {' '}
        <span>{updateStatusItem?.lab_name}</span>
      </>
    ),
    confirmationText: `Please Confirm ${
      updateStatusItem?.status === 'pending'
      || updateStatusItem?.status === 'deactivated'
        ? 'activating'
        : 'deactivating'
    } ${updateStatusItem?.lab_name}`,
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fetchSelectedLabDetails = useCallback(() => {
    getSelectedLabDetails(labId)
      .then((response) => {
        setSelectedLab(response.data);
      })
      .catch(() => {});
  }, [labId]);

  const confirmDeleteLabHandler = (item) => {
    setDeleteLab(item);
    setConfirmationModal('delete-lab');
  };

  const deleteLabHandler = () => {
    setConfirmationButtonLoading(true);
    deleteSelectedLab(deleteLab?.lab_id)
      .then(() => {
        setConfirmationButtonLoading(false);
        setConfirmationModal('');
        setDeleteLab({});
        history.push('/lab-management');
      })
      .catch(() => {
        setConfirmationButtonLoading(false);
      });
  };

  const statusUpdateHandler = (item) => {
    setUpdateStatusItem(item);
    setConfirmationModal('status-update');
  };

  const confirmStatusUpdateHandler = () => {
    setConfirmationButtonLoading(true);
    updateLabStatus(updateStatusItem?.lab_id, {
      status:
        updateStatusItem?.status_slug === 'deactivated'
        || updateStatusItem?.status_slug === 'pending'
          ? 'approved'
          : 'deactivated',
    })
      .then(() => {
        setConfirmationButtonLoading(false);
        setConfirmationModal('');
        setUpdateStatusItem({});
        fetchSelectedLabDetails();
      })
      .catch(() => {
        setConfirmationButtonLoading(false);
      });
  };

  const confirmSuccessHandler = () => {
    setConfirmationModal('');
    setAddTestKitModal(true);
  };

  const addKitHandler = (formData, setError) => {
    const payload = {
      lab_id: labId,
      ...(formData?.product_id?.value
        ? { product_id: formData?.product_id?.value }
        : {}),
      price: formData?.price,
      phlebotomy_cost: formData?.phlebotomy_cost,
      turnaround_time: formData?.turnaround_time?.value,
      bio_markers: (formData?.bio_markers || []).map(
        (kitBioMarker) => kitBioMarker?.value,
      ),
      status: formData?.status?.value,
    };
    setAddKitButtonLoading(true);
    addKitVariant(payload)
      .then((response) => {
        setAddTestKitModal(false);
        setAddKitSuccessMessage(response?.data?.message);
        document.dispatchEvent(new Event(LAB_ADD_KIT_EVENT_LISTENER));
        setConfirmationModal('add-kit-success');
        setAddKitButtonLoading(false);
      })
      .catch((error) => {
        setAddKitButtonLoading(false);
        catchHandler(error, setError, true);
      });
  };

  const actionButton = (
    <UncontrolledDropdown className="button-icon__text">
      <DropdownToggle className="button__action__dot ">
        <DotIcon />
      </DropdownToggle>
      <DropdownMenu className="table__dropdown">
        <DropdownItem tag="div">
          <Button
            className="inline__list"
            onClick={() => statusUpdateHandler(selectedLab)}
          >
            {selectedLab?.status_slug === 'pending'
            || selectedLab?.status_slug === 'deactivated'
              ? 'Activate Lab'
              : 'Deactivate Lab'}
          </Button>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag="div">
          <Button
            className="inline__list red"
            onClick={() => confirmDeleteLabHandler(selectedLab)}
          >
            Delete Lab
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const getLabStatus = (slug, status) => {
    switch (slug) {
      case 'approved':
        return (
          <span className="button-icon button-icon--dropdown cursor-auto" type="button">
            <ApprovedIcon />
            <span className="button-icon__text">{status}</span>
            {actionButton}
          </span>
        );
      case 'pending':
        return (
          <span
            className="button-icon button-icon--dropdown button-icon--yellow cursor-auto"
            type="button"
          >
            <PendingIcon />
            <span className="button-icon__text">{status}</span>
            {actionButton}
          </span>
        );
      case 'deactivated':
        return (
          <span
            className="button-icon button-icon--dropdown button-icon--red cursor-auto"
            type="button"
          >
            <DeactivateIcon />
            <span className="button-icon__text">{status}</span>
            {actionButton}
          </span>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchSelectedLabDetails();
  }, [fetchSelectedLabDetails]);

  useEffect(() => {
    setModalInfo({
      title: 'Test Kit',
      lab: {
        labName: !isEmpty(selectedLab) ? selectedLab?.lab_name : '',
        description: '',
        title: '',
      },
      kit: {
        kitName: '',
        description: '',
        title: 'Select Kit Type and Name',
      },
      cancelButtonText: 'Close',
      successButtonText: 'Add Kit',
    });
  }, [selectedLab]);

  useEffect(() => {
    setHeader('lab management');
  }, [setHeader]);

  const headerSchema = Yup.object().shape({
    product_id: Yup.object().required('Test Kit is required'),
  });

  return (
    <div className="dashboard__whitebox dashboard__whitebox--wrap labmangement">
      <div className="details__header-outer">
        <div className="details__header">
          <h1 className="details__header-main">{selectedLab?.lab_name}</h1>
          <span className="details__header-sub">
            {selectedLab?.city}
            {' '}
            {selectedLab?.state}
          </span>
        </div>
        <div className="details__header-button">
          <Link to="/lab-management" className="button-icon button-icon--blue">
            <ChevronLeftIcon />
            <span className="button-icon__text">Back to List</span>
          </Link>
          <button
            className="button-icon"
            type="button"
            onClick={() => setAddTestKitModal(true)}
          >
            <AddGreenIcon />
            <span className="button-icon__text">Add Kits</span>
          </button>
          <span>
            {getLabStatus(selectedLab.status_slug, selectedLab?.status)}
          </span>
        </div>
      </div>
      <div className="tab">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => toggle('1')}
            >
              Account Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => toggle('2')}
            >
              Payout Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => toggle('3')}
            >
              General Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '4' ? 'active' : ''}
              onClick={() => toggle('4')}
            >
              Lab Managers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '5' ? 'active' : ''}
              onClick={() => toggle('5')}
            >
              Phlebotomists
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '6' ? 'active' : ''}
              onClick={() => toggle('6')}
            >
              Test Kits
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          {activeTab === '1' ? (
            <TabPane tabId="1">
              <LabAccountInfo />
            </TabPane>
          ) : (
            ' '
          )}
          {activeTab === '2' ? (
            <TabPane tabId="2">
              <LabPayoutInfo />
            </TabPane>
          ) : (
            ' '
          )}
          {activeTab === '3' ? (
            <TabPane tabId="3">
              <LabGeneralInfo handleLabDetails={fetchSelectedLabDetails} />
            </TabPane>
          ) : (
            ' '
          )}
          {activeTab === '4' ? (
            <TabPane tabId="4">
              <LabManagers labName={selectedLab?.lab_name} />
            </TabPane>
          ) : (
            ' '
          )}
          {activeTab === '5' ? (
            <TabPane tabId="5">
              <LabPhlebotomist labName={selectedLab?.lab_name} />
            </TabPane>
          ) : (
            ' '
          )}
          {activeTab === '6' ? (
            <TabPane tabId="6">
              <LabTestKits labName={selectedLab?.lab_name} />
            </TabPane>
          ) : (
            ' '
          )}
        </TabContent>

        {showAddTestKitModal ? (
          <AddEditTestKit
            modalInfo={modalInfo}
            isKitSelect
            mergeSchema={headerSchema}
            hideModal={() => setAddTestKitModal(false)}
            onSuccessHandler={(formData, setError) => addKitHandler(formData, setError)}
            addEditButtonLoading={addKitButtonLoading}
          />
        ) : (
          ''
        )}
        {showConfirmationModal === 'add-kit-success' ? (
          <ConfirmationModal
            hideModal={() => setConfirmationModal(false)}
            renderHtml={confirmSuccessHtml}
            successButtonText=" Add Another Test Kit"
            cancelButtonText="Done"
            successHandler={() => confirmSuccessHandler()}
          />
        ) : (
          ''
        )}
        {showConfirmationModal === 'delete-lab' ? (
          <ConfirmationModal
            hideModal={() => {
              setConfirmationModal('');
              setDeleteLab({});
            }}
            renderHtml={confirmDeleteHtml}
            btnColor="primary"
            type="deleteModal"
            cancelButtonText="Close"
            isLoading={confirmationButtonLoading}
            successButtonText=" Delete Lab"
            successHandler={() => deleteLabHandler()}
          />
        ) : (
          ''
        )}
        {showConfirmationModal === 'status-update' ? (
          <ConfirmationModal
            hideModal={() => setConfirmationModal('')}
            renderHtml={confirmDeactivateHtml}
            successButtonText="Confirm"
            successHandler={() => confirmStatusUpdateHandler()}
            cancelButtonText="Cancel"
            isLoading={confirmationButtonLoading}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default LabManagementDetails;
